import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/models/taxonomic-name-parts";
import type { FC } from "react";
import { Taxonomy } from "../taxonomy";

const TOOLTIP_TEXT = "Click to view audience profile";

interface IAudienceNameCell {
  audienceName: string;
  audienceId: string;
  taxonomy: ITaxonomicNamePart[];
}

const AudienceNameCell: FC<IAudienceNameCell> = ({ audienceId, audienceName, taxonomy }) => (
  <Tooltip title={TOOLTIP_TEXT}>
    <div>
      <div>
        <Link className="no-style" to={`/audiences/${audienceId}`}>
          {audienceName}
        </Link>
      </div>
      <Taxonomy friendlyId={audienceId} segments={taxonomy} />
    </div>
  </Tooltip>
);

export default AudienceNameCell;
