import { Button, Tooltip } from "@mui/material";
import "./action-button.scss";

interface IActionButtonProps {
  isDisabled: boolean;
  disabledMessage: string | null;
  onClick: () => void;
  icon: any;
  buttonText: string;
  id: string;
  classes?: string;
}

export function ActionButton({
  isDisabled,
  disabledMessage,
  onClick,
  icon,
  buttonText,
  id,
  classes = "",
}: IActionButtonProps) {
  const button = (
    <Button
      id={id}
      disabled={isDisabled}
      variant="text"
      onClick={() => {
        onClick();
      }}
      className={classes}
    >
      {icon} {buttonText}
    </Button>
  );
  return isDisabled ? (
    <Tooltip title={disabledMessage || ""}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}
