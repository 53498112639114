import { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";
import type { IAllowedRangeConstraint, IDimension } from "../../demographic-selector/models/dimensions";

const expectedConstraintId = "allowedRangeConstraint";

export class RangeCondition extends QueryCondition {
  public readonly NAME: string = "RangeCondition";

  private readonly rangeConstraint: IAllowedRangeConstraint;

  constructor(
    public readonly dimension: IDimension,
    private lowerBound: number = 0,
    private upperBound: number = 0,
    protected parent: QueryCondition | null = null,
  ) {
    super(parent);

    this.rangeConstraint = dimension.metadata.constraints.find(
      (c) => c.constraintType === "allowedRangeConstraint",
    ) as IAllowedRangeConstraint;

    if (!this.rangeConstraint) {
      throw new Error(`No constraint with constraint type ${expectedConstraintId} was found on the loaded dimension`);
    }

    this.lowerBound = lowerBound || this.rangeConstraint.lowerBound;
    this.upperBound = upperBound || this.rangeConstraint.upperBound;
  }

  setRangeLowerBound(lowerBound: number) {
    this.lowerBound = lowerBound;
  }

  getRangeLowerBound() {
    return this.lowerBound;
  }

  setRangeUpperBound(lowerBound: number) {
    this.upperBound = lowerBound;
  }

  getRangeUpperBound() {
    return this.upperBound;
  }

  getMaximumAllowed() {
    return this.rangeConstraint.upperBound;
  }

  getMinimumAllowed() {
    return this.rangeConstraint.lowerBound;
  }

  getValidationState(): ConditionValidationState {
    if (this.lowerBound > this.upperBound) {
      return new ConditionValidationState(false, "The lower age bound cannot be above the upper age bound");
    }

    if (this.lowerBound === 0 && this.upperBound === 0) {
      return new ConditionValidationState(false, "Please choose a non-zero age range");
    }

    if (this.lowerBound < this.rangeConstraint.lowerBound || this.upperBound > this.rangeConstraint.upperBound) {
      return new ConditionValidationState(
        false,
        `Please ensure the age range is between ${this.rangeConstraint.lowerBound} and ${this.rangeConstraint.upperBound}`,
      );
    }

    return new ConditionValidationState(true);
  }

  isParentOf(): boolean {
    return false;
  }

  getConditionData(): AudienceDefinitionData {
    return new AudienceDefinitionData(
      { lowerBound: this.lowerBound, upperBound: this.upperBound, dimensionId: this.dimension.id },
      null,
      null,
      null,
      "range",
    );
  }
}
