import "./login-controls.scss";

import { MenuItem, Tooltip } from "@mui/material";
import type { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import type { OnAuthRequiredFunction } from "@okta/okta-react/bundles/types/OktaContext";
import { WppIconNotificationOff, WppIconNotificationOn } from "@platform-ui-kit/components-library-react";
import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import { useIdentity } from "@vmlyr/appserviceshared/dist/hooks/useIdentity";
import type { OktaGroup } from "@vmlyr/common/types/okta-group";
import { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthenticatedUser } from "../../app-insights";
import ConfigurationHelper from "../../helpers/configuration-helper";
import ContextMenu from "../context-menu";

function GroupOptions() {
  const navigate = useNavigate();
  const { getCurrentIdentity } = useIdentity();
  const baseGroupName: OktaGroup = ConfigurationHelper.GetAudienceToolGroups().oktaGroupName;

  // ensuring groups are in a consistent order; we use this for priority
  const claimsGroups = getCurrentIdentity()?.audienceToolGroups;

  claimsGroups?.sort();

  const { setAudienceToolGroup, getAudienceToolGroup } = useAudienceToolGroup();
  const [currentAudienceGroup, setCurrentAudienceGroup] = useState<string | null>(getAudienceToolGroup());
  const [navigateTo, setNavigateTo] = useState("");

  useEffect(() => {
    if (claimsGroups && getAudienceToolGroup()) {
      setCurrentAudienceGroup(getAudienceToolGroup());
    }
    if (claimsGroups && claimsGroups.length && !getAudienceToolGroup()) {
      setCurrentAudienceGroup(claimsGroups[0]);
      setAudienceToolGroup(claimsGroups[0]);
    }
    if (window.location.pathname === "/login/callback") {
      navigate("/home");
    }
    if (navigateTo !== "") {
      navigate(navigateTo);
      setNavigateTo("");
    }
  }, [claimsGroups]);

  // If there are no claims groups yet a current claims group is set, it means the user has been removed from their selected group.
  // The group selection should be cleared and no options rendered
  if (!claimsGroups && currentAudienceGroup) {
    setCurrentAudienceGroup(null);
  }

  // If there is only a single group, simply select that group and show no dropdown
  if (claimsGroups && claimsGroups.length === 1 && !currentAudienceGroup) {
    setAudienceToolGroup(claimsGroups[0]);
    setCurrentAudienceGroup(claimsGroups[0]);
  }

  if (!claimsGroups || claimsGroups.length < 2) {
    return <></>;
  }

  const handleGroupSelected = (groupName: string) => {
    localStorage.clear();
    setCurrentAudienceGroup(groupName);
    setAudienceToolGroup(groupName);
    setNavigateTo(currentAudienceGroup === groupName ? window.location.pathname : "/home");
  };

  const groups = claimsGroups.map((groupName: string) => {
    // Remove the defaultGroup name plus hyphen from groupName, plus blank ends up 'Ford'
    // Example: 'WPP', 'WPP-Demo' and 'WPP-Test' would become 'Ford', 'Demo' and 'Test'
    let option = groupName.replace(baseGroupName, "").replace(/^-/, "");
    option = option === "" ? "Ford" : option;

    const currentGroupIcon = getAudienceToolGroup() === groupName ? "«" : "";

    return {
      component: (
        <MenuItem
          key={option}
          className="ul-right"
          onClick={() => {
            handleGroupSelected(groupName);
          }}
        >
          <span className="group-option">
            {option} {currentGroupIcon}
          </span>
        </MenuItem>
      ),
      name: option,
    };
  });

  return (
    <div className="group-options-wrapper">
      <ContextMenu className="group-options-context-menu" menuClassName="menu-group-options" disableScrollLock>
        {groups.sort((g) => g.name.length).map((g) => g.component)}
      </ContextMenu>
    </div>
  );
}

interface LoginProps {
  oktaAuth: OktaAuth;
  authState: AuthState;
  _onAuthRequired: OnAuthRequiredFunction;
  AdminAccess: boolean;
  NotificationMessages: string[];
}

interface LoginState {}

function Notifications({ notificationsMsgs }: { notificationsMsgs: string[] }) {
  const navigate = useNavigate();
  if (!notificationsMsgs || notificationsMsgs.length === 0) {
    return (
      <div className="notification-bell">
        <WppIconNotificationOff />
      </div>
    );
  }

  function TooltipMsgs() {
    return (
      <>
        {notificationsMsgs.map((message: string, i: number) => (
          <div key={i} style={{ textAlign: "center" }}>
            {message}
          </div>
        ))}
      </>
    );
  }

  return (
    <Tooltip title={<TooltipMsgs />}>
      <div className="notification-bell">
        <WppIconNotificationOn
          className="wiggle"
          onClick={() => {
            navigate("/admin");
          }}
        />
      </div>
    </Tooltip>
  );
}

export default withOktaAuth(
  class LoginControls extends Component<LoginProps, LoginState> {
    render(): JSX.Element | null {
      const authState = this.props.authState;

      if (authState?.isAuthenticated) {
        setAuthenticatedUser(authState.idToken!.claims.email);
        const nameFirstLetter = authState.idToken!.claims.name?.[0];

        return (
          <>
            <Notifications notificationsMsgs={this.props.NotificationMessages} />
            <div className="user-initial">
              <div className="first-letter fill-primary">{nameFirstLetter}</div>
              <GroupOptions />
            </div>
          </>
        );
      }
      return null;
    }
  },
);
