import "./demographic-accordion.scss";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { AccordionProps, AccordionSummaryProps } from "@mui/material";
import { Accordion, AccordionSummary, styled } from "@mui/material";

const accordion = (props: AccordionProps): JSX.Element => (
  <Accordion disableGutters elevation={0} square slotProps={{ transition: { unmountOnExit: true } }} {...props} />
);

const accordionStyles = (): Record<string, unknown> => ({
  backgroundColor: "transparent",
  border: "0",
  "&:before": {
    display: "none",
  },
});

export const AudienceToolAccordion = styled(accordion)(accordionStyles);

const accordionSummary = (props: AccordionSummaryProps): JSX.Element => (
  <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
);

const summaryStyles = (): Record<string, unknown> => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
});

export const AudienceToolAccordionSummary = styled(accordionSummary)(summaryStyles);
