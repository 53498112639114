"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxonomySegment = void 0;
class TaxonomySegment {
    value;
    segmentName;
    constructor(value, segmentName) {
        this.value = value;
        this.segmentName = segmentName;
    }
    static fromExisting(existing) {
        return new TaxonomySegment(existing.value, existing.segmentName);
    }
}
exports.TaxonomySegment = TaxonomySegment;
