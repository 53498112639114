import { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";
import type {
  IAllowedMultiRangeConstraint,
  IAllowedRangeConstraint,
  IDimension,
} from "../../demographic-selector/models/dimensions";

const expectedConstraintId = "allowedRangeConstraint";

export class MultiRangeCondition extends QueryCondition {
  public readonly NAME: string = "multiRangeCondition";

  private readonly rangeConstraint: IAllowedMultiRangeConstraint;

  private valuesMap: any = {};

  private value: string[] = [];

  constructor(
    public readonly dimension: IDimension,
    values: IAllowedRangeConstraint[] = [],
    protected parent: QueryCondition | null = null,
  ) {
    super(parent);

    this.rangeConstraint = dimension.metadata.constraints.find(() => true) as IAllowedMultiRangeConstraint;

    if (!this.rangeConstraint) {
      throw new Error(`No constraint with constraint type ${expectedConstraintId} was found on the loaded dimension`);
    }

    const filteredValues = values.map((e) => `${e.lowerBound}-${e.upperBound}`) || [];
    this.value = filteredValues;
  }

  getAllowedValues(): string[] {
    for (const range of this.rangeConstraint.allowedValues) {
      this.valuesMap[`${range.lowerBound}-${range.upperBound}`] = range;
    }
    return this.valuesMap;
  }

  getValidationState(): ConditionValidationState {
    if (this.value.length === 0) {
      return new ConditionValidationState(false, "Please make a selection");
    }
    return new ConditionValidationState(true);
  }

  isParentOf(): boolean {
    return false;
  }

  setValue(chosenValue: string[]) {
    this.value = chosenValue;
  }

  getValue(): string[] {
    return this.value;
  }

  getFormattedValues() {
    return this.value.map((e) => this.valuesMap[e]);
  }

  getConditionData(): AudienceDefinitionData {
    return new AudienceDefinitionData(
      { values: this.getFormattedValues(), dimensionId: this.dimension.id },
      null,
      null,
      null,
      "multirange",
    );
  }
}
