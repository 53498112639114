import type { GridCallbackDetails, GridSortModel } from "@mui/x-data-grid-pro";

export function handleSortModelChange(model: GridSortModel, details: GridCallbackDetails): void {
  if (model.length === 0) {
    details.api.setSortModel([
      { field: "pinned", sort: "desc" },
      { field: "sortableDate", sort: "desc" },
    ]);
  }

  if (model.length > 0 && model.every((m) => m.field !== "pinned")) {
    details.api.setSortModel([{ field: "pinned", sort: "desc" }, ...model]);
  }
}
