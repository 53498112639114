import { Container } from "@mui/material";
import type React from "react";
import "../confirmationCard/confirmation-card.scss";
import { ReactComponent as DeactivateIcon } from "../../../assets/icon-deactivate.svg";

const DeactivationCard: React.FC = () => {
  const iconDimensions = {
    width: 40,
    height: 40,
    color: "#8508E8",
  };
  return (
    <Container className="confirmation-container" maxWidth="sm">
      <div className="confirmation-container-inner">
        <DeactivateIcon {...iconDimensions} />
        <h2>Access Denied</h2>
        <p>You don't have the right permissions to access this page.</p>
        <p>
          <a id="support" href="mailto:connekd@wpp.com?subject=Connekd%20support%20request">
            Get support
          </a>{" "}
          for role changes
        </p>
      </div>
    </Container>
  );
};

export default DeactivationCard;
