const distributeAudienceKey = "distributed_audiences";

const getOngoingDistributes = () => {
  const existingDistribute = localStorage.getItem(distributeAudienceKey);
  return existingDistribute ? JSON.parse(existingDistribute) : [];
};

const addOngoingDistribute = (audience: any) => {
  let existingItem = localStorage.getItem(distributeAudienceKey);
  const thisData = {
    audienceId: audience.id,
    audienceName: audience.name,
    status: "ready",
  };

  if (!existingItem) {
    existingItem = JSON.stringify([]);
    localStorage.setItem(distributeAudienceKey, JSON.stringify(thisData));
  }

  const existingStr: string = JSON.stringify(existingItem);
  const existingObj: any[] = JSON.parse(existingItem);
  if (!existingStr.includes(audience.id)) {
    existingObj.push(thisData);
  }

  localStorage.setItem(distributeAudienceKey, JSON.stringify(existingObj));
};

const deleteOngoingDistribute = (distributeAudienceKey: string, audienceId: string) => {
  // @ts-expect-error
  const lsData = JSON.parse(localStorage.getItem(distributeAudienceKey));
  const thisIndex = lsData.findIndex((e: any) => e.audienceId === audienceId);
  lsData.splice(thisIndex, 1);
  localStorage.setItem(distributeAudienceKey, JSON.stringify(lsData));
};

export const useDistributes = () => ({
  addOngoingDistribute,
  getOngoingDistributes,
  deleteOngoingDistribute,
});
