import "../../styles/main.scss";
import "./admin.scss";

import { Alert, Button, Grid, Modal } from "@mui/material";
import { WppButton, WppIconExport } from "@platform-ui-kit/components-library-react";
import { CenteredProgress } from "@vmlyr/appserviceshared/dist/components/centered-progress";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import { userDetails } from "@vmlyr/connekdfordshared/dist/config/userDetails";
import type { ISearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/user-search-results-model";
import { SearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/user-search-results-model";
import debounce from "lodash.debounce";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { ControlHeader } from "../../components/control-header";
import { Loadable } from "../../components/loadable";
import { Tabber } from "../../components/tabber/Tabber";

import ConfigurationHelper from "../../helpers/configuration-helper";
import { LeftMultiselect } from "./components/left-multiselect";
import { Search } from "./components/search";
import { SearchModel } from "./components/search/components/search-filters/models/search-model";
import { UserActions } from "./components/user-actions";
import SaveUsersModal from "./components/user-actions/user-actions-modals/save-users-modal";
import { UserTable } from "./components/user-table";
import UserTableDefinition from "./components/user-table-model";
import type { IuserData } from "./models/userModel";

const debounceTime = 200;
const pageSize = 500;
const activeStatusString = "Active";
const inactiveStatusString = "Inactive";

export interface ICreateUserContext {
  onUserDetailsReceived: (user: any) => void;
}

export const ViewUsersContext = createContext<ICreateUserContext | null>(null);

export default function Admin() {
  const [searchType, setSearchType] = useState<string>("All");
  const [selectedUsers, setSelectedUsers] = useState<IuserData[]>([]);
  const [searchResult, setSearchResult] = useState<SearchResultsModel | null>(null);
  const [searchModel, setSearchModel] = useState<SearchModel>(new SearchModel());
  const [debouncedSearchModel, setDebouncedSearchModel] = useState<SearchModel | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResultPage, setSearchResultPage] = useState(0);
  const [error, setError] = useState("");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [previousScrollHeight, setPreviousScrollHeight] = useState<number | null>();
  const hasOutstandingResults = searchResult && searchResult.results.length < searchResult.count;
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);

  const handleOpenUserDetailsModal = () => {
    setOpenUserDetailsModal(true);
  };
  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  const performSearch = () => {
    if (searchModel.getValidationState().isValid) {
      if (!searchModel.isEmpty()) {
        setIsSearching(true);
      }
      performUserQuery("searching");
      if (previousScrollHeight) {
        window.scrollTo(0, previousScrollHeight);
        setPreviousScrollHeight(null);
      }
    }
  };

  useEffect(() => {
    performSearch();
  }, [debouncedSearchModel]);

  useEffect(() => {
    if (isScrolledToBottom && hasOutstandingResults) {
      performSearch();
    }
  }, [isScrolledToBottom]);

  useEffect(() => {
    performSearch();
  }, [searchType]);

  const handleUsersSelected = (selectedUsers: any) => {
    setSelectedUsers(selectedUsers);
  };

  const exportUsers = () => {
    postWithToken(ConfigurationHelper.GetExportUsersEndpoint(), undefined)
      .then(async (response) => await response.blob())
      .then((blob) => {
        if (blob != null) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "users-export.xlsx";
          document.body.append(a);
          a.click();
          a.remove();
        }
      })
      .catch((err) => {
        setError(`An error occurred whilst exporting users: ${err}`);
      });
  };

  const context: ICreateUserContext = useMemo(
    () => ({
      onUserDetailsReceived: () => {},
    }),
    [searchResult],
  );

  const handleSearchParamsInput = (newSearchParams: SearchModel) => {
    if (newSearchParams.isEmpty()) {
      setSearchModel(new SearchModel());
      setSearchResult(null);
      setSearchResultPage(0);
    } else {
      const newModel = SearchModel.fromExisting(newSearchParams);
      setSearchResultPage(0);
      setSearchModel(newModel);
    }
    launchSearch(newSearchParams);
  };

  const updateMultipleUsers = (usersToUpdate: any, action: string, searchResult: any) => {
    // If not viewing "All" users, they'd have disappeared from this view
    const searchOption = document.querySelector("button.tab.active");
    if (searchOption?.innerHTML !== "All") return searchResult.results.filter((a: any) => !usersToUpdate.includes(a));

    return searchResult.results.map((currentStateItem: any) => {
      const matchingItem = usersToUpdate.find((userToUpdate: any) => currentStateItem.id === userToUpdate.id);
      if (matchingItem) {
        switch (action) {
          case "reactivate": {
            matchingItem.status = activeStatusString;
            matchingItem.status_num = userDetails.userStatus.Active;
            break;
          }
          case "deactivate": {
            matchingItem.status = inactiveStatusString;
            matchingItem.status_num = userDetails.userStatus.Inactive;
            break;
          }
        }
      }
      return matchingItem || currentStateItem;
    });
  };

  const updateSingleUser = (user: any, action: string, searchResult: any) => {
    // If not viewing "All" users, they'd have disappeared from this view
    const searchOption = document.querySelector("button.tab.active");
    if (action === "approved" && searchOption?.innerHTML !== "All")
      return searchResult.results.filter((a: any) => a.id !== user.id);
    return searchResult.results.map((currentStateItem: any) => {
      if (user.id !== currentStateItem.id) {
        return currentStateItem;
      }
      return { ...currentStateItem, ...user };
    });
  };

  // eslint-disable-next-line
  const launchSearch = useCallback(
    debounce((updatedModel: SearchModel) => {
      setDebouncedSearchModel(updatedModel);
    }, debounceTime),
    [],
  );

  const performUserQuery = (action?: string, user?: any) => {
    let searchUrl = ConfigurationHelper.GetUsersEndpoint();
    searchUrl = addQueryParams(
      searchUrl,
      `pageSize=${pageSize}`,
      `page=${searchResultPage}`,
      `text=${searchModel.text}`,
      `searchType=${searchType.toLowerCase()}`,
    );
    for (const key of searchModel.filters.keys()) {
      searchUrl = addQueryParams(
        searchUrl,
        `${key}=${searchModel.filters
          .get(key)
          ?.map((f) => f.code)
          .join(",")}`,
      );
    }
    postWithToken(searchUrl, undefined)
      .then(async (response) => await response.json())
      .then((data) => {
        const searchResultsResponse = {
          results: data.data.recordsets[0],
          count: data.data.numUsers,
        } as ISearchResultsModel;
        const searchResultsModel = SearchResultsModel.fromExisting(searchResultsResponse);
        if (searchResultPage === 0) {
          setSearchResult(searchResultsModel);
        } else {
          const existingIds = new Set(searchResult!.results.map((r: any) => r.id));
          const newResults = [...searchResult!.results];
          for (const result of searchResultsModel.results) {
            // @ts-expect-error
            if (!existingIds.has(result.id)) {
              newResults.push(result);
            }
          }
          // @ts-expect-error
          setSearchResult(new SearchResultsModel(newResults, searchResultsModel.count));
        }
        setSearchResultPage(searchResultPage + 1);
      })
      .then(async () => {
        if (searchResult) {
          let newResults: any;
          if (action === "delete") {
            const usersToDelete = new Set(selectedUsers);
            newResults = searchResult.results.filter((a: any) => !usersToDelete.has(a));
          }
          if (action === "reactivate" || action === "deactivate") {
            newResults = updateMultipleUsers([...selectedUsers], action, searchResult);
          }
          if (action && ["edited", "welcomed", "trained", "approved"].includes(action)) {
            newResults = updateSingleUser(user, action, searchResult);
          }
          if (newResults && newResults.length > 0) {
            setSearchResult({
              ...searchResult,
              results: newResults.sort(
                (a: any, b: any) => a.status_num - b.status_num || a.email.localeCompare(b.email),
              ),
              count: newResults.length,
            });
            setSelectedUsers([]);
          } else if (selectedUsers.length > 0) {
            setSearchResult({ results: [], count: 0 });
            setSelectedUsers([]);
          }
        }
      })
      .catch((err) => {
        setError(`An error occurred whilst retrieving the user list: ${err}`);
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  const handleScroll = (isAtBottom: boolean, ref: React.RefObject<HTMLDivElement>) => {
    setIsScrolledToBottom(isAtBottom);
    if (isAtBottom) {
      setPreviousScrollHeight(ref.current?.offsetTop);
    }
  };

  const handleSearchTypeSelected = (searchType: string) => {
    setSearchType(searchType);
    setIsScrolledToBottom(true);
    setSearchResultPage(0);
  };

  const controlUserActions =
    selectedUsers.length > 0 ? (
      <UserActions
        selectedUsers={selectedUsers}
        reloadUserTable={performUserQuery}
        setSelectedUsers={setSelectedUsers}
      />
    ) : null;
  return (
    <div className="admin-page">
      <ViewUsersContext.Provider value={context}>
        <Grid container className="header">
          <Grid item xs={6}>
            <div>
              <h1 className="header-font">Admin</h1>
            </div>
          </Grid>
        </Grid>
        <div className="users-container-wrapper">
          <Grid item container>
            <Grid item xs={3}>
              <div className="taxonomies-container">
                <LeftMultiselect />
              </div>
            </Grid>
            <Grid item xs={9} className="user-management-container">
              <div className="user-management-list">
                <div className="table-container">
                  <div className="audience-table">
                    <Grid container>
                      <Grid className="header-column-left" item>
                        <div className="grid-container">
                          <h4>Users</h4>
                        </div>
                      </Grid>
                      <Grid className="header-column-right" item>
                        <div className="grid-container">
                          <WppButton
                            variant="secondary"
                            onClick={() => {
                              exportUsers();
                            }}
                          >
                            <WppIconExport slot="icon-start" />
                            Export
                          </WppButton>
                        </div>
                      </Grid>
                      <Grid className="header-column-right" item>
                        <Button
                          className="add-user-btn fill-primary"
                          type="submit"
                          onClick={handleOpenUserDetailsModal}
                        >
                          Add User
                        </Button>
                      </Grid>
                    </Grid>
                    <ControlHeader
                      leftControl={<Search onSearch={handleSearchParamsInput} searchModel={searchModel} />}
                      rightControl={
                        <Tabber
                          tabs={["All", "Pending", "Active", "Inactive"]}
                          selectedOption={searchType}
                          onOptionSelected={handleSearchTypeSelected}
                        />
                      }
                    />
                    {controlUserActions}
                    {!error && (
                      <Loadable
                        loadingObject={searchResult}
                        loadedComponent={() => (
                          <div className={isSearching ? "user-table-refreshing" : "user-table"}>
                            <UserTable
                              userList={searchResult!.results}
                              allowPaging={false}
                              selectedUsers={selectedUsers}
                              onUsersSelected={handleUsersSelected}
                              reloadUserTable={performUserQuery}
                              onScroll={handleScroll}
                              isLoadingMore={isSearching && searchResultPage > 0}
                              columnDefinition={UserTableDefinition(performUserQuery, setSelectedUsers, searchResult)}
                            />
                          </div>
                        )}
                      />
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                    {isScrolledToBottom && hasOutstandingResults && <CenteredProgress />}
                  </div>
                  <Modal
                    open={openUserDetailsModal}
                    onClose={handleCloseUserDetailsModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <SaveUsersModal
                      user=""
                      modalState={setOpenUserDetailsModal}
                      reloadUserTable={performUserQuery}
                      variant="save"
                    />
                  </Modal>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </ViewUsersContext.Provider>
    </div>
  );
}
