import "../table.scss";

import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import type { DataGridProProps, GridSelectionModel } from "@mui/x-data-grid-pro";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import type { Profiles as IProfiles } from "@vmlyr/connekdfordshared/dist/models/api/audience-profiles";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { ControlHeader } from "../../components/control-header";
import { getFilteredProfilesList } from "../../connekd-api/audience-profiles/get-all-profiles";
import { useUserStore } from "../../stores/user";
import { CreateProfileModal } from "./components/create-profile-modal";
import { SearchProfiles } from "./components/search";
import { ProfilesTable } from "./components/table/profiles-table";
import { TableActionButtons } from "./components/table/table-action-buttons";

const DEBOUNCE_TIME = 800;

export const Profiles = (): JSX.Element => {
  const [newProfilesModalHidden, setNewProfilesModalHidden] = useState<boolean>(true);
  const [profilesResults, setProfilesResult] = useState<IProfiles[] | []>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedProfiles, setSelectedProfiles] = useState<IProfiles[]>([]);
  const [searchResultPage, setSearchResultPage] = useState(0);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [canDeleteProfile, setCanDeleteProfile] = useState<boolean>(false);
  const [totalProfilesCount, setTotalProfilesCount] = useState<number>(0);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [profileNameModal, setProfileNameModal] = useState<string>("");
  const [pinnedRows, setPinnedRows] = useState<IProfiles[]>([]);
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);
  const [hasDeleted, setHasDeleted] = useState<boolean>(false);

  const apiRef = useGridApiRef();

  const [isLoading, setIsLoading] = useState(false);

  const isUserAdmin = useUserStore.isAdmin();
  const authenticatedUser = useUserStore.authenticatedUser();

  const performSearch = useCallback(
    async (page: number = 0, term: string = ""): Promise<void> => {
      setIsLoading(true);
      try {
        const res = await getFilteredProfilesList(page, term);
        if (page === 0) {
          setProfilesResult(
            res.data.map((profile) => {
              return { ...profile, sortableDate: profile?.lastModified || profile.createdAtDate };
            }),
          );
        }
        if (page !== 0) {
          const responseWithSortableDate = res.data.map((profile) => {
            return { ...profile, sortableDate: profile?.lastModified || profile.createdAtDate };
          });
          setProfilesResult((prev) => [...prev, ...responseWithSortableDate]);
        }
        if (term !== searchTerm) {
          apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
        }
        setTotalProfilesCount(res.count);
        setLoadError(null);
      } catch (err) {
        console.log(`Unable to fetch profile list due to: ${err}`);
      } finally {
        setIsLoading(false);
        setHasDeleted(false);
      }
    },
    [apiRef, searchTerm],
  );

  const debouncedPerformSearch = useCallback(
    debounce(async (page: number, term: string = "") => {
      await performSearch(page, term);
    }, DEBOUNCE_TIME),
    [searchTerm],
  );

  useEffect(() => {
    performSearch();
  }, []);

  const handleSearchTermChange = async (newTerm: string): Promise<void> => {
    if (newTerm.trim() === "") {
      setSearchTerm("");
      setSearchResultPage(0);
      await debouncedPerformSearch(0);
    } else {
      setSearchTerm(newTerm);
      setSearchResultPage(0);
      await debouncedPerformSearch(0, newTerm);
    }
  };

  const handleProfiles = (profiles: IProfiles[]): void => {
    const userCanDeleteProfiles = isUserAdmin || profiles.every((p) => p.ownerEmail === authenticatedUser?.email);
    setCanDeleteProfile(profiles.length > 0 && userCanDeleteProfiles);
    setSelectedProfiles(profiles);
  };

  const resetTable = () => {
    setPinnedRows([]);
    setSelectedProfiles([]);
    setSelectedIds([]);
    setHasDeleted(true);
    performSearch();
  };

  const handleTableScroll: DataGridProProps["onRowsScrollEnd"] = async (params) => {
    if (totalProfilesCount === params.virtualRowsCount) return;
    if (profilesResults.length <= params.virtualRowsCount && !isLoading && !hasDeleted) {
      setSearchResultPage((prev) => prev + 1);
      await performSearch(searchResultPage + 1, searchTerm);
    }
    if (profilesResults.length <= params.virtualRowsCount && !isLoading && hasDeleted) {
      await performSearch(searchResultPage, searchTerm);
    }
  };

  const handleDuplicateActionButton = () => {
    setIsDuplicated(true);
    setNewProfilesModalHidden(false);
    setProfileNameModal(selectedProfiles[0].profileName);
  };

  return (
    <div className="table-list">
      {!newProfilesModalHidden && (
        <CreateProfileModal
          onClose={() => {
            setNewProfilesModalHidden(true);
            setIsDuplicated(false);
            setProfileNameModal("");
          }}
          isDuplicated={isDuplicated}
          selectedProfiles={selectedProfiles}
          profileNameModal={profileNameModal}
          setProfileNameModal={setProfileNameModal}
        />
      )}

      <Grid container className="header">
        <Grid item xs={6}>
          <div className="margin-left">
            <h1>Profiles</h1>
          </div>
        </Grid>
        <Grid className="header-column-right" item xs={6}>
          <Button
            className="new-profile-button fill-primary"
            onClick={() => {
              setNewProfilesModalHidden(false);
            }}
          >
            New profile
          </Button>
        </Grid>
      </Grid>

      <div className="table-container-wrapper">
        <Stack className="table-container">
          <ControlHeader
            leftControl={
              <>
                <SearchProfiles onSearch={handleSearchTermChange} searchTerm={searchTerm} />
                {selectedProfiles.length > 0 && (
                  <Stack flexDirection="row" alignItems="flex-start">
                    <div className="selected-profiles">{`${selectedProfiles.length} profile${selectedProfiles.length === 1 ? "" : "s"} selected`}</div>
                    <TableActionButtons
                      onDeleteCallback={resetTable}
                      selectedProfiles={selectedProfiles}
                      canDeleteProfile={canDeleteProfile}
                      handleDuplicateActionButton={handleDuplicateActionButton}
                    />
                  </Stack>
                )}
              </>
            }
            rightControl={<></>}
          />

          {loadError === null && (
            <div className={isLoading ? "data-grid-table-refreshing" : "data-grid-table"}>
              <ProfilesTable
                profilesResults={profilesResults}
                onProfilesSelected={handleProfiles}
                isLoading={isLoading}
                handleTableScroll={handleTableScroll}
                apiRef={apiRef}
                pinnedRows={pinnedRows}
                setPinnedRows={setPinnedRows}
                setSelectedIds={setSelectedIds}
                selectedIds={selectedIds}
              />
            </div>
          )}
        </Stack>
      </div>
    </div>
  );
};
