import "./profile-modal.scss";

import { SecondaryButton } from "@connekd/ui-components";
import type { AutocompleteRenderInputParams } from "@mui/material";
import { Autocomplete, Box, Modal, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import type { TextInputChangeEventDetail } from "@platform-ui-kit/components-library";
import { WppIconSearch, WppLabel, WppTextInput } from "@platform-ui-kit/components-library-react";
import type { WppTextInputCustomEvent } from "@platform-ui-kit/components-library/dist/types/components";
import type { IAudienceOverview } from "@vmlyr/appserviceshared/models/audience-overview";
import type { Profiles } from "@vmlyr/connekdfordshared/models/api/audience-profiles";
import debounce from "lodash.debounce";
import type { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/primary-button";
import { Taxonomy } from "../../../components/taxonomy";
import { CreateAudienceProfilePayload, createProfile } from "../../../connekd-api/audience-profiles/create-profile";
import { searchAudiencesForProfiles } from "../../../connekd-api/audiences/search-for-profiles";
import { SearchType } from "../../../connekd-api/audiences/search-type";
import { ProfileView } from "../../audiences/profile/models/profile-view";
import { style } from "../../audiences/profile/styles/modal-style";

interface Props {
  onClose: () => void;
  isDuplicated: boolean;
  selectedProfiles: Profiles[] | ProfileView;
  setProfileNameModal: Dispatch<SetStateAction<string>>;
  profileNameModal: string;
}

const DEBOUNCE_TIME = 300;

export function CreateProfileModal(props: Props): JSX.Element {
  const { onClose, isDuplicated, selectedProfiles, setProfileNameModal, profileNameModal } = props;

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dupicatedAudienceName, setDupicatedAudienceName] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedAudience, setSelectedAudience] = useState<IAudienceOverview | null>(null);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [firstSelectedAudienceUpdate, setFirstSelectedAudienceUpdate] = useState<boolean>(false);
  const [madeTheFirstRequest, setMadeTheFirstRequest] = useState<boolean>(false);

  useEffect(() => {
    if (isDuplicated && !madeTheFirstRequest && Array.isArray(selectedProfiles)) {
      const duplicatedAudienceName = selectedProfiles[0].audience.name;
      handleSearchAudiencesChanged(duplicatedAudienceName);
      setDupicatedAudienceName(duplicatedAudienceName);
      setProfileNameModal(`Copy of ${selectedProfiles[0]?.profileName}`);
      setMadeTheFirstRequest(true);
    }
  }, []);

  useEffect(() => {
    if (isDuplicated && !firstSelectedAudienceUpdate && !!searchResults.length) {
      setSelectedAudience(searchResults[0]);
      setFirstSelectedAudienceUpdate(true);
    }
  }, [searchResults]);

  const handleNameChange = (event: WppTextInputCustomEvent<TextInputChangeEventDetail>) => {
    setProfileNameModal(event.target.value);
  };

  const handleSearchAudiencesChanged = useCallback(async (input: string) => {
    if (input !== "") {
      try {
        const res = await searchAudiencesForProfiles(0, SearchType.All, input);
        setSearchResults(res.results ?? []);
        return;
      } catch (err) {
        console.log(`An error occurred whilst retrieving the search results: ${err}`);
      }
    }
    setSearchResults([]);
    setDupicatedAudienceName("");
    setSelectedAudience(null);
  }, []);

  const handleCreate = async () => {
    if (!isValid) return;

    const dimensions = Array.isArray(selectedProfiles) ? selectedProfiles[0]?.dimensions : selectedProfiles.dimensions;
    const viewToSave: CreateAudienceProfilePayload = {
      name: profileNameModal,
      audienceId: selectedAudience.id,
      dimensions: dimensions ?? [],
    };

    try {
      const response = await createProfile(viewToSave);
      onClose();
      navigate(`/audiences/${selectedAudience.id}?profile=${response.id}`);
    } catch (e) {
      console.log(`Profile was not create due to the following error: ${e}`);
    }
  };

  const isValid = profileNameModal.length > 0 && searchResults.length > 0 && selectedAudience != null;

  const handleChange = (_: SyntheticEvent, value: IAudienceOverview | null): void => {
    setSelectedAudience(value);
    setOpenDialogue(false);
  };
  const handleInputChange = async (input: string) => {
    setSearchTerm(input);
    await debouncedFetchResults(input);
    setDupicatedAudienceName("");
  };
  const debouncedFetchResults = useCallback(debounce(handleSearchAudiencesChanged, DEBOUNCE_TIME), [
    handleSearchAudiencesChanged,
  ]);

  const searchbox = (
    <Autocomplete
      componentsProps={{
        popper: {
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        },
      }}
      filterOptions={(x) => x}
      options={searchResults}
      id={"search-audiences"}
      size="small"
      onChange={(_, value) => {
        handleChange(_, value);
      }}
      inputValue={searchTerm}
      onInputChange={(_, newInputValue) => {
        handleInputChange(newInputValue);
      }}
      disableCloseOnSelect
      getOptionLabel={(option: IAudienceOverview) => option.name}
      onOpen={() => {
        setOpenDialogue(true);
      }}
      onClose={() => {
        setOpenDialogue(false);
      }}
      open={openDialogue}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <WppIconSearch slot="icon-start" aria-label="Search icon" />
                <span>{dupicatedAudienceName || "Search"}</span>
              </Stack>
            }
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
          </TextField>
        );
      }}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <Box
            component="li"
            sx={{ flexShrink: 0, flexDirection: "column", alignItems: "flex-start !important" }}
            {...optionProps}
          >
            <Box component="span" sx={{ flexShrink: 0 }}>
              {option.name}
            </Box>
            <Box component="span" sx={{ flexShrink: 0 }}>
              <Taxonomy friendlyId={option.id} segments={option.taxonomy} />
            </Box>
          </Box>
        );
      }}
    />
  );

  const actionButtons = (
    <div className="action-buttons">
      <SecondaryButton className="cancel" onClick={onClose} label="Cancel" />
      <PrimaryButton className="create" label="Create" disabled={!isValid} onClick={handleCreate} />
    </div>
  );

  const nameInput = (
    <Stack spacing={1}>
      <WppTextInput
        name="New Profile Name"
        id="profile-name-name"
        value={profileNameModal}
        onWppChange={handleNameChange}
        labelConfig={{ text: "Name" }}
        required
      />
    </Stack>
  );

  const audienceSelector = (
    <Stack spacing={1}>
      <WppLabel id="profile-audience-label" labelText="Audience" />
      {searchbox}
    </Stack>
  );

  return (
    <Modal
      id="rename-modal"
      open
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <h2>New Profile</h2>
        <Stack spacing={2}>
          {nameInput}
          {audienceSelector}
        </Stack>
        {actionButtons}
      </Box>
    </Modal>
  );
}
