import type { GridColDef } from "@mui/x-data-grid-pro";
import { WppCheckbox, WppListItem, WppSelect } from "@platform-ui-kit/components-library-react";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

interface IResultMenuProps {
  selectedColumns: string[];
  setSelectedColumns: Dispatch<SetStateAction<string[]>>;
  columns: GridColDef[];
  resultsLoading: boolean;
}

export function ResultsMenu({
  selectedColumns,
  setSelectedColumns,
  columns,
  resultsLoading,
}: IResultMenuProps): JSX.Element {
  const [numberOfSelectedMetrics, setNumberOfSelectedMetrics] = useState(0);
  useEffect(() => {
    setSelectedColumns((prev: string[]) => {
      const newColumns = new Set(prev);
      for (const col of columns) {
        if (col.field.includes("vertical")) {
          newColumns.add(col.field);
        }
      }
      return [...newColumns];
    });
  }, [columns, setSelectedColumns]);

  const handleFilteredItems = () => {
    return columns
      .filter((col) => col.field !== "variable" && col.field !== "components")
      .slice(0, 4)
      .map((option) => {
        let label;
        switch (true) {
          case option.field.includes("vertical"): {
            label = "% Vertical";
            break;
          }
          case option.field.includes("horizontal"): {
            label = "% Horizontal";
            break;
          }
          case option.field.includes("size"): {
            label = "Size (Volume)";
            break;
          }
          case option.field.includes("index"): {
            label = "Index";
            break;
          }
          default: {
            label = option.field;
          }
        }
        return { option, label };
      });
  };

  const handleSelectedColumns = (isChecked: boolean, field: string): void => {
    setSelectedColumns((prevColumns: string[]) => {
      const updatedColumns = new Set(prevColumns);

      if (isChecked) {
        updatedColumns.delete(field);
      } else {
        updatedColumns.add(field);
      }

      const groupedField = field.split("-")[1];
      const groupedFields = columns.filter((col) => col.field.includes(groupedField)).map((col) => col.field);

      for (const field of groupedFields) {
        if (isChecked) {
          updatedColumns.delete(field);
        } else {
          updatedColumns.add(field);
        }
      }

      return [...updatedColumns];
    });
  };

  useEffect(() => {
    const arrOfSelectedMetrics: string[] = [];

    selectedColumns.forEach((element) => {
      arrOfSelectedMetrics.push(element.replace(/[0-9]/g, ""));
    });

    const arrOfSelectedMetricsWithoutDuplicated = [...new Set(arrOfSelectedMetrics)];
    setNumberOfSelectedMetrics(arrOfSelectedMetricsWithoutDuplicated.length);
  }, [selectedColumns, resultsLoading]);

  const placeholderSingularOrPlural = `${numberOfSelectedMetrics} ${numberOfSelectedMetrics === 1 ? "metric" : "metrics"} selected`;
  const placeholderText = !!resultsLoading || !numberOfSelectedMetrics ? "Select Metrics" : placeholderSingularOrPlural;

  return (
    <WppSelect
      size="m"
      placeholder={placeholderText}
      style={{ width: 250, marginRight: 4, marginLeft: 2, marginBottom: 4 }}
    >
      {handleFilteredItems().map((item) => {
        const option = item.option;
        const label = item.label;

        const isChecked = selectedColumns.includes(option.field);

        return (
          <WppListItem key={option.field}>
            <WppCheckbox
              slot="label"
              onWppChange={() => {
                handleSelectedColumns(isChecked, option.field);
              }}
              checked={isChecked}
            ></WppCheckbox>
            <div style={{ marginLeft: 8 }} slot="label">
              {label}
            </div>
          </WppListItem>
        );
      })}
    </WppSelect>
  );
}
