import "./audience-size.scss";

import { Button, Tooltip } from "@mui/material";
import { WppIconAvailableUpdate, WppStatus } from "@platform-ui-kit/components-library-react";
import { formatNumber } from "humanize-plus";
import { ReactComponent as PlayIcon } from "../../../../assets/icon-play.svg";

export const COLOR = {
  UP_TO_DATE: "#01850E",
  STALE: "#E07501",
  ATTENTION: "#DE4259",
  UNLOADED: "#898F98",
};

export enum GetSizeState {
  NOT_SHOWN = "NOT_SHOWN",
  BEFORE_FIRST_LOAD_WITH_VALID_AUDIENCE = "BEFORE_FIRST_LOAD_WITH_VALID_AUDIENCE",
  LOADING_WITHOUT_DATA_DISPLAYED = "LOADING_WITHOUT_DATA_DISPLAYED",
  LOADING_WITH_DATA_DISPLAYED = "LOADING_WITH_DATA_DISPLAYED",
  DISPLAYING_DATA_ALLOW_RELOAD = "DISPLAYING_DATA_ALLOW_RELOAD",
  DISPLAYING_DATA_NO_RELOAD = "DISPLAYING_DATA_NO_RELOAD",
  DISPLAYING_DATA_REQUEST_RELOAD = "DISPLAYING_DATA_REQUEST_RELOAD",
  ERROR = "ERROR",
}

interface Props {
  onRefreshClick: () => void;
  state: GetSizeState;
  audienceSize: number;
  position: "top" | "bottom";
}

function identifyState(
  state: GetSizeState,
  audienceSize: number,
): {
  icon: null | JSX.Element;
  labelText: string | null;
  showAnimation: boolean;
  showButton: boolean;
  statusColour: string;
  tooltipText: string | null;
} {
  switch (state) {
    case GetSizeState.NOT_SHOWN: {
      return {
        icon: null,
        labelText: null,
        showAnimation: false,
        showButton: false,
        statusColour: COLOR.UNLOADED,
        tooltipText: null,
      };
    }

    case GetSizeState.BEFORE_FIRST_LOAD_WITH_VALID_AUDIENCE: {
      return {
        icon: <PlayIcon />,
        labelText: "Get size",
        showAnimation: false,
        showButton: true,
        statusColour: COLOR.UNLOADED,
        tooltipText: "Click here to calculate the audience size",
      };
    }

    case GetSizeState.LOADING_WITHOUT_DATA_DISPLAYED: {
      return {
        icon: <WppIconAvailableUpdate />,
        labelText: "Calculating size...",
        showAnimation: true,
        showButton: true,
        statusColour: COLOR.STALE,
        tooltipText: null,
      };
    }

    case GetSizeState.LOADING_WITH_DATA_DISPLAYED: {
      return {
        icon: <WppIconAvailableUpdate />,
        labelText: formatNumber(audienceSize),
        showAnimation: true,
        showButton: true,
        statusColour: COLOR.STALE,
        tooltipText: null,
      };
    }

    case GetSizeState.DISPLAYING_DATA_ALLOW_RELOAD: {
      return {
        icon: <WppIconAvailableUpdate />,
        labelText: formatNumber(audienceSize),
        showAnimation: false,
        showButton: true,
        statusColour: COLOR.UP_TO_DATE,
        tooltipText: null,
      };
    }

    case GetSizeState.DISPLAYING_DATA_NO_RELOAD: {
      return {
        icon: null,
        labelText: formatNumber(audienceSize),
        showAnimation: false,
        showButton: false,
        statusColour: COLOR.STALE,
        tooltipText: null,
      };
    }

    case GetSizeState.DISPLAYING_DATA_REQUEST_RELOAD: {
      return {
        icon: <WppIconAvailableUpdate />,
        labelText: formatNumber(audienceSize),
        showAnimation: false,
        showButton: true,
        statusColour: COLOR.STALE,
        tooltipText: "Audience size needs recalculating. Click here to recalculate",
      };
    }

    case GetSizeState.ERROR: {
      return {
        icon: null,
        labelText: "Error",
        showAnimation: false,
        showButton: false,
        statusColour: COLOR.ATTENTION,
        tooltipText: "Unable to get audience size. If the issue persists please contact connekd@wpp.com.",
      };
    }

    default: {
      throw new Error(`unknown state for get-size: ${state}`);
    }
  }
}

export function AudienceSizeRender(props: Props): JSX.Element {
  const { onRefreshClick, state, audienceSize, position } = props;
  const { icon, labelText, showAnimation, showButton, statusColour, tooltipText } = identifyState(state, audienceSize);

  const label: JSX.Element | null =
    labelText === null ? null : (
      <WppStatus id="audience-size-text" color={statusColour} withLabel label={labelText} isFilled />
    );

  const button: JSX.Element = showButton ? (
    <Button id="audience-size-button" size="small" onClick={onRefreshClick} disableRipple>
      {icon}
    </Button>
  ) : (
    <></>
  );

  return (
    <span className={`audience-size ${showAnimation ? "calculating" : ""}`} data-testid="audience-size">
      {label}
      <Tooltip
        title={tooltipText}
        placement={(position + "-end") as "top-end" | "bottom-end"}
        arrow
        PopperProps={{
          style: { width: 100, textAlign: "center" },
        }}
      >
        {button}
      </Tooltip>
    </span>
  );
}

AudienceSizeRender.whyDidYouRender = true;
