import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Connekd } from "../../../../assets/connekd_vml_logo.svg";

interface ISetAccessRequest {
  setRequestAccess: (newMessage: boolean) => void;
}
const logoDimensions = {
  width: 125,
  height: 40,
};

export const LoginBox: React.FC<ISetAccessRequest> = ({ setRequestAccess }) => {
  const navigate = useNavigate();

  return (
    <Grid item container className="login-box" xs={2.7}>
      <div className="login-box-inner">
        <Connekd {...logoDimensions} />
        <h2>Sign in</h2>
        <Grid className="header-column-right" item>
          <Button
            className="sign-in-btn fill-primary"
            onClick={() => {
              navigate("/home");
            }}
          >
            Sign in with WPP OKTA
          </Button>
        </Grid>
      </div>
      <div className="landing-links">
        <p
          onClick={() => {
            setRequestAccess(true);
          }}
        >
          Request access
        </p>
        <a id="support" href="mailto:connekd@wpp.com?subject=Connekd%20support%20request">
          Get support
        </a>
      </div>
    </Grid>
  );
};
