import "./selection-actions.scss";

import { Grid } from "@mui/material";
import { WppIconArchive, WppIconCopy } from "@platform-ui-kit/components-library-react";
import { useIdentity } from "@vmlyr/appserviceshared/dist/hooks/useIdentity";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { ReactComponent as EditIcon } from "../../../../assets/icon-edit.svg";
import { ReactComponent as MergeIcon } from "../../../../assets/icon-merge.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icon-trash.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icon-upload.svg";
import { ActionButton } from "../../../../components/action-button";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { useUserStore } from "../../../../stores/user";

const iconDimensions = {
  width: 20,
  height: 20,
};

const mergeIconPosition = {
  viewBox: "0 0 20 19",
};

interface ISelectionActionsProps {
  selectedAudiences: AudienceOverview[];
  allAudiences: AudienceOverview[];
  onDelete: (audiencedToDelete: AudienceOverview[]) => void;
  onMerge: (audienceOne: AudienceOverview, audienceTwo: AudienceOverview) => void;
  onDuplicate: (selectedAudience: AudienceOverview) => void;
  onArchive: (audiencesToArchive: AudienceOverview[]) => void;
  onDistribute: (distributeAudience: AudienceOverview) => void;
  onEdit: (audienceToEdit: AudienceOverview) => void;
}

const userOwnsSelectedAudiences = (selectedAudiences: AudienceOverview[], userName: string | undefined): boolean => {
  const audiencesNotMadeByUser = selectedAudiences.filter((a) => a.owner !== userName);
  return audiencesNotMadeByUser.length === 0;
};

export function SelectionActions({
  selectedAudiences,
  allAudiences,
  onDelete,
  onMerge,
  onDuplicate,
  onArchive,
  onDistribute,
  onEdit,
}: ISelectionActionsProps) {
  const { getCurrentIdentity } = useIdentity();
  const user = useUserStore.authenticatedUser();
  const roleNum = user?.role_num ?? 0;
  const roleLevels = ConfigurationHelper.GetPermissions().audiences[0];
  const canEdit = roleLevels.edit.includes(roleNum);

  const canDuplicate = roleLevels.duplicate.includes(roleNum);
  const canMerge = roleLevels.merge.includes(roleNum);
  const canDistribute = roleLevels.distribute.includes(roleNum);
  const canDeactivate = roleLevels.deactivate.includes(roleNum);
  const canDelete = roleLevels.delete.includes(roleNum);

  if (selectedAudiences.length === 0) return null;

  const selectedAudiencesInMerges = allAudiences.filter((a) =>
    a.mergedAudiences.some((m) => selectedAudiences.map((s) => s.id).includes(m)),
  );
  const ownsSelectedAudiences = userOwnsSelectedAudiences(selectedAudiences, getCurrentIdentity()?.username);
  const selectedAudiencesWhichAreArchived = selectedAudiences.filter((a) => a.isArchived);

  const getDeleteErrorMessage = () => {
    let errorMessage = null;
    if (selectedAudiencesInMerges.length > 0) {
      errorMessage = `The selected audiences cannot be deleted because they're used in the following merged audiences: ${selectedAudiencesInMerges
        .map((m) => m.name)
        .join(", ")}. `;
    }
    // For now, the client has requested that anyone be able to delete anyone else's audience for data rectification purposes
    /*
    if (!ownsSelectedAudiences) {
      errorMessage = `${errorMessage || ""}You are only permitted to delete audiences that you own`;
    } */
    return errorMessage;
  };

  const getArchiveErrorMessage = () => {
    let errorMessage = null;
    if (selectedAudiencesInMerges.length > 0) {
      errorMessage = `You cannot deactivate the following audiences because they participate in a merge: ${selectedAudiencesInMerges
        .map((m) => m.name)
        .join(", ")}. `;
    }
    if (selectedAudiencesWhichAreArchived.length > 0) {
      errorMessage = `${
        errorMessage || ""
      }The following audiences you've selected are already deactivated: ${selectedAudiencesWhichAreArchived
        .map((m) => m.name)
        .join(", ")}. `;
    }
    if (!ownsSelectedAudiences) {
      errorMessage = `${errorMessage || ""}You can only deactivate audiences which you own`;
    }
    return errorMessage;
  };

  const getMergeErrorMessage = () => {
    let errorMessage = null;
    if (selectedAudiences.length !== 2)
      errorMessage = "You must select exactly two audiences in order to be able to perform a merge. ";
    if (selectedAudiencesWhichAreArchived.length > 0)
      errorMessage = `${
        errorMessage || ""
      }The following audiences you've selected are inactive and cannot be used in a merge: ${selectedAudiencesWhichAreArchived
        .map((m) => m.name)
        .join(", ")}. `;

    return errorMessage;
  };

  const deleteErrorMessage = getDeleteErrorMessage();
  const archiveErrorMessage = getArchiveErrorMessage();
  const mergeErrorMessage = getMergeErrorMessage();

  return (
    <Grid container className="selection-actions">
      <Grid
        className="selected-audiences"
        item
      >{`${selectedAudiences.length} audience${selectedAudiences.length === 1 ? "" : "s"} selected`}</Grid>
      <Grid item>
        {canEdit && (
          <ActionButton
            disabledMessage="you can only edit one audience at a time"
            isDisabled={selectedAudiences.length > 1}
            onClick={() => {
              onEdit(selectedAudiences[0]);
            }}
            icon={<EditIcon {...iconDimensions} className="icon edit-icon" />}
            buttonText="Edit"
            id="edit-audience-button"
          />
        )}

        {canDuplicate && (
          <ActionButton
            disabledMessage="you can only duplicate one audience at a time"
            isDisabled={selectedAudiences.length > 1}
            onClick={() => {
              onDuplicate(selectedAudiences[0]);
            }}
            icon={<WppIconCopy {...iconDimensions} className="icon" />}
            buttonText="Duplicate"
            id="duplicate-audience-button"
          />
        )}

        {canMerge && (
          <ActionButton
            disabledMessage={mergeErrorMessage}
            isDisabled={mergeErrorMessage !== null}
            onClick={() => {
              onMerge(selectedAudiences[0], selectedAudiences[1]);
            }}
            icon={<MergeIcon {...iconDimensions} {...mergeIconPosition} className="icon merge-icon" />}
            buttonText="Merge"
            id="merge-audience-button"
          />
        )}

        {canDistribute && (
          <ActionButton
            disabledMessage="you can only distribute one audience at a time"
            isDisabled={selectedAudiences.length > 1}
            onClick={() => {
              onDistribute(selectedAudiences[0]);
            }}
            icon={<UploadIcon {...iconDimensions} className="icon" />}
            buttonText="Distribute"
            id="distribute-audience-button"
          />
        )}

        {canDeactivate && (
          <ActionButton
            disabledMessage={archiveErrorMessage}
            isDisabled={archiveErrorMessage !== null}
            onClick={() => {
              onArchive(selectedAudiences);
            }}
            icon={<WppIconArchive {...iconDimensions} className="icon" />}
            buttonText="Deactivate"
            id="deactivate-audience-button"
          />
        )}

        {canDelete && (
          <ActionButton
            disabledMessage={deleteErrorMessage}
            isDisabled={deleteErrorMessage !== null}
            onClick={() => {
              onDelete(selectedAudiences);
            }}
            icon={<DeleteIcon {...iconDimensions} className="icon" />}
            buttonText="Delete"
            id="delete-audience-button"
          />
        )}
      </Grid>
    </Grid>
  );
}
