export class ConditionValidationState {
  constructor(
    public readonly isValid: boolean,
    public readonly invalidReason: string = "",
  ) {
    if (isValid && invalidReason) {
      throw new Error("A validation state cannot be both valid and have a validation reason");
    }
  }
}
