import "../../../../../styles/main.scss";
import "./audience-overview.scss";

import { Box, Stack } from "@mui/material";
import { AudienceSizeIndicator } from "@vmlyr/appserviceshared/dist/components/audience-size-indicator";
import type { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import { SimpleDate } from "../../../../../components/simple-date";
import { AudienceStringDefinition } from "../../../../../components/string-definition";
import { Taxonomy } from "../../../../../components/taxonomy";
import { definitionFormatter } from "../../helpers/definition-formatter";
import type { ProfileView } from "../../models/profile-view";
import { SaveButtons } from "../save-buttons";
import type { SaveProfileAction } from "../widgets";

interface IAudienceOverviewProps {
  audience: IAudienceOverview;
  actionButtons: JSX.Element;
  isSingleProfilePage: boolean;
  disabled: boolean;
  onViewSaved: (type: SaveProfileAction) => void;
  audienceName?: string;
  activeProfileView: ProfileView | null;
}

export function AudienceOverview({
  audience,
  actionButtons,
  isSingleProfilePage,
  disabled,
  onViewSaved,
  audienceName,
  activeProfileView,
}: IAudienceOverviewProps): JSX.Element {
  const isNotArchived = !audience.isArchived;

  const header = (
    <h1 id="audience-name-header" className="audience-name-header">
      {audience.name}
    </h1>
  );

  const taxonomicTags = <Taxonomy friendlyId={audience.id} segments={audience.taxonomy} classes={"taxonomy-font"} />;

  const sizeIndicator = isNotArchived ? (
    <AudienceSizeIndicator audienceSize={audience.size} audienceTotalSize={audience.totalAudienceSize} />
  ) : null;

  const createdField = (
    <Box className="created-by">
      <>
        <span>Created </span>
        <SimpleDate date={audience.created} isTimeSince={false} />
        <span> by </span>
        <span>{audience.owner}</span>
      </>
    </Box>
  );

  const modifiedField = activeProfileView?.lastModifiedDate ? (
    <Box className="updated-by-text">
      <span>Updated </span>
      <SimpleDate date={activeProfileView.lastModifiedDate} isTimeSince />
      <span> by </span>
      <span>{activeProfileView?.lastModifiedByUser?.email}</span>
    </Box>
  ) : null;

  const stringDefinitionText = definitionFormatter(audience.definition);

  return (
    <Stack className="profiler-header" spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <div>{header}</div>

        <Stack className="action-button" direction="row" spacing={2} alignItems="center">
          {modifiedField}
          {isSingleProfilePage && (
            <SaveButtons
              currentProfile={activeProfileView!}
              disabled={disabled}
              onViewSaved={onViewSaved}
              audienceName={audienceName}
            />
          )}
          {actionButtons}
        </Stack>
      </Stack>

      {taxonomicTags}

      <Stack direction="row" spacing={1} alignItems={"center"}>
        {sizeIndicator}
        {createdField}
      </Stack>

      <AudienceStringDefinition stringDefinitionText={stringDefinitionText} />
    </Stack>
  );
}
