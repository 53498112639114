import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";
import React from "react";

interface IStaticTaxonomyProps {
  segments: ITaxonomicNamePart[];
  friendlyId: string;
}

export function StaticTaxonomy({ segments }: IStaticTaxonomyProps) {
  return (
    <div id="static-taxonomy">
      {segments.map((segment: ITaxonomicNamePart, i: number) => {
        const divider = i === segments.length - 1 ? null : <span className="taxonomy-divider" />;
        const rand = Math.random();
        return segment.value ? (
          <React.Fragment key={`${segment.value}_${rand}`}>
            <span className="taxonomy-part">{segment.value}</span>
            {divider}
          </React.Fragment>
        ) : null;
      })}
    </div>
  );
}
