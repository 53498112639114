import type { GroupCondition } from "../models/group-condition";
import { QueryBuilderModel } from "../models/query-builder-model";
import { detachCondition } from "./helpers";

export const convertGroupToSingleCondition = (
  convertedGroup: GroupCondition,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel => {
  const parentGroup = convertedGroup.getParent() as GroupCondition;
  const conditionToConvertTo = convertedGroup.getLeftOperand() || convertedGroup.getRightOperand();

  if (!conditionToConvertTo) {
    throw new Error("Unable to convert group to a single condition, group missing both left and right operands");
  }

  if (!parentGroup) {
    // this is the root group
    return QueryBuilderModel.fromExisting(currentAudienceDefinition, conditionToConvertTo);
  }

  detachCondition(conditionToConvertTo);

  const isLeftOperandOfParent = parentGroup.getLeftOperand() === convertedGroup;
  if (isLeftOperandOfParent) {
    parentGroup.setLeftOperand(conditionToConvertTo);
  } else {
    parentGroup.setRightOperand(conditionToConvertTo);
  }

  return QueryBuilderModel.fromExisting(currentAudienceDefinition);
};
