import regex from "@vmlyr/common/dist/constants/regex";

export function validatorHelper(name: string | undefined): string | null {
  if (!name) {
    return "Add name";
  }

  if (name.length > 50) {
    return "Audience name cannot exceed over 50 characters";
  }

  if (!name.match(regex.nameValidation)) {
    return "Please only use alphanumeric characters, spaces and punctuation";
  }

  if (!name.match(regex.startsWithLetterOrNumber)) {
    return "The audience name must start with an alphanumeric character";
  }

  if (!name.match(regex.endsWithLetterOrNumber)) {
    return "The audience name must end with an alphanumeric character";
  }

  return null;
}
