import "./query-builder.scss";

import { Box, Button, CircularProgress, Grid, Snackbar, Stack, TextField } from "@mui/material";
import type { TextInputChangeEventDetail } from "@platform-ui-kit/components-library";
import { WppInlineMessage, WppTextInput } from "@platform-ui-kit/components-library-react";
import type { WppTextInputCustomEvent } from "@platform-ui-kit/components-library/dist/types/components";
import { getWithToken, postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";
import type { ICustomAudience } from "@vmlyr/common/dist/models/custom-audience";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DemographicSelector } from "../../../../components/demographic-selector";
import type { IDimensionCategory } from "../../../../components/demographic-selector/models/dimensions";
import type { SelectableCondition } from "../../../../components/demographic-selector/models/selectable-condition";
import { deleteCondition } from "../../../../components/query-builder/events-handlers/condition-deleted";
import { conditionDragged } from "../../../../components/query-builder/events-handlers/condition-dragged";
import { convertGroupToSingleCondition } from "../../../../components/query-builder/events-handlers/group-converter";
import { EmptyCondition } from "../../../../components/query-builder/models/empty-condition";
import type { GroupCondition } from "../../../../components/query-builder/models/group-condition";
import { QueryBuilderModel } from "../../../../components/query-builder/models/query-builder-model";
import { Taxonomy } from "../../../../components/taxonomy";
import { fetchAudiencePreview } from "../../../../connekd-api/audiences/preview";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { humanizeTimeBetweenDates } from "../../../../helpers/date-helper";
import { DragType } from "../../../../models/query-builder/drag-type";
import type { QueryCondition } from "../../../../models/query-builder/query-condition";
import { usePrompt } from "../../../../routing/hooks";
import { ActionType } from "../../action-type";
import { CreateAudienceContextProvider, type ICreateAudienceContext } from "../../context/CreateAudienceContext";
import { AudienceSize } from "../audience-size";
import { QueryConditionEditor } from "./query-condition-editor";

const placeholderAudienceName = "Name audience";

function FieldErrorMessage({ text }: { text: string | undefined }): JSX.Element {
  return (
    <div className="field-error-message">
      {text !== undefined && <WppInlineMessage type="error" size="s" message={text} />}
    </div>
  );
}

interface IAudienceEditorProps {
  model: QueryBuilderModel;
  action: ActionType;
  dimensionCategories: IDimensionCategory[];
  customAudiences: ICustomAudience[];
}

export function QueryBuilder(props: IAudienceEditorProps): JSX.Element {
  const { model, action, dimensionCategories, customAudiences } = props;
  const navigate = useNavigate();

  const [initialModelState] = useState(model.getValidationState().isValid ? model.getQueryAsString() : null);
  const [previousModelState, setPreviousModelState] = useState(initialModelState);
  const [saveError, setSaveError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [audienceDefinition, setAudienceDefinition] = useState<QueryBuilderModel>(model);
  const [lastModified, setLastModified] = useState<string | undefined>();
  const [customAudiencesList, setCustomAudiences] = useState<ICustomAudience[]>(customAudiences);

  const [audiencePreviewSize, setAudiencePreviewSize] = useState<number | null>(null);
  const [audiencePreviewStale, setAudiencePreviewStale] = useState<boolean>(false);
  const [audiencePreviewCalculating, setAudiencePreviewCalculating] = useState<boolean>(false);

  const validationState = audienceDefinition.getValidationState();
  const rootConditionValidationState = audienceDefinition.rootCondition.getValidationState();
  const nameValidationState = audienceDefinition.validateName();
  const taxonomyValidationState = audienceDefinition.validateTaxonomy();
  const descriptorValidationState = audienceDefinition.validateDescriptor();

  const isEmptyEditor = () => audienceDefinition.rootCondition instanceof EmptyCondition;

  useEffect(() => {
    setCustomAudiences(customAudiences);
    if (rootConditionValidationState.isValid) {
      const currentAudienceDefinitionString = audienceDefinition.getQueryAsString();
      if (currentAudienceDefinitionString !== previousModelState) {
        // Actions to take only when the model changes
        setPreviousModelState(currentAudienceDefinitionString);
        setAudiencePreviewStale(true);
      }
    }
  }, [audienceDefinition]);

  useEffect(() => {
    if (isEmptyEditor()) {
      setAudiencePreviewSize(null);
      setAudiencePreviewStale(false);
      setAudiencePreviewCalculating(false);
    }
  }, [audienceDefinition.rootCondition]);

  useEffect(() => {
    if (model.id) {
      getWithToken<IAudienceOverview>(ConfigurationHelper.GetSingleAudienceOverviewEndpoint(model.id))
        .then((data) => {
          setAudiencePreviewStale(false);
          setAudiencePreviewSize(data.size);
        })
        .catch((err) => {
          setSaveError(
            `There was an error retrieving the size of the existing audience ${model.getName()}: ${err.toString()}`,
          );
        });
    }
  }, []);

  if (model.lastModified && lastModified === undefined) {
    setLastModified(humanizeTimeBetweenDates(new Date(), model.lastModified));
  }

  const handleConditionDeleted = (deletedCondition: QueryCondition) => {
    const newDefinition = deleteCondition(deletedCondition, audienceDefinition);
    setAudienceDefinition(newDefinition);
  };

  const handleConditionChanged = () => {
    setAudienceDefinition(QueryBuilderModel.fromExisting(audienceDefinition));
  };

  const handleConditionHighlighted = (highlightedCondition: QueryCondition) => {
    audienceDefinition.highlight(highlightedCondition);
    setAudienceDefinition(QueryBuilderModel.fromExisting(audienceDefinition));
  };

  const handleConditionRemovedFromHighlight = (highlightRemovedCondition: QueryCondition) => {
    audienceDefinition.removeHighlight(highlightRemovedCondition);
    setAudienceDefinition(QueryBuilderModel.fromExisting(audienceDefinition));
  };

  const handleConditionDroppedOnto = (droppedOntoCondition: QueryCondition) => {
    if (audienceDefinition.getDraggedOverCondition() !== null) {
      const newDefinition = conditionDragged(droppedOntoCondition, audienceDefinition);
      newDefinition.cancelDrag();
      setAudienceDefinition(newDefinition);
    }
  };

  const handleConditionPickedUp = (pickedUpCondition: QueryCondition) => {
    const newModel = QueryBuilderModel.fromExisting(audienceDefinition);
    newModel.setConditionBeingDragged(pickedUpCondition);
    newModel.setDraggedOverCondition(pickedUpCondition.getParent()!, DragType.OnTop);
    setAudienceDefinition(newModel);
  };

  const handleConditionDraggedOver = (draggedOverCondition: QueryCondition, type: DragType): void => {
    const newModel = QueryBuilderModel.fromExisting(audienceDefinition);
    newModel.setDraggedOverCondition(draggedOverCondition, type);
    setAudienceDefinition(newModel);
  };

  const handleDemographicDropped = (selectableCondition: SelectableCondition): void => {
    if (selectableCondition.draggedObject) {
      handleConditionDroppedOnto(selectableCondition.draggedObject());
    }
  };

  const handleGroupConvertedToCondition = (convertedGroup: GroupCondition): void => {
    const newDefinition = convertGroupToSingleCondition(convertedGroup, audienceDefinition);
    setAudienceDefinition(newDefinition);
  };

  const handleSaveClick = (): void => {
    setIsSaving(true);
    postWithToken(ConfigurationHelper.CreateAudienceEndpoint(), {
      data: audienceDefinition.getQuery(),
      audienceName: audienceDefinition.getName(),
      audienceId: audienceDefinition.id,
      currentVersionId: audienceDefinition.currentVersionId,
      taxonomy: audienceDefinition.getTaxonomy(),
      descriptor: audienceDefinition.getDescriptor(),
    })
      .then(async (response) => {
        if (response.ok) {
          navigate("/audiences");
        } else {
          setSaveError(`The server returned the following error: ${await response.text()}`);
        }
      })
      .catch((err) => {
        setSaveError(`There was an unexpected error: ${err}`);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCancelClick = (): void => {
    navigate("/audiences");
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newModel = QueryBuilderModel.fromExisting(audienceDefinition);
    newModel.setName(event.target.value);
    event.currentTarget.setAttribute("size", event.target.value.length.toString());
    setAudienceDefinition(newModel);
  };

  const handleFreeTextChange = (event: WppTextInputCustomEvent<TextInputChangeEventDetail>) => {
    const newModel = QueryBuilderModel.fromExisting(audienceDefinition);
    newModel.setDescriptor(event.target.value);
    setAudienceDefinition(newModel);
  };

  const handleInputChange = (): QueryBuilderModel => audienceDefinition;

  const checkCanSave = (): boolean => {
    const nameHasNotChanged = model.getName() === audienceDefinition.getName();
    const originalTaxonomy = JSON.stringify(model.getTaxonomy().map((t) => t.value));
    const currentTaxonomy = JSON.stringify(audienceDefinition.getTaxonomy().map((t) => t.value));
    const taxonomyHasNotChanged = originalTaxonomy === currentTaxonomy;
    const descriptorHasNotChanged = model.getDescriptor() === audienceDefinition.getDescriptor();
    if (!validationState.isValid) {
      return false;
    }
    if (isSaving) {
      return false;
    }
    if (
      audienceDefinition.getQueryAsString() === initialModelState &&
      action === ActionType.Edit &&
      nameHasNotChanged &&
      taxonomyHasNotChanged &&
      descriptorHasNotChanged
    ) {
      return false;
    }
    return true;
  };

  const handleRefreshAudienceSize = (): void => {
    setAudiencePreviewCalculating(true);
    fetchAudiencePreview(audienceDefinition.getQuery())
      .then((result) => {
        setAudiencePreviewSize(result.size);
        setAudiencePreviewStale(false);
        setAudiencePreviewCalculating(false);
      })
      .catch((error) => {
        setSaveError(`There was a problem calculating the audience size: ${error.message}`);
      });
  };

  const handleTaxonomyChange = (newTaxonomy: ITaxonomicNamePart[]) => {
    const newModel = QueryBuilderModel.fromExisting(audienceDefinition);
    newModel.setTaxonomy(newTaxonomy);
    setAudienceDefinition(newModel);
  };

  usePrompt("Are you sure you want to leave? Any unsaved changed will be lost", checkCanSave());

  const context: ICreateAudienceContext = {
    model: audienceDefinition,
    customAudiences: customAudiencesList,
    onDelete: handleConditionDeleted,
    onChange: handleConditionChanged,
    onHighlight: handleConditionHighlighted,
    onRemoveHighlight: handleConditionRemovedFromHighlight,
    onConditionDroppedOnto: handleConditionDroppedOnto,
    onConditionPickedUp: handleConditionPickedUp,
    onConditionDraggedOver: handleConditionDraggedOver,
    onGroupConvertedToCondition: handleGroupConvertedToCondition,
    onInputChange: handleInputChange,
  };

  const descriptorSegment = audienceDefinition.getTaxonomy().find((segment) => segment.segmentName === "Descriptor");
  const hasDescriptor = descriptorSegment ? { value: descriptorSegment.value?.toString() } : {};

  const snackbar = (
    <Snackbar
      open={saveError !== ""}
      autoHideDuration={5000}
      onClose={() => {
        setSaveError("");
      }}
      message={saveError}
    />
  );

  const audienceNameField = (
    <TextField
      className="heading-xl-two audience-name-input"
      inputProps={{
        size: audienceDefinition.getName() ? audienceDefinition.getName().length : placeholderAudienceName.length + 1,
      }}
      id="audience-name"
      variant="standard"
      InputProps={{ disableUnderline: true }}
      value={audienceDefinition.getName()}
      placeholder={placeholderAudienceName}
      onChange={handleNameChange}
      error={!nameValidationState.isValid}
    />
  );

  const audienceSize = (
    <AudienceSize
      audienceSize={audiencePreviewSize}
      isStale={audiencePreviewStale}
      isLoading={audiencePreviewCalculating}
      hasValidBuilder={rootConditionValidationState.isValid}
      isEmptyEditor={isEmptyEditor()}
      onRefreshClick={handleRefreshAudienceSize}
      position={"bottom"}
    />
  );

  const cancelButton = (
    <div>
      <Button className="text-secondary fixed-width-button" variant="text" onClick={handleCancelClick}>
        Cancel
      </Button>
    </div>
  );

  const saveButton = (
    <div>
      <Button
        id="save-audience-button"
        disabled={!checkCanSave()}
        className="fill-primary fixed-width-button"
        variant="outlined"
        onClick={handleSaveClick}
      >
        Save
      </Button>
    </div>
  );

  const showLastModified: boolean = !isSaving && lastModified !== undefined;

  const lastModifiedSpan = (
    <span className="last-modified">
      Updated {lastModified} ago by {audienceDefinition.lastModifiedBy}
    </span>
  );

  const taxonomy = (
    <Taxonomy
      friendlyId={audienceDefinition.id}
      isEditable
      segments={audienceDefinition.getTaxonomy()}
      onChange={handleTaxonomyChange}
    />
  );

  const nameError = (
    <FieldErrorMessage text={nameValidationState.isValid ? undefined : nameValidationState.invalidReason} />
  );

  const taxonomyError = (
    <FieldErrorMessage text={taxonomyValidationState.isValid ? undefined : taxonomyValidationState.invalidReason} />
  );

  const customDescriptorError = (
    <FieldErrorMessage text={descriptorValidationState.isValid ? undefined : descriptorValidationState.invalidReason} />
  );

  const customDescriptor = (
    <Stack spacing={1}>
      <WppTextInput
        size="s"
        name="customDescriptor"
        placeholder="Custom Descriptor"
        onWppChange={handleFreeTextChange}
        {...hasDescriptor}
      />

      <div>
        <Box sx={{ position: "absolute" }}>{customDescriptorError}</Box>
      </div>
    </Stack>
  );

  const demographicSelector = (
    <DemographicSelector
      onDemographicDropped={handleDemographicDropped}
      currentAudienceFriendlyId={audienceDefinition.id}
      dimensionCategories={dimensionCategories}
    />
  );

  const queryConditionEditor = (
    <div className="query-builder">
      <QueryConditionEditor condition={audienceDefinition.rootCondition} />
    </div>
  );

  const savingSpinner = <CircularProgress size="1.5rem" sx={{ top: "0.3rem", position: "relative" }} />;

  const firstRow = (
    <Grid container justifyContent="space-between">
      <Grid item className="audience-name-field">
        <div className="text-field-container">{audienceNameField}</div>
        {nameError}
      </Grid>

      <Stack spacing={2} alignItems="center" className="fish-button-container" direction="row">
        {showLastModified && lastModifiedSpan}
        {audienceSize}
        {cancelButton}
        {saveButton}
        {isSaving && savingSpinner}
      </Stack>
    </Grid>
  );

  // NOTE: spacing is 0 as the customDescriptorError field is creating the necessary space
  const secondRow = (
    <Stack spacing={0}>
      <Stack direction={"row"}>
        {taxonomy}
        {audienceDefinition.getTaxonomy().length > 0 && customDescriptor}
      </Stack>
      {taxonomyError}
    </Stack>
  );

  return (
    <CreateAudienceContextProvider value={context}>
      {snackbar}

      <Stack className="pane-for-screen-heading" spacing={2}>
        {firstRow}
        {secondRow}
      </Stack>

      <Stack direction="row" sx={{ height: "calc(100vh - 225px)" }}>
        <Box className="pane-for-dimension-selector">{demographicSelector}</Box>

        <Box sx={{ overflow: "auto", height: "auto", flex: 1 }}>{queryConditionEditor}</Box>
      </Stack>
    </CreateAudienceContextProvider>
  );
}
