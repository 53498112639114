import type { IDimension, IDimensionCategory } from "../../../../components/demographic-selector/models/dimensions";

export class SelectableDimension {
  constructor(
    public readonly dimension: IDimension,
    public isExpanded: boolean,
    public isVisible: boolean,
    public isVisibleInitially: boolean,
    public isSelected: boolean,
  ) {}

  select(): void {
    this.isSelected = true;
  }

  deselect(): void {
    this.isSelected = false;
  }

  toggle(): void {
    this.isSelected = !this.isSelected;
  }

  getIsSelected(): boolean {
    return this.isSelected;
  }

  getIsExpanded(): boolean {
    return this.isExpanded;
  }

  getIsVisible(): boolean {
    return this.isVisible;
  }

  getIsVisibleInitially(): boolean {
    return this.isVisibleInitially;
  }
}

export class SelectableDimensionCategory {
  public readonly dimensions: SelectableDimension[];

  public readonly categories: SelectableDimensionCategory[];

  public readonly name: string;

  public isExpanded: boolean;

  public isVisible: boolean;
  public isVisibleInitially: boolean;

  constructor(category: IDimensionCategory, filterDimensions: string[]) {
    this.dimensions =
      category.dimensions
        .map((d) => new SelectableDimension(d, false, true, true, true))
        .filter((d) => !filterDimensions.includes(d.dimension.name)) || [];
    this.name = category.categoryName;
    this.categories = category.categories.map((c) => new SelectableDimensionCategory(c, filterDimensions)) || [];
    this.isExpanded = false;
    this.isVisible = true;
    this.isVisibleInitially = true;
  }

  select(): void {
    for (const d of this.dimensions) d.select();
  }

  deselect(): void {
    for (const d of this.dimensions) d.deselect();
  }

  getIsSelected(): boolean {
    return !this.dimensions.some((d) => !d.getIsSelected());
  }

  toggle(): void {
    this.getIsSelected() ? this.deselect() : this.select();
  }

  getSelectedDimensions(): SelectableDimension[] {
    return this.dimensions.filter((d) => d.getIsSelected());
  }

  getIsVisible(): SelectableDimension[] {
    return this.dimensions.filter((d) => d.getIsVisible());
  }

  getIsExpanded(): SelectableDimension[] {
    return this.dimensions.filter((d) => d.getIsExpanded());
  }

  getIsVisibleInitially(): SelectableDimension[] {
    return this.dimensions.filter((d) => d.getIsVisibleInitially());
  }
}
