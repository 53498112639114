import { TextField } from "@mui/material";
import { memo } from "react";
import { NameError } from "../../../components/name-error-message";
import type { Group } from "../../../store/groups";
import { useGroupsActions } from "../../../store/groups";
import "../save-as-audience-modal/save-as-audience-modal.scss";

const PLACEHOLDER = "Group name";

interface Props {
  groupId: Group["id"];
  text: Group["name"];
  validationError: Group["validationError"];
}

function GroupNameInputComponent(props: Props): JSX.Element | null {
  const { groupId, text, validationError } = props;
  const { updateGroupName } = useGroupsActions();

  return (
    <div>
      <TextField
        className="heading-xl-two group-title-input"
        variant="standard"
        InputProps={{ disableUnderline: true }}
        placeholder={PLACEHOLDER}
        onChange={(event) => {
          updateGroupName(groupId, event.target.value);
        }}
        value={text}
      />
      {validationError !== null && <NameError errorMessage={validationError} />}
    </div>
  );
}

export const GroupNameInput = memo(GroupNameInputComponent);
