import { useEffect, useState } from "react";
import { DemographicSelector } from "../../../components/demographic-selector";
import type { IDimensionCategory } from "../../../components/demographic-selector/models/dimensions";
import type { SelectableCondition } from "../../../components/demographic-selector/models/selectable-condition";
import { listDimensions } from "../../../connekd-api/dimensions/list";

interface Props {
  handleDemographicDropped: (item: SelectableCondition) => void;
}

export function DemographicPicker(props: Props): JSX.Element | null {
  const { handleDemographicDropped } = props;

  const [dimensionCategories, setDimensionCategories] = useState<IDimensionCategory[] | null>(null);

  useEffect(() => {
    listDimensions()
      .then((response) => {
        setDimensionCategories(response);
      })
      .catch((err: Error) => {
        console.error({ err });
      });
  }, []);

  return dimensionCategories === null ? null : (
    <DemographicSelector
      onDemographicDropped={handleDemographicDropped}
      currentAudienceFriendlyId={""}
      dimensionCategories={dimensionCategories}
    />
  );
}
