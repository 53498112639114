import type { IDimension, IDimensionCategory } from "../../components/demographic-selector/models/dimensions";

const extractDimensionsFromCategory = (dimensionCategory: IDimensionCategory, dimensions: IDimension[]): void => {
  for (const d of dimensionCategory.dimensions || []) {
    dimensions.push(d);
  }

  for (const c of dimensionCategory.categories || []) {
    extractDimensionsFromCategory(c, dimensions);
  }
};

export function extractAllDimensions(dimensionCategories: IDimensionCategory[]): IDimension[] {
  const dimensions = new Array<IDimension>();

  for (const c of dimensionCategories) {
    extractDimensionsFromCategory(c, dimensions);
  }

  return dimensions;
}
