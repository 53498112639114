import "./heading-buttons.scss";

import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { ReactComponent as MoreIcon } from "../../../../../../../assets/icon-more.svg";
import type { VariableData } from "../../../../../store/variables";
import { useVariablesActions } from "../../../../../store/variables";

interface Props {
  variableId: VariableData["id"];
  open: boolean;
  variableOptionsAreOpen: null | HTMLElement;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
}

export function HeadingButtons(props: Props): JSX.Element {
  const { open, variableOptionsAreOpen, handleClick, handleClose, variableId } = props;
  const { removeVariable } = useVariablesActions();

  return (
    <Grid className="project-variable-header" container>
      <Grid className="header-column-right" item xs={12}>
        <Button
          size="small"
          variant="text"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disableRipple
        >
          <MoreIcon />
        </Button>
        <Menu
          className="project-variable-options"
          anchorEl={variableOptionsAreOpen}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            className="project-variable-options project-variable-option-warning"
            onClick={() => {
              removeVariable(variableId);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
