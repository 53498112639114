import type { GridColDef } from "@mui/x-data-grid-pro";
import NameCell from "../../screens/audiences/table/name-cell";
import SizeCell from "../../screens/audiences/table/size-cell";
import { SimpleDate } from "../simple-date";

const AudienceTableDefinition: GridColDef[] = [
  {
    field: "pinned",
    headerName: "Pinned",
  },
  {
    field: "size",
    headerName: "Size",
    flex: 1,
    renderCell: (value) => <SizeCell audience={value.row} isArchived={value.row.isArchived} />,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (value) => <NameCell audience={value.row} />,
  },
  {
    field: "sortableDate",
  },
  {
    field: "modified",
    headerName: "Updated",
    flex: 1,
    renderCell: (value) => <SimpleDate date={value.row.modified} isTimeSince />,
  },
  { field: "owner", headerName: "Owner", flex: 1, sortable: false },
];

export default AudienceTableDefinition;
