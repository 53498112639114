import { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";
import type { IAllowedValuesConstraint, IDimension } from "../../demographic-selector/models/dimensions";

const expectedConstraintId = "allowedValuesConstraint";

export class MultipleCondition extends QueryCondition {
  public readonly NAME: string = "MultipleCondition";

  private readonly allowedValuesConstraint: IAllowedValuesConstraint;

  private value: string[] | string;

  constructor(
    public readonly dimension: IDimension,
    value: string[] = [],
    protected parent: QueryCondition | null = null,
  ) {
    super(parent);
    if (!dimension) {
      throw new Error("A dimension must be provided to a discreet condition");
    }
    this.allowedValuesConstraint = dimension.metadata.constraints.find(
      (c) => c.constraintType === expectedConstraintId,
    ) as IAllowedValuesConstraint;
    if (!this.allowedValuesConstraint) {
      throw new Error(`No constraint with constraint type ${expectedConstraintId} was found on the loaded dimension`);
    }
    const filteredValues = value.filter((v) => this.allowedValuesConstraint.allowedValues.includes(v));
    this.value = filteredValues;
  }

  setValue(chosenValue: string[] | string) {
    this.value = chosenValue;
  }

  getValue(): string[] | string {
    return this.value;
  }

  getAllowedValues(): string[] {
    return this.allowedValuesConstraint.allowedValues;
  }

  getDimensionName(): string {
    return this.dimension.name;
  }

  getValidationState(): ConditionValidationState {
    if (this.value.length === 0) {
      return new ConditionValidationState(false, "Please make a selection");
    }
    return new ConditionValidationState(true);
  }

  getConditionData(): AudienceDefinitionData {
    return new AudienceDefinitionData(
      { value: this.value, dimensionId: this.dimension.id },
      null,
      null,
      null,
      "multiple",
    );
  }

  isParentOf(): boolean {
    return false;
  }
}
