import type { GridColDef } from "@mui/x-data-grid-pro";

import { SimpleDate } from "../../../../components/simple-date";

import OwnerCell from "../../../../components/table-common/owner-name-cell";
import NameCell from "./name-cell";
import TitleCell from "./title-cell";

const ProfilesTableDefinition = (): GridColDef[] => [
  {
    field: "profileName",
    headerName: "Title",
    flex: 1.4,
    renderCell: (value) => <TitleCell profile={value.row} />,
  },
  {
    field: "audience",
    headerName: "Audience name",
    flex: 1.2,
    valueGetter: (params) => params.row?.audience.name,
    renderCell: (value) => <NameCell profiles={value.row} />,
  },
  {
    field: "createdAtDate",
    headerName: "Created",
    flex: 0.8,
    renderCell: (value) => <SimpleDate date={value.row.createdAtDate} isTimeSince />,
  },
  {
    field: "lastModified",
    headerName: "Last updated",
    flex: 0.8,
    renderCell: (value) => <SimpleDate date={value.row.lastModified} isTimeSince />,
  },
  {
    field: "ownerEmail",
    headerName: "Owner",
    flex: 0.8,
    renderCell: (value) => <OwnerCell email={value.row.ownerEmail} />,
  },
  {
    field: "sortableDate",
  },
  {
    field: "pinned",
  },
];

export default ProfilesTableDefinition;
