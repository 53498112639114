import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { CenteredProgress } from "../centered-progress";

interface ILoadableProps {
  loadingObject: any | null | boolean;
  loadedComponent: () => JSX.Element | JSX.Element[];
  noLoader?: boolean;
  errorMessage?: string;
}

export function Loadable({
  loadingObject,
  loadedComponent,
  noLoader = false,
  errorMessage = "",
}: ILoadableProps): JSX.Element | null {
  if (errorMessage) return <WppInlineMessage size="s" type="error" message={errorMessage} />;

  if (!loadingObject) return noLoader ? null : <CenteredProgress />;

  return <>{loadedComponent()}</>;
}
