import "./faq.scss";

import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { faqContent as faqContentFordDemo } from "./faq-ford-demo";

const faqTable: any = {
  faqContentFordDemo,
};

export const FAQ = () => {
  const { getAudienceToolGroup } = useAudienceToolGroup();
  const currentGroup = getAudienceToolGroup();
  const faqSet = ConfigurationHelper.GetAudienceToolGroups(currentGroup).faqFilename;

  return faqTable[faqSet];
};
