import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { User } from "../../types/users";

interface CurrentUserResponse {
  status: string;
  message: string;
  data: User;
}

export async function getCurrentUser(): Promise<User | undefined> {
  const endpoint = ConfigurationHelper.GetCurrentUserEndpoint();

  const response: Response = await postWithToken(endpoint, undefined);

  if (!response.ok) {
    console.error("something went wrong with retrieving the user data", response);
    return undefined;
  }

  const body = (await response.json()) as CurrentUserResponse;
  return body.data;
}
