import "./left-multiselect.scss";

import { Grid, List, ListItem } from "@mui/material";

export function LeftMultiselect() {
  return (
    <Grid>
      <div className="left-multiselect-section">
        <p>Taxonomies</p>
        <List className="MyList">
          <ListItem>Users</ListItem>
        </List>
      </div>
    </Grid>
  );
}
