import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { ProjectQueryObject } from "../../screens/explore/models/submitQueryModel";
import type { ResultsData } from "../../screens/explore/store/projects";

export async function fetchProjectResult(queryObject: ProjectQueryObject): Promise<ResultsData> {
  const endpoint = `${ConfigurationHelper.getConnekdApiEndpoint()}/Projects/Query`;

  const response: Response = await postWithToken(endpoint, queryObject);

  if (response.ok) {
    return await response.json();
  }

  console.error("unable to fetch projects result", response);
  throw new Error(await response.text());
}
