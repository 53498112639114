import { create } from "zustand";
import { SearchModel } from "../screens/audiences/components/search/components/search-filters/models/search-model";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/models/api/audience-overview";

interface AudienceListData {
  currentSearchModel: SearchModel;
  audiences: AudienceOverview[];
  pinned: AudienceOverview[];
}

interface Actions {
  updateAudiences: (xs: AudienceOverview[]) => void;
  updateSearchModel: (newModel: SearchModel) => void;
  updatePinned: (newPinned: AudienceOverview[]) => void;
}

export type AudienceListState = AudienceListData & { actions: Actions };

const useAudienceListStore = create<AudienceListState>((set) => ({
  currentSearchModel: new SearchModel("", new Map()),

  audiences: [],

  pinned: [],

  actions: {
    updateAudiences: (xs: AudienceOverview[]): void => {
      set(() => ({
        audiences: xs,
      }));
    },

    updateSearchModel: (newModel: SearchModel): void => {
      set(() => ({
        currentSearchModel: newModel,
      }));
    },

    updatePinned: (newPinned: AudienceOverview[]) => {
      set(() => ({
        pinned: newPinned,
      }));
    },
  },
}));

export const useAudienceListActions = (): Actions => useAudienceListStore((store) => store.actions);

export const useAudienceSearchModel = (): AudienceListState["currentSearchModel"] =>
  useAudienceListStore((store) => store.currentSearchModel);

export const useAudienceList = (): AudienceListState["audiences"] => useAudienceListStore((store) => store.audiences);
export const useAudiencePinned = (): AudienceListState["pinned"] => useAudienceListStore((store) => store.pinned);
