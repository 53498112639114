import "./style.scss";

import { Button } from "@mui/material";

interface Props {
  className?: string;
  label: string;
  disabled: boolean;
  onClick: () => void;
}

export function PrimaryButton(props: Props): JSX.Element {
  const extraClassName = props.className === undefined ? "" : ` ${props.className}`;

  return (
    <Button
      className={`connekd-button fill-primary${extraClassName}`}
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
    >
      {props.label}
    </Button>
  );
}
