import "./role-change-toast.scss";

import { Stack } from "@mui/material";
import { WppIconInfo } from "@platform-ui-kit/components-library-react";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../connekd-api/users/get-current";
import { getUserData } from "../users/get-current-user";

const iconDimensions = {
  width: 15,
  height: 15,
};

const FORTY_FIVE_MINUTES = 45 * 60 * 1000;

async function compareContextToSqlServer(
  contextRole: string,
  setRoleHasChanged: (value: boolean) => void,
): Promise<void> {
  try {
    const currentUser = await getCurrentUser();

    if (currentUser !== undefined && currentUser.role !== contextRole) {
      setRoleHasChanged(true);
    }
  } catch {
    console.error("unable to take out user data");
  }
}

const refresh = (): void => {
  location.reload();
};

export function RoleChangeToast(): JSX.Element | null {
  const [roleHasChanged, setRoleHasChanged] = useState(false);

  useEffect(() => {
    setInterval(async () => {
      try {
        const response = await getUserData();
        const result = await response.json();

        if (result && result.status === "success") {
          compareContextToSqlServer(result.data.role, setRoleHasChanged);
        }
      } catch (error) {
        console.error(error);
      }
    }, FORTY_FIVE_MINUTES);
  }, []);

  if (!roleHasChanged) {
    return null;
  }

  const icon = (
    <span className="circle-icon">
      <WppIconInfo {...iconDimensions} />
    </span>
  );

  const message = <span className="message">Your permissions have been updated</span>;

  const reloadButton = (
    <a className="refresh-button" onClick={refresh}>
      Reload
    </a>
  );

  return (
    <Stack className="role-change-toast" direction="row" spacing={2} alignItems="center">
      {icon}
      {message}
      {reloadButton}
    </Stack>
  );
}
