import "./context-menu.scss";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { WppIconChevron } from "@platform-ui-kit/components-library-react";
import type * as React from "react";
import { useState } from "react";

interface IContextMenuProps {
  children: Array<JSX.Element | null> | JSX.Element;
  buttonText: string | null;
  showChevron: boolean;
  className: string | null;
  disableScrollLock?: boolean;
  menuClassName?: string;
}

export default function ContextMenu({
  children,
  buttonText,
  showChevron,
  className,
  disableScrollLock,
  menuClassName,
}: IContextMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const buttonId = `basic-button-${buttonText}`;

  return (
    <span className={`context-menu ${className}`}>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleToggle}
        disableRipple
      >
        {buttonText}
        {showChevron && <WppIconChevron className={isOpen ? "open" : "closed"} />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        className={menuClassName}
        open={open}
        onClick={handleClose}
        onClose={handleToggle}
        disableScrollLock={disableScrollLock}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        {children}
      </Menu>
    </span>
  );
}

ContextMenu.defaultProps = {
  buttonText: null,
  showChevron: true,
  className: null,
  disableScrollLock: false,
  menuClassname: null,
};
