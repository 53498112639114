import { barX, plot, text } from "@observablehq/plot";
import type { IChartData } from "../../../models/chart-data";
import { EXTENDED_DISCRETE_COLOR_RANGE } from "../../../theme";

/*
const calculateAudienceVariance = (dataPoint: IChartData, dataSet: IChartData[]) => {
  if (!dataSet.includes(dataPoint)) throw new Error("The provided dataset did not contain the provided datapoint");

  let totalDatasetSize = dataSet.map((d) => d.DATASETCNT).reduce((previous, current) => previous + current);
  let totalAudienceSize = dataSet.map((d) => d.CNT).reduce((previous, current) => previous + current);

  const dataPointAsFractionOfAudience = dataPoint.CNT / totalAudienceSize;
  const dataPointAsFractionOfDataset = dataPoint.DATASETCNT / totalDatasetSize;

  const audienceVariance = dataPointAsFractionOfAudience / dataPointAsFractionOfDataset;

  return audienceVariance;
};

export const getDataPointTitle = (dataPoint: IChartData, dataSet: IChartData[]) => {
  const audienceVariance = calculateAudienceVariance(dataPoint, dataSet);
  const percentage = Math.round(audienceVariance * 100);

  if (percentage < 100) {
    return `This member of the general audience is ${percentage}% less likely than a member of this audience to be in this metric`;
  }
  if (percentage > 100) {
    return `This member of the general audience is ${Math.abs(
      100 - percentage
    )}% more likely than a member of this audience to be in this metric`;
  }
  if (percentage === 100) {
    return "A member of the general audience has about the same likelihood as a member of this audience of being in this metric";
  }
};

export const calculateWeightedAffinity = (dataPoint: IChartData, dataSet: IChartData[]) => {
  const audienceVariance = calculateAudienceVariance(dataPoint, dataSet);
  return audienceVariance * dataPoint.CNT;
};
*/

export const createBarChart = (
  dataSubsetToUse: IChartData[],
  totalAudienceSize: number,
  audienceSize: number,
  dimensionType: string,
): any => {
  const calculateFillColor = (d: IChartData) => {
    const index = dataSubsetToUse.indexOf(d);
    return EXTENDED_DISCRETE_COLOR_RANGE[index];
  };

  const calculatePercentageInAudience = (metricSize: number) => {
    const maxSize = dimensionType === "multiple" ? audienceSize : totalAudienceSize;
    return ((metricSize / maxSize) * 100).toFixed(1);
  };

  const dataDomain = dataSubsetToUse.map((d) => d.METRIC);

  const renderedChart = plot({
    height: 375,
    marks: [
      barX(dataSubsetToUse, { x: "CNT", y: "METRIC", fill: calculateFillColor, stroke: "white", strokeWidth: 5 }),
      text(dataSubsetToUse, {
        x: "CNT",
        y: "METRIC",
        text: (d: IChartData) => {
          const percentage = calculatePercentageInAudience(d.CNT);
          return `${percentage}%`;
        },
        dx: 20,
        fill: (d: IChartData) => {
          const percentage = calculatePercentageInAudience(d.CNT);
          if (percentage === "0.0") {
            return "#8792A4";
          }
          return calculateFillColor(d);
        },
        stroke: calculateFillColor,
        strokeWidth: 0.4,
      }),
    ],
    x: {
      label: "",
      tickFormat: (metricSize: number) => `${calculatePercentageInAudience(metricSize)}%`,
      ticks: 0,
    },
    y: {
      label: "",
      tickSize: 0,
      domain: dataDomain,
    },
  });

  const titles = renderedChart.querySelectorAll("title");
  for (const title of titles) {
    const parent = title.parentNode;
    parent.setAttribute("title", title.innerHTML);
  }
  return renderedChart;
};

export const removeNullMetrics = (data: IChartData[]) => data.filter((d) => d.METRIC);

export const getTopX = (data: IChartData[], x: number): IChartData[] => removeNullMetrics(data).slice(0, x);
