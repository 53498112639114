import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { SearchModel } from "../../screens/explore/components/search/components/search-filters/models/search-model";
import type { IProjectData } from "../../screens/explore/models/projectModel";

const PAGE_SIZE = 50;

export enum SearchTypes {
  Active = "Active",
  Inactive = "Inactive",
  All = "All",
}

export async function listProjects(
  searchResultPage: number,
  searchModel: SearchModel,
  searchType: SearchTypes,
): Promise<IProjectData[]> {
  let searchUrl = `${ConfigurationHelper.getConnekdApiEndpoint()}/Projects`;

  searchUrl = addQueryParams(
    searchUrl,
    `pageSize=${PAGE_SIZE}`,
    `page=${searchResultPage}`,
    `text=${searchModel.text}`,
    `searchType=${searchType.toLowerCase()}`,
  );

  const response: Response = await postWithToken(searchUrl, undefined);
  const data = await response.json();

  return data.data.recordsets[0] as any[];
}
