import regex from "@vmlyr/common/dist/constants/regex";
import type { IUserAttributeValue } from "../../../../../../../components/users/models/user-attributes";
import { ConditionValidationState } from "../../../../../../../models/validation-state";

export class SearchModel {
  constructor(
    public text: string = "",
    public filters: Map<string, IUserAttributeValue[]> = new Map<string, IUserAttributeValue[]>(),
  ) {}

  updateFilters(newFilters: Map<string, IUserAttributeValue[]>): SearchModel {
    if (newFilters === this.filters) {
      throw new Error("The incoming filters object was the same as the existing filters object");
    }
    return new SearchModel(this.text, newFilters);
  }

  updateText(newText: string): SearchModel {
    return new SearchModel(newText, this.filters);
  }

  equals(other: SearchModel) {
    const textMatches = other.text === this.text;
    const filtersMatch = JSON.stringify(other.filters.entries()) === JSON.stringify(this.filters.entries());

    return textMatches && filtersMatch;
  }

  isEmpty(): boolean {
    return this.text === "" && this.filters.size === 0;
  }

  getValidationState(): ConditionValidationState {
    if (!this.text.match(regex.nameValidation)) {
      return new ConditionValidationState(false, "The search text must not contain special characters");
    }
    return new ConditionValidationState(true);
  }

  static fromExisting(model: SearchModel) {
    return new SearchModel(model.text, model.filters);
  }
}
