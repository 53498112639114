import { Modal } from "@mui/material";
import { WppButton, WppIconTick } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import EditUsersModal from "../../components/user-actions/user-actions-modals/edit-users-modal";
import type { IuserData } from "../../models/userModel";

const dateFormatter = (incomingDate: string) => {
  try {
    const date = new Date(incomingDate);

    // Checking if date is valid
    if (isNaN(date.getTime())) {
      throw new TypeError("Invalid date format");
    }

    const day = date.getDate();
    const year = date.getFullYear();

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  } catch {
    console.error(`An error occurred when formatting the date:`);
    return null;
  }
};

export default function LastLoggedInCell({
  user,
  reloadUserTable,
}: {
  user: IuserData;
  reloadUserTable: (action?: string) => void;
}) {
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const handleOpenUserDetailsModal = () => {
    setOpenUserDetailsModal(true);
  };
  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  return (
    <div>
      <>
        {user.status === "Active" ? (
          <div className="last-logged-in">
            {user.last_logged_in ? dateFormatter(user.last_logged_in) : "Never logged in"}
          </div>
        ) : (
          <WppButton size="s" variant="secondary" onClick={handleOpenUserDetailsModal}>
            <WppIconTick slot="icon-start" />
            Approve
          </WppButton>
        )}
      </>

      <Modal
        open={openUserDetailsModal}
        onClose={handleCloseUserDetailsModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <EditUsersModal
          user={user}
          modalState={setOpenUserDetailsModal}
          reloadUserTable={reloadUserTable}
          variant="approved"
        />
      </Modal>
    </div>
  );
}
