import regex from "@vmlyr/common/dist/constants/regex";
import type { ITaxonomicSegmentValue } from "../../../../../../../components/taxonomy/models/taxonomy-segment";
import { ConditionValidationState } from "../../../../../../../models/validation-state";

export class SearchModel {
  constructor(
    public text: string = "",
    public filters: Map<string, ITaxonomicSegmentValue[]> = new Map<string, ITaxonomicSegmentValue[]>(),
  ) {}

  updateFilters(newFilters: Map<string, ITaxonomicSegmentValue[]>): SearchModel {
    this.filters = newFilters;
    return this;
  }

  updateText(newText: string): SearchModel {
    this.text = newText;
    return this;
  }

  isEmpty(): boolean {
    return this.text === "" && this.filters.size === 0;
  }

  getValidationState(): ConditionValidationState {
    if (!this.text.match(regex.nameValidation)) {
      return new ConditionValidationState(false, "The search text must not contain special characters");
    }
    return new ConditionValidationState(true);
  }
}
