import { ICustomAudience } from "../../screens/audiences/profile/helpers/definition-formatter";
import type { Operator } from "./operator";

export class AudienceDefinitionData {
  constructor(
    public readonly leftOperand: any,
    public readonly rightOperand: any = null,
    public readonly operator: Operator | null = null,
    public readonly name: string | null = null,
    public readonly type: string,
    public readonly customAudiences: [] | null | ICustomAudience = null,
  ) {
    if (!leftOperand && type !== "customAudience") {
      throw new Error("There must always be at-least a left operand");
    }
    if (leftOperand && !rightOperand && operator !== null) {
      throw new Error("If there is only a left operand, the operator must be None");
    }
    if (leftOperand && rightOperand && operator === null) {
      throw new Error("If there is a left and a right operand, there must be an operator other than None");
    }
  }
}
