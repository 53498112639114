import "./donut-chart.scss";

import { RadialChart } from "react-vis";

import { GraphDataPoint } from "../../../models/graph-data-point";
import type { IGraphProps } from "../../index";
import { EXTENDED_DISCRETE_COLOR_RANGE } from "../../theme";
import { removeNullMetrics } from "../bar-chart/helpers/bar-chart-helper";
import { CustomLegend } from "./custom-legend";

export const graphDimensions = {
  width: 400,
  height: 355,
};

export function DonutChart({ data, dimension }: IGraphProps) {
  const countKey = "CNT";
  const dataSubsetToUse = removeNullMetrics(data);
  const totalPersonCount = data.map((d: any) => +d[countKey]).reduce((a: number, b: number) => a + b);
  const maxItems = 10;
  const transformedData: GraphDataPoint[] = dataSubsetToUse.map(
    (d: any, i: number) =>
      new GraphDataPoint(+d[countKey], d.METRIC, EXTENDED_DISCRETE_COLOR_RANGE[i], totalPersonCount),
  );
  const checkIfTopTen: string = data.length > maxItems ? "- Top 10 Results" : "";
  const checkForSource: string = dimension.sourceExtra ? ` from ${dimension.sourceExtra}` : "";

  return (
    <>
      <div data-testid="discreet-graph" className="discreet-graph">
        <RadialChart
          data={transformedData}
          {...graphDimensions}
          innerRadius={120}
          radius={140}
          colorType="literal"
          padAngle={0.0174533}
        >
          <CustomLegend dataPoints={transformedData} />
        </RadialChart>
      </div>
      <small className="graph-footer">
        % of Audience {checkIfTopTen} | Source: Choreograph IDN {checkForSource}
      </small>
    </>
  );
}
