import { WppCheckbox } from "@platform-ui-kit/components-library-react";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useEffect, useState } from "react";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import type { IuserData } from "../../models/userModel";

export default function TrainedCell({
  user,
  reloadUserTable,
  setSelectedUsers,
  searchResult,
}: {
  user: IuserData;
  reloadUserTable: (action?: string, trained?: any) => void;
  setSelectedUsers: (users: any) => void;
  searchResult: any;
}) {
  const optionName = `trained_${user.id}`;
  const [trained, setTrained] = useState(user.training_complete);

  useEffect(() => {
    setTrained(user.training_complete);
  }, [searchResult]);

  function SetTrainingCompleteValue(userId: string, userEmail: string, checked: boolean) {
    postWithToken(ConfigurationHelper.GetUpdateUserEndpoint(), {
      action: "setTrainingCompleteFlag",
      userId,
      userEmail,
      newSetting: checked,
    })
      .then(async (response) => {
        if (response.ok) {
          reloadUserTable("trained", { id: user.id, training_complete: checked });
          setSelectedUsers([]);
        } else {
          console.error(`Error setting training complete setting for ${userEmail}`);
          console.error(response);
        }
      })
      .catch((err) => {
        console.error(`There was an unexpected error: ${err}`);
      });
  }

  return (
    <WppCheckbox
      name={optionName}
      checked={trained}
      onWppChange={({ detail: { checked } }) => {
        SetTrainingCompleteValue(user.id, user.email, checked);
      }}
    />
  );
}
