import { DragType } from "../../../models/query-builder/drag-type";
import { Operator } from "../../../models/query-builder/operator";
import type { QueryCondition } from "../../../models/query-builder/query-condition";
import { EmptyCondition } from "../models/empty-condition";
import { GroupCondition } from "../models/group-condition";
import { QueryBuilderModel } from "../models/query-builder-model";
import { detachCondition } from "./helpers";

function dragOntoGroup(
  draggedCondition: QueryCondition,
  draggedOnto: GroupCondition,
  dragType: DragType,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel {
  if (dragType === DragType.LeftOperand) {
    detachCondition(draggedCondition);
    draggedOnto.setLeftOperand(draggedCondition);
  }
  if (dragType === DragType.RightOperand) {
    detachCondition(draggedCondition);
    draggedOnto.setRightOperand(draggedCondition);
  }

  return QueryBuilderModel.fromExisting(currentAudienceDefinition);
}

function createFirstGroupDrag(
  draggedCondition: QueryCondition,
  draggedOnto: QueryCondition,
  dragType: DragType,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel {
  switch (dragType) {
    case DragType.Above: {
      return new QueryBuilderModel(
        new GroupCondition(null, "", draggedCondition, draggedOnto),
        currentAudienceDefinition.getName(),
        currentAudienceDefinition.getTaxonomy(),
        currentAudienceDefinition.id,
        currentAudienceDefinition.currentVersionId,
        currentAudienceDefinition.lastModified,
        currentAudienceDefinition.lastModifiedBy,
      );
    }
    case DragType.Below:
    case DragType.OnTop: {
      return new QueryBuilderModel(
        new GroupCondition(null, "", draggedOnto, draggedCondition),
        currentAudienceDefinition.getName(),
        currentAudienceDefinition.getTaxonomy(),
        currentAudienceDefinition.id,
        currentAudienceDefinition.currentVersionId,
        currentAudienceDefinition.lastModified,
        currentAudienceDefinition.lastModifiedBy,
      );
    }
    default: {
      throw new Error(`Unexpected drag type of ${dragType} when creating the first group`);
    }
  }
}

function dragOntoCondition(
  draggedCondition: QueryCondition,
  draggedOnto: QueryCondition,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel {
  const parentGroup = draggedOnto.getParent() as GroupCondition;
  const dragType = draggedOnto.getDragType();
  const isDraggedOntoGroup = draggedOnto instanceof GroupCondition;

  if (isDraggedOntoGroup) {
    // Dragging on top of a group is always a NOOP
    return currentAudienceDefinition;
  }

  if (draggedCondition.getParent() === draggedOnto.getParent()) {
    if (draggedOnto instanceof EmptyCondition) {
      return new QueryBuilderModel(
        draggedCondition,
        currentAudienceDefinition.getName(),
        currentAudienceDefinition.getTaxonomy(),
        currentAudienceDefinition.id,
        "",
        null,
        "",
      );
    }
    return draggedCondition.getParent() === null
      ? createFirstGroupDrag(draggedCondition, draggedOnto, dragType, currentAudienceDefinition)
      : reOrderDrag(draggedCondition, draggedOnto, currentAudienceDefinition);
  }
  if (parentGroup.getLeftOperand() === null) {
    return dragOntoGroup(draggedCondition, parentGroup, DragType.LeftOperand, currentAudienceDefinition);
  }
  if (parentGroup.getRightOperand() === null) {
    return dragOntoGroup(draggedCondition, parentGroup, DragType.RightOperand, currentAudienceDefinition);
  }
  // A new group needs creating
  const isLeftOperand = parentGroup.getLeftOperand() === draggedOnto;
  const isRightOperand = parentGroup.getRightOperand() === draggedOnto;
  if (!isLeftOperand && !isRightOperand) {
    throw new Error("The dragged onto was neither the left nor the right operand of the calculated parent group");
  }
  const conditionToGroup = isLeftOperand ? parentGroup.getLeftOperand() : parentGroup.getRightOperand();
  if (conditionToGroup === null) {
    throw new Error("Either the left or the right operand was null when attempting to group based on a drag");
  }
  const firstConditionInNewGroup = dragType === DragType.Above ? draggedCondition : conditionToGroup;
  const secondConditionInNewGroup = dragType === DragType.Above ? conditionToGroup : draggedCondition;
  detachCondition(firstConditionInNewGroup);
  detachCondition(secondConditionInNewGroup);
  const newGroup = new GroupCondition(
    parentGroup,
    "New Group",
    firstConditionInNewGroup,
    secondConditionInNewGroup,
    Operator.And,
  );
  if (isLeftOperand) {
    parentGroup.setLeftOperand(newGroup);
  } else {
    parentGroup.setRightOperand(newGroup);
  }

  return QueryBuilderModel.fromExisting(currentAudienceDefinition);
}

function reOrderDrag(
  draggedCondition: QueryCondition,
  draggedOnto: QueryCondition,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel {
  if (draggedCondition === draggedOnto) {
    // Dragging onto ones self is a noop
    return currentAudienceDefinition;
  }
  const parent = draggedCondition.getParent() as GroupCondition;
  const currentLeftOperand = parent.getLeftOperand();
  const currentRightOperand = parent.getRightOperand();
  parent.setLeftOperand(currentRightOperand);
  parent.setRightOperand(currentLeftOperand);

  return QueryBuilderModel.fromExisting(currentAudienceDefinition);
}

export const conditionDragged = (
  draggedCondition: QueryCondition,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel => {
  const draggedOnto = currentAudienceDefinition.getDraggedOverCondition();

  if (draggedOnto === null) {
    throw new Error("An attempt was made to drag onto an empty condition");
  }

  if (draggedCondition === draggedOnto || draggedCondition.isParentOf(draggedOnto)) {
    return currentAudienceDefinition;
  }

  if (draggedCondition.getParent() !== null && draggedCondition.getParent() === draggedOnto.getParent()) {
    return reOrderDrag(draggedCondition, draggedOnto, currentAudienceDefinition);
  }

  switch (draggedOnto.getDragType()) {
    case DragType.LeftOperand:
    case DragType.RightOperand: {
      return dragOntoGroup(
        draggedCondition,
        draggedOnto as GroupCondition,
        draggedOnto.getDragType(),
        currentAudienceDefinition,
      );
    }
    case DragType.Above:
    case DragType.Below:
    case DragType.OnTop: {
      return dragOntoCondition(draggedCondition, draggedOnto, currentAudienceDefinition);
    }
    default: {
      throw new Error(`The drag type ${draggedOnto.getDragType()} was not handled`);
    }
  }
};
