import "./recent-audiences-table.scss";

import { Alert } from "@mui/material";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import type { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { useNavigate } from "react-router-dom";
import NameCell from "../../screens/audiences/table/name-cell";
import SizeCell from "../../screens/audiences/table/size-cell";
import { handleSortModelChange } from "../table-common/handle-sort-model-change";
import { SimpleDate } from "../simple-date";

const columnDefinition: GridColDef[] = [
  {
    field: "size",
    headerName: "Size",
    flex: 1,
    renderCell: (value) => <SizeCell audience={value.row} isArchived={value.row.isArchived} />,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (value) => <NameCell audience={value.row} />,
  },
  {
    field: "sortableDate",
  },
  {
    field: "modified",
    headerName: "Updated",
    flex: 1,
    renderCell: (value) => <SimpleDate date={value.row.modified} isTimeSince />,
  },
  { field: "owner", headerName: "Owner", flex: 1, sortable: false },
];

const initialTableState: GridInitialStatePro = {
  columns: {
    columnVisibilityModel: {
      sortableDate: false,
    },
  },
  sorting: {
    sortModel: [{ field: "sortableDate", sort: "desc" }],
  },
};

interface Props {
  audienceList: AudienceOverview[];
}

export function RecentAudiencesTable(props: Props): JSX.Element {
  const { audienceList } = props;

  const navigate = useNavigate();

  const navigateToAudience = (param: GridRowParams): void => {
    const clickedAudience = audienceList.find((a) => a.id === param.id);

    if (clickedAudience === undefined) {
      console.warn("registered row click event, but did not find a matching audience");
      return;
    }

    navigate(`/audiences/${clickedAudience.id}`);
  };

  if (audienceList.length === 0) {
    return <Alert severity="info">You do not currently have any audiences defined</Alert>;
  }

  return (
    <div id="recent-audiences-table">
      <DataGrid
        initialState={initialTableState}
        columns={columnDefinition}
        rows={audienceList}
        checkboxSelection={false}
        disableSelectionOnClick
        hideFooter={true}
        onSortModelChange={handleSortModelChange}
        onRowClick={navigateToAudience}
      />
    </div>
  );
}
