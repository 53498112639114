"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudienceOverview = void 0;
const taxonomy_segment_1 = require("./taxonomy-segment");
class AudienceOverview {
    id;
    name;
    size;
    owner;
    mergedAudiences;
    totalAudienceSize;
    cachedSizeChecksums;
    cachedSize;
    modifiedBy;
    definition;
    hasError = false;
    isArchived;
    created;
    modified;
    taxonomy;
    constructor(id, name, taxonomy, size, owner, created, modified, mergedAudiences, totalAudienceSize, isArchived, cachedSizeChecksums = null, cachedSize = null, modifiedBy = null, definition = null) {
        this.id = id;
        this.name = name;
        this.size = size;
        this.owner = owner;
        this.mergedAudiences = mergedAudiences;
        this.totalAudienceSize = totalAudienceSize;
        this.cachedSizeChecksums = cachedSizeChecksums;
        this.cachedSize = cachedSize;
        this.modifiedBy = modifiedBy;
        this.definition = definition;
        this.isArchived = isArchived || false;
        this.created = new Date(created);
        this.modified = modified === null ? null : new Date(modified);
        this.taxonomy = taxonomy.map((t) => taxonomy_segment_1.TaxonomySegment.fromExisting(t));
    }
    static fromExisting(existing) {
        return new AudienceOverview(existing.id, existing.name, existing.taxonomy, existing.size, existing.owner, existing.created, existing.modified, existing.mergedAudiences, existing.totalAudienceSize, existing.isArchived, existing.cachedSizeChecksums, existing.cachedSize, existing.modifiedBy, existing.definition);
    }
}
exports.AudienceOverview = AudienceOverview;
