import "../table.scss";

import { Alert, Grid, Modal } from "@mui/material";
import type { DataGridProProps } from "@mui/x-data-grid-pro";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { SearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/search-results-model";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AudienceTable } from "../../components/audience-table";
import ConfirmUserActionModal from "../../components/confirm-user-action-modal";
import { ControlHeader } from "../../components/control-header";
import { Tabber } from "../../components/tabber/Tabber";
import { archiveAudiences } from "../../connekd-api/audiences/archive";
import { deleteAudiences } from "../../connekd-api/audiences/delete";
import { listAudiencesPage } from "../../connekd-api/audiences/list";
import { searchAudiences } from "../../connekd-api/audiences/search";
import { SearchType } from "../../connekd-api/audiences/search-type";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { useDistributes } from "../../hooks/useDistributes";
import { useUserStore } from "../../stores/user";
import { HeadingAndButtons } from "./components/heading-and-buttons";
import { Search } from "./components/search";
import { SearchModel } from "./components/search/components/search-filters/models/search-model";
import { SelectionActions } from "./components/selection-actions";
import type { IViewAudienceContext } from "./context";
import { ViewAudiencesContextProvider } from "./context";
import {
  useAudienceList,
  useAudienceListActions,
  useAudienceSearchModel,
  useAudiencePinned,
} from "../../stores/audiences-list";

const DEBOUNCE_TIME = 800;
const PAGE_SIZE = 100;

export default function Audiences(): JSX.Element {
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState<SearchType>(SearchType.Active);
  const [searchResult, setSearchResult] = useState<SearchResultsModel | null>(null);
  const [activeSearchesCount, setActiveSearchCount] = useState(0);
  const [searchResultPage, setSearchResultPage] = useState(0);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newSearchParams, setNewSearchParams] = useState<SearchModel>();

  const { updateAudiences, updateSearchModel } = useAudienceListActions();

  const currentSearchModel = useAudienceSearchModel();

  const { addOngoingDistribute } = useDistributes();

  const audienceList = useAudienceList();
  const pinnedAudiences = useAudiencePinned();

  const [selectedAudiences, setSelectedAudiences] = useState<AudienceOverview[]>([]);

  const [resetPinned, setResetPinned] = useState<number>(0);
  const [isRequestInitialised, setIsRequestInitialised] = useState<boolean>(false);

  const incrementActiveSearchCount = (): void => {
    setActiveSearchCount((currentValue) => currentValue + 1);
  };

  const decrementActiveSearchCount = (): void => {
    setActiveSearchCount((currentValue) => currentValue - 1);
  };

  useEffect(() => {
    updateAudiences([]);
    performSearch();
  }, [searchType]);

  useEffect((): void => {
    const searchResultsArray = searchResult?.results || [];
    const content = searchResultsArray.map((x) => ({
      ...x,
      sortableDate: x.modified || x.created,
    }));

    updateAudiences(content);
  }, [searchResult]);

  useEffect(() => {
    if (searchResultPage === 0 && newSearchParams !== undefined && !isRequestInitialised) {
      updateSearchModel(newSearchParams);
      launchSearch();
    }
  }, [newSearchParams?.text, newSearchParams?.filters, isRequestInitialised]);

  const performSearch = useCallback(async (): Promise<void> => {
    if (currentSearchModel.getValidationState().isValid) {
      performAudienceQuery();
    }
  }, [searchType]);

  const resetTable = (): void => {
    setSelectedAudiences([]);
    setResetPinned(resetPinned + 1);
    setActiveSearchCount(0);
    performSearch();
  };

  const openModal = (): void => setModalIsOpen(true);
  const closeModal = (): void => setModalIsOpen(false);

  const navigateToMergeAudience = (audienceOne: AudienceOverview, audienceTwo: AudienceOverview) => {
    navigate(
      `/audiences/merge/${audienceOne.id}/${audienceTwo.id}?name=Merge of ${audienceOne.name} and ${audienceTwo.name}`,
    );
  };

  const navigateToDuplicateAudience = (audience: AudienceOverview) => {
    navigate(`/audiences/duplicate/${audience.id}`);
  };

  const navigateToEditAudience = (audience: AudienceOverview) => {
    navigate(`/audiences/edit/${audience.id}`);
  };

  const handleArchive = (audiencesToArchive: AudienceOverview[]) => {
    const previousResult = searchResult;
    setSearchResult(null);

    archiveAudiences(audiencesToArchive)
      .then(() => {
        if (previousResult !== null) {
          const audiencesToMarkAsArchived = previousResult.results.filter((r) => audiencesToArchive.includes(r));
          for (const r of audiencesToMarkAsArchived) {
            r.isArchived = true;
          }

          setSearchResult({ ...previousResult });
          setSelectedAudiences([]);
        }
      })
      .catch((error) => {
        setLoadError(`An error occurred whilst archiving the audience: ${error.message}`);
      });
  };

  const context: IViewAudienceContext = useMemo(
    () => ({
      onAudienceDetailsReceived: (audience: AudienceOverview) => {
        if (searchResult) {
          const updatedAudience = searchResult.results.find((a) => a.id === audience.id);
          if (updatedAudience) {
            updatedAudience.size = audience.size;
            updatedAudience.mergedAudiences = audience.mergedAudiences;
          }
        }
      },
    }),
    [searchResult],
  );

  const updateSearchTerms = (newSearchParams: SearchModel) => {
    setIsRequestInitialised(false);
    setSearchResultPage(0);
    setNewSearchParams(newSearchParams);
  };

  // eslint-disable-next-line
  const launchSearch = useCallback(
    debounce(() => {
      performAudienceQuery();
    }, DEBOUNCE_TIME),
    [],
  );

  const performAudienceDelete = (): void => {
    const audiencesToDelete = [...selectedAudiences];

    deleteAudiences(audiencesToDelete)
      .catch((error) => {
        setLoadError(`An error occurred whilst deleting an audience: ${error.message}`);
      })
      .finally(() => {
        resetTable();
      });
  };

  const getAllAudiences = (page: number = 0): void => {
    incrementActiveSearchCount();
    listAudiencesPage(searchResultPage, searchType, PAGE_SIZE)
      .then((data) => {
        const searchResultsResponse = data;
        const searchResultsModel = SearchResultsModel.fromExisting(searchResultsResponse);
        if (page === 0) {
          setSearchResult(searchResultsModel);
        }
        if (page !== 0 && searchResult) {
          setSearchResult((prev) => {
            if (prev) {
              const data = [...prev.results, ...searchResultsResponse.results];
              const results = new SearchResultsModel(data, searchResultsResponse.count);
              return results;
            }
            return prev;
          });
        }
        setSearchResultPage(searchResultPage + 1);
        setLoadError(null);
      })
      .catch((error) => {
        setLoadError(`An error occurred whilst retrieving the audience list: ${error}`);
      })
      .finally(() => {
        decrementActiveSearchCount();
        setIsRequestInitialised(false);
      });
  };

  const performAudienceQuery = (): void => {
    setIsRequestInitialised(true);
    if (currentSearchModel.isEmpty()) {
      getAllAudiences();
      return;
    }
    incrementActiveSearchCount();
    searchAudiences(searchResultPage, searchType, currentSearchModel, PAGE_SIZE)
      .then((data) => {
        const searchResultsModel = SearchResultsModel.fromExisting(data);
        setSearchResult(searchResultsModel);

        if (searchResultPage === 0) {
          setSearchResult(searchResultsModel);
        } else {
          const existingIds = new Set(searchResult!.results.map((r) => r.id));
          const newResults = [...searchResult!.results];
          for (const result of searchResultsModel.results) {
            if (!existingIds.has(result.id)) {
              newResults.push(result);
            }
          }
          setSearchResult(new SearchResultsModel(newResults, searchResultsModel.count));
        }
        setSearchResultPage(searchResultPage + 1);
        setLoadError(null);
      })
      .catch((error) => {
        setLoadError(`An error occurred whilst retrieving the search results: ${error}`);
      })
      .finally(() => {
        decrementActiveSearchCount();
        setIsRequestInitialised(false);
      });
  };

  const loadNextPage: DataGridProProps["onRowsScrollEnd"] = async (params) => {
    if (searchResult?.count === params.virtualRowsCount) return;
    if (searchResult?.count === null) return;
    if (
      searchResult?.results &&
      searchResult.count >= params.virtualRowsCount &&
      !currentSearchModel.isEmpty() &&
      !isRequestInitialised
    ) {
      setIsRequestInitialised(true);
      setSearchResultPage((prev) => prev + 1);
      performAudienceQuery();
      return;
    }
    if (
      searchResult?.results &&
      searchResult.results.length <= params.virtualRowsCount &&
      !currentSearchModel.text &&
      !isRequestInitialised
    ) {
      setIsRequestInitialised(true);
      setSearchResultPage((prev) => prev + 1);
      await getAllAudiences(searchResultPage + 1);
    }
  };

  const handleSearchTypeSelected = (searchType: SearchType) => {
    setSearchType(searchType);
    setSearchResultPage(0);
  };

  const user = useUserStore.authenticatedUser();
  const roleNum = user?.role_num ?? 0;

  let canChangeAudiences = false;
  const roleLevels = ConfigurationHelper.GetPermissions().audiences[0];
  if (
    roleLevels.edit.includes(roleNum) ||
    roleLevels.duplicate.includes(roleNum) ||
    roleLevels.merge.includes(roleNum) ||
    roleLevels.distribute.includes(roleNum) ||
    roleLevels.deactivate.includes(roleNum) ||
    roleLevels.delete.includes(roleNum)
  ) {
    canChangeAudiences = true;
  }

  return (
    <div className="table-list">
      <Grid container className="header">
        <HeadingAndButtons
          count={searchResult?.count}
          onAddAudienceClick={() => {
            navigate("/audiences/create");
          }}
          roleNums={ConfigurationHelper.GetPermissions().audiences[0].create}
          roleNumUser={roleNum}
        />
      </Grid>

      <div className="table-container-wrapper">
        <Grid className="table-container">
          <ControlHeader
            leftControl={
              <>
                <Search onSearch={updateSearchTerms} searchModel={currentSearchModel} />
                {searchResult !== null && (
                  <SelectionActions
                    selectedAudiences={selectedAudiences}
                    allAudiences={searchResult.results}
                    onDelete={openModal}
                    onMerge={navigateToMergeAudience}
                    onDuplicate={navigateToDuplicateAudience}
                    onArchive={handleArchive}
                    onDistribute={addOngoingDistribute}
                    onEdit={navigateToEditAudience}
                  />
                )}
              </>
            }
            rightControl={
              <Tabber
                tabs={[SearchType.All, SearchType.Active, SearchType.Inactive]}
                selectedOption={searchType}
                onOptionSelected={handleSearchTypeSelected}
              />
            }
          />

          {loadError === null && (
            <div className={activeSearchesCount === 0 ? "data-grid-table" : "data-grid-table-refreshing"}>
              <ViewAudiencesContextProvider value={context}>
                <AudienceTable
                  audienceList={audienceList}
                  pinnedAudiences={pinnedAudiences}
                  resetPinned={resetPinned}
                  selectable={canChangeAudiences}
                  onAudiencesSelected={setSelectedAudiences}
                  isSearchResult={currentSearchModel.text !== "" || currentSearchModel.filters.size > 0}
                  onScroll={loadNextPage}
                  isLoading={activeSearchesCount !== 0}
                />
              </ViewAudiencesContextProvider>
            </div>
          )}

          {loadError !== null && <Alert severity="error">{loadError}</Alert>}
        </Grid>
      </div>

      <Modal open={modalIsOpen} onClose={closeModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <ConfirmUserActionModal
          modalState={closeModal}
          action="delete"
          performAction={performAudienceDelete}
          confirmationText="Are you sure you want to delete this audience? This cannot be reversed."
        />
      </Modal>
    </div>
  );
}
