import "./user-actions.scss";

import { Grid, Modal, Snackbar } from "@mui/material";
import { useState } from "react";
import { ReactComponent as DeactivateIcon } from "../../../../assets/icon-deactivate.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icon-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icon-trash.svg";
import { ReactComponent as ReactivateIcon } from "../../../../assets/icon-undo.svg";
import { ActionButton } from "../../../../components/action-button";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import type { IuserData } from "../../models/userModel";
import ConfirmUserActionModal from "../../../../components/confirm-user-action-modal";
import EditUsersModal from "./user-actions-modals/edit-users-modal";
import { useUserStore } from "../../../../stores/user";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";

const iconDimensions = {
  width: 20,
  height: 20,
};

interface ISelectionActionsProps {
  selectedUsers: IuserData[];
  reloadUserTable: any;
  setSelectedUsers: any;
}

enum ModalType {
  Edit = "edit",
  Delete = "delete",
  Deactivate = "deactivate",
  Reactivate = "reactivate",
}

export function UserActions({ selectedUsers, reloadUserTable, setSelectedUsers }: ISelectionActionsProps) {
  const user = useUserStore.authenticatedUser();
  const roleNum = user?.role_num;
  const canEdit = ConfigurationHelper.GetPermissions().audiences[0].edit.includes(roleNum ?? 0);

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [deleteError, setDeleteError] = useState("");

  if (selectedUsers.length === 0) return null;

  const handleOpenModal = (type: ModalType) => {
    setOpenModal(true);
    setModalType(type);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const performTableAction = (endpoint: string, action: string) => {
    const usersToUpdate = {
      action: `${action}User`,
      users: [...selectedUsers],
    };

    const userEmailsToUpdate = selectedUsers.map((user: any) => user.email);

    postWithToken(endpoint, usersToUpdate)
      .then(async (response: Response): Promise<void> => {
        if (response.ok) {
          reloadUserTable(action);
        } else {
          const errorMessage = `Error performing action: ${action} user for ${userEmailsToUpdate.join(", ")}.`;
          setDeleteError(errorMessage);
        }
      })
      .catch(() => {
        const errorMessage = `Error performing action: ${action} user for ${userEmailsToUpdate.join(", ")}.`;
        setDeleteError(errorMessage);
      });
  };

  return (
    <>
      <Grid container className="user-actions">
        <Grid
          className="selected-users"
          item
        >{`${selectedUsers.length} user${selectedUsers.length === 1 ? "" : "s"} selected`}</Grid>
        <Grid item>
          {canEdit && (
            <Grid>
              <ActionButton
                disabledMessage="You can only edit one user at a time"
                isDisabled={selectedUsers.length > 1}
                onClick={() => {
                  handleOpenModal(ModalType.Edit);
                }}
                icon={<EditIcon {...iconDimensions} className="icon edit-icon" />}
                buttonText="Edit"
                id="edit-user-button"
              />
              <ActionButton
                disabledMessage={null}
                isDisabled={false}
                onClick={() => {
                  handleOpenModal(ModalType.Delete);
                }}
                icon={<DeleteIcon {...iconDimensions} className="icon delete-icon" />}
                buttonText="Delete"
                id="delete-user-button"
              />
              <ActionButton
                disabledMessage={'All selected users must be "Active" or "Pending"'}
                isDisabled={!selectedUsers.every((user) => user.status === "Active" || user.status === "Pending")}
                onClick={() => {
                  handleOpenModal(ModalType.Deactivate);
                }}
                icon={<DeactivateIcon {...iconDimensions} className="icon deactivate-icon" />}
                buttonText="Deactivate"
                id="deactivate-user-button"
              />
              <ActionButton
                disabledMessage={'All selected users must be "Inactive"'}
                isDisabled={!selectedUsers.every((user) => user.status === "Inactive")}
                onClick={() => {
                  handleOpenModal(ModalType.Reactivate);
                }}
                icon={<ReactivateIcon {...iconDimensions} className="icon reactivate-icon" />}
                buttonText="Reactivate"
                id="deactivate-user-button"
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
          {modalType === "edit" && (
            <EditUsersModal
              user={selectedUsers[0]}
              modalState={handleCloseModal}
              reloadUserTable={reloadUserTable}
              setSelectedUsers={setSelectedUsers}
              variant="edited"
            />
          )}
          {modalType === "delete" && (
            <ConfirmUserActionModal
              itemType="Users"
              items={selectedUsers}
              modalState={handleCloseModal}
              action="delete"
              performAction={() => performTableAction(ConfigurationHelper.GetDeleteUsersEndpoint(), "delete")}
            />
          )}
          {modalType === "deactivate" && (
            <ConfirmUserActionModal
              itemType="Users"
              items={selectedUsers}
              modalState={handleCloseModal}
              action="deactivate"
              performAction={() => performTableAction(ConfigurationHelper.GetUpdateUserEndpoint(), "deactivate")}
            />
          )}
          {modalType === "reactivate" && (
            <ConfirmUserActionModal
              itemType="Users"
              items={selectedUsers}
              modalState={handleCloseModal}
              action="reactivate"
              performAction={() => performTableAction(ConfigurationHelper.GetUpdateUserEndpoint(), "reactivate")}
            />
          )}
        </>
      </Modal>

      <Snackbar
        open={deleteError !== ""}
        autoHideDuration={5000}
        onClose={() => {
          setDeleteError("");
        }}
        message={deleteError}
      />
    </>
  );
}
