import regex from "@vmlyr/common/dist/constants/regex";
import { ConditionValidationState } from "../../../../../../../models/validation-state";

export class SearchModel {
  constructor(public text: string = "") {}

  updateText(newText: string): SearchModel {
    return new SearchModel(newText);
  }

  equals(other: SearchModel) {
    const textMatches = other.text === this.text;

    return textMatches;
  }

  isEmpty(): boolean {
    return this.text === "";
  }

  getValidationState(): ConditionValidationState {
    if (!this.text.match(regex.nameValidation)) {
      return new ConditionValidationState(false, "The search text must not contain special characters");
    }
    return new ConditionValidationState(true);
  }

  static fromExisting(model: SearchModel) {
    return new SearchModel(model.text);
  }
}
