"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OKTA_DEV_FORD_TEST = exports.OKTA_DEV_FORD = exports.OKTA_DEV_DEMO_TEST = exports.OKTA_DEV_DEMO = exports.OKTA_HUB_FORD_TEST = exports.OKTA_HUB_FORD = exports.OKTA_HUB_DEMO_TEST = exports.OKTA_HUB_DEMO = void 0;
// NOTE: these okta groups are provided by wpp.okta.com
exports.OKTA_HUB_DEMO = "GRP-HUB-Authn-VMLYR-WPPAudience-Demo";
exports.OKTA_HUB_DEMO_TEST = "GRP-HUB-Authn-VMLYR-WPPAudience-Test-Demo";
exports.OKTA_HUB_FORD = "GRP-HUB-Authn-VMLYR-WPPAudience";
exports.OKTA_HUB_FORD_TEST = "GRP-HUB-Authn-VMLYR-WPPAudience-Test";
// NOTE: these okta groups are provided by devh-wpp.okta.com
exports.OKTA_DEV_DEMO = "GRP-Devh-Authn-VMLYR-WPPAudience-Demo";
exports.OKTA_DEV_DEMO_TEST = "GRP-Devh-Authn-VMLYR-WPPAudience-Test-Demo";
exports.OKTA_DEV_FORD = "GRP-Devh-Authn-VMLYR-WPPAudience";
exports.OKTA_DEV_FORD_TEST = "GRP-Devh-Authn-VMLYR-WPPAudience-Test";
