import "./test-strips.scss";

// @ts-expect-error
const cypressRunning = window.Cypress;

export function TestStrips() {
  return cypressRunning ? (
    <>
      <div className="test-strip test-strip-top" />
      <div className="test-strip test-strip-bottom" />
    </>
  ) : (
    <></>
  );
}
