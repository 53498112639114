import "./style.scss";

import { Stack } from "@mui/material";
import { useState } from "react";
import { AudienceStringDefinition } from "../../../../../components/string-definition";
import type { Group } from "../../../store/groups";
import { useGroup } from "../../../store/groups";
import { GroupCardHeading } from "../group-card-heading";
import { GroupsQueryBuilder } from "../groups-query-builder";
import { SaveAsAudienceModal } from "../save-as-audience-modal";
import { parseTree } from "./parse-tree";

interface IGroupCardProps {
  groupId: Group["id"];
  stringDefinition: boolean;
}

function getStringDefinitionText(definition: Group["definition"]): string {
  const rootCondition = definition.rootCondition;
  const content = parseTree(rootCondition);
  return content === "" ? "- No definitions set -" : `(${content})`;
}

export function GroupCard({ groupId, stringDefinition }: IGroupCardProps): JSX.Element {
  const [groupOptionsAreOpen, setGroupOptionsAreOpen] = useState<null | HTMLElement>(null);
  const open = Boolean(groupOptionsAreOpen);

  const group = useGroup(groupId);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setGroupOptionsAreOpen(event.currentTarget);
  };

  const handleClose = (): void => {
    setGroupOptionsAreOpen(null);
  };

  const stringDefinitionText = getStringDefinitionText(group.definition ?? "");

  return (
    <Stack className="group-card" spacing={1}>
      <GroupCardHeading
        model={group.definition}
        open={open}
        groupOptionsAreOpen={groupOptionsAreOpen}
        handleClick={handleClick}
        handleClose={handleClose}
        groupId={groupId}
      />
      <AudienceStringDefinition hidden={!stringDefinition} stringDefinitionText={stringDefinitionText} />
      <GroupsQueryBuilder groupId={groupId} condition={group.definition.rootCondition} />
      <SaveAsAudienceModal groupId={groupId} />
    </Stack>
  );
}
