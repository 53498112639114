// TODO: handle dimensions being null

export class ProfileView {
  constructor(
    public dimensions: string[],
    public name: string,
    public id: string,
    public audienceId: string,
    public creatorUserId?: string,
    public lastModifiedByUser?: {
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userId?: string | null;
    },
    public lastModifiedDate?: string | Date | null,
  ) {}
}
