import "./create-project.scss";

import { Box, Snackbar, Stack, Tooltip } from "@mui/material";
import { WppActionButton, WppButton, WppIconExport } from "@platform-ui-kit/components-library-react";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { CenteredProgress } from "../../../components/centered-progress";
import type { SelectableCondition } from "../../../components/demographic-selector/models/selectable-condition";
import { conditionDragged } from "../../../components/query-builder/events-handlers/condition-dragged";
import { AudienceCondition } from "../../../components/query-builder/models/audience-condition";
import type { QueryBuilderModel } from "../../../components/query-builder/models/query-builder-model";
import { SimpleDate } from "../../../components/simple-date";
import { listDimensions } from "../../../connekd-api/dimensions/list";
import type { ProjectOverview } from "../../../connekd-api/projects/get-by-id";
import { getProjectOverview } from "../../../connekd-api/projects/get-by-id";
import { fetchProjectResult } from "../../../connekd-api/projects/query";
import { createProjectExport } from "../../../connekd-api/projects/start-export";
import { usePrompt } from "../../../routing/hooks";
import { useExportsActions, useProjectExportsData } from "../../../stores/exports";
import { ProjectNameInput } from "../components/project-name-input";
import type { GroupQuery, ProjectQueryObject } from "../models/submitQueryModel";
import type { Group } from "../store/groups";
import {
  useGroupHasUnsavedChanges,
  useGroupisSaving,
  useGroupIsValid,
  useGroupsActions,
  useGroupsData,
  useGroupsDropTarget,
  useGroupsNudge,
} from "../store/groups";
import {
  useProjectActions,
  useProjectHasUnsavedChanges,
  useProjectId,
  useProjectTitle,
  useProjectValidation,
} from "../store/projects";
import type { VariableData } from "../store/variables";
import {
  useVariableHasUnsavedChanges,
  useVariableIsValid,
  useVariablesActions,
  useVariablesData,
  useVariablesDropTarget,
} from "../store/variables";
import { useUserStore } from "../../../stores/user";
import { DemographicPicker } from "./demographic-picker";
import { GroupsSection } from "./groups-section";
import { ResultsSection } from "./results-section";
import { VariablesSection } from "./variables-section";
import { useHasRunState, useProjectRunActions } from "../store/projects";

const MAX_EXPORT_LIMIT = 10;

function useUnsavedChanges(): boolean {
  const dirtyProjects = useProjectHasUnsavedChanges();
  const dirtyGroups = useGroupHasUnsavedChanges();
  const dirtyVariables = useVariableHasUnsavedChanges();
  return dirtyProjects || dirtyGroups || dirtyVariables;
}

export function CreateProject(): JSX.Element {
  const params = useParams();
  const { state } = useLocation();
  // NOTE: forcing re-render to update Run button ; this can go when we correct the QueryBuilderModel state issue
  useGroupsNudge();
  const isSaving = useGroupisSaving();

  const { hasRun } = useProjectRunActions();
  const hasRunProject = useHasRunState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalProjectTitle, setOriginalProjectTitle] = useState<string>("");
  const [projectLastUpdated, setProjectLastUpdated] = useState<{ lastUpdated: string | null } | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const [saveMessage, setSaveMessage] = useState<string | undefined>();

  const clearSaveMessage = (): void => {
    setSaveMessage(undefined);
  };

  const userData = useUserStore.authenticatedUser();

  const { overrideProjectId, overrideProjectTitle, resetProject, saveProject, setResults, setResultsLoading } =
    useProjectActions();

  const { updateGroupDefinition, resetGroups, overrideGroups, hasGroupChanged } = useGroupsActions();
  const groupsDropTarget = useGroupsDropTarget();

  const { updateVariableDefinition, resetVariables, overrideVariables, haveVariablesSelectionChanged } =
    useVariablesActions();
  const variablesDropTarget = useVariablesDropTarget();

  const hasUnsavedChanges = useUnsavedChanges();

  const hasTitle = useProjectTitle() !== "";
  const projectIsValid = useProjectValidation() === null && hasTitle;
  const groupsAreValid = useGroupIsValid();
  const variablesAreValid = useVariableIsValid();

  const queryIsValid = groupsAreValid && variablesAreValid;

  const canSave = hasRunProject && projectIsValid && queryIsValid && hasUnsavedChanges;

  const groups = useGroupsData();
  const variables = useVariablesData();
  const projectIdFromParams = params.projectId;
  const currentExportIds = useProjectExportsData();

  useEffect(() => {
    if (projectIdFromParams !== undefined) {
      overrideProjectId(projectIdFromParams);
    }
  }, [overrideProjectId, projectIdFromParams]);

  const projectIdFromState = useProjectId();

  const { addProjectExportId } = useExportsActions();

  const handleDemographicDropped = (selectableCondition: SelectableCondition): void => {
    if (selectableCondition.draggedObject !== null) {
      const droppedOntoCondition = selectableCondition.draggedObject();

      if (groupsDropTarget !== null) {
        const group: Group | undefined = groups.find((g) => g.id === groupsDropTarget);

        if (group !== undefined) {
          const groupDef: QueryBuilderModel = group.definition;
          if (groupDef.getDraggedOverCondition() !== null) {
            const newDefinition = conditionDragged(droppedOntoCondition, groupDef);
            newDefinition.cancelDrag();
            updateGroupDefinition(groupsDropTarget, newDefinition);
            return;
          }
        }
      }

      if (variablesDropTarget !== null) {
        // disallows Saved Audiences from being dropped into variables
        if (droppedOntoCondition instanceof AudienceCondition) {
          return;
        }

        const variable: VariableData | undefined = variables.find((g) => g.id === variablesDropTarget);
        if (variable !== undefined) {
          const variableDef = variable.variableDefinition;
          if (variableDef.getDraggedOverCondition() !== null) {
            const newDefinition = conditionDragged(droppedOntoCondition, variableDef);
            newDefinition.cancelDrag();
            updateVariableDefinition(variablesDropTarget, newDefinition);
          }
        }
      }
    }
  };

  useEffect(() => {
    const currentProjectId: string | undefined = state && state[0] && state[0].id ? state[0].id : projectIdFromParams;
    if (currentProjectId !== undefined && currentProjectId.length > 0) {
      setIsLoading(true);
      listDimensions()
        .then((dimensions) => {
          const dimensionsList = dimensions.flatMap((e) =>
            e.categories.length > 0 ? e.categories.flatMap((f) => f.dimensions) : e.dimensions,
          );

          getProjectOverview(dimensionsList, currentProjectId)
            .then((response: ProjectOverview) => {
              const titlePrefix = state && state[0] && state[0].id ? "Copy of " : "";
              response.title = `${titlePrefix}${response.title}`;
              overrideProjectTitle(response.title);
              setOriginalProjectTitle(response.title);
              overrideGroups(response.groups);
              overrideVariables(response.variables);
              setProjectLastUpdated({ lastUpdated: response.modifiedDatetime });
            })
            .catch((err: Error) => {
              console.error(`unable to get project overview`, err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((err: Error) => {
          console.error(`unable to list dimensions`, err);
        });
    } else {
      resetProject();
      resetGroups();
      resetVariables();
    }
  }, []);

  const lastUpdateInfo =
    projectLastUpdated !== null && projectLastUpdated.lastUpdated !== null ? (
      <div className="last-modified">
        last updated <SimpleDate date={new Date(projectLastUpdated.lastUpdated)} isTimeSince />
      </div>
    ) : null;

  const handleSaveProject = async (): Promise<void> => {
    const isValidUser = userData?.id;

    if (!isValidUser) {
      alert("something went wrong with your credentials");
      return;
    }
    const projectId = state && state[0] ? "" : projectIdFromState;
    const saveResult: { projectId: string; updatedAt: string } = await saveProject(projectId);
    if (saveResult && saveResult.projectId && saveResult.updatedAt) {
      setProjectLastUpdated({ lastUpdated: saveResult.updatedAt });
      overrideProjectId(saveResult.projectId.split("-")[0]);
      setSaveMessage("✅ Project saved successfully");
      hasGroupChanged(false);
      haveVariablesSelectionChanged(false);
    } else {
      setSaveMessage(`❌ Unable to save project: ${saveResult}`);
    }
  };

  const handleExportButtonClick = async (): Promise<void> => {
    const isValidUser = userData?.id;

    if (!isValidUser) {
      alert("something went wrong with your credentials");
      return;
    }

    if (projectIdFromState) {
      try {
        const response: { exportId: string } = await createProjectExport({ id: projectIdFromState });
        addProjectExportId(response.exportId);
      } catch (error) {
        console.error("Error during project export", error);
      }
    }
  };

  const handleRunButtonClick = (): void => {
    try {
      setResultsLoading(true);
      setSelectedColumns([]);
      const query: ProjectQueryObject = {
        projectId: projectIdFromState,
        groupDefinitions: groups.map(
          (x: Group): GroupQuery => ({ id: x.id, name: x.name, groupDefinition: x.definition.getQuery() }),
        ),
        variableDefinitions: variables.map((x: VariableData) => x.variableDefinition.getQuery()),
      };
      fetchProjectResult(query)
        .then((result: any) => {
          setResults(result);
          const el = document.querySelector("#resultsAnchor");
          el?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        })
        .catch((error) => {
          console.error("unable to run get-project-results", error);
          setResultsLoading(false);
        });
    } catch {
      console.error("unable to get query");
    }

    hasRun(true);
  };

  usePrompt("Are you sure you want to leave? Any unsaved changes will be lost", hasUnsavedChanges);
  const isProjectIdDefined = projectIdFromState !== undefined && projectIdFromState.length > 0;
  const canRunExports = isProjectIdDefined && hasRunProject && currentExportIds.length <= MAX_EXPORT_LIMIT;

  const snackbar = (
    <Snackbar
      open={saveMessage !== undefined}
      autoHideDuration={5000}
      onClose={() => {
        clearSaveMessage();
      }}
      message={saveMessage}
    />
  );

  const exportTooltipText = canRunExports
    ? ""
    : `Run and save the Project before attempting to export. You can only run ${MAX_EXPORT_LIMIT} exports at the same time`;

  const exportButton = (
    <Tooltip title={exportTooltipText}>
      <WppActionButton
        className="export-project-button"
        variant="primary"
        disabled={!canRunExports}
        onClick={handleExportButtonClick}
      >
        <WppIconExport slot="icon-start" />
        Export
      </WppActionButton>
    </Tooltip>
  );

  const runTooltipText = queryIsValid ? "" : "Populate groups and variables to generate results";
  const runButton = (
    <Tooltip title={runTooltipText}>
      <WppButton
        className="run-project-button"
        variant="secondary"
        disabled={!queryIsValid}
        onClick={handleRunButtonClick}
      >
        Run
      </WppButton>
    </Tooltip>
  );

  const saveTooltipText = canSave
    ? ""
    : projectIsValid
      ? "Populate groups and variables to save your project"
      : "Please add a project title";

  const saveButton = (
    <Tooltip title={saveTooltipText}>
      <WppButton className="save-project-button" variant="primary" disabled={!canSave} onClick={handleSaveProject}>
        Save
      </WppButton>
    </Tooltip>
  );

  const projectHeaderPane = (
    <div className="project-header">
      <ProjectNameInput originalProjectTitle={originalProjectTitle} />
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        {lastUpdateInfo}
        {exportButton}
        {runButton}
        {saveButton}
      </Box>
    </div>
  );

  const editor = (
    <>
      {snackbar}
      <Stack className="create-project" direction="row">
        <Box className="pane-for-dimension-selector">
          {!isSaving && <DemographicPicker handleDemographicDropped={handleDemographicDropped} />}
        </Box>
        <Box className="project-config" sx={{ overflow: "auto", height: "auto", flex: 1 }}>
          {projectHeaderPane}
          <Stack className="create-project-query-sections">
            <GroupsSection />
            <VariablesSection />
            <ResultsSection
              hasRunProject={hasRunProject}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
          </Stack>
        </Box>
      </Stack>
    </>
  );

  return <div className="explore-create-project-page">{isLoading ? <CenteredProgress /> : editor}</div>;
}
