import "./individual-metric.scss";

import { Grid } from "@mui/material";

interface IKeyMetricProps {
  quantity: string;
  label: string;
}

export function IndividualMetric({ quantity, label }: IKeyMetricProps) {
  return (
    <Grid item xs={4} lg={2} sx={{ textAlign: { xs: "center", lg: "left" } }} className="individual-metric">
      <h4>{quantity}</h4>
      <p>{label}</p>
    </Grid>
  );
}
