import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";
import regex from "@vmlyr/common/dist/constants/regex";
import { deepClone } from "../../../helpers/object-helpers";
import type { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import type { DragType } from "../../../models/query-builder/drag-type";
import type { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";

export class QueryBuilderModel {
  constructor(
    public readonly rootCondition: QueryCondition,
    public name: string,
    public taxonomy: ITaxonomicNamePart[] = [],
    public readonly id: string,
    public readonly currentVersionId: string,
    public readonly lastModified: Date | null,
    public readonly lastModifiedBy: string,
  ) {
    rootCondition.setParent(null);
    if (id) {
      const existingSegment = taxonomy.find((t) => t.segmentName === "id");
      existingSegment
        ? (existingSegment.value = id)
        : taxonomy.push({ value: id, segmentName: "id", isEditable: false });
    }
  }

  setTaxonomy(taxonomicParts: ITaxonomicNamePart[]) {
    const getSegmentByName = (array: ITaxonomicNamePart[], segmentName: string) => {
      return array.find((item) => item.segmentName === segmentName);
    };

    const currentModelDescriptor = getSegmentByName(this.taxonomy, "Descriptor");
    const taxonomicPartsDescriptor = getSegmentByName(taxonomicParts, "Descriptor");

    if (currentModelDescriptor && !taxonomicPartsDescriptor) {
      taxonomicParts.push(currentModelDescriptor);
    }

    this.taxonomy = taxonomicParts;
  }

  getTaxonomy() {
    return deepClone(this.taxonomy);
  }

  public static fromExisting(model: QueryBuilderModel, rootCondition: QueryCondition | null = null) {
    return new QueryBuilderModel(
      rootCondition || model.rootCondition,
      model.getName(),
      model.taxonomy,
      model.id,
      model.currentVersionId,
      model.lastModified,
      model.lastModifiedBy,
    );
  }

  setName(newName: string) {
    this.name = newName;
  }

  getName(): string {
    return this.name;
  }

  setDescriptor(newDescriptor: string) {
    const containsDescriptor = this.taxonomy.find((e) => e.segmentName === "Descriptor");
    if (containsDescriptor) {
      containsDescriptor.value = newDescriptor;
    } else {
      this.taxonomy.push({ segmentName: "Descriptor", value: newDescriptor, isEditable: true });
    }
  }

  getDescriptor(): string {
    const containsDescriptor: ITaxonomicNamePart | undefined = this.taxonomy.find(
      (e) => e.segmentName === "Descriptor",
    );

    return containsDescriptor?.value === null || containsDescriptor?.value === undefined
      ? ""
      : containsDescriptor.value;
  }

  validateName(): ConditionValidationState {
    if (!this.name) {
      return new ConditionValidationState(false, "Add name");
    }
    if (this.name.length > 50) {
      return new ConditionValidationState(false, "Audience name cannot exceed over 50 characters");
    }
    if (!this.name.match(regex.nameValidation)) {
      return new ConditionValidationState(false, "Please only use alphanumeric characters, spaces and punctuation");
    }
    if (!this.name.match(regex.startsWithLetterOrNumber)) {
      return new ConditionValidationState(false, "The audience name must start with an alphanumeric character");
    }
    if (!this.name.match(regex.endsWithLetterOrNumber)) {
      return new ConditionValidationState(false, "The audience name must end with an alphanumeric character");
    }
    return new ConditionValidationState(true);
  }

  validateTaxonomy(): ConditionValidationState {
    if (!this.taxonomy) {
      return new ConditionValidationState(false, "Please set the audience's taxonomic name");
    }
    if (
      this.taxonomy.some((t: ITaxonomicNamePart) => t.value === null) ||
      this.taxonomy.some((t: ITaxonomicNamePart) => t.value?.length === 1 && t.segmentName !== "Descriptor") ||
      this.taxonomy.length === 0
    ) {
      return new ConditionValidationState(false, "Add tags");
    }
    return new ConditionValidationState(true);
  }

  validateDescriptor(): ConditionValidationState {
    const descriptor = this.taxonomy.find((item) => item.segmentName === "Descriptor");
    if (descriptor?.value?.length && descriptor.value.length > 20) {
      return new ConditionValidationState(false, "Maximum character limit of 20 exceeded");
    }
    if (descriptor?.value?.length && !descriptor.value.match(regex.containsOnlyAlphanumeric)) {
      return new ConditionValidationState(false, "Please only use alphanumeric characters");
    }
    return new ConditionValidationState(true);
  }

  highlight(highlightedCondition: QueryCondition) {
    this.rootCondition.setHighlight(highlightedCondition);
  }

  removeHighlight(highlightedCondition: QueryCondition) {
    this.rootCondition.removeHighlight(highlightedCondition);
    const conditionParent = highlightedCondition.getParent();
    if (conditionParent !== null) {
      this.highlight(conditionParent);
    }
  }

  setDraggedOverCondition(draggedOver: QueryCondition, type: DragType) {
    this.rootCondition.setIsDragTarget(draggedOver, type);
  }

  getDraggedOverCondition() {
    return this.rootCondition.getDraggedOverCondition();
  }

  setConditionBeingDragged(condition: QueryCondition | null) {
    this.rootCondition.setDraggedCondition(condition);
  }

  getConditionBeingDragged() {
    return this.rootCondition.getConditionBeingDragged();
  }

  cancelDrag(): void {
    this.rootCondition.setIsDragTarget(null, null);
    this.rootCondition.setDraggedCondition(null);
  }

  setDraggingDemographic() {
    this.cancelDrag();
  }

  getValidationState(): ConditionValidationState {
    const nameValidationState = this.validateName();
    if (!nameValidationState.isValid) {
      return nameValidationState;
    }

    const taxonomyValidationState = this.validateTaxonomy();
    if (!taxonomyValidationState.isValid) {
      return taxonomyValidationState;
    }

    const descriptorValidationState = this.validateDescriptor();
    if (!descriptorValidationState.isValid) {
      return descriptorValidationState;
    }

    return this.rootCondition.getValidationState();
  }

  /**
   * Gets an object describing all of the data within the query and the relationship between different parts of the query
   */
  getQuery(): AudienceDefinitionData {
    return this.rootCondition.getConditionData();
  }

  /**
   * Gets an object describing all of the data within the query and the relationship between different parts of the query
   */
  getQueryAsString(): string {
    return JSON.stringify(this.getQuery());
  }
}
