import { Container } from "@mui/material";
import type React from "react";
import "./confirmation-card.scss";
import { WppIconPending } from "@platform-ui-kit/components-library-react";

const ConfirmationCard: React.FC = () => {
  const iconDimensions = {
    width: 40,
    height: 40,
    color: "#8508E8",
  };
  return (
    <Container className="confirmation-container" maxWidth="sm">
      <div className="confirmation-container-inner">
        <WppIconPending {...iconDimensions} />
        <h2>Access Pending</h2>
        <p>We'll email you once you have access</p>
        <p>Typical response time is 24 hours</p>
      </div>
    </Container>
  );
};

export default ConfirmationCard;
