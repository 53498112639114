import { useOktaAuth } from "@okta/okta-react";
import { Outlet, Route } from "react-router-dom";
import { useEffect } from "react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { setCurrentIdentity } from "@vmlyr/appserviceshared/dist/hooks/useIdentity";
import { getApplicationGroups } from "@vmlyr/common/dist/helpers/group-claim-helper";
import { PublicNavigationBar } from "../components/header/nav/public-nav";
import RequestAccessForm from "../screens/landing/requestAccessForm";
import ConfigurationHelper from "../helpers/configuration-helper";
import { useGetUser } from "../hooks/useGetUser";

const oktaTokenRenewAtMins = 5;

declare global {
  interface Window {
    oktaTokenRenewing: boolean;
  }
}

window.oktaTokenRenewing = false;

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { user } = useGetUser();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  const oktaTokenData = sessionStorage.getItem("okta-token-storage");
  if (oktaTokenData && oktaTokenData !== "{}") {
    const timeExpiry = JSON.parse(oktaTokenData || "").accessToken?.expiresAt;
    const timeNow = Math.floor(Date.now() / 1000);
    if (timeExpiry - timeNow < oktaTokenRenewAtMins * 60 && !window.oktaTokenRenewing) {
      window.oktaTokenRenewing = true;
      oktaAuth.tokenManager.renew("accessToken").then(function () {
        window.oktaTokenRenewing = false;
      });
    }
  }

  if (authState === null) {
    console.log("auth state was undefined");
    return null;
  }

  if (!authState.isAuthenticated) {
    const originalUri = window.location.pathname + window.location.search;
    oktaAuth.signInWithRedirect({ originalUri }).catch((error) => {
      console.error("okta sign-in error", error);
    });

    return null;
  }

  const { dollyFeature } = ConfigurationHelper.GetFeatureToggles();

  setCurrentIdentity({
    username: authState.accessToken!.claims.sub.toLowerCase(),
    // Sort alphabetically, so index position 0 as the default group (as it will have shortest name)
    // This is used in establishing the default group for users
    audienceToolGroups: getApplicationGroups(authState.accessToken!.claims, dollyFeature.useTestGroups),
    accessToken: authState.accessToken!.accessToken,
  });

  if (!authState || !authState?.isAuthenticated) {
    return <></>;
  }
  if (authState?.isAuthenticated && user?.status === "success" && !user?.data?.id) {
    return (
      <Route
        path="/request-access"
        element={
          <>
            <PublicNavigationBar />
            <RequestAccessForm setToggleConfirmation />
          </>
        }
      />
    );
  }
  if (authState?.isAuthenticated && user?.status === "success" && user?.data?.id) {
    return <Outlet />;
  }

  return <></>;
};
