import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { User } from "../types/users";
import { createSelectors } from "../zustand/selectors";

interface UserData {
  isAdmin: boolean;
  canSeeExplore: boolean;
  authenticatedUser: User | null;
}

interface UserActions {
  loadUser: (user: User) => void;
}

export type UserState = UserData & { actions: UserActions };

const useUserStoreBase = create(
  devtools<UserState>(
    (set) => ({
      isAdmin: false,
      canSeeExplore: false,
      authenticatedUser: null,

      actions: {
        loadUser: (user: User): void => {
          set(() => ({
            canSeeExplore: user.role_num >= 3,
            isAdmin: user.role_num >= 8,
            authenticatedUser: user,
          }));
        },
      },
    }),
    { name: "userStore" },
  ),
);

export const useUserStore = createSelectors(useUserStoreBase).use;

export const useAuthenticatedUser = (): UserState["authenticatedUser"] =>
  useUserStoreBase((store) => store.authenticatedUser);

export const useUserCanSeeExplore = (): UserState["canSeeExplore"] => useUserStoreBase((store) => store.canSeeExplore);

export const useUserIsAdmin = (): UserState["isAdmin"] => useUserStoreBase((store) => store.isAdmin);

export const useUserActions = (): UserActions => useUserStoreBase((store) => store.actions);
