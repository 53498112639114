import type { SelectableDimension, SelectableDimensionCategory } from "../../models/selectable-dimension";

const extractDimensionsFromCategory = (selectableCategory: SelectableDimensionCategory) => {
  let { dimensions } = selectableCategory;
  for (const c of selectableCategory.categories) {
    dimensions = dimensions.concat(extractDimensionsFromCategory(c));
  }
  return dimensions;
};

export const extractSelectableDimensions = (selectableCategories: SelectableDimensionCategory[]) => {
  let dimensions = new Array<SelectableDimension>();
  for (const c of selectableCategories) {
    dimensions = dimensions.concat(extractDimensionsFromCategory(c));
  }
  return dimensions;
};
