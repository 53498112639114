import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteButton } from "../../../../../components/delete-button/delete-button";
import { MultipleValueEditor } from "../../../../../components/editors/multiple";
import type { MultiRangeCondition } from "../../../../../components/query-builder/models/multi-range-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";

interface IMultipleConditionEditor {
  condition: MultiRangeCondition;
}

export function MultiRangeConditionEditor({ condition }: IMultipleConditionEditor): JSX.Element {
  const context = useCreateAudienceContext();

  const handleStateSelected = (state: string[]) => {
    condition.setValue(state);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className="editor-state-condition">
      <DeleteButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        isHidden={!condition.getIsHighlighted()}
      />
      <MultipleValueEditor
        currentValue={condition.getValue()}
        options={Object.keys(condition.getAllowedValues())}
        valueName={"Age Groups"}
        onOptionChosen={handleStateSelected}
        error={error}
        showPaperComponents={false}
      />
    </div>
  );
}
