import "./table-action.scss";

import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import type { GridEventListener, GridRowParams, GridSelectionModel } from "@mui/x-data-grid-pro";
import type { Profiles as IProfiles, Profiles } from "@vmlyr/connekdfordshared/dist/models/api/audience-profiles";
import type { Dispatch, MutableRefObject, SetStateAction } from "react";
import type { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { useNavigate } from "react-router-dom";
import type { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { handleSortModelChange } from "../../../../components/table-common/handle-sort-model-change";
import ProfilesTableDefinition from "./profiles-table-model";

const arrayValuesToObjectKeys = (array: Array<string | number>): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  for (const value of array) {
    result[`${value}`] = true;
  }
  return result;
};

const initialTableState: GridInitialStatePro = {
  columns: {
    columnVisibilityModel: {
      pinned: false,
      sortableDate: false,
    },
  },
  sorting: {
    sortModel: [
      { field: "pinned", sort: "desc" },
      { field: "sortableDate", sort: "desc" },
    ],
  },
};

interface IProfilesTable {
  profilesResults: Profiles[];
  onProfilesSelected: (selectedProfiles: IProfiles[]) => void;
  isLoading: boolean;
  handleTableScroll: GridEventListener<"rowsScrollEnd">;
  apiRef: MutableRefObject<GridApiPro>;
  setPinnedRows: Dispatch<SetStateAction<IProfiles[]>>;
  pinnedRows: IProfiles[];
  setSelectedIds: Dispatch<SetStateAction<GridSelectionModel>>;
  selectedIds: GridSelectionModel;
}

export const ProfilesTable = ({
  profilesResults,
  onProfilesSelected,
  isLoading,
  handleTableScroll,
  apiRef,
  setPinnedRows,
  pinnedRows,
  setSelectedIds,
  selectedIds,
}: IProfilesTable): JSX.Element => {
  const [tableRowData, setTableRowData] = useState<Profiles[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const profileIds: string[] = profilesResults ? profilesResults.map((value) => value.id) : [];
    const profileIdObject = arrayValuesToObjectKeys(profileIds);
    for (const row of pinnedRows) {
      if (profileIdObject[row.id] === undefined) {
        profilesResults.push(row);
      }
    }
    recalculatePinned();
    const rowsWithPinnedUpdated = updatePinnedColumnInRowData(profilesResults || []);
    setTableRowData(rowsWithPinnedUpdated);
  }, [profilesResults]);

  useEffect(() => {
    recalculatePinned();
    setTableRowData((trd) => updatePinnedColumnInRowData(trd));
  }, [selectedIds]);

  const recalculatePinned = (): void => {
    const selectedIdsObject = arrayValuesToObjectKeys(selectedIds);
    const pinnedRows = tableRowData.filter((row) => !!selectedIdsObject[row.id]);
    setPinnedRows(pinnedRows);
    onProfilesSelected(pinnedRows);
  };

  const updatePinnedColumnInRowData = (rows: Profiles[]) => {
    const selectedIdsObject = arrayValuesToObjectKeys(selectedIds);
    const updatedRows = rows.map((row) => ({
      ...row,
      pinned: !!selectedIdsObject[row.id],
    }));
    return updatedRows.sort((a: any, b: any) => b.pinned - a.pinned);
  };

  const handleProfilesSelected = (selectedRows: GridSelectionModel): void => {
    setSelectedIds(selectedRows);
  };

  const page: string = window.location.pathname;
  const pageHeight: string = page.indexOf("/profiles") === 0 ? `${window.innerHeight - 300}px` : "600px";
  const handleRowClick = (param: GridRowParams): void => {
    navigate(`/audiences/${param.row.audience.id}?profile=${param.id}`);
  };

  return (
    <Box className="profiles-table" sx={{ height: pageHeight }}>
      <DataGrid
        initialState={initialTableState}
        apiRef={apiRef}
        loading={isLoading}
        onRowsScrollEnd={handleTableScroll}
        checkboxSelection
        columns={ProfilesTableDefinition()}
        rows={tableRowData}
        onSelectionModelChange={handleProfilesSelected}
        disableSelectionOnClick
        onRowClick={handleRowClick}
        selectionModel={selectedIds}
        hideFooter
        onSortModelChange={handleSortModelChange}
      />
    </Box>
  );
};
