import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";

export const addFriendIdSegment = (segments: ITaxonomicNamePart[], friendlyId: string) => {
  if (friendlyId && !segments.find((s: ITaxonomicNamePart) => s.value === friendlyId)) {
    segments.push({
      value: friendlyId,
      segmentName: "uniqueId",
      isEditable: false,
    });
  }
  const friendIdIndex = segments.indexOf(segments.find((s) => s.value === friendlyId)!);
  if (friendIdIndex !== -1) {
    const friendIdSegment = segments[friendIdIndex];
    segments.splice(friendIdIndex, 1);
    return [...segments, friendIdSegment];
  }
  return segments;
};
