import "./discreet-value-dropdown.scss";

import type { SelectChangeEvent } from "@mui/material";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";

interface Props {
  options: string[];
  valueName: string;
  onOptionChosen: (option: string) => void;
  currentValue: string[] | string;
  error: JSX.Element | null;
}

export function DiscreetValueEditor(props: Props): JSX.Element {
  const { options, valueName, onOptionChosen, currentValue, error } = props;

  const inputId = `${valueName}_input`;
  const inputLabelId = `${inputId}_label`;

  const handleChange = (event: SelectChangeEvent): void => {
    onOptionChosen(event.target.value);
  };

  const selectValue = Array.isArray(currentValue) ? currentValue[0] : currentValue;

  return (
    <div className="editor-discreet-control">
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <InputLabel id={inputLabelId} className={"editor-discreet-label"}>
            {valueName}
          </InputLabel>
        </Grid>

        <Grid item>
          <InputLabel>is</InputLabel>
        </Grid>

        <Grid item>
          <Select
            id={inputId}
            value={selectValue}
            labelId={inputLabelId}
            label={valueName}
            onChange={handleChange}
            size="small"
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {error !== null && (
          <Grid item xs={12}>
            {error}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
