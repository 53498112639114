import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ExportsData {
  currentExportIds: string[];
  projectExportIds: string[];
}

interface ExportsActions {
  addExportId: (exportId: string) => void;
  addProjectExportId: (exportId: string) => void;
  removeExportId: (exportId: string) => void;
  removeProjectExportId: (exportId: string) => void;
}

export type ExportsState = ExportsData & { actions: ExportsActions };

const storage = {
  name: "exports-storage",
  storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
  partialize: (state: ExportsState) => ({ values: state.currentExportIds }),
};

const useExportsStore = create<ExportsState>()(
  persist(
    (set): ExportsState => ({
      currentExportIds: [],
      projectExportIds: [],

      actions: {
        addExportId: (exportId: string) => {
          set((state) => ({
            currentExportIds: [...state.currentExportIds, exportId],
          }));
        },

        addProjectExportId: (exportId: string) => {
          set((state) => ({
            projectExportIds: [...state.projectExportIds, exportId],
          }));
        },

        removeExportId: (exportId: string) => {
          set((state) => ({
            currentExportIds: state.currentExportIds.filter((id) => id !== exportId),
          }));
        },

        removeProjectExportId: (exportId: string) => {
          set((state) => ({
            projectExportIds: state.projectExportIds.filter((id) => id !== exportId),
          }));
        },
      },
    }),

    storage,
  ),
);

export const useExportsData = (): ExportsData["currentExportIds"] => useExportsStore((store) => store.currentExportIds);

export const useProjectExportsData = (): ExportsData["projectExportIds"] =>
  useExportsStore((store) => store.projectExportIds);

export const useExportsActions = (): ExportsActions => useExportsStore((store) => store.actions);
