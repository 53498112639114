import { Button, Grid, Tooltip } from "@mui/material";
import { WppIconChevron } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import type { VariableData } from "../store/variables";
import { useVariablesActions, useVariablesData } from "../store/variables";
import { VariableCard } from "./components/variable-card";

const getTooltipMessage = (count: number, itemType: string): string => {
  return count >= 10 ? `You can add up to 10 ${itemType}` : "";
};

export function VariablesSection(): JSX.Element {
  const { addVariable } = useVariablesActions();
  const variables = useVariablesData();
  const variablesCount = variables.length;

  const [variablesIsOpen, setVariablesIsOpen] = useState(true);

  const handleVariablesToggle = (): void => {
    setVariablesIsOpen(!variablesIsOpen);
  };

  const addVariableTooltipMessage = getTooltipMessage(variablesCount, "variables");

  const variablesCards = (
    <div className={variablesIsOpen ? "section-open rows" : "section-closed rows"}>
      {variables.map((variable: VariableData) => (
        <VariableCard key={variable.id} variableId={variable.id} />
      ))}
    </div>
  );

  const variableTooltip = (
    <Tooltip title={addVariableTooltipMessage}>
      <Button
        aria-controls={variablesIsOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={variablesIsOpen ? "true" : undefined}
        onClick={() => {
          if (variablesCount < 10) {
            addVariable();
          }
        }}
        disableRipple
      >
        <div className="section-add-button">+ Variable</div>
      </Button>
    </Tooltip>
  );

  const variablesSection = (
    <Grid item container className="project-config-section">
      <Grid item xs={6} className="section-button">
        <Button
          aria-controls={variablesIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={variablesIsOpen ? "true" : undefined}
          onClick={handleVariablesToggle}
          disableRipple
        >
          <WppIconChevron className={variablesIsOpen ? "chevron-open" : "chevron-closed"} />
          <div className="section-count">
            <strong>Variables (rows)</strong> <span className="count">{variablesCount}</span>
          </div>
        </Button>
      </Grid>
      <Grid item xs={6} className="section-add">
        {variableTooltip}
      </Grid>
    </Grid>
  );

  return (
    <>
      {variablesSection}
      {variablesCards}
    </>
  );
}
