import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { ICustomAudience } from "@vmlyr/common/models/custom-audience";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function fetchCustomAudiences() {
  try {
    const result: ICustomAudience[] = await getWithToken(ConfigurationHelper.GetCustomAudienceEndpoint());
    if (result) {
      return await result;
    }
  } catch (err: any) {
    console.error("couldnt fetch custom audiences", err);
  }
}
