import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function deleteAudiences(audiences: AudienceOverview[]): Promise<void> {
  const endpoint = ConfigurationHelper.DeleteAudiencesEndpoint();
  const response = await postWithToken(endpoint, audiences);

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  if (response.status !== 204) {
    console.error(response);
    throw new Error("received bad resposne code");
  }
}
