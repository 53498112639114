import { StepLabel } from "@mui/material";
import type React from "react";
import { ReactComponent as Checkmark } from "../../assets/checkmark.svg";

interface Props {
  completed: boolean;
  stepNumber: number;
  label: string;
}

export const ToggleStep: React.FC<Props> = ({ label, completed, stepNumber }) => (
  <StepLabel
    StepIconProps={{
      icon: completed ? (
        <span className="step-complete">
          <Checkmark />
        </span>
      ) : (
        <span className="step-pending">{stepNumber}</span>
      ),
    }}
  >
    {label && <span className="icon-label">{label}</span>}
  </StepLabel>
);
