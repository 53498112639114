import "./save-highlight-modal.scss";

import { Grid, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { WppButton } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import { useProjectActions } from "../../../store/projects";
import {
  useProjectHorizontalHighlight,
  useProjectIndexHighlight,
  useProjectVerticalHighlight,
  useShowSaveHighlightModal,
} from "../../../store/projects";

interface Props {
  handleClose: () => void;
  handleSaveHighlights: (verticalValue: number, horizontalValue: number, indexValue: number) => void;
  verticalHighlight: number;
  horizontalHighlight: number;
  indexHighlight: number;
}

function SaveHighlightModalContainer(props: Props): JSX.Element {
  const { handleClose, handleSaveHighlights, verticalHighlight, horizontalHighlight, indexHighlight } = props;

  const [highlightValues, setHighlightValues] = useState({
    verticalHighlight,
    horizontalHighlight,
    indexHighlight,
  });

  const handleChange = (event: any) => {
    if ((event.target.id !== "index-highlight-number" && event.target.value > 100) || event.target.value < 0) {
      event.target.value = 100;
    }
    setHighlightValues((currentDetails) => ({
      ...currentDetails,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSave = () => {
    handleSaveHighlights(
      highlightValues.verticalHighlight,
      highlightValues.horizontalHighlight,
      highlightValues.indexHighlight,
    );
  };

  return (
    <div className="save-highlight-modal-container">
      <h3 className="save-highlight-header">Figure highlights</h3>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <Grid container direction="column" alignItems="flex-start" spacing={3.7}>
            <Grid item>
              <Typography>Vertical (%)</Typography>
            </Grid>
            <Grid item>
              <Typography>Horizontal (%)</Typography>
            </Grid>
            <Grid item>
              <Typography>Index</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column" alignItems="center" spacing={3.7} sx={{ marginLeft: "35px" }}>
            <Grid item>
              <Typography>Equal or greater than</Typography>
            </Grid>
            <Grid item>
              <Typography>Equal or greater than</Typography>
            </Grid>
            <Grid item>
              <Typography>Equal or greater than</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column" alignItems="flex-end" spacing={2}>
            <Grid item>
              <TextField
                id="vertical-highlight-number"
                type="number"
                size="small"
                value={highlightValues.verticalHighlight}
                name="verticalHighlight"
                sx={{
                  width: "100px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: "#8508e8",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#8508e8",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  inputProps: { max: 100 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="horizontal-highlight-number"
                type="number"
                size="small"
                value={highlightValues.horizontalHighlight}
                name="horizontalHighlight"
                sx={{
                  width: "100px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: "#8508e8",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#8508e8",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  inputProps: { max: 100 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="index-highlight-number"
                type="number"
                size="small"
                value={highlightValues.indexHighlight}
                name="indexHighlight"
                sx={{
                  width: "100px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: "#8508e8",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#8508e8",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="form-btn highlight-buttons" item md={15} spacing={1} justifyContent="flex-end">
        <Grid item>
          <WppButton className="cancel-btn" size="s" variant="secondary" onClick={handleClose}>
            Cancel
          </WppButton>
        </Grid>
        <Grid item>
          <WppButton className="save-btn" size="s" variant="primary" onClick={handleSave}>
            Save
          </WppButton>
        </Grid>
      </Grid>
    </div>
  );
}

export const SaveHighlightModal = (): JSX.Element => {
  const { closeShowSaveHighlightModal, setHighlightsValues } = useProjectActions();
  const shouldShowModal = useShowSaveHighlightModal();
  const verticalHighlight = useProjectVerticalHighlight();
  const horizontalHighlight = useProjectHorizontalHighlight();
  const indexHighlight = useProjectIndexHighlight();

  function handleCloseModal(): void {
    closeShowSaveHighlightModal();
  }

  function handleSaveHighlights(verticalValue: number, horizontalValue: number, indexValue: number): void {
    setHighlightsValues(verticalValue, horizontalValue, indexValue);
    closeShowSaveHighlightModal();
  }

  return (
    <>
      <Modal
        open={shouldShowModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <SaveHighlightModalContainer
          verticalHighlight={verticalHighlight}
          horizontalHighlight={horizontalHighlight}
          indexHighlight={indexHighlight}
          handleClose={handleCloseModal}
          handleSaveHighlights={handleSaveHighlights}
        />
      </Modal>
    </>
  );
};
