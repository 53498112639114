import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";

import ConfigurationHelper from "../../helpers/configuration-helper";

export interface User {
  id: string;
  id_full: string;
  email: string;
  first_name: string;
  last_name: string;
  job_title: string;
  entity_num: number;
  entity: string;
  company_num: number;
  company: string;
  role_num: number;
  role: string;
  status_num: number;
  status: string;
  last_logged_in: string; // Date string in ISO format
  welcome_email_sent: boolean;
  training_complete: boolean;
  messages: string[];
}
export type IUserDataResponse =
  | {
      status: "success";
      data: User;
      message?: string;
    }
  | {
      status: "error";
      data: [];
      message?: string;
    };

export async function getUserData(): Promise<Response> {
  const data = await postWithToken(ConfigurationHelper.GetCurrentUserEndpoint(), undefined);

  if (!data.ok && data.statusText === "No token") {
    throw new Error();
  }

  return data;
}

export async function GetCurrentUserData(maxRetries = 5, retryInterval = 1000): Promise<IUserDataResponse> {
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const data = await getUserData();

      if (!data.ok) {
        return {
          status: "error",
          message: "Couldn't get new user data",
          data: [],
        };
      }

      return await data.json();
    } catch {
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      retries++;
    }
  }

  throw new Error(`After ${retries} retries, we were unable to fetch the current user data`);
}
