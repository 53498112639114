import "./confirm-user-action-modal.scss";

import Close from "@mui/icons-material/Close";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import { WppButton, WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { useState } from "react";

interface IModal {
  items?: any;
  itemType?: string;
  modalState: any;
  action: string;
  performAction: () => void;
  confirmationText?: string;
}

const ConfirmUserActionModal: React.FC<IModal> = ({
  items,
  itemType,
  modalState,
  action,
  performAction,
  confirmationText,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  function updateUser() {
    modalState(false);
    performAction();
  }

  const handleCancel = (): void => {
    modalState(false);
  };

  const handleUserAction = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMessage("");
    updateUser();
  };

  const selectedItemLabel = items
    ? items.map((selectedItem: any) => {
        return itemType === "Users"
          ? selectedItem.email
          : selectedItem.name || selectedItem.profileName || selectedItem.title;
      })
    : null;

  const buttons = (
    <Stack className="button-container" direction="row" spacing={1} justifyContent="flex-end">
      <WppButton className="cancel-btn" variant="secondary" onClick={handleCancel}>
        No, cancel
      </WppButton>
      <Button className="confirm-btn" type="submit" onClick={handleUserAction}>
        {`Yes, ${action}`}
      </Button>
    </Stack>
  );

  return (
    <div className="confirm-user-form-wrapper">
      <div className="confirm-user-modal-container">
        <Grid container>
          <Grid item xs={10}>
            <div className="modal-header">Are you sure?</div>
          </Grid>
          <Grid item xs={2} className="close-btn">
            <IconButton className="close-btn-inner" onClick={handleCancel} size="small">
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Grid>
          {confirmationText ? (
            <p>{confirmationText}</p>
          ) : selectedItemLabel && selectedItemLabel.length > 1 ? (
            <>
              <div className="modal-content">
                {action} {items.length} {itemType}:
              </div>
              <div className="user-list-container">
                <ul className="user-list">
                  {selectedItemLabel.map((email: string, index: number) => (
                    <div key={index}>{email}</div>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <div className="modal-content">
              {action} : {selectedItemLabel}
            </div>
          )}
        </Grid>

        {buttons}

        <div className="error-container">
          {errorMessage !== "" && <WppInlineMessage size="s" type="error" message={errorMessage} />}
        </div>
      </div>
    </div>
  );
};

export default ConfirmUserActionModal;
