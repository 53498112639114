import type React from "react";
import { GetStarted } from "../../../../components/query-builder-get-started";
import { AudienceCondition } from "../../../../components/query-builder/models/audience-condition";
import { CustomAudienceCondition } from "../../../../components/query-builder/models/custom-audience-condition";
import { DiscreetCondition } from "../../../../components/query-builder/models/discreet-condition";
import { EmptyCondition } from "../../../../components/query-builder/models/empty-condition";
import { GroupCondition } from "../../../../components/query-builder/models/group-condition";
import { MultiRangeCondition } from "../../../../components/query-builder/models/multi-range-condition";
import { MultipleCondition } from "../../../../components/query-builder/models/multiple-condition";
import { RangeCondition } from "../../../../components/query-builder/models/range-condition";
import { DragType } from "../../../../models/query-builder/drag-type";
import type { QueryCondition } from "../../../../models/query-builder/query-condition";
import { useCreateAudienceContext } from "../../context/CreateAudienceContext";
import { AudienceConditionEditor } from "./editors/audience-condition-editor";
import { DragTarget } from "./editors/components/drag-target";
import { CustomAudienceConditionEditor } from "./editors/custom-audience-condition-editor";
import { DiscreetConditionEditor } from "./editors/discreet-condition-editor";
import { GroupConditionEditor } from "./editors/group-condition-editor";
import { MultiRangeConditionEditor } from "./editors/multi-range-condition-editor";
import { MultipleConditionEditor } from "./editors/multiple-condition-editor";
import { RangeConditionEditor } from "./editors/range-condition-editor";

interface MouseEventsProps {
  condition: QueryCondition;
  children: JSX.Element;
}

function MouseEventsHandler(props: MouseEventsProps): JSX.Element {
  const { condition, children } = props;

  const context = useCreateAudienceContext();

  const isEmptyCondition = condition instanceof EmptyCondition;

  const conditionIsDescendntOfDraggedCondition =
    condition.getParent() && context.model.getConditionBeingDragged()?.isParentOf(condition);

  const shouldRenderDragTargets =
    condition.getIsDragTarget() && !isEmptyCondition && !conditionIsDescendntOfDraggedCondition;

  const dragAbove = (
    <DragTarget
      onDraggedOver={() => {
        context.onConditionDraggedOver(condition, DragType.Above);
      }}
      dragType={DragType.Above}
    />
  );

  const dragBelow = (
    <DragTarget
      onDraggedOver={() => {
        context.onConditionDraggedOver(condition, DragType.Below);
      }}
      dragType={DragType.Below}
    />
  );

  return (
    <div
      className="editor"
      draggable
      onMouseEnter={() => {
        context.onHighlight(condition);
      }}
      onMouseLeave={() => {
        context.onRemoveHighlight(condition);
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        context.onConditionDraggedOver(condition, DragType.OnTop);
      }}
      onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        context.onConditionDroppedOnto(condition);
      }}
      onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        context.onConditionPickedUp(condition);
      }}
    >
      {shouldRenderDragTargets && dragAbove}
      {children}
      {shouldRenderDragTargets && dragBelow}
    </div>
  );
}

interface IQueryConditionEditorProps {
  condition: QueryCondition;
}

function selectEditor(condition: QueryCondition): JSX.Element {
  if (condition instanceof EmptyCondition) {
    return <GetStarted condition={condition} />;
  }

  if (condition instanceof RangeCondition) {
    return <RangeConditionEditor condition={condition} />;
  }

  if (condition instanceof DiscreetCondition && !condition.dimension.multiSql) {
    return <DiscreetConditionEditor condition={condition} />;
  }

  if (
    condition instanceof MultipleCondition ||
    (condition instanceof DiscreetCondition && condition.dimension.multiSql)
  ) {
    return <MultipleConditionEditor condition={condition as MultipleCondition} />;
  }

  if (condition instanceof AudienceCondition) {
    return <AudienceConditionEditor condition={condition} />;
  }

  if (condition instanceof MultiRangeCondition) {
    return <MultiRangeConditionEditor condition={condition} />;
  }

  if (condition instanceof CustomAudienceCondition) {
    return <CustomAudienceConditionEditor condition={condition} />;
  }

  throw new TypeError(`No condition editor was found for condition type ${condition.NAME}`);
}

export function QueryConditionEditor({ condition }: IQueryConditionEditorProps): JSX.Element {
  if (condition instanceof GroupCondition) {
    return <GroupConditionEditor condition={condition} />;
  }

  const editor = selectEditor(condition);
  return <MouseEventsHandler condition={condition}>{editor}</MouseEventsHandler>;
}
