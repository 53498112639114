import "./key-metrics.scss";

import { Grid } from "@mui/material";
import { WppIconGlobe } from "@platform-ui-kit/components-library-react";
import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import type { GroupConfig } from "@vmlyr/connekdfordshared/config/groups";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import newBackgroundImage from "./assets/background-transparent-x.png";
import oldBackgroundImage from "./assets/background-transparent.png";
import { IndividualMetric } from "./components/key-metric";

const oldMetrics = (
  <>
    <IndividualMetric quantity="230M+" label="Adult consumers" />
    <IndividualMetric quantity="5.9K+" label="Consumer behaviors" />
    <IndividualMetric quantity="9K+" label="Data signals" />
  </>
);

const newMetrics = (
  <>
    <IndividualMetric quantity="234M+" label="Adult consumers" />
    <IndividualMetric quantity="7K+" label="Consumer behaviors" />
    <IndividualMetric quantity="10K+" label="Data signals" />
  </>
);

function allowNewAssets(): boolean {
  const { getAudienceToolGroup } = useAudienceToolGroup();
  const toolGroup: string | null = getAudienceToolGroup();
  const config: GroupConfig = ConfigurationHelper.GetAudienceToolGroups(toolGroup);
  return config.useNewAssets;
}

export function KeyMetrics() {
  const shouldUseNewAssets = allowNewAssets();

  return (
    <div className="key-metrics">
      <div
        className="key-metrics-inner"
        style={{ backgroundImage: `url(${shouldUseNewAssets ? newBackgroundImage : oldBackgroundImage})` }}
      >
        <Grid container>
          <Grid item xs={12}>
            <WppIconGlobe />
            <h4 className="country">United States</h4>
          </Grid>
          {shouldUseNewAssets ? newMetrics : oldMetrics}
        </Grid>
      </div>
    </div>
  );
}
