import { Checkbox, FormControlLabel } from "@mui/material";

interface IWidgetCheckboxProps {
  label: string;
  isSelected: boolean;
  onSelect: () => void;
}

export function WidgetCheckbox({ label, isSelected, onSelect }: IWidgetCheckboxProps): JSX.Element | null {
  return (
    <div key={label} className="widget-options">
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            key={label}
            checked={isSelected}
            onChange={() => {
              onSelect();
            }}
          />
        }
        value={label}
        checked={isSelected}
      />
    </div>
  );
}
