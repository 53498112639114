import "./save-as-audience-modal.scss";

import { CircularProgress, Grid, Modal, Snackbar } from "@mui/material";
import type { TextInputChangeEventDetail } from "@platform-ui-kit/components-library";
import { WppButton, WppInlineMessage, WppTextInput } from "@platform-ui-kit/components-library-react";
import type { WppTextInputCustomEvent } from "@platform-ui-kit/components-library/dist/types/components";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";
import { useState } from "react";
import { QueryBuilderModel } from "../../../../../components/query-builder/models/query-builder-model";
import { Taxonomy } from "../../../../../components/taxonomy";
import ConfigurationHelper from "../../../../../helpers/configuration-helper";
import type { Group } from "../../../store/groups";
import { useGroup, useGroupisSaving, useGroupsActions, useShowSaveGroupModal } from "../../../store/groups";
import { GroupNameInput } from "../group-card-name-input";

interface ISaveAsAudienceModal {
  groupId: Group["id"];
}

interface Props {
  groupId: Group["id"];
  handleClose: () => void;
  saveError: string;
  setSaveError: (error: string) => void;
}

function SaveAsAudienceModalContainer(props: Props): JSX.Element {
  const { groupId, handleClose, saveError, setSaveError } = props;
  const { updateGroupDefinition, toggleIsSavingValue } = useGroupsActions();
  const isSaving = useGroupisSaving();

  const group = useGroup(groupId);

  const taxonomyValidationState = group.definition.validateTaxonomy();
  const descriptorValidationState = group.definition.validateDescriptor();

  const handleFreeTextChange = (event: WppTextInputCustomEvent<TextInputChangeEventDetail>): void => {
    const newModel = QueryBuilderModel.fromExisting(group.definition);
    newModel.setDescriptor(event.target.value);
    updateGroupDefinition(groupId, newModel);
  };

  const handleTaxonomyChange = (newTaxonomy: ITaxonomicNamePart[]): void => {
    const newModel = QueryBuilderModel.fromExisting(group.definition);
    newModel.setTaxonomy(newTaxonomy);
    updateGroupDefinition(groupId, newModel);
  };

  const handleSaveClick = (): void => {
    toggleIsSavingValue(true);

    const payload = {
      data: group.definition.getQuery(),
      audienceName: group.name,
      audienceId: group.definition.id || "",
      currentVersionId: group.definition.currentVersionId || "",
      taxonomy: group.definition.getTaxonomy(),
      descriptor: group.definition.getDescriptor(),
    };

    postWithToken(ConfigurationHelper.CreateAudienceEndpoint(), payload)
      .then(async (response) => {
        if (response.ok) {
          setSaveError(`The audience ${group.name} has been successfully saved.`);
          handleClose();
        } else {
          setSaveError(`The server returned the following error: ${await response.text()}`);
        }
      })
      .catch((err: unknown) => {
        setSaveError(`There was an unexpected error: ${String(err)}`);
      })
      .finally(() => {
        toggleIsSavingValue(false);
      });
  };

  const checkCanSave = (): boolean => {
    return (
      taxonomyValidationState.isValid &&
      descriptorValidationState.isValid &&
      group.name.length > 0 &&
      group.definition.rootCondition.getValidationState().isValid
    );
  };

  return (
    <div className="modal-container">
      <Snackbar
        open={saveError !== ""}
        autoHideDuration={5000}
        onClose={() => {
          setSaveError("");
        }}
        message={saveError}
      />
      <h3 className="save-as-audience-header">Save audience</h3>
      <Grid container className="group-header">
        <Grid item xs={12}>
          <GroupNameInput groupId={group.id} text={group.name} validationError={group.validationError} />
        </Grid>
      </Grid>
      <div className="fill-in-error">
        {!group.definition.rootCondition.getValidationState().isValid && (
          <WppInlineMessage
            type="error"
            size="s"
            message={group.definition.rootCondition.getValidationState().invalidReason}
          />
        )}
      </div>
      <Grid container className="taxonomy-container">
        <Grid item>
          <Taxonomy friendlyId={""} isEditable segments={[]} onChange={handleTaxonomyChange} />
          <div className="taxonomy-error">
            {!taxonomyValidationState.isValid && (
              <WppInlineMessage type="error" size="s" message={taxonomyValidationState.invalidReason} />
            )}
          </div>
        </Grid>
        <Grid item className="custom-descriptor">
          <WppTextInput
            size="s"
            name="customDescriptor"
            placeholder="Custom Descriptor"
            onWppChange={handleFreeTextChange}
            {...[]}
          />
          <div className="custom-descriptor-error">
            {!descriptorValidationState.isValid && (
              <WppInlineMessage type="error" size="s" message={descriptorValidationState.invalidReason} />
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container className="form-btn" md={15} spacing={1} justifyContent="flex-end">
        <Grid item>
          <WppButton className="cancel-btn" size="s" variant="secondary" onClick={handleClose}>
            Cancel
          </WppButton>
        </Grid>
        <Grid item>
          <WppButton
            className="save-btn"
            size="s"
            variant="primary"
            disabled={!checkCanSave()}
            onClick={handleSaveClick}
          >
            Save
          </WppButton>
        </Grid>
        {isSaving && <CircularProgress size="1.5rem" sx={{ top: "0.3rem", position: "relative" }} />}
      </Grid>
    </div>
  );
}

export const SaveAsAudienceModal = ({ groupId }: ISaveAsAudienceModal): JSX.Element => {
  const [saveError, setSaveError] = useState("");

  const { closeSaveGroupModal } = useGroupsActions();
  const saveModalIdFlag = useShowSaveGroupModal();
  const shouldShowThisModal = saveModalIdFlag === groupId;

  function handleCloseModal(): void {
    closeSaveGroupModal();
  }

  return (
    <>
      <Snackbar
        open={saveError !== ""}
        autoHideDuration={5000}
        onClose={() => {
          setSaveError("");
        }}
        message={saveError}
      />
      <Modal
        open={shouldShowThisModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <SaveAsAudienceModalContainer
          groupId={groupId}
          handleClose={handleCloseModal}
          saveError={saveError}
          setSaveError={setSaveError}
        />
      </Modal>
    </>
  );
};
