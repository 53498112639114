"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchResultsModel = void 0;
class SearchResultsModel {
    results;
    count;
    constructor(results, count) {
        this.results = results;
        this.count = count;
    }
    static fromExisting(existing) {
        return new SearchResultsModel(existing.results, existing.count);
    }
}
exports.SearchResultsModel = SearchResultsModel;
