"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecondaryButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_library_react_1 = require("@platform-ui-kit/components-library-react");
function SecondaryButton(props) {
    const { className, label, disabled = false, loading = false, small = false, onClick } = props;
    return ((0, jsx_runtime_1.jsx)(components_library_react_1.WppButton, { className: className ?? "", variant: "secondary", disabled: disabled, loading: !disabled && loading, size: small ? "s" : "m", onClick: onClick, children: label }));
}
exports.SecondaryButton = SecondaryButton;
