import { WppCheckbox } from "@platform-ui-kit/components-library-react";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useEffect, useState } from "react";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import type { IuserData } from "../../models/userModel";

export default function WelcomedCell({
  user,
  reloadUserTable,
  setSelectedUsers,
  searchResult,
}: {
  user: IuserData;
  reloadUserTable: (action?: string, welcomedUser?: any) => void;
  setSelectedUsers: (users: any) => void;
  searchResult: any;
}) {
  const optionName = `welcomed_${user.id}`;
  const [welcomed, setWelcomed] = useState(user.welcome_email_sent);

  useEffect(() => {
    setWelcomed(user.welcome_email_sent);
  }, [searchResult]);

  function SetWelcomeEmailSentValue(userId: string, userEmail: string, checked: boolean) {
    postWithToken(ConfigurationHelper.GetUpdateUserEndpoint(), {
      action: "setWelcomeEmailFlag",
      userId,
      userEmail,
      newSetting: checked,
    })
      .then(async (response) => {
        if (response.ok) {
          reloadUserTable("welcomed", { id: user.id, welcome_email_sent: checked });
          setSelectedUsers([]);
        } else {
          console.error(`Error setting welcome email sent setting for ${userEmail}`);
          console.error(response);
        }
      })
      .catch((err) => {
        console.error(`There was an unexpected error: ${err}`);
      });
  }

  return (
    <WppCheckbox
      name={optionName}
      checked={welcomed}
      onWppChange={({ detail: { checked } }) => {
        SetWelcomeEmailSentValue(user.id, user.email, checked);
      }}
    />
  );
}
