import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function archiveAudiences(audiences: AudienceOverview[]): Promise<void> {
  const audienceIds = audiences.map((a) => a.id);

  const response = await postWithToken(ConfigurationHelper.ArchiveAudienceEndpoint(), { audienceIds });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
}
