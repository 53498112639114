import { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";

export class AudienceCondition extends QueryCondition {
  public readonly NAME: string = "AudienceCondition";

  constructor(public readonly friendlyId: string) {
    super();
  }

  isParentOf(): boolean {
    return false;
  }

  getValidationState(): ConditionValidationState {
    return new ConditionValidationState(true);
  }

  getConditionData(): AudienceDefinitionData {
    return new AudienceDefinitionData({ friendlyId: this.friendlyId }, null, null, null, "audience");
  }
}
