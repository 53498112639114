import "./search.scss";

import { Grid } from "@mui/material";
import { WppTextInput, WppIconSearch } from "@platform-ui-kit/components-library-react";

interface ISearchProps {
  onSearch: (searchText: string) => void;
  searchTerm: string;
}

export function SearchProfiles({ onSearch, searchTerm }: ISearchProps): JSX.Element {
  const handleSearchChanged = (e: any): void => {
    onSearch(e.target?.value || "");
  };

  return (
    <span className="search">
      <Grid container>
        <Grid item>
          <WppTextInput name="searchText" placeholder="Search" onWppChange={handleSearchChanged} value={searchTerm}>
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
          </WppTextInput>
        </Grid>
      </Grid>
    </span>
  );
}
