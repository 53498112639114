import "./styles.scss";

import type React from "react";
import { useState } from "react";
import { conditionDragged } from "../../../../../components/query-builder/events-handlers/condition-dragged";
import { AudienceCondition } from "../../../../../components/query-builder/models/audience-condition";
import { CustomAudienceCondition } from "../../../../../components/query-builder/models/custom-audience-condition";
import { DiscreetCondition } from "../../../../../components/query-builder/models/discreet-condition";
import { EmptyCondition } from "../../../../../components/query-builder/models/empty-condition";
import { GroupCondition } from "../../../../../components/query-builder/models/group-condition";
import { MultiRangeCondition } from "../../../../../components/query-builder/models/multi-range-condition";
import { MultipleCondition } from "../../../../../components/query-builder/models/multiple-condition";
import { QueryBuilderModel } from "../../../../../components/query-builder/models/query-builder-model";
import { RangeCondition } from "../../../../../components/query-builder/models/range-condition";
import { DragType } from "../../../../../models/query-builder/drag-type";
import type { QueryCondition } from "../../../../../models/query-builder/query-condition";
import type { Group } from "../../../store/groups";
import { useGroup, useGroupsActions } from "../../../store/groups";
import { AudienceConditionEditor } from "../query-builder/editors/audience-condition-editor";
import { DragTarget } from "../query-builder/editors/components/drag-target";
import { CustomAudienceConditionEditor } from "../query-builder/editors/custom-audience-condition-editor";
import { DiscreetConditionEditor } from "../query-builder/editors/discreet-condition-editor";
import { EmptyConditionEditor } from "../query-builder/editors/empty-condition/empty-condition-editor";
import { GroupConditionEditor } from "../query-builder/editors/group-condition-editor";
import { MultiRangeConditionEditor } from "../query-builder/editors/multi-range-condition-editor";
import { MultipleConditionEditor } from "../query-builder/editors/multiple-condition-editor";
import { RangeConditionEditor } from "../query-builder/editors/range-condition-editor";

interface MouseEventsProps {
  condition: QueryCondition;
  groupId: Group["id"];
  children: JSX.Element;
}

const useDragTargetState = () => {
  const setIsDragTarget = useState<boolean>(false)[1];
  return setIsDragTarget;
};

function MouseEventsHandler(props: MouseEventsProps): JSX.Element {
  const { condition, children, groupId } = props;

  const { updateGroupDefinition, setGroupsDropTarget } = useGroupsActions();

  const definition = useGroup(groupId).definition;

  const isEmptyCondition = condition instanceof EmptyCondition;

  const isDescendant = condition.getParent() !== null && condition.getConditionBeingDragged()?.isParentOf(condition);

  const setIsDragTarget = useDragTargetState();

  const handleMouseEnter = (): void => {
    definition.highlight(condition);
  };

  const handleMouseLeave = (): void => {
    definition.removeHighlight(condition);
  };

  const handleDragStart = (): void => {
    const newModel = QueryBuilderModel.fromExisting(definition);
    newModel.setConditionBeingDragged(condition);
    newModel.setDraggedOverCondition(condition.getParent()!, DragType.OnTop);
    updateGroupDefinition(groupId, newModel);
  };

  const handleDragEnter = (type: DragType): void => {
    setGroupsDropTarget(groupId);
    const newModel = QueryBuilderModel.fromExisting(definition);
    newModel.setDraggedOverCondition(condition, type);
    updateGroupDefinition(groupId, newModel);
  };

  const handleDragLeave = (): void => {
    const newDefinition = conditionDragged(condition, definition);
    if (props.condition.NAME === "EmptyCondition") {
      newDefinition.cancelDrag();
      updateGroupDefinition(groupId, newDefinition);
    }
  };

  const handleDragEnterGroup = (dragType: DragType): void => {
    handleDragEnter(dragType);
    setIsDragTarget(true);
  };

  const handleDragLeaveGroup = (): void => {
    const newDefinition = conditionDragged(condition, definition);
    newDefinition.cancelDrag();
    setIsDragTarget(false);
  };

  const shouldRenderDragTargets: boolean = !isEmptyCondition && !(isDescendant ?? false) && condition.getIsDragTarget();

  const dropAbove: JSX.Element = (
    <DragTarget
      onDragEnter={() => {
        handleDragEnterGroup(DragType.Above);
      }}
      onDragLeave={() => {
        handleDragLeaveGroup();
        handleDragEnter(DragType.Above);
        const newDefinition = conditionDragged(condition, definition);
        newDefinition.cancelDrag();
      }}
      dragType={DragType.Above}
    />
  );

  const dropBelow: JSX.Element = (
    <DragTarget
      onDragEnter={() => {
        handleDragEnterGroup(DragType.Below);
      }}
      onDragLeave={() => {
        handleDragLeaveGroup();
        handleDragEnter(DragType.Below);
        const newDefinition = conditionDragged(condition, definition);
        newDefinition.cancelDrag();
      }}
      dragType={DragType.Below}
    />
  );

  return (
    <div
      className="editor group-editor"
      draggable
      onMouseEnter={() => {
        handleMouseEnter();
      }}
      onMouseLeave={() => {
        handleMouseLeave();
      }}
      onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragStart();
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragEnter(DragType.OnTop);
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
      }}
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragLeave();
      }}
    >
      {shouldRenderDragTargets && dropAbove}
      {children}
      {shouldRenderDragTargets && dropBelow}
    </div>
  );
}

function selectEditor(condition: QueryCondition, groupId: Group["id"]): JSX.Element {
  if (condition instanceof EmptyCondition) {
    return <EmptyConditionEditor condition={condition} />;
  }

  if (condition instanceof RangeCondition) {
    return <RangeConditionEditor condition={condition} groupId={groupId} />;
  }

  if (condition instanceof DiscreetCondition && condition.dimension.multiSql === null) {
    return <DiscreetConditionEditor condition={condition} groupId={groupId} />;
  }

  if (
    condition instanceof MultipleCondition ||
    (condition instanceof DiscreetCondition && condition.dimension.multiSql !== null)
  ) {
    return <MultipleConditionEditor condition={condition as MultipleCondition} groupId={groupId} />;
  }

  if (condition instanceof AudienceCondition) {
    return <AudienceConditionEditor condition={condition} groupId={groupId} />;
  }

  if (condition instanceof MultiRangeCondition) {
    return <MultiRangeConditionEditor condition={condition} groupId={groupId} />;
  }

  if (condition instanceof CustomAudienceCondition) {
    return <CustomAudienceConditionEditor condition={condition} groupId={groupId} />;
  }

  throw new TypeError(`No condition editor was found for condition type ${condition.NAME}`);
}

interface Props {
  condition: QueryCondition;
  groupId: Group["id"];
}

export function GroupsQueryBuilder(props: Props): JSX.Element {
  const { groupId, condition } = props;

  if (condition instanceof GroupCondition) {
    return <GroupConditionEditor condition={condition} groupId={groupId} />;
  }

  const editor = selectEditor(condition, groupId);
  return (
    <MouseEventsHandler groupId={groupId} condition={condition}>
      {editor}
    </MouseEventsHandler>
  );
}
