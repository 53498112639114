import type { ICustomAudience } from "@vmlyr/common/dist/models/custom-audience";
import { createContext, useContext } from "react";
import type { GroupCondition } from "../../../components/query-builder/models/group-condition";
import type { QueryBuilderModel } from "../../../components/query-builder/models/query-builder-model";
import type { DragType } from "../../../models/query-builder/drag-type";
import type { QueryCondition } from "../../../models/query-builder/query-condition";

export interface ICreateAudienceContext {
  model: QueryBuilderModel;
  customAudiences: ICustomAudience[];
  onDelete: (deletedCondition: QueryCondition) => void;
  onChange: (changedCondition: QueryCondition) => void;
  onHighlight: (highlightedCondition: QueryCondition) => void;
  onRemoveHighlight: (unHighlightedCondition: QueryCondition) => void;
  onConditionDroppedOnto: (droppedCondition: QueryCondition) => void;
  onConditionPickedUp: (pickedupcondition: QueryCondition) => void;
  onConditionDraggedOver: (draggedOverCondition: QueryCondition, type: DragType) => void;
  onGroupConvertedToCondition: (convertedGroup: GroupCondition) => void;
  onInputChange: () => QueryBuilderModel;
}

const CreateAudienceContext = createContext<ICreateAudienceContext | undefined>(undefined);

export const CreateAudienceContextProvider = CreateAudienceContext.Provider;

export const useCreateAudienceContext = (): ICreateAudienceContext => {
  const ctx = useContext(CreateAudienceContext);

  if (ctx === undefined) {
    throw new Error("create-audiences context was undefined");
  }

  return ctx;
};
