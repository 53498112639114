import "./size-cell.scss";

import { CircularProgress } from "@mui/material";
import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { AudienceSizeIndicator } from "@vmlyr/appserviceshared/dist/components/audience-size-indicator";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { IAudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { useEffect, useState } from "react";
import ConfigurationHelper from "../../../helpers/configuration-helper";
import type { IViewAudienceContext } from "../context";
import { useViewAudiencesContext } from "../context";

async function getAudienceById(audienceId: string): Promise<IAudienceOverview> {
  return await getWithToken<IAudienceOverview>(ConfigurationHelper.GetSingleAudienceOverviewEndpoint(audienceId));
}

interface SizeCellProps {
  audience: IAudienceOverview;
  isArchived: boolean;
}

export default function SizeCell({ audience, isArchived }: SizeCellProps): JSX.Element {
  const [currentAudience, setCurrentAudience] = useState(audience);
  const [error, setError] = useState("");

  const context: IViewAudienceContext = useViewAudiencesContext();

  const audienceSizeNotLoaded = (currentAudience.size === null || currentAudience.totalAudienceSize === null) && !error;
  const genericError = "Unable to get audience size. If the issue persists please contact connekd@wpp.com.";

  useEffect(() => {
    if (!isArchived && audienceSizeNotLoaded) {
      getAudienceById(audience.id)
        .then((data) => {
          const audienceOverview = AudienceOverview.fromExisting(data);
          setError("");

          context.onAudienceDetailsReceived(audienceOverview);

          const newAudience = {
            ...audience,
            size: data.size,
            totalAudienceSize: data.totalAudienceSize,
          };
          setCurrentAudience(newAudience);
        })
        .catch(() => {
          setError(genericError);
        });
    }
    if ([-1, null].includes(currentAudience.size) && !audienceSizeNotLoaded) {
      setError(genericError);
    }
  }, [audience]);

  if (isArchived) {
    return <span className="disabled-label">This audience has been disabled</span>;
  }

  if (audienceSizeNotLoaded) {
    return <CircularProgress size="1.5rem" />;
  }

  if (error) {
    return <WppInlineMessage size="s" type="error" message={error} showTooltipFrom={10} />;
  }

  return (
    <AudienceSizeIndicator audienceSize={currentAudience.size} audienceTotalSize={currentAudience.totalAudienceSize} />
  );
}
