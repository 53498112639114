import type { ICustomAudience } from "@vmlyr/common/dist/models/custom-audience";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { fetchCustomAudiences } from "../connekd-api/projects/fetch-custom-audiences";

interface State {
  customAudiences: ICustomAudience[];
  actions: Actions;
}

interface Actions {
  fetchCustomAudiences: () => Promise<void>;
}

type CustomAudiencesState = State & { actions: Actions };

const useCustomAudiencesStore = create(
  devtools<CustomAudiencesState>((set) => ({
    customAudiences: [],

    actions: {
      fetchCustomAudiences: async () => {
        try {
          const response = await fetchCustomAudiences();
          set(() => ({ customAudiences: response }));
        } catch (err) {
          console.error("error while trying to load custom audience data", err);
          set(() => ({ customAudiences: [] }));
        }
      },
    },
  })),
);

export const useCustomAudiences = (): CustomAudiencesState["customAudiences"] =>
  useCustomAudiencesStore((state) => state.customAudiences);

export const useCustomAudiencesActions = (): Actions => useCustomAudiencesStore((state) => state.actions);
