import { QueryCondition } from "../../../models/query-builder/query-condition";
import { EmptyCondition } from "../models/empty-condition";
import type { GroupCondition } from "../models/group-condition";
import { QueryBuilderModel } from "../models/query-builder-model";

export const deleteCondition = (
  deletedCondition: QueryCondition,
  currentAudienceDefinition: QueryBuilderModel,
): QueryBuilderModel => {
  // Deleting the root item
  if (!deletedCondition.getParent()) {
    return QueryBuilderModel.fromExisting(currentAudienceDefinition, new EmptyCondition());
  }

  // Deleting any other item
  const parentCondition = deletedCondition.getParent() as GroupCondition;
  if (parentCondition.getLeftOperand() === deletedCondition) {
    parentCondition.setLeftOperand(null);
  } else if (parentCondition.getRightOperand() === deletedCondition) {
    parentCondition.setRightOperand(null);
  } else {
    throw new Error(
      "Critical error: deleted condition was neither the left nor the right operand of the node marked as its parent",
    );
  }
  return QueryBuilderModel.fromExisting(currentAudienceDefinition);
};
