import "./App.css";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import type { OktaAuth } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { reactPlugin } from "./app-insights";
import { oktaConfiguration } from "./authConfig";
import ErrorBoundary from "./components/errors/error-boundary";
import { Header } from "./components/header/header";
import { loadingSpinner } from "./components/loading-spinner";
import { OngoingDistributes } from "./components/ongoing-distributes";
import { OngoingExports } from "./components/ongoing-exports";
import { RoleChangeToast } from "./components/role-change-toast";
import { TestStrips } from "./components/test-strips";
import { GetCurrentUserData } from "./components/users/get-current-user";
import { recordUserLogin } from "./connekd-api/users/record-login";
import ConfigurationHelper from "./helpers/configuration-helper";
import { OktaFailedLogin } from "./routing/okta-failed-login";
import { PublicRoutes } from "./routing/public-routes";
import { RequiredAuth } from "./routing/secure-route";
import Admin from "./screens/admin/index";
import Audiences from "./screens/audiences";
import { ProfileAudience } from "./screens/audiences/profile";
import { CreateAudience } from "./screens/create-audience";
import { Explore } from "./screens/explore";
import { CreateProject } from "./screens/explore/create-project";
import { Home } from "./screens/home";
import { Landing } from "./screens/landing";
import { Profiles } from "./screens/profiles";
import { useUserActions, useUserStore } from "./stores/user";
import { AudienceToolTheme } from "./theme";

LicenseInfo.setLicenseKey(ConfigurationHelper.GetMaterialUILicense());

enum OktaAuthState {
  Pending = "auth-pending",
  OK = "auth-ok",
  Failed = "auth-failed",
}

function OktaErrorComponent(): JSX.Element | null {
  window.location.href = "/request-access";
  return null;
}

async function determineOktaAuthState(): Promise<OktaAuthState> {
  try {
    const isAuthed = await oktaConfiguration.isAuthenticated();
    return isAuthed ? OktaAuthState.OK : OktaAuthState.Failed;
  } catch (error) {
    console.error("Error checking authentication:", error);
    return OktaAuthState.Pending;
  }
}

function App(): JSX.Element {
  // Clear away any legacy dimensions cache, no longer used
  const keys = Object.keys(localStorage).filter(
    (item) => item.includes("Dimensions") || item.includes("SelectedAudiences"),
  );
  for (const item of keys) localStorage.removeItem(item);

  const { loadUser } = useUserActions();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [oktaAuthState, setOktaAuthState] = useState<OktaAuthState>(OktaAuthState.Pending);
  const hasExplorePagePermissions = useUserStore.canSeeExplore();

  useEffect(() => {
    determineOktaAuthState()
      .then((result: OktaAuthState) => {
        setOktaAuthState(result);
      })
      .catch((error) => {
        console.error("Error while trying to check authentication:", error);
      });
  }, []);

  if (!window.location.href.match("login") && oktaAuthState === OktaAuthState.Pending) {
    determineOktaAuthState()
      .then((result) => {
        setOktaAuthState(result);
      })
      .catch((error) => {
        console.error("Error while trying to check authentication:", error);
      });
  }

  async function restoreOriginalUri(oktaAuth: OktaAuth, originalUri: string): Promise<void> {
    document.body.style.opacity = "0";
    setTimeout(() => {
      document.body.style.opacity = "1";
    }, 10000);

    const userData = await GetCurrentUserData();
    if (userData.status === "success" && userData.data.id !== undefined) {
      loadUser(userData.data);
      await recordUserLogin(userData.data.id);
      document.body.style.opacity = "1";

      navigate(originalUri);
    } else {
      OktaFailedLogin(userData);
    }
  }

  // Public paths
  if (["/request-access", "/access-pending", "/access-denied"].includes(pathname)) {
    return <PublicRoutes />;
  }

  if (pathname === "/") {
    if (oktaAuthState === OktaAuthState.Pending) {
      return loadingSpinner;
    }

    const element = oktaAuthState === OktaAuthState.OK ? <Navigate to="/home" /> : <Navigate to="login" />;

    return (
      <>
        <TestStrips />
        <Routes>
          <Route path="login/callback" element={<LoginCallback />} />
          <Route path="/" element={element} />
        </Routes>
      </>
    );
  }

  return (
    <Security oktaAuth={oktaConfiguration} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={AudienceToolTheme}>
        <ErrorBoundary />
        <Header />
        <RoleChangeToast />
        <main>
          <TestStrips />
          <OngoingExports />
          <OngoingDistributes />
          <Routes>
            <Route path="/login/callback" element={<LoginCallback errorComponent={OktaErrorComponent} />} />
            <Route path="/login" element={<Landing />} />
            <Route path="/" element={<RequiredAuth />}>
              <Route path="/audiences" element={<Audiences />} />
              <Route path="/home" element={<Home />} />
              <Route path="/audiences/create" element={<CreateAudience />} />
              <Route path="/audiences/duplicate/:audienceIdToDuplicate" element={<CreateAudience />} />
              <Route path="/audiences/edit/:audienceId" element={<CreateAudience />} />
              <Route path="/audiences/merge/:audienceOneId/:audienceTwoId" element={<CreateAudience />} />
              <Route path="/audiences/:audienceId" element={<ProfileAudience />} />
              <Route path="?profile=:profileId" element={<ProfileAudience />} />
              <Route
                path="/explore"
                element={hasExplorePagePermissions ? <Explore /> : <Navigate replace to="/home" />}
              />
              <Route
                path="/explore/create"
                element={hasExplorePagePermissions ? <CreateProject /> : <Navigate replace to="/home" />}
              />
              <Route
                path="/explore/duplicate/:projectId"
                element={hasExplorePagePermissions ? <CreateProject /> : <Navigate replace to="/home" />}
              />
              <Route
                path="/explore/edit/:projectId"
                element={hasExplorePagePermissions ? <CreateProject /> : <Navigate replace to="/home" />}
              />
              <Route path="/profiles" element={<Profiles />} />
              <Route path="/admin" element={<Admin />} />
            </Route>
          </Routes>
        </main>
      </ThemeProvider>
    </Security>
  );
}

export default withAITracking(reactPlugin, App);
