import "./create-audience.scss";

import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CenteredProgress } from "../../components/centered-progress";
import type { IDimensionCategory } from "../../components/demographic-selector/models/dimensions";
import { AudienceCondition } from "../../components/query-builder/models/audience-condition";
import { EmptyCondition } from "../../components/query-builder/models/empty-condition";
import { GroupCondition } from "../../components/query-builder/models/group-condition";
import { parseToQueryCondition } from "../../components/query-builder/models/helpers/query-model-parser";
import { QueryBuilderModel } from "../../components/query-builder/models/query-builder-model";
import { listDimensions } from "../../connekd-api/dimensions/list";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { extractAllDimensions } from "../../helpers/dimension-helper";
import { Operator } from "../../models/query-builder/operator";
import { ActionType, determineActionType } from "./action-type";
import { QueryBuilder } from "./components/query-builder";
import type { IAudienceDefinition } from "./models";

async function listCustomAudiences(): Promise<unknown> {
  return await getWithToken(ConfigurationHelper.GetCustomAudienceEndpoint());
}

async function getAudienceById(audienceId: string): Promise<IAudienceDefinition> {
  return await getWithToken<IAudienceDefinition>(ConfigurationHelper.GetSingleAudienceDefinitionEndpoint(audienceId));
}

export function CreateAudience(): JSX.Element {
  const params = useParams();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const actionType = determineActionType(params);

  const [queryBuilderModel, setQueryBuilderModel] = useState<QueryBuilderModel | null>(null);
  const [dimensionCategories, setDimensionCategories] = useState<IDimensionCategory[] | null>(null);
  const [customAudiences, setCustomAudiences] = useState<any>(null);

  const { getAudienceToolGroup } = useAudienceToolGroup();
  const { showCustomAudiences } = ConfigurationHelper.GetAudienceToolGroups(getAudienceToolGroup());

  useEffect(() => {
    if (showCustomAudiences) {
      listCustomAudiences().then((customAudiences) => {
        setCustomAudiences(customAudiences);
      });
    }

    listDimensions().then((dimensionCategories) => {
      setDimensionCategories(dimensionCategories);

      switch (actionType) {
        case ActionType.Duplicate: {
          const audienceId: string = params.audienceIdToDuplicate!;
          getAudienceById(audienceId).then((audienceDefinition) => {
            const rootCondition = parseToQueryCondition(
              audienceDefinition.definition,
              extractAllDimensions(dimensionCategories),
            );
            const model = new QueryBuilderModel(rootCondition, "", [], "", "", null, "");
            setQueryBuilderModel(model);
          });
          break;
        }

        case ActionType.Edit: {
          const audienceId: string = params.audienceId!;
          getAudienceById(audienceId).then((audienceDefinition) => {
            if (audienceDefinition.isArchived) navigate(`/audiences/${audienceDefinition.friendlyId}`);
            const rootCondition = parseToQueryCondition(
              audienceDefinition.definition,
              extractAllDimensions(dimensionCategories),
            );
            const model = new QueryBuilderModel(
              rootCondition,
              audienceDefinition.name,
              audienceDefinition.taxonomy,
              audienceDefinition.friendlyId!,
              audienceDefinition.currentVersionId || "",
              audienceDefinition.lastModified ? new Date(audienceDefinition.lastModified) : null,
              audienceDefinition.lastModifiedBy!,
            );
            setQueryBuilderModel(model);
          });
          break;
        }

        case ActionType.Merge: {
          const groupCondition = new GroupCondition(
            null,
            "",
            new AudienceCondition(params.audienceOneId!),
            new AudienceCondition(params.audienceTwoId!),
            Operator.And,
          );
          const model = new QueryBuilderModel(groupCondition, "", [], "", "", null, "");

          setQueryBuilderModel(model);
          break;
        }

        case ActionType.Create: {
          const model = new QueryBuilderModel(new EmptyCondition(), "", [], "", "", null, "");
          setQueryBuilderModel(model);
          break;
        }

        default: {
          throw new Error(`Unhandled action type ${actionType}`);
        }
      }
    });
  }, [
    actionType,
    params.audienceId,
    params.audienceOneId,
    params.audienceTwoId,
    searchParams,
    params.audienceIdToDuplicate,
    navigate,
  ]);

  const hasRequiredData = queryBuilderModel !== null && dimensionCategories !== null;

  return (
    <div className="create-audience">
      {hasRequiredData ? (
        <QueryBuilder
          model={queryBuilderModel}
          action={actionType}
          dimensionCategories={dimensionCategories}
          customAudiences={customAudiences}
        />
      ) : (
        <CenteredProgress />
      )}
    </div>
  );
}
