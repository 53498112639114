"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
function Spinner({ size, centered }) {
    const sizeInPx = `${size ?? 40}px`;
    const styles = centered === true ? { display: "flex", margin: "0 auto 0 auto" } : { display: "flex" };
    return (0, jsx_runtime_1.jsx)(material_1.CircularProgress, { sx: styles, size: sizeInPx, "data-testid": "progress" });
}
exports.Spinner = Spinner;
