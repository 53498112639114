"use strict";
/**
 * Strip any none numeric characters from a string and convert to number, as a string
 * NB: this will fail with negative numbers
 * @param input
 * @return number as a string, if number, else empty string
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.toNumOnlyString = void 0;
const toNumOnlyString = (input) => {
    try {
        // split on hyphen
        let updatedInput = input;
        if (input.includes("Up to $")) {
            updatedInput = input.replace("Up to $", "$0-");
        }
        if (updatedInput.includes("-")) {
            const inputs = updatedInput.split("-");
            return ((Number(inputs[0].replaceAll(/[^\d.-]/g, "")) + Number(inputs[1].replaceAll(/[^\d.-]/g, ""))) /
                2).toString();
        }
        // coerce to string, strip non digits, output number
        return updatedInput.replaceAll(/[^\d.-]/g, "");
    }
    catch (e) {
        console.error(e);
        return "";
    }
};
exports.toNumOnlyString = toNumOnlyString;
