import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserActions } from "../stores/user";
import { GetCurrentUserData } from "../components/users/get-current-user";
import type { IUserDataResponse } from "../components/users/get-current-user";

const errorMsg =
  "Sorry there was an error handling your request.\n\nWe've been alerted regarding the issue, please check and try again or try later.";

export const useGetUser = (): {
  user: IUserDataResponse | null;
} => {
  const { loadUser } = useUserActions();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useState<IUserDataResponse | null>(null);

  const getUserData = useCallback(async (): Promise<void> => {
    const userData = await GetCurrentUserData();
    setUser(userData);
    if (userData.status === "success") {
      if (userData.data.id !== undefined) {
        loadUser(userData.data);
      }
      if (
        // Not found or not active
        (userData.message === "User not found" || userData.data.status_num !== 2) &&
        pathname !== "/request-access"
      ) {
        if (userData.data.status_num === 1) {
          navigate("/access-pending");
        } else if (userData.data.status_num === 3) {
          navigate("/access-denied");
        } else {
          navigate("/request-access");
        }
      }
    }
    if (userData.status === "error") {
      alert(`${errorMsg}\n\nError info: ${userData.message}`);
    }
  }, [loadUser, navigate, pathname]);

  useEffect(() => {
    if (!user) getUserData();
  }, [getUserData, user]);

  return { user };
};
