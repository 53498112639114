import "./ongoing-exports.scss";

import { Grid, IconButton } from "@mui/material";
import { WppIconReject, WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { getWithToken, postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useEffect, useState } from "react";
import sanitize from "sanitize-filename";
import { ReactComponent as GreenTick } from "../../assets/green-tick.svg";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { IOngoingExport } from "../../hooks/useExports/models/ongoing-export";
import { useInterval } from "../../hooks/useInterval";
import { useExportsActions, useExportsData, useProjectExportsData } from "../../stores/exports";
import { Cutoff } from "../cutoff/Cutoff";
import { Progress } from "../progress";

const EXPORT_CHECK_INTERVAL = 2000;

const calculateProgress = (exportData: IOngoingExport): string => {
  const totalFields = exportData.progress.length;
  const completeFields = exportData.exportedFile ? totalFields : exportData.progress.filter((p) => p.complete).length;
  return `${Math.round((completeFields / totalFields) * 100)}%`;
};

// NOTE: `active` here means any state not pending or processing
function jobIsActive(job: IOngoingExport): boolean {
  return !job.exportedFile && job.state !== "error";
}

async function httpCheckAudienceExport(exportId: string): Promise<IOngoingExport> {
  const endpoint = ConfigurationHelper.CheckExportAudienceEndpoint(exportId);
  return await getWithToken<IOngoingExport>(endpoint);
}

async function httpCheckProjectExport(exportId: string): Promise<IOngoingExport> {
  const endpoint = ConfigurationHelper.CheckExportProjectEndpoint(exportId);
  return await getWithToken<IOngoingExport>(endpoint);
}

async function httpDeleteExport(exportId: string): Promise<void> {
  const endpoint = ConfigurationHelper.StopExportEndpoint();
  await postWithToken(endpoint, exportId)
    .then((response: Response) => {
      console.log("export-delete response", response);
    })
    .catch((error) => {
      console.error(`export cancellation failed`, error);
    });
}

async function httpDeleteProjectExport(exportId: string): Promise<void> {
  const endpoint = ConfigurationHelper.StopProjectExportEndpoint();
  await postWithToken(endpoint, exportId)
    .then((response: Response) => {
      console.log("export-delete response", response);
    })
    .catch((error) => {
      console.error(`export cancellation failed`, error);
    });
}

function mergeJobs(a: IOngoingExport, b: IOngoingExport): IOngoingExport {
  return {
    ...b,
    progress: b.progress.map((x) => {
      const match = a.progress.find((y) => x.dimensionId === y.dimensionId);
      const complete = x.complete || (match === undefined ? false : match.complete);
      return { ...x, complete };
    }),
  };
}

function getActiveExports(exportIds: any, jobList: any) {
  return exportIds.filter((exportId: any) => {
    const exportJob = jobList.find((job: any) => job.exportId === exportId);
    return exportJob === undefined || jobIsActive(exportJob);
  });
}

export function OngoingExports(): JSX.Element {
  const currentExportIds = useExportsData();
  const currentProjectExportIds = useProjectExportsData();
  const { removeExportId, removeProjectExportId } = useExportsActions();

  const [jobList, setJobList] = useState<IOngoingExport[]>([]);

  async function refreshExports(): Promise<void> {
    const activeExports = getActiveExports(currentExportIds, jobList);
    const activeProjectExports = getActiveExports(currentProjectExportIds, jobList);

    const updates = activeExports.map((x: any) => httpCheckAudienceExport(x));
    const projectUpdates = activeProjectExports.map((x: any) => httpCheckProjectExport(x));

    const [resolvedUpdates, resolvedProjectUpdates] = await Promise.all([
      Promise.all(updates),
      Promise.all(projectUpdates),
    ]);

    const refreshedExports = [...resolvedUpdates, ...resolvedProjectUpdates];
    const mergedExports = [...jobList];

    for (const job of refreshedExports) {
      const match = mergedExports.find((ex) => ex.exportId === job.exportId);

      if (match === undefined) {
        mergedExports.push(job);
      } else {
        const index = mergedExports.indexOf(match);
        const updatedJob = mergeJobs(match, job);
        mergedExports[index] = updatedJob;
      }
    }

    setJobList(mergedExports);
  }

  useInterval(() => {
    const hasActiveExports = jobList.some((job) => jobIsActive(job));

    if (hasActiveExports) {
      refreshExports().catch((error) => {
        console.log("failed to refresh exports in interval", error);
      });
    }
  }, EXPORT_CHECK_INTERVAL);

  useEffect(() => {
    refreshExports().catch((error) => {
      console.log("failed to refresh exports on ids change", error);
    });
  }, [currentExportIds.join("/"), currentProjectExportIds.join("/")]);

  const handleDelete = (exportId: string): void => {
    const currentJob = jobList.find((job) => job.exportId === exportId);
    if (currentJob?.projectId) {
      removeProjectExportId(exportId);
      httpDeleteProjectExport(exportId).catch((err) => {
        console.warn(`failed to remove a exportID: ${err}`);
      });
    } else if (currentJob?.audienceId) {
      removeExportId(exportId);
      httpDeleteExport(exportId).catch((err) => {
        console.warn(`failed to remove a exportID: ${err}`);
      });
    }
    setJobList(jobList.filter((job) => job.exportId !== exportId));
  };

  const determineFileName = (job: IOngoingExport) => {
    let determinedName;
    if (job.audienceName) {
      determinedName = job.audienceName;
    } else if (job.projectName) {
      determinedName = job.projectName;
    } else {
      determinedName = "";
    }
    return determinedName.toLowerCase().replaceAll(" ", "_");
  };

  return (
    <ul className="ongoing-exports">
      {jobList.map((job, index) => {
        const fileName = sanitize(`export_of_${determineFileName(job)}_${index}.xlsx`);
        return (
          <li key={`${job.exportId}_${index}`}>
            <Grid container>
              {!job.errorReason && (
                <>
                  <Grid item>
                    <span className="progress-icon">{job.exportedFile ? <GreenTick /> : <Progress />}</span>
                  </Grid>
                  <Grid item>
                    <span className="export-name">
                      Export of "<Cutoff text={job.audienceName || job.projectName || ""} maxLength={12} />"
                    </span>
                    <span className="progress-indicator">{calculateProgress(job)}</span>
                  </Grid>
                </>
              )}
              <Grid item>
                {job.errorReason && (
                  <WppInlineMessage
                    size="s"
                    type="error"
                    message={`There was an error generating the export: ${job.errorReason}`}
                  />
                )}
                {!job.errorReason && (
                  <a
                    className="download-button"
                    href={`data:application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${job.exportedFile}`}
                    download={fileName}
                    onClick={() => {
                      handleDelete(job.exportId);
                    }}
                    style={{ visibility: job.exportedFile ? "visible" : "hidden" }}
                  >
                    Download
                  </a>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDelete(job.exportId);
                  }}
                >
                  <WppIconReject />
                </IconButton>
              </Grid>
            </Grid>
          </li>
        );
      })}
    </ul>
  );
}
