import { useState } from "react";
import { DragType } from "../../../../../../../models/query-builder/drag-type";
import "./drag-target.scss";

interface Props {
  onDragEnter: () => void;
  onDragLeave: () => void;
  dragType: DragType;
}

export function DragTarget({ onDragEnter, onDragLeave, dragType }: Props): JSX.Element {
  const [isDraggedOver, setIsDraggedOver] = useState<boolean>(false);
  const handleDragEnter = (): void => {
    setIsDraggedOver(true);
    onDragEnter();
  };

  const handleDragLeave = (): void => {
    setIsDraggedOver(false);
    onDragLeave();
  };

  let className = null;
  let text = "drop here";

  switch (dragType) {
    case DragType.Above:
    case DragType.Below: {
      className = isDraggedOver
        ? "query-builder-dropzone query-builder-dropzone-highlighted"
        : "query-builder-dropzone";
      text = "drop here";
      break;
    }
    case DragType.LeftOperand:
    case DragType.RightOperand: {
      className = isDraggedOver ? "query-builder-missing query-builder-missing-highlighted" : "query-builder-missing";
      text = "Missing component";
      break;
    }

    default: {
      throw new Error(`The drag target could not render because the passed-in drag type ${dragType} was not handled`);
    }
  }

  return (
    <div
      className={className}
      draggable
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragEnter();
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
      }}
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragLeave();
      }}
    >
      {text}
    </div>
  );
}
