import "./header.scss";

import { Button, Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { useUserStore } from "../../../../stores/user";

export function HomeHeader(): React.ReactElement {
  const navigate = useNavigate();

  const user = useUserStore.authenticatedUser();

  const roleNum = user?.role_num ?? 0;
  const canCreate = ConfigurationHelper.GetPermissions().audiences[0].create.includes(roleNum);

  return (
    <>
      <Grid item xs={6}>
        <h1>Get Started</h1>
      </Grid>
      <Grid className="header-column-right" item xs={6}>
        {canCreate && (
          <Button
            className="create-new-audience fill-primary"
            onClick={() => {
              navigate("/audiences/create");
            }}
          >
            Create New Audience
          </Button>
        )}
      </Grid>
    </>
  );
}
