import { WppInlineMessage } from "@platform-ui-kit/components-library-react";

interface INameErrorProps {
  errorMessage: string;
}
export function NameError({ errorMessage }: INameErrorProps) {
  return (
    <div className="name-error">
      <WppInlineMessage type="error" size="s" message={errorMessage} />
    </div>
  );
}
