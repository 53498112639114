import { Button } from "@mui/material";
import { WppIconWarning } from "@platform-ui-kit/components-library-react";
import { ControlHeader } from "../../../../../components/control-header";
import "./inactive-banner.scss";

interface IInactiveBannerProps {
  onActivate: () => void;
}

export function InactiveBanner({ onActivate }: IInactiveBannerProps) {
  const leftControl = (
    <>
      {" "}
      <WppIconWarning /> <span className="label">Inactive audience</span>{" "}
    </>
  );
  const rightControl = (
    <Button variant="text" onClick={onActivate}>
      Activate
    </Button>
  );
  return (
    <div className="inactive-banner">
      <ControlHeader leftControl={leftControl} rightControl={rightControl} />
    </div>
  );
}
