import "./search-filters.scss";

import { AccordionDetails, Button, Checkbox, FormControlLabel, Menu, MenuItem } from "@mui/material";
import { WppIconTune, WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useEffect, useState } from "react";
import {
  AudienceToolAccordion,
  AudienceToolAccordionSummary,
} from "../../../../../../components/audience-tool-accordion";
import { UserAttribute } from "../../../../../../components/users/models/user-attributes";
import type { IUserAttributeValue } from "../../../../../../components/users/models/user-attributes";
import ConfigurationHelper from "../../../../../../helpers/configuration-helper";

interface ISearchFiltersProps {
  onFiltersChanged: (filters: Map<string, IUserAttributeValue[]>) => void;
  filters: Map<string, IUserAttributeValue[]>;
}

export function SearchFilters({ onFiltersChanged, filters }: ISearchFiltersProps) {
  const [userAttributes, setUserAttributes] = useState<UserAttribute[] | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getWithToken<UserAttribute[]>(ConfigurationHelper.GetUserAttributesEndpoint())
      .then((data) => {
        const mappedUserAttributes = data.map((d) => new UserAttribute(d.userAttribute, d.allowedValues));
        setUserAttributes(mappedUserAttributes);
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelected = (filterName: string, filterValue: IUserAttributeValue, checked: boolean) => {
    const updatedMap = new Map<string, IUserAttributeValue[]>(filters);
    if (checked) {
      if (updatedMap.has(filterName)) {
        updatedMap.get(filterName)?.push(filterValue);
      } else {
        updatedMap.set(filterName, [filterValue]);
      }
    } else {
      const existingFilter = updatedMap.get(filterName);
      existingFilter?.splice(existingFilter.indexOf(filterValue), 1);
      if (existingFilter?.length === 0) {
        updatedMap.delete(filterName);
      }
    }
    onFiltersChanged(updatedMap);
  };

  const getFilterCount = () => {
    let filterCount = 0;
    for (const key of filters.keys()) {
      filterCount += filters.get(key)!.length;
    }
    return filterCount;
  };

  const filterCount = getFilterCount();

  const buttonId = `search-filters`;

  return errorMessage ? (
    <WppInlineMessage size="s" type="error" message={errorMessage} />
  ) : (
    <span className="search-filters">
      <Button
        id={buttonId}
        size="small"
        className="secondary-button"
        variant="outlined"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
      >
        <WppIconTune />
        Filters {filterCount ? ` (${filterCount})` : ""}
      </Button>
      <Menu
        id="basic-menu"
        className="search-filters"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        {userAttributes?.map((t) => {
          let filterCount = 0;
          const formControls = t.allowedValues?.map((a) => {
            const checked = filters.has(t.userAttribute) && filters.get(t.userAttribute)!.includes(a);
            if (checked) filterCount += 1;
            return (
              <li key={a.code}>
                <FormControlLabel
                  label={a.userFriendlyName}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        handleFilterSelected(t.userAttribute, a, e.target.checked);
                      }}
                    />
                  }
                />
              </li>
            );
          });
          return (
            <MenuItem key={t.userAttribute}>
              <AudienceToolAccordion>
                <AudienceToolAccordionSummary>
                  <h4>{`${t.userAttribute}${filterCount ? ` (${filterCount})` : ""}`}</h4>
                </AudienceToolAccordionSummary>
                <AccordionDetails>
                  <ul>{formControls}</ul>
                </AccordionDetails>
              </AudienceToolAccordion>
            </MenuItem>
          );
        })}
      </Menu>
    </span>
  );
}
