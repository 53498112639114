import { OktaAuth } from "@okta/okta-auth-js";
import ConfigurationHelper from "./helpers/configuration-helper";

export const oktaConfiguration = new OktaAuth({
  issuer: ConfigurationHelper.GetAppIssuer(),
  clientId: ConfigurationHelper.GetAppClientID(),
  redirectUri: ConfigurationHelper.GetAuthRedirectUrl(),
  tokenManager: {
    storage: "sessionStorage",
    // autoRenew: true, // true is default
    expireEarlySeconds: 50 * 60, // (60 min - 50 mins = every 10 mins). This affects local only, it's 30 secs on non-local envs
  },
});
