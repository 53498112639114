import "./graph-renderer.scss";
import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useEffect, useState } from "react";
import { CenteredProgress } from "../../../../../../components/centered-progress";
import type { IDimension } from "../../../../../../components/demographic-selector/models/dimensions";
import ConfigurationHelper from "../../../../../../helpers/configuration-helper";
import { BarGraph } from "./components/bar-chart";
import { DonutChart } from "./components/donut-chart";
import { IChartData } from "./models/chart-data";

const defaultChartType = "bar";

interface IGraphRendererProps {
  dimension: IDimension;
  audienceId: string;
  audienceSize: number;
}

export interface IGraphProps {
  data: IChartData[];
  categoryName: string;
  dimension: IDimension;
  audienceSize: number;
}

export function GraphRenderer({ dimension, audienceId, audienceSize }: IGraphRendererProps): JSX.Element {
  const [chartData, setChartData] = useState<IChartData[] | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getWithToken<IChartData[]>(ConfigurationHelper.GetChartEndpoint(audienceId, dimension.id))
      .then((data) => {
        setChartData(data);
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  }, []);

  let control;

  if (!chartData) {
    control = <CenteredProgress />;
  } else if (errorMessage) {
    control = <WppInlineMessage message={errorMessage} size="s" type="error" />;
  } else {
    const { chartType } = dimension;
    control =
      !chartType || chartType === defaultChartType ? (
        <BarGraph dimension={dimension} data={chartData} categoryName={dimension.name} audienceSize={audienceSize} />
      ) : (
        <DonutChart dimension={dimension} data={chartData} categoryName={dimension.name} audienceSize={audienceSize} />
      );
  }

  return (
    <div className="graph-container centered-graph">
      <h3>{dimension.name}</h3>
      {control}
    </div>
  );
}
