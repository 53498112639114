import { useState } from "react";
import { PublicNavigationBar } from "../../../components/header/nav/public-nav";
import ConfirmationCard from "../confirmationCard";
import RequestAccessForm from "../requestAccessForm";

export const Apply: React.FC = () => {
  const [toggleConfirmation, setToggleConfirmation] = useState<boolean>(false);

  return (
    <>
      <PublicNavigationBar />
      {toggleConfirmation ? <ConfirmationCard /> : <RequestAccessForm setToggleConfirmation={setToggleConfirmation} />}
    </>
  );
};
