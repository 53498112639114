export class GraphDataPoint {
  public readonly percentageOfTotal: number;

  public readonly percentageOfTotalString: string;

  constructor(
    public readonly angle: number,
    public readonly label: string,
    public readonly color: string,
    public readonly totalPersonCount: number,
  ) {
    if (!label) throw new Error(`The metric cannot be empty`);
    if (isNaN(angle)) throw new Error(`Incoming value ${label} is not a number`);
    if (!color) this.color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    this.percentageOfTotalString = ((angle / totalPersonCount) * 100).toFixed(1);
    this.percentageOfTotal = Number.parseFloat(this.percentageOfTotalString);
  }
}
