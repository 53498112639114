import { useLocation, useNavigate } from "react-router-dom";

export const OktaFailedLogin = (userData: any): JSX.Element => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  setTimeout(() => {
    if (pathname.includes("/login/callback") && (userData?.status !== "success" || userData?.data?.length === 0)) {
      navigate("/request-access");
    } else if (pathname.includes("/home") && userData?.message === "User not found") {
      navigate("/request-access");
    }
  }, 3000);
  return <></>;
};
