export interface ITaxonomicSegmentValue {
  userFriendlyName: string;
  code: string;
}

export class TaxonomySegment {
  constructor(
    public segmentName: string,
    public allowedValues: ITaxonomicSegmentValue[] | null = null,
  ) {}
}
