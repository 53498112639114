import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";

export interface CreateAudienceProfilePayload {
  name: string;
  audienceId: string;
  dimensions: string[];
}

export async function createProfile(payload: CreateAudienceProfilePayload): Promise<{ id: string }> {
  const url: string = `${ConfigurationHelper.getConnekdApiEndpoint()}/Audiences/Profiles/Create`;

  const response: Response = await postWithToken(url, payload);

  if (!response.ok) {
    console.error(payload, response);
    throw new Error("failed to create audience-profile");
  }

  return (await response.json()) as { id: string };
}
