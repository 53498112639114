"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configPermissions = void 0;
const usersAndAbove = [2, 3, 8, 9, 10];
const advUsersAndAbove = [3, 8, 9, 10];
const configPermissions = () => [
    {
        audiences: [
            {
                create: advUsersAndAbove,
                deactivate: advUsersAndAbove,
                delete: advUsersAndAbove,
                distribute: usersAndAbove,
                duplicate: advUsersAndAbove,
                edit: advUsersAndAbove,
                export: usersAndAbove,
                merge: advUsersAndAbove,
            },
        ],
    },
];
exports.configPermissions = configPermissions;
