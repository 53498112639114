import "./empty-condition-editor.scss";
import type { EmptyCondition } from "../../../../../../../components/query-builder/models/empty-condition";
import { useProjectConditionType } from "../../../../../store/projects";

interface IEmptyConditionEditorProps {
  condition: EmptyCondition;
  isVariableContext?: boolean;
}

export function EmptyConditionEditor({
  condition,
  isVariableContext = false,
}: IEmptyConditionEditorProps): JSX.Element {
  const conditionType: string = useProjectConditionType();

  const canDrop = isVariableContext && conditionType === "AudienceCondition";

  const dragTargetClass = condition.getIsDragTarget() ? "highlightOk" : "";
  const audienceClass = condition.getIsDragTarget() ? "highlightDanger" : "";

  return (
    <div className={`editor-empty-project-condition ${canDrop ? audienceClass : dragTargetClass}`}>
      <span className="in-border-text">Drop component</span>
    </div>
  );
}
