import "./new-profile-modal.scss";

import { Box, Button, Grid, Modal } from "@mui/material";
import { extractSelectableDimensions } from "../../helpers/selectable-dimension-category";
import type { ProfileView } from "../../models/profile-view";
import type { SelectableDimension, SelectableDimensionCategory } from "../../models/selectable-dimension";
import { style } from "../../styles/modal-style";

interface IRenameModalProps {
  categories: SelectableDimensionCategory[] | null;
  onClose: () => void;
  activeProfileView: ProfileView | null;
}

export function NewProfileModal({ categories, onClose, activeProfileView }: IRenameModalProps): JSX.Element {
  const allDimensions = extractSelectableDimensions(categories!);

  const handleClearAllDemographics = (): void => {
    allDimensions.map((d: SelectableDimension): void => {
      d.deselect();
    });

    activeProfileView!.dimensions = allDimensions
      .filter((d: SelectableDimension) => d.getIsSelected())
      .map((d: SelectableDimension) => d.dimension.id);

    onClose();
  };

  return (
    <Modal
      id="clear-all-modal"
      open
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={12}>
            <h3>Starting again will remove all widgets. Continue?</h3>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "right" }} className="action-buttons">
            <Button
              variant="text"
              onClick={() => {
                onClose();
              }}
            >
              No
            </Button>
            <Button variant="outlined" className="fill-primary" onClick={handleClearAllDemographics}>
              Yes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
