import "./taxonomy.scss";

import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";
import EditableTaxonomy from "./components/editable-taxonomy";
import { StaticTaxonomy } from "./components/static-taxonomy";
import { addFriendIdSegment } from "./helpers";

interface ITaxonomyProps {
  isEditable: boolean;
  segments: ITaxonomicNamePart[];
  friendlyId: string;
  onChange: (segments: ITaxonomicNamePart[]) => void;
  classes?: string;
}

export function Taxonomy({ isEditable, segments, friendlyId, onChange, classes = "" }: ITaxonomyProps) {
  segments = addFriendIdSegment(segments, friendlyId);

  return (
    <div className={`taxonomy ${classes}`}>
      {isEditable ? (
        <EditableTaxonomy initialSegments={segments} onChange={onChange} />
      ) : (
        <StaticTaxonomy friendlyId={friendlyId} segments={segments} />
      )}
    </div>
  );
}

Taxonomy.defaultProps = {
  isEditable: false,
  onChange: () => {},
};
