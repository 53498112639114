import { humanizeTimeBetweenDates } from "../helpers/date-helper";

interface ISimpleDateProps {
  date: Date | string;
  isTimeSince: boolean;
}

export function SimpleDate({ date, isTimeSince }: ISimpleDateProps): JSX.Element {
  if (date) {
    const dateObject = new Date(date);
    return <>{isTimeSince ? `${humanizeTimeBetweenDates(new Date(), dateObject)} ago` : dateObject.toDateString()}</>;
  }

  return <>{isTimeSince ? "Never" : null}</>;
}
