import { AudienceDefinitionData } from "../../../../models/query-builder/audience-definition-data";

type Operator = "and" | "or" | null;

interface RangeOperand {
  dimensionId: string;
  lowerBound: number;
  upperBound: number;
}

interface MultirangeOperand {
  dimensionId: string;
  values: RangeOperand[];
}

interface DiscreetMultipleOperand {
  dimensionId: string;
  value: string | string[];
}

interface GroupOperand {
  leftOperand: AudienceDefinitionData;
  rightOperand?: AudienceDefinitionData;
  operator?: Operator;
}

export interface ICustomAudience {
  dataSource: string;
  segmentId: string;
  segmentName: string;
}
function capitalizeWords(str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function definitionFormatter(node: AudienceDefinitionData): string {
  const formatDimensionId = (dimensionId: string) => capitalizeWords(dimensionId.split("-").slice(2).join(" "));

  const formatRange = (node: RangeOperand) =>
    `(${formatDimensionId(node.dimensionId)} [${node.lowerBound} to ${node.upperBound}])`;

  const formatAgeRange = (node: MultirangeOperand) => {
    const parsedValues: string = node.values.map((e) => `${e.lowerBound}-${e.upperBound}`).join(" or ");
    return `(${formatDimensionId(node.dimensionId)} [${parsedValues}]`;
  };

  const formatDiscreetMultiple = (node: DiscreetMultipleOperand) =>
    `(${formatDimensionId(node.dimensionId)} [${Array.isArray(node.value) ? node.value.join(" or ") : node.value}])`;

  const formatGroup = (node: GroupOperand) => {
    const left = node.leftOperand ? definitionFormatter(node.leftOperand) : "";
    const right = node.rightOperand ? definitionFormatter(node.rightOperand) : "";
    const operator = node.operator ? ` ${node.operator} ` : "";
    return `(${left}${operator}${right})`;
  };

  const formatCustomAudience = (node: ICustomAudience) => {
    if (!node) {
      return "(Some custom audience)";
    }
    return `${node.segmentName} (${node.segmentId}) from ${node.dataSource} `;
  };

  switch (node.type) {
    case "range":
      return formatRange(node.leftOperand as RangeOperand);
    case "multirange":
      return formatAgeRange(node.leftOperand as MultirangeOperand);
    case "discreet":
    case "multiple":
      return formatDiscreetMultiple(node.leftOperand as DiscreetMultipleOperand);
    case "group":
      return formatGroup(node as GroupOperand);
    case "customAudience":
      return formatCustomAudience(node.customAudiences as ICustomAudience);
    default:
      return "";
  }
}
