import type React from "react";
import { useEffect, useRef } from "react";

import Box from "@mui/material/Box";

import type { GridColDef, GridSelectionModel } from "@mui/x-data-grid-pro";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";

import { CenteredProgress } from "@vmlyr/appserviceshared/dist/components/centered-progress";
import { useOnScreen } from "@vmlyr/appserviceshared/dist/hooks/useOnScreen";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";

const initialTableState = {
  columns: {
    columnVisibilityModel: {
      created: false,
    },
  },
};

interface UserTableProps {
  userList: AudienceOverview[] | null;
  selectable: boolean;
  allowPaging: boolean;
  onScroll: (isScrolledToBottom: boolean, ref: React.RefObject<HTMLDivElement>) => void;
  columnDefinition: GridColDef[];
  onUsersSelected: any;
  isLoadingMore: any;
  selectedUsers: any;
}

export function UserTable({
  userList,
  allowPaging,
  onScroll,
  columnDefinition,
  onUsersSelected,
  isLoadingMore,
  selectedUsers,
}: UserTableProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isScrolledToBottom = useOnScreen(ref);
  const tableRef: any = useRef(null);

  useEffect(() => {
    onScroll(false, tableRef);
    setTimeout(() => {
      if (tableRef.current instanceof HTMLElement) {
        const tableWindow = tableRef.current.querySelector(".MuiDataGrid-virtualScroller");
        const handleScroll = () => {
          const lastRow = tableRef.current.querySelector(".MuiDataGrid-row--lastVisible");
          if (lastRow) {
            onScroll(true, lastRow);
            setTimeout(() => {
              onScroll(false, tableRef);
            }, 0);
          } else {
            onScroll(false, tableRef);
          }
        };
        tableWindow.addEventListener("scroll", handleScroll);
      }
    }, 0);
  }, []);

  useEffect(
    () => {
      onScroll(isScrolledToBottom, ref);
    },
    // eslint-disable-next-line
    [isScrolledToBottom],
  );

  const handleSelectionChanged = (e: GridSelectionModel) => {
    onUsersSelected(e.map((f) => userList!.find((a) => a.id === f)!));
  };

  const getSelectedUsersIDs = selectedUsers.map((item: any) => item.id);

  if (!userList) {
    return <CenteredProgress />;
  }

  const pageHeight = `${window.innerHeight - 370}px`;
  return (
    <>
      <Box sx={{ height: pageHeight }}>
        <DataGrid
          checkboxSelection
          selectionModel={getSelectedUsersIDs}
          onSelectionModelChange={handleSelectionChanged}
          initialState={initialTableState}
          columns={columnDefinition}
          rows={userList}
          pageSize={allowPaging ? 5 : userList.length}
          rowsPerPageOptions={allowPaging ? [5] : [userList.length]}
          disableSelectionOnClick
          hideFooter={!allowPaging}
          ref={tableRef}
        />
      </Box>
      {isLoadingMore && <CenteredProgress />}
    </>
  );
}

UserTable.defaultProps = {
  selectable: true,
  allowPaging: true,
  isLoadingMore: false,
  onScroll: () => {},
  reloadUserTable: () => {},
};
