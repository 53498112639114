import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import type { ISearchResultsModel } from "@vmlyr/connekdfordshared/models/api/search-results-model";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { SearchType } from "./search-type";

export async function searchAudiencesForProfiles(
  searchResultPage: number,
  searchType: SearchType,
  searchText: string,
): Promise<ISearchResultsModel> {
  let searchUrl = ConfigurationHelper.GetSearchAudiencesEndpoint();
  searchUrl = addQueryParams(
    searchUrl,
    `page=${searchResultPage}`,
    `text=${searchText || ""}`,
    `searchType=${searchType.toLowerCase()}`,
  );

  return await getWithToken<ISearchResultsModel>(searchUrl);
}
