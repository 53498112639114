import "./editable-taxonomy.scss";

import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { ITaxonomicNamePart } from "@vmlyr/appserviceshared/dist/models/taxonomic-name-parts";
import { useEffect, useState } from "react";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { CenteredProgress } from "../../../centered-progress";
import ContextMenu from "../../../taxonomic-options";
import type { ITaxonomicSegmentValue } from "../../models/taxonomy-segment";
import { TaxonomySegment } from "../../models/taxonomy-segment";

interface IEditableTaxonomyProps {
  initialSegments: ITaxonomicNamePart[] | null;
  onChange: (segments: ITaxonomicNamePart[]) => void;
}
export default function EditableTaxonomy({ initialSegments, onChange }: IEditableTaxonomyProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [segments, setSegments] = useState<ITaxonomicNamePart[]>(initialSegments || []);
  const [allowedTaxonomy, setAllowedTaxonomy] = useState<TaxonomySegment[] | null>(null);

  useEffect(() => {
    getWithToken<TaxonomySegment[]>(ConfigurationHelper.GetAllowedTaxonomyEndpoint())
      .then((data: TaxonomySegment[]) => {
        const mappedTaxonomySegments = data.map((d) => new TaxonomySegment(d.segmentName, d.allowedValues));
        setAllowedTaxonomy(mappedTaxonomySegments);
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  }, []);

  useEffect(() => {
    if (allowedTaxonomy !== null) {
      allowedTaxonomy.forEach((t: TaxonomySegment) => {
        if (!segments.find((s: ITaxonomicNamePart) => s.segmentName === t.segmentName)) {
          const segmentCode = t.allowedValues?.[0]?.code || null;
          const singleAllovedValue = t.allowedValues?.length === 1;
          segments.push({
            segmentName: t.segmentName,
            value: singleAllovedValue ? segmentCode : null,
            isEditable: true,
          });
        }
      });
      setSegments([...segments]);
      onChange(segments);
    }
  }, [allowedTaxonomy]);

  const handleTaxonomySegmentUpdated = (segment: TaxonomySegment, newValue: ITaxonomicSegmentValue) => {
    const nameSegment = segments.find((s: ITaxonomicNamePart) => s.segmentName === segment.segmentName)!;
    nameSegment.value = newValue.code;
    const trimDescriptor = segments.filter((e) => e.segmentName !== "Descriptor");
    setSegments([...trimDescriptor]);
    onChange(trimDescriptor);
  };

  const isLoaded = allowedTaxonomy && segments.length;

  const taxonomy = isLoaded ? (
    <div id="editable-taxonomy">
      {segments.map((nameSegment: ITaxonomicNamePart, i) => {
        const taxonomySegment: TaxonomySegment =
          allowedTaxonomy.find((tax: TaxonomySegment) => tax.segmentName === nameSegment.segmentName) ??
          new TaxonomySegment(nameSegment.value!, null);

        const segmentValue = nameSegment.value || taxonomySegment.segmentName;
        const selectSingleValue: boolean = segmentValue === "Region" || segmentValue === "Market";
        const hasAllowedValues = taxonomySegment.allowedValues;

        return (
          hasAllowedValues && (
            <ContextMenu
              className={nameSegment.segmentName}
              key={i}
              buttonText={selectSingleValue ? hasAllowedValues[0].code : segmentValue}
            >
              {hasAllowedValues.map((value: ITaxonomicSegmentValue) => (
                <div
                  id={value.code}
                  key={value.code}
                  onClick={() => {
                    handleTaxonomySegmentUpdated(taxonomySegment, value);
                  }}
                >
                  {value.userFriendlyName}
                </div>
              ))}
            </ContextMenu>
          )
        );
      })}
    </div>
  ) : (
    <CenteredProgress />
  );
  return errorMessage ? (
    <WppInlineMessage size="s" message={`The taxonomy failed to load with the following error: ${errorMessage}`} />
  ) : (
    taxonomy
  );
}
