import { AccordionDetails } from "@mui/material";
import {
  AudienceToolAccordion,
  AudienceToolAccordionSummary,
} from "../../../../../../components/audience-tool-accordion";
import type { SelectableDimension, SelectableDimensionCategory } from "../../../models/selectable-dimension";
import { WidgetCheckbox } from "../../widget-checkbox";

interface IWidgetCategoryProps {
  category: SelectableDimensionCategory;
  handleDimensionSelected: (dimension: SelectableDimension) => void;
  onAccordionClicked: (d: SelectableDimensionCategory) => void;
}

const ignoredDimension = "Age Groups";

export function WidgetCategory({
  category,
  handleDimensionSelected,
  onAccordionClicked,
}: IWidgetCategoryProps): JSX.Element {
  return (
    <AudienceToolAccordion
      key={category.name}
      className={category.isVisible ? "demographic-accordion-editor" : "demographic-accordion-hidden"}
      expanded={category.isExpanded}
    >
      <AudienceToolAccordionSummary
        onClick={(e) => {
          e.stopPropagation();
          onAccordionClicked(category);
        }}
      >
        <h4>{category.name}</h4>
      </AudienceToolAccordionSummary>
      <AccordionDetails>
        {category.categories.map((c: SelectableDimensionCategory, i: number) => (
          <WidgetCategory
            key={`${c.name}${i}`}
            category={c}
            handleDimensionSelected={handleDimensionSelected}
            onAccordionClicked={onAccordionClicked}
          />
        ))}
        {category.dimensions
          .filter((d: SelectableDimension) => d.isVisible && d.dimension.name !== ignoredDimension)
          .map((d: SelectableDimension) => (
            <WidgetCheckbox
              key={d.dimension.name}
              label={d.dimension.name}
              isSelected={d.getIsSelected()}
              onSelect={() => {
                handleDimensionSelected(d);
              }}
            />
          ))}
      </AccordionDetails>
    </AudienceToolAccordion>
  );
}
