import "./tabber.scss";

interface ITabberProps<T> {
  tabs: T[];
  selectedOption: T;
  onOptionSelected: ((option: T) => void) | null;
}

export function Tabber<T extends string>({ tabs, selectedOption, onOptionSelected }: ITabberProps<T>) {
  if (tabs.length === 0) throw new Error("You must provide at least one tab");
  return (
    <span className="tabber">
      {tabs.map((o: T) => (
        <button
          key={o}
          onClick={() => {
            if (onOptionSelected) {
              onOptionSelected(o);
            }
          }}
          className={`tab ${selectedOption === o ? "active" : ""}`}
        >
          {o}
        </button>
      ))}
    </span>
  );
}
