import type { IDimension } from "../../components/demographic-selector/models/dimensions";
import { parseToQueryCondition } from "../../components/query-builder/models/helpers/query-model-parser";
import { QueryBuilderModel } from "../../components/query-builder/models/query-builder-model";
import type { DragType } from "../../models/query-builder/drag-type";
import type { QueryCondition } from "../../models/query-builder/query-condition";
import type { Group } from "../../screens/explore/store/groups";
import type { VariableData } from "../../screens/explore/store/variables";

const deserialiseQueryCondition = (dimensions: IDimension[], data: any): QueryCondition => {
  return parseToQueryCondition(data, dimensions);
};

interface RawQueryCondition {
  isHighlighted: boolean;
  isDragTarget: boolean;
  isBeingDragged: boolean;
  dragType: null | DragType;
}

export interface RawGroup {
  id: number;
  name: string;
  validationError: null | string;
  definition: {
    rootCondition: RawQueryCondition;
    name: string;
    taxonomy: Array<{
      value: string | null;
      segmentName: string;
      isEditable: boolean;
    }>;
    id: string;
    currentVersionId: string;
    lastModified: Date | null;
    lastModifiedBy: string;
  };
}

export function deserializeGroup(dimensions: IDimension[], rawGroup: RawGroup): Group {
  const rootCondition = deserialiseQueryCondition(dimensions, rawGroup.definition);

  return {
    id: rawGroup.id,
    name: rawGroup.name,
    validationError: null,
    definition: new QueryBuilderModel(
      rootCondition,
      rawGroup.definition.name,
      rawGroup.definition.taxonomy,
      rawGroup.definition.id,
      rawGroup.definition.currentVersionId,
      rawGroup.definition.lastModified,
      rawGroup.definition.lastModifiedBy,
    ),
  };
}

export interface RawVariable {
  id: number;
  definition: {
    rootCondition: RawQueryCondition;
    name: string;
    taxonomy: Array<{
      value: string | null;
      segmentName: string;
      isEditable: boolean;
    }>;
    id: string;
    currentVersionId: string;
    lastModified: Date | null;
    lastModifiedBy: string;
  };
}

export function deserializeVariable(dimensions: IDimension[], rawVariable: RawVariable): VariableData {
  const rootCondition = deserialiseQueryCondition(dimensions, rawVariable.definition);

  return {
    id: rawVariable.id,
    variableDefinition: new QueryBuilderModel(
      rootCondition,
      rawVariable.definition.name,
      rawVariable.definition.taxonomy,
      rawVariable.definition.id,
      rawVariable.definition.currentVersionId,
      rawVariable.definition.lastModified,
      rawVariable.definition.lastModifiedBy,
    ),
  };
}
