import { Tooltip } from "@mui/material";

interface ICutoffProps {
  text: string;
  maxLength: number;
}

/**
 * Primary UI component for user interaction
 */
export function Cutoff({ text, maxLength }: ICutoffProps) {
  const shouldCutOff = text.length > maxLength;

  if (!shouldCutOff) {
    return <span className="cutoff">{text}</span>;
  }

  return (
    <Tooltip title={text}>
      <span className="cutoff">{`${text.slice(0, Math.max(0, maxLength))}...`}</span>
    </Tooltip>
  );
}
