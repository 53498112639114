import { Stack, Modal } from "@mui/material";
import { WppIconCopy, WppIconExport, WppIconEye, WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { Profiles } from "@vmlyr/connekdfordshared/dist/models/api/audience-profiles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../../assets/icon-trash.svg";
import { ActionButton } from "../../../../components/action-button";
import { deleteProfile } from "../../../../connekd-api/audience-profiles/delete-profile";
import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { useExportsActions } from "../../../../stores/exports";
import ConfirmUserActionModal from "../../../../components/confirm-user-action-modal";

interface Props {
  selectedProfiles: Profiles[];
  canDeleteProfile: boolean;
  handleDuplicateActionButton: () => void;
  onDeleteCallback: () => void;
}

export const TableActionButtons = ({
  selectedProfiles,
  canDeleteProfile,
  handleDuplicateActionButton,
  onDeleteCallback,
}: Props): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const { addExportId } = useExportsActions();

  const navigate = useNavigate();
  const performDeleteProfile = async (): Promise<void> => {
    try {
      await Promise.all(selectedProfiles.map(async (profile) => await deleteProfile(profile.id)));
    } catch (err) {
      console.log(`Unable to delete profiles due to: ${err}`);
      console.error(err);
    } finally {
      onDeleteCallback();
    }
  };

  const handleDeleteProfile = async (): Promise<void> => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExport = (id: string, viewSelections: string) => {
    if (!isLoading) {
      setIsLoading(true);
      postWithToken(ConfigurationHelper.ExportAudienceEndpoint(), {
        audienceId: id,
        exportAllDimensions: false,
        exportedViewNames: [viewSelections],
      })
        .then(async (response) => {
          if (!response.ok) {
            setServerError(await response.text());
            return;
          }

          const responseObject = await response.json();
          addExportId(responseObject.exportId);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Stack className="selection-actions" direction="row" spacing={1}>
      <ActionButton
        disabledMessage="You can only view one profile at a time"
        isDisabled={selectedProfiles.length !== 1}
        onClick={() => {
          navigate(`/audiences/${selectedProfiles[0].audience.id}?profile=${selectedProfiles[0].id}`);
        }}
        icon={<WppIconEye size="m" color="primary" className="icon" />}
        buttonText="View"
        id="view-profile-button"
      />

      <ActionButton
        disabledMessage="You can only duplicate one profile at a time"
        isDisabled={selectedProfiles.length !== 1}
        onClick={handleDuplicateActionButton}
        icon={<WppIconCopy size="m" className="icon" />}
        buttonText="Duplicate"
        id="duplicate-profile-button"
      />

      <ActionButton
        isDisabled={!canDeleteProfile}
        disabledMessage="User does not have permissions to delete one or more of the selected profiles"
        onClick={handleDeleteProfile}
        icon={<DeleteIcon size="m" color="primary" className="icon" />}
        buttonText="Delete"
        id="delete-profile-button"
      />

      <ActionButton
        disabledMessage="You can export only one profile"
        isDisabled={selectedProfiles.length !== 1}
        onClick={() => {
          handleExport(selectedProfiles[0].audience.id, selectedProfiles[0].id);
        }}
        icon={<WppIconExport size="m" color="primary" className="icon" />}
        buttonText="Export"
        id="export-project-button"
      />
      {serverError && <WppInlineMessage size="s" type="error" message={serverError} />}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ConfirmUserActionModal
          modalState={handleCloseModal}
          action="delete"
          performAction={performDeleteProfile}
          confirmationText="Are you sure you want to delete this profile? This cannot be reversed."
        />
      </Modal>
    </Stack>
  );
};
