"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIdentity = exports.getCurrentIdentity = exports.setCurrentIdentity = void 0;
class UserIdentity {
    username;
    accessToken;
    audienceToolGroups;
    constructor(username, accessToken, audienceToolGroups) {
        this.username = username;
        this.accessToken = accessToken;
        this.audienceToolGroups = audienceToolGroups;
        if (!username) {
            throw new Error("A user identity must have a username");
        }
        if (!accessToken) {
            throw new Error("A user identity must have an access token");
        }
    }
    static fromExisting(existing) {
        return new UserIdentity(existing.username, existing.accessToken, existing.audienceToolGroups);
    }
}
const key = `audiencetool_identity`;
const setCurrentIdentity = (identity) => {
    if (!identity) {
        return;
    }
    const identityToStore = UserIdentity.fromExisting(identity);
    sessionStorage.setItem(key, JSON.stringify(identityToStore));
};
exports.setCurrentIdentity = setCurrentIdentity;
const getCurrentIdentity = () => {
    const storedIdentity = sessionStorage.getItem(key);
    if (!storedIdentity) {
        return null;
    }
    return UserIdentity.fromExisting(JSON.parse(storedIdentity));
};
exports.getCurrentIdentity = getCurrentIdentity;
const useIdentity = () => ({ getCurrentIdentity: exports.getCurrentIdentity, setCurrentIdentity: exports.setCurrentIdentity });
exports.useIdentity = useIdentity;
