import { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";

export class CustomAudienceCondition extends QueryCondition {
  public readonly NAME: string = "CustomAudienceCondition";

  constructor(private value: any) {
    super();
  }

  setValue(chosenValue: any): void {
    this.value = chosenValue;
  }

  getValue() {
    return this.value;
  }

  isParentOf(): boolean {
    return false;
  }

  getValidationState(): ConditionValidationState {
    if (!this.value) {
      return new ConditionValidationState(false, "Please make a selection");
    }
    return new ConditionValidationState(true);
  }

  getConditionData(): AudienceDefinitionData {
    return new AudienceDefinitionData(null, null, null, null, "customAudience", this.value);
  }
}
