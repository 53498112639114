import "./save-buttons.scss";

import { Button, Modal } from "@mui/material";
import { Stack } from "@mui/system";
import { WppButton } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CreateProfileModal } from "../../../profiles/components/create-profile-modal";
import type { ProfileView } from "../models/profile-view";
import { SaveProfileAction } from "./widgets";
import ConfirmUserActionModal from "../../../../components/confirm-user-action-modal";

interface ISaveButtons {
  disabled: boolean;
  onViewSaved: (type: SaveProfileAction) => void;
  audienceName?: string;
  currentProfile?: ProfileView;
}
export const SaveButtons = ({ currentProfile, disabled, onViewSaved, audienceName }: ISaveButtons): JSX.Element => {
  const [searchParams] = useSearchParams();
  const profileId: string | null = searchParams.get("profile");

  const [modalText, setModalText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [newProfilesModalHidden, setNewProfilesModalHidden] = useState<boolean>(true);
  const [profileNameModal, setProfileNameModal] = useState(currentProfile?.name || "New Profile");

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const performSave = () => {
    onViewSaved(SaveProfileAction.SaveOrUpdate);
  };

  const saveAsNewButton = (
    <Button
      className="save-profile-button"
      onClick={() => {
        setNewProfilesModalHidden(false);
      }}
      disabled={disabled}
    >
      Save as new
    </Button>
  );

  const saveProfileButton = (
    <WppButton
      className="save-profile-button"
      type="button"
      name="Save Profile"
      variant="secondary"
      disabled={disabled}
      onClick={() => {
        if ((currentProfile && currentProfile?.id.length === 0) || !currentProfile?.id) {
          setModalText(`Saving will lock this profile to the audience: ${audienceName}`);
          setOpenModal(true);
        }
        if ((currentProfile && currentProfile?.id.length > 0) || (profileId && profileId.length > 0)) {
          setModalText(
            "Updating will overwrite the previously saved profile for this audience. This action cannot be undone. Continue?",
          );
          setOpenModal(true);
        }
      }}
    >
      Save
    </WppButton>
  );

  return (
    <Stack className="profile-controls" direction="row" spacing={1}>
      {saveAsNewButton}
      {saveProfileButton}
      {!newProfilesModalHidden && (
        <CreateProfileModal
          onClose={() => {
            setNewProfilesModalHidden(true);
            setProfileNameModal("New Profile");
          }}
          isDuplicated={true}
          selectedProfiles={currentProfile!}
          profileNameModal={profileNameModal}
          setProfileNameModal={setProfileNameModal}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ConfirmUserActionModal
          modalState={handleCloseModal}
          action="save"
          performAction={performSave}
          confirmationText={modalText}
        />
      </Modal>
    </Stack>
  );
};
