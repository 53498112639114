import "./ongoing-distributes.scss";

import { Grid, IconButton } from "@mui/material";
import { WppIconReject, WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useEffect, useState } from "react";
import { ReactComponent as GreenTick } from "../../assets/green-tick.svg";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { useDistributes } from "../../hooks/useDistributes";
import type { IOngoingDistribute } from "../../hooks/useDistributes/models/ongoing-distribute";
import { useInterval } from "../../hooks/useInterval";
import { Cutoff } from "../cutoff/Cutoff";
import { Progress } from "../progress";

export function OngoingDistributes(): JSX.Element {
  const distributeAudienceKey = "distributed_audiences";
  const { getOngoingDistributes, deleteOngoingDistribute } = useDistributes();
  const [ongoingDistributes, setOngoingDistributes] = useState(getOngoingDistributes());

  useInterval(() => {
    setOngoingDistributes(() => getOngoingDistributes());
  }, 200);

  useEffect(() => {
    for (const ongoingDistribute of ongoingDistributes) {
      // @ts-expect-error
      const lsData = JSON.parse(localStorage.getItem(distributeAudienceKey));
      const thisIndex = lsData.findIndex((e: any) => e.audienceId === ongoingDistribute.audienceId);

      if (lsData[thisIndex].status === "ready") {
        lsData[thisIndex].status = "triggered";
        localStorage.setItem(distributeAudienceKey, JSON.stringify(lsData));
        getWithToken(ConfigurationHelper.DistributeAudienceUsers(lsData[thisIndex].audienceId)).then((data: any) => {
          if (data?.status && data.status.length > 0) {
            lsData[thisIndex].status = data.status;
            lsData[thisIndex].message = data.message;
            localStorage.setItem(distributeAudienceKey, JSON.stringify(lsData));
          }
        });
      }
    }
  }, [ongoingDistributes]);

  const handleDelete = (distributeData: IOngoingDistribute) => {
    deleteOngoingDistribute(distributeAudienceKey, distributeData.audienceId);
  };

  return (
    <ul className="ongoing-distributes">
      {localStorage.getItem(distributeAudienceKey) !== null &&
        // @ts-expect-error
        JSON.parse(localStorage.getItem(distributeAudienceKey)).map((d, i) => {
          const complete = d.status !== "ready" && d.status !== "triggered";
          const prefixText = complete ? "Distributed" : "Distributing";
          return (
            <li key={`${d.audienceId}_${i}`}>
              <Grid container>
                {d.status !== "failure" && (
                  <>
                    <Grid item>
                      <span className="progress-icon">{complete ? <GreenTick /> : <Progress />}</span>
                    </Grid>
                    <Grid item>
                      <span className="distribute-name">
                        {prefixText} "<Cutoff text={d.audienceName} maxLength={12} />"
                      </span>
                    </Grid>
                  </>
                )}
                <Grid item>
                  {d.status === "failure" && <WppInlineMessage size="s" type="error" message={d.message} />}
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleDelete(d);
                    }}
                  >
                    <WppIconReject />
                  </IconButton>
                </Grid>
              </Grid>
            </li>
          );
        })}
    </ul>
  );
}
