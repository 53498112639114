import "./custom-legend.scss";

import type { GraphDataPoint } from "../../../../models/graph-data-point";

interface ICustomLegendProps {
  dataPoints: GraphDataPoint[];
}

export function CustomLegend(props: ICustomLegendProps): JSX.Element {
  const { dataPoints } = props as ICustomLegendProps;
  const dataPointsWithColours = dataPoints.map((d) => ({
    ...d,
    color: d.color,
  }));

  return (
    <ul className="custom-legend">
      {dataPointsWithColours.map((dataPoint) => (
        <li key={dataPoint.label}>
          <span style={{ backgroundColor: dataPoint.color }} className="legend-color" />
          <span className="legend-label">{dataPoint.label}</span>
          <span className="percentage">{dataPoint.percentageOfTotalString}%</span>
        </li>
      ))}
    </ul>
  );
}
