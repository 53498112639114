import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteButton } from "../../../../../../components/delete-button/delete-button";
import { DiscreetValueEditor } from "../../../../../../components/editors/discreet-value";
import { MultipleValueEditor } from "../../../../../../components/editors/multiple";
import type { DiscreetCondition } from "../../../../../../components/query-builder/models/discreet-condition";
import type { QueryBuilderModel } from "../../../../../../components/query-builder/models/query-builder-model";
import type { Group } from "../../../../store/groups";
import { useGroup, useGroupsActions, useGroupsNudge } from "../../../../store/groups";
import type { VariableData } from "../../../../store/variables";
import { useVariable, useVariablesActions } from "../../../../store/variables";
import { useProjectRunActions } from "../../../../store/projects";

type Props = {
  condition: DiscreetCondition;
} & (
  | {
      groupId: Group["id"];
      variableId?: never;
    }
  | {
      groupId?: never;
      variableId: VariableData["id"];
    }
);
export function DiscreetConditionEditor(props: Props): JSX.Element {
  const { condition, groupId, variableId } = props;

  const { nudgeState, removeGroupDefinition, hasGroupChanged } = useGroupsActions();
  const { haveVariablesSelectionChanged } = useVariablesActions();
  const { hasRun } = useProjectRunActions();
  useGroupsNudge();

  let definition: QueryBuilderModel | undefined;

  if (groupId !== undefined) {
    const group = useGroup(groupId);
    definition = group.definition;
  } else if (variableId !== undefined) {
    definition = useVariable(variableId).variableDefinition;
  }

  const handleStateSelected = (state: string | string[]): void => {
    if (groupId !== undefined) {
      hasGroupChanged(true);
    } else if (variableId !== undefined) {
      haveVariablesSelectionChanged(true);
    }
    hasRun(false);
    condition.setValue(state);
    nudgeState();
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  const handleDelete = (): void => {
    if (definition === undefined) {
      console.error("Definition is undefined or null");
    } else {
      removeGroupDefinition(condition, definition);
    }
  };

  return (
    <div className="editor-highlighted-condition">
      <DeleteButton onDelete={handleDelete} isHidden={!condition.getIsHighlighted()} />

      {groupId == null ? (
        <MultipleValueEditor
          currentValue={condition.getValue()}
          options={condition.getAllowedValues()}
          valueName={condition.getDimensionName()}
          onOptionChosen={handleStateSelected}
          error={error}
          showPaperComponents={!(variableId == null)}
        />
      ) : (
        <DiscreetValueEditor
          currentValue={condition.getValue()}
          options={condition.getAllowedValues()}
          valueName={condition.getDimensionName()}
          onOptionChosen={handleStateSelected}
          error={error}
        />
      )}
    </div>
  );
}
