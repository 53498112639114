import "./home-page.scss";

import { Grid } from "@mui/material";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import type { GroupConfig } from "@vmlyr/connekdfordshared/config/groups";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import type { ISearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/search-results-model";
import { SearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/search-results-model";
import { useEffect, useState } from "react";
import { CenteredProgress } from "../../components/centered-progress";
import { Loadable } from "../../components/loadable";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { convertErrorToString } from "../../helpers/error-helper";
import { useUserStore } from "../../stores/user";
import { FAQ } from "./components/faq";
import { HomeHeader } from "./components/header";
import { HeadlineCta, HeadlinePosition } from "./components/headline-cta";
import { KeyMetrics } from "./components/key-metrics";
import { RecentAudiences } from "./components/recent-audiences";

interface IHomeProps {
  initialAudienceList: AudienceOverview[] | null;
}

function allowNewAssets(): boolean {
  const { getAudienceToolGroup } = useAudienceToolGroup();
  const toolGroup: string | null = getAudienceToolGroup();
  const config: GroupConfig = ConfigurationHelper.GetAudienceToolGroups(toolGroup);
  return config.useNewAssets;
}

const newMetrics = (
  <>
    <HeadlineCta
      headerImage="/assets/headline-cta-1.jpg"
      title="Existing Audiences"
      description="See what audiences are available to you"
      path="/audiences"
      position={HeadlinePosition.Left}
      displayModal={false}
    />
    <HeadlineCta
      headerImage="/assets/headline-cta-2.jpg"
      title="New Audience"
      description="Create a new audience"
      path="/audiences/create"
      position={HeadlinePosition.Middle}
      displayModal
    />
    <HeadlineCta
      headerImage="/assets/headline-cta-3.jpg"
      title="Featured Audience Profile"
      description="Understand audience composition"
      path="/downloads/Connekd-Audience-Profile-(Example).pdf"
      position={HeadlinePosition.Right}
      displayModal={false}
    />
  </>
);

const oldMetrics = (
  <>
    <HeadlineCta
      headerImage="/assets/headline-cta-1-old.jpg"
      title="Existing Audiences"
      description="See what audiences are available to you"
      path="/audiences"
      position={HeadlinePosition.Left}
      displayModal={false}
    />
    <HeadlineCta
      headerImage="/assets/headline-cta-2-old.jpg"
      title="New Audience"
      description="Create a new audience"
      path="/audiences/create"
      position={HeadlinePosition.Middle}
      displayModal
    />
    <HeadlineCta
      headerImage="/assets/headline-cta-3-old.jpg"
      title="Featured Audience Profile"
      description="Understand audience composition"
      path="/downloads/Connekd-Audience-Profile-(Example).pdf"
      position={HeadlinePosition.Right}
      displayModal={false}
    />
  </>
);

export function Home({ initialAudienceList }: IHomeProps): JSX.Element {
  const { getAudienceToolGroup } = useAudienceToolGroup();
  const [audienceList, setAudienceList] = useState<AudienceOverview[] | null>(initialAudienceList);
  const [audienceCount, setAudienceCount] = useState<number | null>(null);
  const [audienceListError, setAudienceListError] = useState("");

  const user = useUserStore.authenticatedUser();
  const userHasAccess = user?.status_num === 2;

  const getRecentAudiences = async (): Promise<void> => {
    try {
      const response = await getWithToken<ISearchResultsModel>(
        ConfigurationHelper.GetAudiencesEndpoint(0, 10, "active"),
      );

      const searchResultsModel = SearchResultsModel.fromExisting(response);
      setAudienceList(searchResultsModel.results);
      setAudienceCount(searchResultsModel.count);
    } catch (error) {
      setAudienceListError(convertErrorToString(error as Error));
    }
  };

  useEffect(() => {
    if (getAudienceToolGroup()?.length) getRecentAudiences();
  }, [getAudienceToolGroup()]);

  const shouldUseNewAssets = allowNewAssets();

  return (
    <div className="home-page">
      <Grid container className="header">
        <HomeHeader />
      </Grid>
      <Grid container className="headline-metrics">
        {shouldUseNewAssets ? newMetrics : oldMetrics}
      </Grid>
      <KeyMetrics />
      <Grid container className="footer">
        <Grid item xs={8} className="audience-table-container">
          {audienceList ? (
            userHasAccess && (
              <Loadable
                errorMessage={audienceListError}
                loadingObject={audienceList}
                loadedComponent={() => (
                  <RecentAudiences audienceList={audienceList} totalAudienceCount={audienceCount!} />
                )}
              />
            )
          ) : (
            <CenteredProgress />
          )}
        </Grid>
        <FAQ />
      </Grid>
    </div>
  );
}

Home.defaultProps = {
  initialAudienceList: null,
};
