import "./recent-audiences.scss";

import { Alert } from "@mui/material";
import { WppButton } from "@platform-ui-kit/components-library-react";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { useNavigate } from "react-router-dom";
import { RecentAudiencesTable } from "../../../../components/recent-audiences-table";
import { IViewAudienceContext, ViewAudiencesContextProvider } from "../../../audiences/context";

interface Props {
  audienceList: AudienceOverview[];
  totalAudienceCount: number;
}

export function RecentAudiences({ audienceList, totalAudienceCount = 1 }: Props): JSX.Element {
  const navigate = useNavigate();

  const context: IViewAudienceContext = {
    onAudienceDetailsReceived: () => {
      // NOTE: nothing to do on most-recent
    },
  };

  return (
    <div className="recent-audiences">
      {audienceList.length === 0 ? (
        <Alert severity="info">You do not currently have any audiences defined</Alert>
      ) : (
        <>
          <h3>
            Most Recent Audiences
            <span>{` ${audienceList.length} of ${totalAudienceCount}`}</span>
          </h3>

          <ViewAudiencesContextProvider value={context}>
            <RecentAudiencesTable audienceList={audienceList} />
          </ViewAudiencesContextProvider>

          <div className="view-all-audiences">
            <WppButton
              variant="secondary"
              onClick={() => {
                navigate("/audiences");
              }}
            >
              View all audiences
            </WppButton>
          </div>
        </>
      )}
    </div>
  );
}
