import "./landing-page.scss";

import { Box } from "@mui/material";
import { useState } from "react";
import { Apply } from "./apply/index";
import { LoginBox } from "./components/loginBox";

export const Landing: React.FC = () => {
  const [requestAccess, setRequestAccess] = useState<boolean>(false);

  return requestAccess ? (
    <Apply />
  ) : (
    <Box
      sx={{
        backgroundImage: `url("/assets/background.jpg")`,
      }}
      className="landing-box"
    >
      <LoginBox setRequestAccess={setRequestAccess} />
    </Box>
  );
};
