import "./operator-select.scss";

import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GroupCondition } from "../../../../../components/query-builder/models/group-condition";
import { Operator } from "../../../../../models/query-builder/operator";

interface Props {
  handleOperatorChanged: (event: SelectChangeEvent) => void;
  condition: GroupCondition;
}

export function OperatorSelect(props: Props): JSX.Element {
  const { handleOperatorChanged, condition } = props;

  return (
    <Select
      disableUnderline
      variant="standard"
      className="operator-select"
      labelId={`condition-${condition.getName()}-operator-label`}
      value={condition.getOperator()}
      label="operator"
      size="small"
      onChange={handleOperatorChanged}
    >
      <MenuItem value={Operator.And}>And</MenuItem>
      <MenuItem value={Operator.Or}>Or</MenuItem>
      <MenuItem value={Operator.IsNot}>Is Not</MenuItem>
    </Select>
  );
}
