import type { Params } from "react-router-dom";

export enum ActionType {
  Create,
  Edit,
  Merge,
  Duplicate,
}

export function determineActionType(params: Readonly<Params>): ActionType {
  if (params.audienceOneId && params.audienceTwoId) {
    return ActionType.Merge;
  }

  if (params.audienceIdToDuplicate) {
    return ActionType.Duplicate;
  }

  if (params.audienceId) {
    return ActionType.Edit;
  }

  return ActionType.Create;
}
