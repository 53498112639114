import { Button, Grid } from "@mui/material";
import "../../home/components/header/header.scss";
import "../../../styles/main.scss";

interface IHeadingAndButtonsProps {
  onAddAudienceClick: () => void;
  count: number | undefined;
  roleNums: number[];
  roleNumUser: number;
}

export function HeadingAndButtons({ onAddAudienceClick, count, roleNums, roleNumUser }: IHeadingAndButtonsProps) {
  return (
    <>
      <Grid item xs={6}>
        <div className="margin-left">
          <h1>Audiences{count && count > 0 ? <span>{count}</span> : ""}</h1>
        </div>
      </Grid>
      <Grid className="header-column-right" item xs={6}>
        {roleNums.includes(roleNumUser) && (
          <Button className="create-new-audience fill-primary" onClick={onAddAudienceClick}>
            Create New Audience
          </Button>
        )}
      </Grid>
    </>
  );
}
