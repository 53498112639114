import "./export-modal.scss";

import { useState } from "react";

import { Box, Button, Checkbox, FormControlLabel, Grid, Modal } from "@mui/material";
import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { ConditionalTooltip } from "@vmlyr/appserviceshared/dist/components/conditional-tooltip";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { Progress } from "../../../../../components/progress";
import ConfigurationHelper from "../../../../../helpers/configuration-helper";
import { style } from "../../styles/modal-style";
import { useExportsData } from "../../../../../stores/exports";
import { ProfileView } from "../../models/profile-view";

interface IExportModalProps {
  allViews: ProfileView[] | null;
  audienceId: string;
  onClose: () => void;
  onExportSaved: (exportId: string) => void;
}

const MAX_EXPORT_LIMIT = 10;

export function ExportModal({ allViews, audienceId, onExportSaved, onClose }: IExportModalProps): JSX.Element {
  const [viewSelections, setViewSelections] = useState<ProfileView[]>([]);
  const [exportAllDimensions, setIsAllViewsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const currentExportIds = useExportsData();

  const handleSave = async () => {
    if (currentExportIds.length >= MAX_EXPORT_LIMIT) {
      alert(`You can't queue up more than ${MAX_EXPORT_LIMIT} audiences at once. Cancel some existing exports.`);
      return;
    }

    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await postWithToken(ConfigurationHelper.ExportAudienceEndpoint(), {
        audienceId,
        exportAllDimensions,
        exportedViewNames: viewSelections.map((v) => v.id),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const { exportId } = await response.json();
      onExportSaved(exportId);
    } catch (error) {
      setServerError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getValidationMessage = () => {
    if (viewSelections.length === 0 && !exportAllDimensions) {
      return "Please choose at-least one view or all dimensions";
    }
    return null;
  };

  const onSelect = (selectedView: ProfileView) => {
    const indexOfViewInExistingSet = viewSelections.indexOf(selectedView);
    if (indexOfViewInExistingSet === -1) {
      const newSelections = [...viewSelections, selectedView];
      setViewSelections(newSelections);
    } else {
      const newSelections = viewSelections.filter((v) => v !== selectedView);
      setViewSelections(newSelections);
    }
  };

  const validationMessage = getValidationMessage();

  const handleAllViewsSelected = () => {
    setIsAllViewsSelected(!exportAllDimensions);
  };

  return (
    <Modal
      id="export-modal"
      open
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={12}>
            <h3>Export</h3>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="All components"
              control={<Checkbox checked={exportAllDimensions} onChange={handleAllViewsSelected} />}
              value="All components"
              checked={exportAllDimensions}
            />
            {allViews?.map((v) => (
              <ConditionalTooltip
                key={v.id}
                text="This view must be saved before it can be used in an export"
                condition={!v.id}
              >
                <FormControlLabel
                  label={v.name}
                  disabled={!v.id}
                  control={
                    <Checkbox
                      checked={viewSelections.includes(v)}
                      onChange={() => {
                        onSelect(v);
                      }}
                    />
                  }
                  value={v.name}
                  checked={viewSelections.includes(v)}
                />
              </ConditionalTooltip>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }} className="action-buttons">
            {isLoading && <Progress />}
            <Button
              disabled={validationMessage !== null || isLoading}
              variant="contained"
              className={validationMessage === null && !isLoading ? "fill-primary" : ""}
              onClick={handleSave}
            >
              Add to Queue
            </Button>
            <Button
              disabled={isLoading}
              variant="text"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {serverError && <WppInlineMessage size="s" type="error" message={serverError} />}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
