import { WppListItem, WppSelect } from "@platform-ui-kit/components-library-react";

interface IContextMenuProps {
  children: Array<JSX.Element | null>;
  buttonText: string | null;
  className: string | null;
}

export default function ContextMenu({ children, buttonText, className }: IContextMenuProps) {
  const buttonId = buttonText!;

  return (
    <div className={`context-menu ${className}`}>
      <WppSelect size="s" placeholder={buttonId}>
        <div className="custom-dropdown">
          {children.map((child, index) => {
            const id = index;
            return (
              <WppListItem
                key={id}
                onClick={() => {
                  // @ts-expect-error
                  document.getElementById(child.key)?.click();
                }}
              >
                <div slot="label">{child}</div>
              </WppListItem>
            );
          })}
        </div>
      </WppSelect>
    </div>
  );
}
