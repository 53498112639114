import { WppIconEdit } from "@platform-ui-kit/components-library-react";
import "./icon-link.scss";

import { Link } from "react-router-dom";

interface IIconLinkProps {
  to: string;
  icon: "edit";
  linkText: string;
  openInNewWindow?: boolean;
}

export function IconLink({ to, icon, linkText, openInNewWindow = false }: IIconLinkProps): JSX.Element {
  let iconComponent;

  switch (icon) {
    case "edit": {
      iconComponent = <WppIconEdit />;
      break;
    }

    default: {
      throw new Error(`Incoming icon type ${icon} was not handled`);
    }
  }

  const target = openInNewWindow ? "_blank" : "_self";

  return (
    <Link className="icon-link" to={to} target={target}>
      {iconComponent} {linkText}
    </Link>
  );
}
