import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { IDimensionCategory } from "../../components/demographic-selector/models/dimensions";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function listDimensions(): Promise<IDimensionCategory[]> {
  const endpoint = ConfigurationHelper.GetDimensionsEndpoint();

  const response = await getWithToken<IDimensionCategory[]>(endpoint);

  return response;
}
