import "./drag-target.scss";

import { useState } from "react";
import { DragType } from "../../../../../../models/query-builder/drag-type";

interface IDragTargetProps {
  onDraggedOver: () => void;
  dragType: DragType;
}

export function DragTarget({ onDraggedOver, dragType }: IDragTargetProps) {
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const handleIsDraggedOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsDraggedOver(true);
    onDraggedOver();
  };

  const handleIsNotDraggedOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsDraggedOver(false);
  };

  let className = null;
  let text = "";

  switch (dragType) {
    case DragType.Above:
    case DragType.Below: {
      className = isDraggedOver ? "drag-target-highlighted" : "drag-target";
      text = "drop here";
      break;
    }
    case DragType.LeftOperand:
    case DragType.RightOperand: {
      className = isDraggedOver ? "drag-target-operand-highlighted" : "drag-target-operand";
      text = "Missing component";
      break;
    }
    default: {
      throw new Error(`The drag target could not render because the passed-in drag type ${dragType} was not handled`);
    }
  }

  return (
    <div className={className} onDragEnter={handleIsDraggedOver} onDragLeave={handleIsNotDraggedOver}>
      <span onDragEnter={handleIsDraggedOver} onDragLeave={handleIsNotDraggedOver}>
        {text}
      </span>
    </div>
  );
}
