import "./project-actions.scss";

import { Grid } from "@mui/material";
import { WppIconArchive, WppIconCopy, WppIconExport, WppIconEye } from "@platform-ui-kit/components-library-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../assets/icon-trash.svg";
import { ReactComponent as ReactivateIcon } from "../../../assets/icon-undo.svg";
import { ActionButton } from "../../../components/action-button";
import { useAuthenticatedUser, useUserIsAdmin } from "../../../stores/user";
import type { IProjectData } from "../models/projectModel";

interface Props {
  selectedProjects: IProjectData[];
  applyArchive: () => void;
  applyExport: () => void;
  applyUnarchive: () => void;
  applyDelete: () => void;
}

export function ProjectActions(props: Props): JSX.Element {
  const { selectedProjects, applyArchive, applyExport, applyUnarchive, applyDelete } = props;

  const navigate = useNavigate();
  const [canDelete, setCanDelete] = useState(false);
  const [deleteDisabledMsg, setDeleteDisabledMsg] = useState("No Projects selected");

  const authenticatedUser = useAuthenticatedUser();
  const isAdmin = useUserIsAdmin();

  useEffect(() => {
    const someSelected = selectedProjects.length > 0;

    if (!someSelected) {
      setCanDelete(false);
      setDeleteDisabledMsg("No projects selected");
      return;
    }

    if (isAdmin) {
      setCanDelete(true);
      return;
    }

    const allProjectsBelongToUser = selectedProjects.every((project) => project.email === authenticatedUser?.email);

    setCanDelete(allProjectsBelongToUser);

    if (!allProjectsBelongToUser) {
      setDeleteDisabledMsg("Must be admin, or all projects must be yours");
    }
  }, [selectedProjects, isAdmin]);

  const viewProjectButton = (
    <ActionButton
      disabledMessage="You can only view one active project at a time"
      isDisabled={selectedProjects.length > 1 || selectedProjects.some((e) => e.isArchived)}
      onClick={() => {
        navigate(`/explore/edit/${selectedProjects[0].id}`);
      }}
      icon={<WppIconEye />}
      buttonText="View"
      id="view-project-button"
    />
  );

  const duplicateProjectButton = (
    <ActionButton
      disabledMessage={"You can duplicate only one active project at a time"}
      isDisabled={selectedProjects.length > 1 || selectedProjects.some((e) => e.isArchived)}
      onClick={() => {
        navigate(`/explore/create`, { state: selectedProjects });
      }}
      icon={<WppIconCopy />}
      buttonText="Duplicate"
      id="duplicate-project-button"
    />
  );

  const exportProjectButton = (
    <ActionButton
      disabledMessage={"All selected projects must be active"}
      isDisabled={selectedProjects.some((e) => e.isArchived)}
      onClick={() => {
        applyExport();
      }}
      icon={<WppIconExport />}
      buttonText="Export"
      id="export-project-button"
    />
  );

  const deactivateProjectButton = (
    <ActionButton
      disabledMessage={"All selected projects must be active"}
      isDisabled={selectedProjects.some((e) => e.isArchived)}
      onClick={applyArchive}
      icon={<WppIconArchive />}
      buttonText="Deactivate"
      id="deactivate-project-button"
    />
  );

  const reactivateProjectButton = (
    <ActionButton
      disabledMessage={"All selected projects must be deactivated"}
      isDisabled={!selectedProjects.every((e) => e.isArchived)}
      onClick={applyUnarchive}
      icon={<ReactivateIcon className="icon reactivate-icon" />}
      buttonText="Reactivate"
      id="reactivate-project-button"
    />
  );

  const deleteProjectButton = (
    <ActionButton
      disabledMessage={deleteDisabledMsg}
      isDisabled={!canDelete}
      onClick={applyDelete}
      icon={<DeleteIcon className="icon delete-icon" />}
      buttonText="Delete"
      id="delete-project-button"
    />
  );

  return (
    <Grid container className="project-actions">
      <Grid
        className="selected-projects"
        item
      >{`${selectedProjects.length} project${selectedProjects.length === 1 ? "" : "s"} selected`}</Grid>
      <Grid item>
        <Grid>
          {viewProjectButton}
          {duplicateProjectButton}
          {exportProjectButton}
          {deactivateProjectButton}
          {reactivateProjectButton}
          {deleteProjectButton}
        </Grid>
      </Grid>
    </Grid>
  );
}
