import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";

export interface Profile {
  id: string;
  name: string;
  dimensions: string[];
  audienceId: string;
  creatorUserId: string;

  creator: {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
  };

  lastModifiedDate: Date | null;
  lastModifiedByUser: {
    userId: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
  };
}

export async function listProfiles(audienceId: string): Promise<Profile[]> {
  const searchUrl: string = `${ConfigurationHelper.getConnekdApiEndpoint()}/audience/${audienceId}/profiles`;

  const response: { result: Profile[] } = await getWithToken(searchUrl);

  return response.result;
}
