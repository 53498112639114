import type { GridColDef } from "@mui/x-data-grid-pro";
import { SimpleDate } from "../../../../components/simple-date";
import OwnerCell from "../../table/owner-cell";
import TitleCell from "../../table/title-cell";

const ProjectTableDefinition = (): GridColDef[] => [
  {
    field: "title",
    headerName: "Title",
    flex: 1.6,
    renderCell: (value) => <TitleCell project={value.row} />,
  },
  {
    field: "last-run",
    headerName: "Last run",
    flex: 0.8,
    renderCell: (value) => <SimpleDate date={value.row.lastRun} isTimeSince />,
  },
  {
    field: "last-updated",
    headerName: "Last updated",
    flex: 0.8,
    renderCell: (value) => <SimpleDate date={value.row.lastModified} isTimeSince />,
  },
  {
    field: "owner",
    headerName: "Owner",
    flex: 0.8,
    renderCell: (value) => <OwnerCell project={value.row} />,
  },
  {
    field: "effectiveDate",
  },
];

export default ProjectTableDefinition;
