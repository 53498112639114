import type { GridColDef } from "@mui/x-data-grid-pro";
import EmailCell from "../../table/email-cell";
import EntityCell from "../../table/entity-cell";
import LastLoggedInCell from "../../table/last-logged-in-cell";
import StatusCell from "../../table/status-cell";
import TrainedCell from "../../table/trained-cell";
import WelcomedCell from "../../table/welcomed-cell";

const UserTableDefinition = (
  reloadUserTable: (action?: string) => void,
  setSelectedUsers: any,
  searchResult: any,
): GridColDef[] => [
  {
    field: "email",
    headerName: "Email",
    flex: 1.6,
    renderCell: (value) => <EmailCell user={value.row} />,
  },
  {
    field: "entity",
    headerName: "Entity",
    flex: 0.8,
    renderCell: (value) => <EntityCell user={value.row} />,
  },
  {
    field: "welcome",
    headerName: "Welcomed",
    flex: 0.8,
    renderCell: (value) => (
      <WelcomedCell
        user={value.row}
        reloadUserTable={reloadUserTable}
        setSelectedUsers={setSelectedUsers}
        searchResult={searchResult}
      />
    ),
  },
  {
    field: "trained",
    headerName: "Trained",
    flex: 0.8,
    renderCell: (value) => (
      <TrainedCell
        user={value.row}
        reloadUserTable={reloadUserTable}
        setSelectedUsers={setSelectedUsers}
        searchResult={searchResult}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.8,
    renderCell: (value) => <StatusCell user={value.row} />,
  },
  {
    field: "lastLoggedIn",
    headerName: "Last logged in",
    flex: 1,
    renderCell: (value) => <LastLoggedInCell user={value.row} reloadUserTable={reloadUserTable} />,
  },
];

export default UserTableDefinition;
