import "./graphs.scss";

import { Grid, Stack, TextField } from "@mui/material";
import { WppIconEdit, WppInlineMessage } from "@platform-ui-kit/components-library-react";
import type { Dispatch, SetStateAction } from "react";
import type { IDimension } from "../../../../../components/demographic-selector/models/dimensions";
import type { ProfileView } from "../../models/profile-view";
import { SaveButtons } from "../save-buttons";
import type { SaveProfileAction } from "../widgets";
import { GraphRenderer } from "./graph-renderer";

interface Props {
  view: ProfileView;
  allDimensions: IDimension[];
  audienceId: string;
  audienceSize: number;
  disabled: boolean;
  onViewSaved: (type: SaveProfileAction) => void;
  audienceName: string;
  profileName: string;
  setProfileName: React.Dispatch<React.SetStateAction<string>>;
  isSingleProfilePage: boolean;
  setWidth: Dispatch<SetStateAction<number>>;
  width: number;
}

export function Graphs(props: Props): JSX.Element {
  const {
    view,
    allDimensions,
    audienceId,
    audienceSize,
    disabled,
    onViewSaved,
    audienceName,
    profileName,
    setProfileName,
    isSingleProfilePage,
    setWidth,
    width,
  } = props;

  const handleNameChange = (newName: string): void => {
    setProfileName(newName);

    const newWidth = Math.max(160, newName.length * 10 + 2);
    setWidth(newWidth);
  };

  const nameField = (
    <Stack direction="row" spacing={1} alignItems="center">
      <WppIconEdit />
      <TextField
        id="name-input"
        variant="standard"
        value={profileName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleNameChange(event.target.value);
        }}
        InputProps={{
          style: { cursor: "pointer", width },
          disableUnderline: true,
        }}
      />
    </Stack>
  );

  const graphsGridItems = view.dimensions?.map((dimensionId: string) => {
    const dimension = allDimensions.find((ad: IDimension) => ad.id === dimensionId);

    if (dimension === undefined) {
      console.error(`Dimension "${dimensionId}" was not available.`);
      const errMessage = `Dimension "${dimensionId}" was not found in the dimensions collection.`;

      return (
        <Grid key={dimensionId} item xs={12} className="errorContainer">
          <WppInlineMessage size="s" type="error" message={errMessage} />
        </Grid>
      );
    }

    return (
      <Grid key={dimensionId} item xs={12}>
        <GraphRenderer dimension={dimension} audienceId={audienceId} audienceSize={audienceSize} />
      </Grid>
    );
  });

  return (
    <>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom="16px">
        {nameField}

        {!isSingleProfilePage && (
          <SaveButtons
            currentProfile={view}
            disabled={disabled}
            onViewSaved={onViewSaved}
            audienceName={audienceName}
          />
        )}
      </Stack>

      <Grid container spacing={2}>
        {graphsGridItems}
      </Grid>
    </>
  );
}
