"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addQueryParams = exports.joinPaths = void 0;
const joinPaths = (baseUrl, ...args) => {
    const path = args.join("/").replace("\\", "/").replace("//", "/");
    return baseUrl.endsWith("/") ? `${baseUrl}${path}` : `${baseUrl}/${path}`;
};
exports.joinPaths = joinPaths;
const addQueryParams = (baseUrl, ...queryParams) => {
    let newUrl = baseUrl;
    for (const q of queryParams) {
        const connector = newUrl.includes("?") ? "&" : "?";
        if (!q.includes("=")) {
            throw new Error("Please include query params in the name=value format. They do not need to be encoded");
        }
        const parts = q.split("=");
        if (parts.length !== 2) {
            throw new Error(`The format of the query parameter ${q} is not in the name=value format`);
        }
        newUrl = parts[1] ? `${newUrl}${connector}${encodeURIComponent(parts[0])}=${encodeURIComponent(parts[1])}` : newUrl;
    }
    return newUrl;
};
exports.addQueryParams = addQueryParams;
