import "./index.scss";

import { Box, Button, Stack, Snackbar } from "@mui/material";
import { ConditionalTooltip } from "@vmlyr/appserviceshared/dist/components/conditional-tooltip";
import { getWithToken, postWithToken, putWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { CenteredProgress } from "../../../components/centered-progress";
import { IDimension, IDimensionCategory } from "../../../components/demographic-selector/models/dimensions";
import { IconLink } from "../../../components/icon-link";
import { Loadable } from "../../../components/loadable";

import ConfigurationHelper from "../../../helpers/configuration-helper";
import { extractAllDimensions } from "../../../helpers/dimension-helper";
import { optionalString } from "../../../helpers/string-helpers";
import { useExportsActions } from "../../../stores/exports";
import { useUserStore } from "../../../stores/user";
import { listProfiles, Profile } from "../../../connekd-api/audience-profiles/list-by-audience";
import { AudienceOverview } from "./components/audience-overview/audience-overview";
import { ClearAllModal } from "./components/clear-all-modal";
import { ExportModal } from "./components/export-modal";
import { Graphs } from "./components/graphs";
import { InactiveBanner } from "./components/inactive-banner";
import { NewProfileModal } from "./components/new-profile-modal";
import { Widgets } from "./components/widgets";
import { SaveProfileAction } from "./components/widgets";
import { extractSelectableDimensions } from "./helpers/selectable-dimension-category";
import type { SelectableDimension } from "./models/selectable-dimension";
import { SelectableDimensionCategory } from "./models/selectable-dimension";
import { ProfileView } from "./models/profile-view";

interface IProfileAudienceProps {
  initialAudienceOverview: IAudienceOverview | null;
  initialLoadErrorMessage: string;
  initialCategories: SelectableDimensionCategory[];
  initialAllDimensions: IDimension[] | null;
}

export function ProfileAudience(props: IProfileAudienceProps): JSX.Element {
  const { initialAudienceOverview, initialLoadErrorMessage, initialCategories, initialAllDimensions } = props;
  const params = useParams();
  const { search, state } = useLocation();
  const isSingleProfilePage = search.includes("profile");

  const [searchParams] = useSearchParams();

  const { addExportId } = useExportsActions();

  const [isLoading, setIsLoading] = useState(false);

  const [activeProfileView, setActiveProfileView] = useState<ProfileView | null>(null);
  const [profileViews, setProfileViews] = useState<ProfileView[]>([]);

  const [audience, setAudience] = useState<IAudienceOverview | null>(initialAudienceOverview);
  const [loadError, setloadError] = useState(initialLoadErrorMessage);
  const [dimensionCategories, setDimensionCategories] = useState<SelectableDimensionCategory[] | null>(
    initialCategories,
  );
  const [saveMessage, setSaveMessage] = useState<string>("");
  const [allDimensions, setAllDimensions] = useState<IDimension[] | null>(initialAllDimensions);
  const [openExportModel, setOpenExportModel] = useState(false);
  const [isNewProfileOpen, setIsNewProfileOpen] = useState<boolean>(false);
  const [isClearAllModalOpen, setIsClearAllModalOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(130);
  const [profileName, setProfileName] = useState<string>("New Profile");

  const user = useUserStore.authenticatedUser();
  const roleNum = user?.role_num ?? 0;

  const roleLevels = ConfigurationHelper.GetPermissions().audiences[0];
  const canEdit = roleLevels.edit.includes(roleNum);

  const canExport = roleLevels.export.includes(roleNum);

  const audienceId: string = params.audienceId!;
  const profileId: string | null = searchParams.get("profile");

  const isArchivedAudience = audience?.isArchived === true;
  const areSaveButtonsDisabled = activeProfileView?.dimensions?.length === 0 || profileName.length === 0;

  const { getAudienceToolGroup } = useAudienceToolGroup();
  const oktaGroup = getAudienceToolGroup();
  const { dimensionsToIgnore } = ConfigurationHelper.GetAudienceToolGroups(oktaGroup);

  useEffect(() => {
    setIsLoading(true);
    getWithToken<IAudienceOverview>(ConfigurationHelper.GetSingleAudienceOverviewEndpoint(audienceId))
      .then(async (data) => {
        setAudience(data);
        return await getWithToken<IDimensionCategory[]>(ConfigurationHelper.GetDimensionsEndpoint());
      })
      .then(async (retrievedDimensions) => {
        const selectableCategories = retrievedDimensions.map(
          (d: IDimensionCategory) => new SelectableDimensionCategory(d, dimensionsToIgnore),
        );
        setAllDimensions(extractAllDimensions(retrievedDimensions));
        setDimensionCategories(selectableCategories);
        return await listProfiles(audienceId);
      })
      .then((data: Profile[]) => {
        let profileView = new ProfileView([], profileName, "", audienceId, user?.id);
        if (state?.pathName !== "profiles") {
          const findProfile = data.find((e) => e.id === profileId);
          profileView = findProfile ? findProfile : profileView;
        }
        if (state?.pathName === "profiles") {
          profileView = new ProfileView(state.dimensions, state.profileName, "", audienceId, user?.id || "");
        }
        setIsLoading(false);
        setProfileName(profileView.name);
        setActiveProfileView(profileView);
        const newWidth = Math.max(160, profileView.name.length * 10);
        setWidth(newWidth);
        setProfileViews(data);
      })

      .catch((err: Error) => {
        console.error({ err });
        setloadError(err.message);
      });
  }, [audienceId, profileId]);

  useEffect(() => {
    if (activeProfileView) {
      const allSelectableDimensions = extractSelectableDimensions(dimensionCategories!);
      const dimensionsInView = allSelectableDimensions.filter((d) =>
        activeProfileView.dimensions.includes(d.dimension.id),
      );
      const dimensionsNotInView = allSelectableDimensions.filter(
        (d) => !activeProfileView.dimensions.includes(d.dimension.id),
      );

      for (const d of dimensionsInView) d.select();
      for (const d of dimensionsNotInView) d.deselect();

      update();
    }
  }, [activeProfileView]);

  const update = (): void => {
    const allDimensions = extractSelectableDimensions(dimensionCategories!);
    activeProfileView!.dimensions = allDimensions.filter((d) => d.getIsSelected()).map((d) => d.dimension.id);
    setDimensionCategories([...dimensionCategories!]);
  };

  const handleViewSelected = (view: ProfileView) => {
    setActiveProfileView(view);
    setProfileName(view.name);
    const newWidth = Math.max(130, view.name.length * 10);
    setWidth(newWidth);
  };
  const handleSaveView = async (type: SaveProfileAction) => {
    if (!activeProfileView) return;

    const viewToSave = new ProfileView(
      activeProfileView.dimensions,
      profileName,
      activeProfileView.id,
      audienceId,
      user?.id,
    );

    const endpoint =
      type === SaveProfileAction.CreateNew || activeProfileView.id.length === 0
        ? ConfigurationHelper.CreateProfileEndpoint()
        : ConfigurationHelper.UpdateProfileEndpoint();

    const requestProfileSave =
      type === SaveProfileAction.CreateNew || activeProfileView.id.length === 0 ? postWithToken : putWithToken;

    try {
      const response = await requestProfileSave(endpoint, viewToSave);
      if (!response.ok) {
        setSaveMessage(await response.text());
        return;
      }

      const data = await response.json();

      if (type === SaveProfileAction.CreateNew || activeProfileView?.id?.length === 0) {
        const newView = new ProfileView(activeProfileView.dimensions, profileName, data.id, audienceId, user?.id);
        setProfileViews((prev) => {
          return [...prev, newView];
        });
        setActiveProfileView(newView);
      }

      if (type === SaveProfileAction.SaveOrUpdate && activeProfileView.id?.length > 0) {
        const now = new Date();
        const formattedDateNow = now.toISOString();
        const updatedView = new ProfileView(
          activeProfileView.dimensions,
          profileName,
          activeProfileView.id,
          audienceId,
          user?.id,
          {
            email: user?.email,
            firstName: user?.first_name,
            lastName: user?.last_name,
            userId: user?.id,
          },
          formattedDateNow,
        );

        setProfileViews((prev) => {
          return prev.map((profile) => {
            return profile.id === updatedView.id ? { ...updatedView } : profile;
          });
        });
        setActiveProfileView(updatedView);
      }

      setSaveMessage(`Succesfully saved profile: ${profileName}`);
    } catch (err) {
      setloadError(err instanceof Error ? err.message : String(err));
    }
  };

  const handleDimensionSelected = (dimension: SelectableDimension) => {
    dimension.toggle();
    dimension.isExpanded = true;
    update();
  };

  const handleExportClick = () => {
    setOpenExportModel(true);
  };

  const handleExportSaved = (exportId: string) => {
    setOpenExportModel(false);
    addExportId(exportId);
  };

  const handleActivate = () => {
    if (!isLoading) {
      setIsLoading(true);
      postWithToken(ConfigurationHelper.UnarchiveAudienceEndpoint(audience!.id), undefined).then(async (response) => {
        if (!response.ok) setloadError(await response.text());
        setAudience({ ...audience!, isArchived: false });
        setIsLoading(false);
      });
    }
  };

  const isNotArchived = audience?.isArchived !== true;
  const shouldShowProfiler = isNotArchived && !loadError && audience;

  const actionButtons = (
    <Stack
      direction="row"
      alignItems="baseline"
      spacing={2}
      id="action-buttons"
      className={optionalString("inactive", audience?.isArchived)}
    >
      {!isSingleProfilePage && (
        <ConditionalTooltip
          text="An audience must be enabled for it to be edited"
          condition={audience?.isArchived === true}
        >
          {canEdit ? (
            <span className="edit-definition-button">
              <IconLink
                to={optionalString(`/audiences/edit/${audienceId}`, !audience?.isArchived)}
                icon="edit"
                linkText="Edit definition"
                openInNewWindow
              />
            </span>
          ) : (
            <></>
          )}
        </ConditionalTooltip>
      )}
      {canExport && (
        <Button disabled={audience?.isArchived} className="fill-primary" variant="outlined" onClick={handleExportClick}>
          Export
        </Button>
      )}
    </Stack>
  );

  const profilerHeaderPane = (
    <Loadable
      errorMessage={loadError}
      loadingObject={audience}
      loadedComponent={() => (
        <AudienceOverview
          audience={audience!}
          actionButtons={actionButtons}
          isSingleProfilePage={isSingleProfilePage}
          disabled={areSaveButtonsDisabled}
          onViewSaved={handleSaveView}
          audienceName={audience?.name}
          activeProfileView={activeProfileView}
        />
      )}
    />
  );
  const editor = (
    <>
      {isArchivedAudience && <InactiveBanner onActivate={handleActivate} />}
      <Stack className="profiler" direction="row" spacing={0}>
        <Box className="pane-for-dimension-selector">
          {shouldShowProfiler && (
            <Loadable
              loadingObject={activeProfileView && dimensionCategories}
              loadedComponent={() => (
                <Widgets
                  disabled={profileViews.length === 0}
                  profileViews={profileViews!}
                  view={activeProfileView!}
                  categories={dimensionCategories!}
                  audienceId={audienceId}
                  handleDimensionSelected={handleDimensionSelected}
                  setDimensionCategories={setDimensionCategories}
                  setIsNewProfileOpen={setIsNewProfileOpen}
                  setIsClearAllOpen={setIsClearAllModalOpen}
                  handleViewSelected={handleViewSelected}
                  isSingleProfilePage={isSingleProfilePage}
                  profileName={profileName}
                  setActiveProfileView={setActiveProfileView}
                  setProfileName={setProfileName}
                />
              )}
            />
          )}
        </Box>

        <Box className="pane-for-content" sx={{ overflow: "auto", height: "auto", flex: 1 }}>
          {profilerHeaderPane}
          {shouldShowProfiler && (
            <Loadable
              loadingObject={allDimensions && audience && activeProfileView}
              loadedComponent={() => (
                <Box className="all-graphs-container">
                  <Graphs
                    disabled={areSaveButtonsDisabled}
                    onViewSaved={handleSaveView}
                    allDimensions={allDimensions!}
                    view={activeProfileView!}
                    audienceId={audience.id}
                    audienceSize={audience.size || 0}
                    audienceName={audience.name}
                    profileName={profileName}
                    setProfileName={setProfileName}
                    isSingleProfilePage={isSingleProfilePage}
                    setWidth={setWidth}
                    width={width}
                  />
                </Box>
              )}
            />
          )}
        </Box>
      </Stack>
      {audience?.isArchived && (
        <div className="inactive-audience-editor">
          Inactive audience. <button onClick={handleActivate}>Activate</button>
        </div>
      )}
    </>
  );

  const exportModal = openExportModel ? (
    <ExportModal
      audienceId={audience!.id}
      allViews={profileViews}
      onClose={() => {
        setOpenExportModel(false);
      }}
      onExportSaved={handleExportSaved}
    />
  ) : null;

  const newProfileModal = isNewProfileOpen ? (
    <NewProfileModal
      onClose={() => {
        setIsNewProfileOpen(false);
      }}
      categories={dimensionCategories}
      activeProfileView={activeProfileView}
    />
  ) : null;

  const clearAllModal = isClearAllModalOpen ? (
    <ClearAllModal
      onClose={() => {
        setIsClearAllModalOpen(false);
      }}
      categories={dimensionCategories}
      activeProfileView={activeProfileView}
    />
  ) : null;

  return (
    <div className="profiler-page" data-testid="profiler">
      <Snackbar
        open={saveMessage !== ""}
        autoHideDuration={5000}
        onClose={() => {
          setSaveMessage("");
        }}
        message={saveMessage}
      />
      {exportModal}
      {newProfileModal}
      {clearAllModal}
      {isLoading ? <CenteredProgress /> : editor}
    </div>
  );
}

ProfileAudience.defaultProps = {
  initialAudienceOverview: null,
  initialLoadErrorMessage: "",
  initialViews: null,
  initialCategories: null,
  initialProfileView: null,
  initialAllDimensions: null,
  initialRenamingView: null,
};
