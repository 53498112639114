import { toNumOnlyString } from "@vmlyr/common/dist/helpers/sort-values";

/**
 * Sort string columns with numerics with extra characters, eg: % or $ or ','
 * @param a
 * @param b
 */
export const sortNumericalColumns = (a: string, b: string): number => {
  const aN = toNumOnlyString(a.toString());
  const bN = toNumOnlyString(b.toString());

  if (aN !== "" && bN !== "") {
    return Number(aN) - Number(bN);
  }

  // both a & b are non-numerical, use base values

  return a.toLowerCase().localeCompare(b.toLowerCase());
};
