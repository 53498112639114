import { WppTag } from "@platform-ui-kit/components-library-react";
import type { IuserData } from "../../models/userModel";

export default function StatusCell({ user }: { user: IuserData }) {
  switch (user.status) {
    case "Active": {
      return <WppTag label={user.status} variant="positive" />;
    }
    case "Inactive": {
      return <WppTag label={user.status} variant="negative" />;
    }
    default: {
      return <WppTag label={user.status} variant="secondary" />;
    }
  }
}
