export interface IUserAttributeValue {
  userFriendlyName: string;
  code: string;
}

export class UserAttribute {
  constructor(
    public userAttribute: string,
    public allowedValues: IUserAttributeValue[] | null = null,
  ) {}
}
