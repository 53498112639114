// @ts-nocheck

import React from "react";

import whyDidYouRender from "@welldone-software/why-did-you-render";

import ReactDOM from "react-dom/client";
import "@platform-ui-kit/components-library/dist/platform-ui-kit/wpp-theme.css";
import "./index.scss";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import browserHistory from "./routing/history";
import { unregister } from "./serviceWorkerRegistration";

if (process.env.NODE_ENV === "development") {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const rootElement = document.querySelector("#root");
rootElement!.className = "root";
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <HistoryRouter history={browserHistory}>
      <App />
    </HistoryRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(outputWebVitals);

global.webVitalsStats = [];
function outputWebVitals(metrics: object): void {
  if (process.env.REACT_APP_ENV_NAME !== "Prod") {
    global.webVitalsStats[metrics.name] = metrics;
    if (metrics.rating !== "good") {
      console.warn("Web Vitals stats less than good:");
      console.log(global.webVitalsStats);
    }
  }
}
