import { AccordionDetails, Grid } from "@mui/material";
import { AudienceToolAccordion, AudienceToolAccordionSummary } from "../../../../components/audience-tool-accordion";

export const faqContent = (
  <Grid className="faq-container" item xs={4}>
    <div className="faq-section">
      <h3>FAQ</h3>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>Is all data actually connected?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Yes. One of the key advantages of Connekd is that all the data assets are connected to the core data spine –
            Choreograph’s IDN – so you can easily mix and match all available data assets both for building audiences
            and getting profiling detail.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>What does the data include?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            At its core, Connekd is built around Choreograph’s IDN as the main data spine (large-scale and carefully
            groomed US adult consumer database, benchmarked to Census 2020). It also utilizes a range of other WPP-owned
            data sources and additional partnerships with 3rd party providers that increase the density of signals and
            provide industry-specific detail.
          </p>
          <p>
            All external and internal WPP data assets undergo a process of fusion with core data spine (IDN) and
            effectively become a subset of inter-connected data environment.
          </p>
          <p>
            Currently featured data assets include Choreograph IDN (demographics, geography, psychographics, retail
            buyers, consumer packaged goods, health (not sensitive), property, finance), Census 2020, S&P Global
            Mobility (Polk) (full scale automotive data across current ownership, loyalty, and in-market signals), and
            Choreograph’s Audience Origin survey-based consumer data (fused into IDN and scaled to the entire US adult
            population).
          </p>
          <p>
            New data assets will gradually be introduced in the future. Please see&nbsp;
            <i>‘What data will be added in the near future?’</i> for details.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>What data will be added in near future?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Choreograph teams are exploring new 3rd party data opportunities and looking to include a range of
            additional WPP assets in the connected data environment to increase audience precision, relevance, and
            breadth of insight. This also includes several sources you are using today as well as 1st party data
            platforms and identity resolution providers commonly used (e.g., Neustar, LiveRamp).
          </p>
          <p>The following data partners are currently in integration process:</p>
          <ul>
            <li>
              <strong>Audience Origin</strong> - Choreograph’s survey-based consumer data fused into IDN and scaled to
              US adult population scale. The fusion process is conducted in 2 stages:
            </li>
            <ul>
              <li>Stage 1 – deterministic match of records between Audience Origin and IDN.</li>
              <li>Stage 2 – probabilistic scaling (modeling) of individual attribute.</li>
            </ul>
          </ul>
          <p>The fusion process is also split in 2 phases:</p>
          <ul className="no-space">
            <ul>
              <li>
                Phase 1 – core part of Audience Origin survey covering questions asked to 100% of the sample
                (demographics, psychographics, media diaries) – already available in Connekd.
              </li>
              <li>
                Phase 2 – ’Tools’ and ’Plus’ parts of Audience Origin survey represented by approx. 50% of the sample
                (media consumption, dedicated deep dives (e.g., social media, addressable TV, gaming) – to be available
                in Connekd in Q4 2023.
              </li>
            </ul>

            <li>
              Custom Surveys – Kantar or Dynata panel-based client or industry-specific survey data assets, following
              similar process to Audience Origin fusion.
            </li>
            <li>Polk/TransUnion – automotive financial data, credit score stats.</li>
            <li>Experian – a key data partner used by Ford to develop T1 brand and in-market audiences.</li>
            <li>
              Additional media deep dives – other media-specific data sources that would enrich media consumption
              insight.
            </li>
            <li>
              Other verticals – data assets representing key additional data verticals related to WPP client structure
              and priorities.
            </li>
          </ul>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How frequently is the data refreshed?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Each data component of Choreograph IDN ecosystem gets refreshed at different frequencies depending on where
            the data is sourced from, however, to streamline the release process, all data in Connekd is refreshed every
            6 weeks.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>What can I use Connekd data for?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Connekd can serve as a single source of truth on consumers and audiences across all campaign stages – from
            strategy & planning, through creative & experience development, data science, connections planning, media
            buying, and analytics.
          </p>
          <p>The most common uses cases are:</p>
          <ul>
            <li>
              <strong>Audience Building</strong> – build custom audience definitions using Boolean logic across any/all
              data components and connectors.
            </li>
            <li>
              <strong>Audience Editing</strong> – view and edit definition of any existing audience.
            </li>
            <li>
              <strong>Audience/Market Sizing</strong> – know precise size of any audience, even before saving its
              definition.
            </li>
            <li>
              <strong>Audience Profiling</strong> – build audience profile across any/all data assets available in the
              platform.
            </li>
            <li>
              <strong>Audience Comparisons</strong> – compare profiling data for multiple audience through exporting
              individual audience profiles to Excel.
            </li>
            <li>
              <strong>Audience Overlaps</strong> – understand size (volume) overlap between any two audiences through
              using <i>Merge</i> option in <i>Audience List</i> view.
            </li>
            <li>
              <strong>Audience Exploration</strong> – consumer and audience insight exploration for individual audiences
              in Audience Profile view and through exports to Excel.
            </li>
            <li>
              <strong>Data Exports to Excel</strong> – audience profiles for individual audiences can be exported to
              Excel in Audience Profile view.
            </li>
            <li>
              <strong>Data Science Environment</strong> – dedicated data science environment sitting on the backend of
              Connekd and utilizing the same data & semantic layer; supporting Snowpark (for Python, Java, and Scala)
              and Data Robot tools.
            </li>
            <li>
              <strong>Holistic Segmentations</strong> – enabled through the data science environment and utilizing
              any/all data assets and semantic layer.
            </li>
            <li>
              <strong>Survey Data Fusion & Scaling</strong> – custom survey data collected through Kantar Panel or
              Dynata Panel can now be effectively fused into IDN/Connekd.
            </li>
          </ul>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How fast is Connekd processing data?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Connekd typically processes 300 billion rows of data in just under 8 seconds. For comparison, a market
            standard for similar data processing requirements is around 2 minutes.
          </p>
          <p>
            On average, you can create a complex definition of an audience in just a few minutes and generate its
            complete profile in a few clicks – or generate a comprehensive export file in just under a minute.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How long does it take for the audience to populate in the system?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Once you create and save a new audience definition – it gets instantly populated and is ready to work with.
            There is no waiting time and no need for any additional processing.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>Is there a specific audience naming convention I should follow?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>Connekd audiences use two separate audience naming elements:</p>
          <ul>
            <li>
              Audience Description – a free text space limited to 50 characters. This is only for internal purposes and
              never leaves the platform. You can use whatever naming structure you need.
            </li>
            <li>
              Taxonomic Name – component-based coded name built off selections made in drop-down boxes under&nbsp;
              <i>Audience Description</i>. This creates a coded taxonomic segment ID that gets shared with activation
              platforms and is used in campaign tagging setup. Taxonomic name components also fuel filtering options
              in&nbsp;
              <i>Audience List</i> view. The list of components can be further extended – please reach out to&nbsp;
              <a href="mailto:connekd@wpp.com">connekd@wpp.com</a> to request additional elements to be added.
            </li>
          </ul>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How do I access an Audience Profile view?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            To access an audience profile, you just need to click on any audience name in the <i>Audience List </i>
            view. You get redirected to <i>Audience Profile</i> view where you can select desired dimensions out of all
            data categories and dimensions available in the platform. You can also conveniently export the data to
            Excel.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How is Automotive Loyalty defined?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>S&P Global Mobility (Polk) models car buyers’ loyalty based on their 3 most recent vehicle purchases:</p>
          <ul>
            <li>
              <strong>Super Loyal</strong> – a specific behavior was observed in 3 of 3 last transactions (e.g., Ford
              Super Loyal means that the last 3 vehicles purchased were of the Ford brand).
            </li>
            <li>
              <strong>Loyal</strong> – a specific behavior was observed in 2 of 3 last transactions.
            </li>
            <li>
              <strong>Nomad</strong> – a specific behavior was observed in 1 of 3 last transactions.
            </li>
          </ul>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>What geography levels can I work with in Connekd?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>Connekd offers 4 pre-defined geography levels:</p>
          <ul>
            <li>National (default level)</li>
            <li>State</li>
            <li>FDAF</li>
            <li>DMA</li>
          </ul>
          <p>
            Connekd data also features ZIP and Census Block level data. These signals can be used to build out custom
            geographies/areas, if needed. Please reach out to <a href="mailto:connekd@wpp.com">connekd@wpp.com</a> for
            more details or to request custom geographies.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How can I distribute audiences to activation platforms?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            Connekd audiences are linked to activation space through a network of Choreograph’s AmeriLINK integrations
            across key ad platforms:
          </p>
          <ul>
            <li>Programmatic display & video (DV360, TTD, Xandr, Google Ad Manager, Lotame, MediaMath, OpenX),</li>
            <li>Social media (Meta),</li>
            <li>CRM platforms (Oracle Bluekai, Adobe Audience Manager, Salesforce Audience Studio).</li>
          </ul>
          <p>
            Audience distribution cannot be currently self-served and needs to be processed by our team. To request an
            audience distribution, please reach out to <a href="mailto:connekd@wpp.com">connekd@wpp.com</a> and provide:
          </p>
          <ul>
            <li>Taxonomic segment ID,</li>
            <li>Destination channel,</li>
            <li>Destination channel seat number.</li>
          </ul>
        </AccordionDetails>
      </AudienceToolAccordion>
      <AudienceToolAccordion>
        <AudienceToolAccordionSummary>
          <h4>How can I report an issue, suggest a change, or ask an additional question?</h4>
        </AudienceToolAccordionSummary>
        <AccordionDetails>
          <p>
            In all cases, please reach out to <a href="mailto:connekd@wpp.com">connekd@wpp.com</a>. Our platform team
            will assist you in your inquiry.
          </p>
        </AccordionDetails>
      </AudienceToolAccordion>
    </div>
  </Grid>
);
