import type { Profiles } from "@vmlyr/connekdfordshared/dist/models/api/audience-profiles";
import AudienceNameCell from "../../../../components/table-common/audience-name-cell";

interface Props {
  profiles: Pick<Profiles, "audience">;
}

export default function NameCell(props: Props): JSX.Element {
  const {
    profiles: { audience },
  } = props;

  return <AudienceNameCell audienceId={audience.id} taxonomy={audience.taxonomy} audienceName={audience.name} />;
}
