export const EXTENDED_DISCRETE_COLOR_RANGE = [
  "#A683E6",
  "#788FE8",
  "#189ED6",
  "#02A785",
  "#5FA250",
  "#A8923E",
  "#CF863A",
  "#E87482",
  "#DC74CB",
  "#8792A4",
  "#D9D2F9",
  "#C7DAFA",
  "#9BD9FD",
  "#ADE6DB",
  "#D2E3B6",
  "#EBDEA0",
  "#F3D998",
  "#F7D1D5",
  "#F0CBF1",
  "#D8DCE5",
  "#7843CF",
  "#4F4DD0",
  "#176689",
  "#006D55",
  "#376C1F",
  "#6C5816",
  "#984B15",
  "#C23642",
  "#B436A1",
  "#575667",
];
