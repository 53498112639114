import "./bar-chart.scss";

import { sum } from "lodash";
import { useEffect, useRef } from "react";
import type { IGraphProps } from "../..";
import type { IChartData } from "../../models/chart-data";
import { createBarChart, getTopX } from "./helpers/bar-chart-helper";

const calculateDataCutoffPoint = (data: IChartData[]) =>
  // Return max of 10, min of 5, or data length if between
  Math.max(Math.min(data.length, 10), 5);

const ignoreTopTen = new Set(["Household Income"]);

const maxLegendLength = 74;

export function BarGraph({ dimension, data, categoryName, audienceSize }: IGraphProps) {
  const ref = useRef<HTMLDivElement>(null);
  data = data.map((d) => ({
    ...d,
    METRIC: d.METRIC.length > maxLegendLength ? `${d.METRIC.slice(0, maxLegendLength)}…` : d.METRIC,
  }));
  const dataSubsetLength = calculateDataCutoffPoint(data);
  const dataSubsetToUse = getTopX(data, dataSubsetLength);
  const hasLongTitles = data.map((d) => d.METRIC).some((m) => m.length > 10);
  const totalAudienceSize = sum(data.map((d) => d.CNT));
  const dimensionType = dimension.metadata.type;
  const checkIfTopTen: string = data.length > 10 && !ignoreTopTen.has(dimension.name) ? "- Top 10 Results" : "";
  const checkForSource: string = dimension.sourceExtra ? ` from ${dimension.sourceExtra}` : "";
  useEffect(() => {
    if (ref.current && ref.current.children.length === 0) {
      const newPlot = createBarChart(dataSubsetToUse, totalAudienceSize, audienceSize, dimensionType);
      ref.current.append(newPlot);
    }
  }, [dataSubsetToUse, categoryName, data.length, totalAudienceSize, audienceSize, dimensionType, dataSubsetLength]);

  return (
    <>
      <div className={`bar-graph ${hasLongTitles ? "shortened" : ""}`} ref={ref} />
      <small className="graph-footer">
        % of Audience {checkIfTopTen} | Source: Choreograph IDN {checkForSource}
      </small>
    </>
  );
}
