import type { QueryCondition } from "../../../models/query-builder/query-condition";
import { GroupCondition } from "../models/group-condition";

export const detachCondition = (conditionToDetach: QueryCondition) => {
  const parent = conditionToDetach.getParent();
  if (parent === null) {
    return;
  }
  if (!(parent instanceof GroupCondition)) {
    throw new TypeError("A query condition unexpectedly did not have a parent which was a group condition");
  }
  const parentGroup = parent;
  if (parentGroup.getLeftOperand() === conditionToDetach) {
    parentGroup.setLeftOperand(null);
  }
  if (parentGroup.getRightOperand() === conditionToDetach) {
    parentGroup.setRightOperand(null);
  }
};
