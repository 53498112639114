import "./heading-and-buttons.scss";

import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "../../../../../assets/icon-more.svg";
import { EmptyCondition } from "../../../../../components/query-builder/models/empty-condition";
import type { QueryBuilderModel } from "../../../../../components/query-builder/models/query-builder-model";
import { fetchAudiencePreview } from "../../../../../connekd-api/audiences/preview";
import ConfigurationHelper from "../../../../../helpers/configuration-helper";
import { AudienceSize } from "../../../../create-audience/components/audience-size";
import type { Group } from "../../../store/groups";
import { useGroup, useGroupsActions, useGroupsNudge } from "../../../store/groups";
import { GroupNameInput } from "../group-card-name-input";

interface IHeadingAndButtonsProps {
  model: QueryBuilderModel;
  groupId: Group["id"];
  open: boolean;
  groupOptionsAreOpen: null | HTMLElement;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
}

export function GroupCardHeading(props: IHeadingAndButtonsProps): JSX.Element {
  const { model, open, groupOptionsAreOpen, handleClick, handleClose, groupId } = props;

  const [initialModelState] = useState(model.getValidationState().isValid ? model.getQueryAsString() : null);
  const [previousQuery, setPreviousQuery] = useState(initialModelState);

  const [audiencePreviewSize, setAudiencePreviewSize] = useState<number | null>(null);
  const [audiencePreviewStale, setAudiencePreviewStale] = useState<boolean>(false);
  const [audiencePreviewCalculating, setAudiencePreviewCalculating] = useState<boolean>(false);

  const { removeGroup, openSaveGroupModal } = useGroupsActions();

  useGroupsNudge();
  const group = useGroup(groupId);
  const groupDefinition = group.definition;

  const rootConditionValidationState = groupDefinition.rootCondition.getValidationState();

  const isEmptyEditor = (): boolean => groupDefinition.rootCondition instanceof EmptyCondition;

  let stringifiedGroupDefinition: string;

  try {
    stringifiedGroupDefinition = groupDefinition.getQueryAsString();
  } catch (err) {
    stringifiedGroupDefinition = "";
  }

  useEffect(() => {
    if (rootConditionValidationState.isValid) {
      const query = groupDefinition.getQueryAsString();
      if (query !== previousQuery) {
        setPreviousQuery(query);
        setAudiencePreviewStale(true);
      }
    }
  }, [groupDefinition, stringifiedGroupDefinition]);

  useEffect(() => {
    if (isEmptyEditor()) {
      setAudiencePreviewSize(null);
      setAudiencePreviewStale(false);
      setAudiencePreviewCalculating(false);
    }
  }, []);

  useEffect(() => {
    if (model.id !== undefined && model.id.length > 0) {
      getWithToken<IAudienceOverview>(ConfigurationHelper.GetSingleAudienceOverviewEndpoint(model.id))
        .then((data: IAudienceOverview) => {
          setAudiencePreviewStale(true);
          setAudiencePreviewSize(data.size);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  if (audiencePreviewSize !== null) {
  }

  const handleRefreshAudienceSize = (): void => {
    setAudiencePreviewCalculating(true);

    const query = group.definition.getQuery();
    fetchAudiencePreview(query)
      .then((result) => {
        setAudiencePreviewSize(result.size);
        setAudiencePreviewStale(false);
        setAudiencePreviewCalculating(false);
      })
      .catch((error) => {
        console.error(`There was a problem calculating the audience size: ${error.message}`);
      });
  };

  const saveButton = (
    <MenuItem
      className="project-group-option"
      onClick={() => {
        openSaveGroupModal(groupId);
        handleClose();
      }}
    >
      Save as new audience
    </MenuItem>
  );

  const deleteButton = (
    <MenuItem
      className="project-group-option project-group-option-warning"
      onClick={() => {
        removeGroup(groupId);
      }}
    >
      Delete
    </MenuItem>
  );

  const menu = (
    <Menu
      className="project-group-options"
      anchorEl={groupOptionsAreOpen}
      open={open}
      onClose={handleClose}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {saveButton}
      {deleteButton}
    </Menu>
  );

  const getSize = (
    <AudienceSize
      audienceSize={audiencePreviewSize}
      isStale={audiencePreviewStale}
      isLoading={audiencePreviewCalculating}
      hasValidBuilder={rootConditionValidationState.isValid}
      isEmptyEditor={isEmptyEditor()}
      position={"top"}
      onRefreshClick={handleRefreshAudienceSize}
    />
  );

  const menuButton = (
    <Button
      size="small"
      variant="text"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      disableRipple
    >
      <MoreIcon />
    </Button>
  );

  return (
    <Grid container className="project-group-header">
      <Grid item className="header-column-left" xs={7}>
        <GroupNameInput groupId={group.id} text={group.name} validationError={group.validationError} />{" "}
      </Grid>
      <Grid item className="get-size" xs={8}>
        {getSize}
      </Grid>
      <Grid item className="more-options" xs={4}>
        {menuButton}
      </Grid>
      {menu}
    </Grid>
  );
}
