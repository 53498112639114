import "./header.scss";

import { Button, Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";

export function ExploreHeader(): React.ReactElement {
  const navigate = useNavigate();

  const handleNewProject = () => {
    navigate("/explore/create");
  };

  return (
    <>
      <Grid item xs={6}>
        <h1>Explore</h1>
        <h5>Explore data in cross-tab views</h5>
      </Grid>
      <Grid className="header-column-right" item xs={6}>
        <Button className="new-project fill-primary" onClick={handleNewProject}>
          New Project
        </Button>
      </Grid>
    </>
  );
}
