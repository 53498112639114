import type { AudienceDefinitionData } from "../../../../models/query-builder/audience-definition-data";
import type { QueryCondition } from "../../../../models/query-builder/query-condition";
import type { IDimension } from "../../../demographic-selector/models/dimensions";
import { AudienceCondition } from "../audience-condition";
import { CustomAudienceCondition } from "../custom-audience-condition";
import { DiscreetCondition } from "../discreet-condition";
import { GroupCondition } from "../group-condition";
import { MultiRangeCondition } from "../multi-range-condition";
import { MultipleCondition } from "../multiple-condition";
import { RangeCondition } from "../range-condition";

const getDimension = (id: string, allDimensions: IDimension[]): IDimension => {
  if (!id) throw new Error("Get dimension cannot be called with an empty id");

  if (!allDimensions) throw new Error("An array of dimensions must be provided");

  const dimension = allDimensions.find((d) => d.id === id);

  if (!dimension) throw new Error(`There was no dimension with the id ${id} in the dimensions array`);

  return dimension;
};

export const parseToQueryCondition = (data: AudienceDefinitionData, allDimensions: IDimension[]): QueryCondition => {
  if (!data) throw new Error("The audience definition cannot be null");

  switch (data.type) {
    case "range": {
      return new RangeCondition(
        getDimension(data.leftOperand.dimensionId, allDimensions),
        data.leftOperand.lowerBound,
        data.leftOperand.upperBound,
      );
    }

    case "discreet": {
      return new DiscreetCondition(getDimension(data.leftOperand.dimensionId, allDimensions), data.leftOperand.value);
    }

    case "group": {
      return new GroupCondition(
        null,
        data.name || "",
        parseToQueryCondition(data.leftOperand, allDimensions),
        parseToQueryCondition(data.rightOperand, allDimensions),
        data.operator!,
      );
    }

    case "audience": {
      return new AudienceCondition(data.leftOperand.friendlyId);
    }

    case "multiple": {
      return new MultipleCondition(getDimension(data.leftOperand.dimensionId, allDimensions), data.leftOperand.value);
    }

    case "multirange": {
      return new MultiRangeCondition(
        getDimension(data.leftOperand.dimensionId, allDimensions),
        data.leftOperand.values,
      );
    }

    case "customAudience": {
      return new CustomAudienceCondition(data.customAudiences);
    }

    default: {
      throw new Error(`Unhandled incoming data type ${data.type}`);
    }
  }
};
