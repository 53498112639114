// @ts-nocheck

import { createTheme } from "@mui/material/styles";

export const AudienceToolTheme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
  },
});
