import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteButton } from "../../../../../components/delete-button/delete-button";
import { DiscreetValueEditor } from "../../../../../components/editors/discreet-value";
import type { DiscreetCondition } from "../../../../../components/query-builder/models/discreet-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";

interface IDiscreetConditionEditorProps {
  condition: DiscreetCondition;
}

export function DiscreetConditionEditor({ condition }: IDiscreetConditionEditorProps): JSX.Element {
  const context = useCreateAudienceContext();

  const handleStateSelected = (state: string): void => {
    condition.setValue(state);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className="editor-state-condition">
      <DeleteButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        isHidden={!condition.getIsHighlighted()}
      />
      <DiscreetValueEditor
        currentValue={condition.getValue()}
        options={condition.getAllowedValues()}
        valueName={condition.getDimensionName()}
        onOptionChosen={handleStateSelected}
        error={error}
      />
    </div>
  );
}
