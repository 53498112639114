import "./styles.scss";

import { useState } from "react";
import type { QueryBuilderModel } from "../../../../../components/query-builder/models/query-builder-model";
import type { VariableData } from "../../../store/variables";
import { useVariable } from "../../../store/variables";
import { VariablesQueryBuilder } from "../variables-query-builder";
import { HeadingButtons } from "./components/heading-buttons";

interface IVariableCardProps {
  variableId: VariableData["id"];
}

export function VariableCard({ variableId }: IVariableCardProps): JSX.Element {
  const [variableOptionsAreOpen, setVariableOptionsAreOpen] = useState<null | HTMLElement>(null);
  const open = Boolean(variableOptionsAreOpen);

  const variableDefinition: QueryBuilderModel = useVariable(variableId).variableDefinition;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setVariableOptionsAreOpen(event.currentTarget);
  };

  const handleClose = (): void => {
    setVariableOptionsAreOpen(null);
  };

  return (
    <div className="variable-container">
      <HeadingButtons
        open={open}
        variableOptionsAreOpen={variableOptionsAreOpen}
        handleClick={handleClick}
        handleClose={handleClose}
        variableId={variableId}
      />
      <VariablesQueryBuilder variableId={variableId} condition={variableDefinition.rootCondition} />
    </div>
  );
}
