import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import { createContext, useContext } from "react";

export interface IViewAudienceContext {
  onAudienceDetailsReceived: (audience: AudienceOverview) => void;
}

const ViewAudiencesContext = createContext<IViewAudienceContext | undefined>(undefined);

export const ViewAudiencesContextProvider = ViewAudiencesContext.Provider;

export const useViewAudiencesContext = (): IViewAudienceContext => {
  const ctx = useContext(ViewAudiencesContext);

  if (ctx === undefined) {
    throw new Error("view-audiences context was undefined");
  }

  return ctx;
};
