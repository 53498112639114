import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import type { IProjectData } from "../../models/projectModel";

const TOOLTIP_TEXT = "Click to open project";

interface Props {
  project: Pick<IProjectData, "id" | "isArchived" | "title">;
}

export default function TitleCell(props: Props): JSX.Element {
  const { project } = props;
  return (
    <Tooltip className="project-list-title-tooltip" placement="top-start" title={TOOLTIP_TEXT}>
      <Link className="no-style" to={`/explore/edit/${project.id}`}>
        <div className={project.isArchived ? "disabled-label" : ""}>{project.title}</div>
      </Link>
    </Tooltip>
  );
}
