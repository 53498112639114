import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useProjectActions, useProjectTitle, useProjectValidation } from "../../store/projects";
import { NameError } from "../name-error-message";

const PLACEHOLDER = "Project";

export function ProjectNameInput({ originalProjectTitle }: { originalProjectTitle: string }): JSX.Element {
  const { updateTitle } = useProjectActions();

  const title = useProjectTitle();
  const validationError = useProjectValidation();

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        className="project-title-input"
        variant="standard"
        InputProps={{ disableUnderline: true }}
        placeholder={PLACEHOLDER}
        onChange={(event) => {
          const projectName = event.target.value;
          updateTitle(projectName, originalProjectTitle);
        }}
        value={title}
        fullWidth={true}
      />
      {validationError !== null && <NameError errorMessage={validationError} />}
    </Box>
  );
}
