import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { AudienceDefinitionData } from "../../models/query-builder/audience-definition-data";

export interface IAudiencePreview {
  size: number | null;
  isStale: boolean;
  isCalculating: boolean;
}

export async function fetchAudiencePreview(audienceDefinition: AudienceDefinitionData): Promise<IAudiencePreview> {
  const endpoint = `${ConfigurationHelper.getConnekdApiEndpoint()}/Audiences/Preview`;

  const response: Response = await postWithToken(endpoint, audienceDefinition);

  if (response.ok) {
    const payload = await response.json();
    const audiencePreview = {
      ...payload,
      isStale: false,
      isCalculating: false,
    } as IAudiencePreview;

    return audiencePreview;
  }

  console.error("unable to fetch audiences preview", response);
  throw new Error(await response.text());
}
