import { Button } from "@mui/material";
import { memo } from "react";
import { ReactComponent as DragIcon } from "../../../assets/icon-drag.svg";
import { useProjectActions } from "../../../screens/explore/store/projects";
import { AudienceCondition } from "../../query-builder/models/audience-condition";
import type { SelectableCondition } from "../models/selectable-condition";

interface Props {
  option: SelectableCondition;
  onDemographicDropped: (option: SelectableCondition) => void;
}

const DraggableButtonComponent = (props: Props): JSX.Element => {
  const { option, onDemographicDropped } = props;
  const { setDraggedCondition } = useProjectActions();

  const handleDragStart = (e: React.DragEvent<HTMLButtonElement>): void => {
    if (option.draggedObject !== null) {
      const draggedObject = option.draggedObject();
      if (draggedObject instanceof AudienceCondition) {
        setDraggedCondition("AudienceCondition");
      } else {
        setDraggedCondition("");
      }
    }

    const { target } = e;
    if (target) {
      // @ts-expect-error
      const cloneNode = target.cloneNode(true);
      cloneNode.style.position = "absolute";
      cloneNode.id = "dragGhost";
      // @ts-expect-error
      target.parentNode.insertBefore(cloneNode, target);
      e.dataTransfer.setDragImage(cloneNode, 2, 2);
    }
  };

  return (
    <Button
      draggable
      id={option.id}
      disabled={!option.draggedObject}
      disableRipple
      variant="text"
      onDragStart={handleDragStart}
      onDragEnd={() => {
        document.querySelector("#dragGhost")?.remove();
        onDemographicDropped(option);
      }}
      className={option.isVisible ? "audience-pick-button" : "audience-pick-button-hidden"}
    >
      <span className="dragIcon">
        <DragIcon />
      </span>
      <span className="optionName">{option.name}</span>
    </Button>
  );
};

export const DraggableButton = memo(DraggableButtonComponent);
