import "./styles.scss";

import { Step, StepLabel, Stepper } from "@mui/material";
import BackgroundImageUrl from "../../assets/get-started-background.jpg";
import { DragType } from "../../models/query-builder/drag-type";
import type { QueryCondition } from "../../models/query-builder/query-condition";
import { useCreateAudienceContext } from "../../screens/create-audience/context/CreateAudienceContext";
import type { QueryBuilderModel } from "../query-builder/models/query-builder-model";
import { ToggleStep } from "./toggle-step";

interface Props {
  condition: QueryCondition;
}

interface ITaxonomyItem {
  segmentName: string;
  value: string | null;
  isEditable: boolean;
}

export function GetStarted({ condition }: Props): JSX.Element {
  const context = useCreateAudienceContext();

  const handleConditionDraggedOver = (): void => {
    context.onConditionDraggedOver(condition, DragType.OnTop);
  };

  const handleConditionNoLongerDraggedOver = (): void => {
    // context.onConditionDraggedOver(condition.getParent()!, DragType.OnTop);
  };

  const handleConditionDroppedOnto = (): void => {
    context.onConditionDroppedOnto(condition);
  };

  const handleInputChange = (): QueryBuilderModel => context.onInputChange();

  return (
    <div className="editor-empty-condition" style={{ backgroundImage: `url(${BackgroundImageUrl})` }}>
      <h3>Get Started</h3>
      <Stepper orientation="vertical">
        <Step key={1}>
          <ToggleStep label="Name audience" completed={handleInputChange().name.length > 0} stepNumber={1} />
        </Step>
        <Step key={2}>
          <ToggleStep
            label="Apply tags"
            completed={
              handleInputChange().taxonomy.length > 0 &&
              handleInputChange().taxonomy.every((taxonomyItem: ITaxonomyItem) => taxonomyItem.value !== null)
            }
            stepNumber={2}
          />
        </Step>
        <Step key={3}>
          <StepLabel
            StepIconProps={{
              icon: <span className="step-pending">3</span>,
            }}
          >
            <div
              id="initial-drag-target"
              onDragEnter={handleConditionDraggedOver}
              onDragLeave={handleConditionNoLongerDraggedOver}
              onDragEnd={handleConditionDroppedOnto}
              className={`initial-drag-target ${condition.getIsDragTarget() ? "highlighted" : null}`}
            >
              <span className="in-border-text">Drop component</span>
            </div>
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}
