import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { CreateProjectExportInput, CreateProjectExportOutput } from "@vmlyr/common/dist/types/connekd-api";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function createProjectExport(payload: CreateProjectExportInput): Promise<CreateProjectExportOutput> {
  const endpoint = `${ConfigurationHelper.getConnekdApiEndpoint()}/Projects/Export`;

  const response: Response = await postWithToken(endpoint, payload);
  if (response.ok) {
    const data = await response.json();

    console.log("start export result", { data });
    return data;
  }

  console.error("unable to create export", response);
  throw new Error(await response.text());
}
