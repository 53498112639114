import "./widgets.scss";

import { Button, Stack } from "@mui/material";
import { WppIconSearch, WppListItem, WppSelect, WppTextInput } from "@platform-ui-kit/components-library-react";
import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import { extractSelectableDimensions } from "../../helpers/selectable-dimension-category";
import type { SelectableDimension, SelectableDimensionCategory } from "../../models/selectable-dimension";
import { WidgetCategory } from "./components/widget-category";
import { ProfileView } from "../../models/profile-view";
import { useParams } from "react-router-dom";

export enum SaveProfileAction {
  SaveOrUpdate,
  CreateNew,
}

interface IWidgetsProps {
  categories: SelectableDimensionCategory[];
  view: ProfileView;
  audienceId: string;
  handleDimensionSelected: (dimension: SelectableDimension) => void;
  setIsNewProfileOpen: Dispatch<SetStateAction<boolean>>;
  setIsClearAllOpen: Dispatch<SetStateAction<boolean>>;
  setDimensionCategories: Dispatch<SetStateAction<SelectableDimensionCategory[] | null>>;
  disabled: boolean;
  profileViews: ProfileView[];
  handleViewSelected: (profile: ProfileView) => void;
  isSingleProfilePage?: boolean;
  profileName: string;
  setActiveProfileView: Dispatch<SetStateAction<ProfileView | null>>;
  setProfileName: Dispatch<SetStateAction<string>>;
}

export function Widgets(props: IWidgetsProps): JSX.Element {
  const {
    categories,
    handleDimensionSelected,
    setDimensionCategories,
    disabled,
    profileViews,
    setIsClearAllOpen,
    setIsNewProfileOpen,
    handleViewSelected,
    isSingleProfilePage,
    profileName,
    setActiveProfileView,
    setProfileName,
  } = props;
  const params = useParams();
  const audienceId: string = params.audienceId!;

  const [searchTerm, setSearchTerm] = useState<string>("");

  const allDimensions = extractSelectableDimensions(categories);

  const updateList = (list: SelectableDimensionCategory[], searchInput: string, parent?: any): void => {
    if (list?.[0]) {
      list.forEach((category: SelectableDimensionCategory, i: number) => {
        if (!list[i].isVisibleInitially) {
          return;
        }
        category.isVisible = false;
        const searchLongEnough = searchInput.length >= 2;
        if (!searchLongEnough) {
          category.isVisible = true;
          category.isExpanded = false;
        }
        if (category.categories[0]) {
          updateList(category.categories, searchInput, category);
        }

        category.dimensions.forEach((option: SelectableDimension) => {
          option.isVisible =
            !option.dimension.name ||
            !searchInput ||
            option.dimension.name.toLowerCase().includes(searchInput.toLowerCase());

          if (option.isVisible) {
            category.isVisible = true;
            if (searchLongEnough) {
              category.isExpanded = true;
            }
          }
        });
        if (category.isVisible && parent) {
          parent.isVisible = true;
          if (searchLongEnough) {
            parent.isExpanded = true;
          }
        }
      });
    }
    setDimensionCategories(list);
  };

  const handleSearchDimensionsChanged = (e: any): void => {
    setSearchTerm(e.target.value || "");
    updateList(categories, e.target.value || "");
  };

  const handleProfileSelected = (profile: ProfileView): void => {
    handleViewSelected(profile);
  };

  const newProfile = (
    <Stack className="new-profile" direction="row" justifyContent="space-between" alignItems="center">
      <span className="profile-font">Profiles</span>
      <Button
        className="clear-all"
        onClick={() => {
          setIsNewProfileOpen(true);
          setActiveProfileView(new ProfileView([], "New Profile", "", audienceId, ""));
          setProfileName("New Profile");
        }}
      >
        New
      </Button>
    </Stack>
  );

  const dropdownList = (
    <WppSelect className="dropdown-list" placeholder={profileName} disabled={disabled}>
      <WppIconSearch slot="icon-start" aria-label="Search icon" />
      {profileViews.length > 0 &&
        profileViews.map((value, i) => (
          <WppListItem
            key={i}
            onClick={() => {
              handleProfileSelected(value);
            }}
          >
            <p slot="label">{value.name}</p>
          </WppListItem>
        ))}
    </WppSelect>
  );

  const searchbox = (
    <WppTextInput
      name="searchDimensions"
      placeholder="Search"
      onWppChange={handleSearchDimensionsChanged}
      value={searchTerm}
      className="search-dimensions-input"
    >
      <WppIconSearch slot="icon-start" aria-label="Search icon" />
    </WppTextInput>
  );

  const hasSomeDimension = allDimensions?.some((dimension: SelectableDimension) => dimension.isSelected);
  const clearAll = (
    <Stack className="clear-all-container" direction="row" justifyContent="space-between" alignItems="center">
      <span>Components</span>
      <Button
        className="clear-all"
        disabled={!hasSomeDimension}
        onClick={() => {
          setIsClearAllOpen(true);
        }}
      >
        Clear All
      </Button>
    </Stack>
  );

  const editor = (
    <Stack spacing={0}>
      {categories.map((c, i) => (
        <WidgetCategory
          key={`${c.name}${i}`}
          category={c}
          handleDimensionSelected={handleDimensionSelected}
          onAccordionClicked={(d: SelectableDimensionCategory): void => {
            d.isExpanded = !d.isExpanded;
            setDimensionCategories([...categories]);
          }}
        />
      ))}
    </Stack>
  );

  return (
    <Stack spacing={1} className="widgets" data-testid="widgets" direction="column">
      {!isSingleProfilePage && newProfile}
      {!isSingleProfilePage && dropdownList}
      {clearAll}
      {searchbox}
      <div className="scrollable-editor">{editor}</div>
    </Stack>
  );
}
