import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteButton } from "../../../../../components/delete-button/delete-button";
import { MultipleValueEditor } from "../../../../../components/editors/multiple";
import type { MultipleCondition } from "../../../../../components/query-builder/models/multiple-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";

interface IMultipleConditionEditor {
  condition: MultipleCondition;
}

export function MultipleConditionEditor({ condition }: IMultipleConditionEditor): JSX.Element {
  const context = useCreateAudienceContext();

  const handleStateSelected = (state: string[]) => {
    condition.setValue(state);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className="editor-state-condition">
      <DeleteButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        isHidden={!condition.getIsHighlighted()}
      />
      <MultipleValueEditor
        currentValue={condition.getValue()}
        options={condition.getAllowedValues()}
        valueName={condition.getDimensionName()}
        onOptionChosen={handleStateSelected}
        error={error}
        showPaperComponents={false}
      />
    </div>
  );
}
