import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { ProjectInput } from "@vmlyr/common/dist/types/connekd-api";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function createProject(projectData: ProjectInput, projectId?: string): Promise<{ projectId: string }> {
  const endpoint = `${ConfigurationHelper.getConnekdApiEndpoint()}/Projects/Create`;

  const payload = {
    projectId: projectId,
    title: projectData.title,

    groups: projectData.groups.map((x: any) => {
      return {
        id: x.id,
        name: x.name,
        definition: x.definition.getQuery(),
      };
    }),
    variables: projectData.variables.map((x: any) => {
      return {
        id: x.id,
        name: x.name,
        definition: x.variableDefinition.getQuery(),
      };
    }),
  };

  const response: Response = await postWithToken(endpoint, payload);

  if (response.ok) {
    const payload: { projectId: string; createdAt: string } = await response.json();
    return payload;
  }

  const errorText = await response.text();
  console.error("unable to create project", errorText);
  throw new Error(errorText);
}
