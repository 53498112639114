import { AudienceCondition } from "../../../../../components/query-builder/models/audience-condition";
import { CustomAudienceCondition } from "../../../../../components/query-builder/models/custom-audience-condition";
import { DiscreetCondition } from "../../../../../components/query-builder/models/discreet-condition";
import { EmptyCondition } from "../../../../../components/query-builder/models/empty-condition";
import { GroupCondition } from "../../../../../components/query-builder/models/group-condition";
import { MultiRangeCondition } from "../../../../../components/query-builder/models/multi-range-condition";
import { MultipleCondition } from "../../../../../components/query-builder/models/multiple-condition";
import { RangeCondition } from "../../../../../components/query-builder/models/range-condition";
import type { Operator } from "../../../../../models/query-builder/operator";
import type { QueryCondition } from "../../../../../models/query-builder/query-condition";

export function parseTree(node: QueryCondition): string {
  if (node instanceof EmptyCondition) {
    return "";
  }

  if (node instanceof RangeCondition) {
    return `(${node.dimension.name} [${node.getRangeLowerBound()} to ${node.getRangeUpperBound()}])`;
  }

  if (node instanceof DiscreetCondition) {
    const values = [node.getValue()].flat().join(" or ");
    return `(${node.dimension.name} [${values}])`;
  }

  if (node instanceof MultipleCondition) {
    const values = [node.getValue()].flat().join(" or ");
    return `(${node.dimension.name} [${values}])`;
  }

  if (node instanceof MultiRangeCondition) {
    const values = [node.getValue()].flat().join(" or ");
    return `(${node.dimension.name} [${values}])`;
  }

  if (node instanceof AudienceCondition) {
    return `audience: ${node.friendlyId}`;
  }

  if (node instanceof CustomAudienceCondition) {
    return "(some custom audience)";
  }

  if (node instanceof GroupCondition) {
    const leftOperand = node.getLeftOperand();
    const left: string = leftOperand === null ? "???" : parseTree(leftOperand);

    const rightOperand = node.getRightOperand();
    const right: string = rightOperand === null ? "???" : parseTree(rightOperand);

    const operator: Operator = node.getOperator();
    const parent: QueryCondition | null = node.getParent();

    const content = `${left} ${operator.replace("isnot", "is not")} ${right}`;
    const needsParentheses: boolean = parent instanceof GroupCondition && operator !== parent.getOperator();

    return needsParentheses ? `(${content})` : content;
  }

  console.error("Invalid node type for string definition:", node);
  return "";
}
