import "./search.scss";

import { Grid } from "@mui/material";
import { WppIconSearch, WppTextInput } from "@platform-ui-kit/components-library-react";
import type { ITaxonomicSegmentValue } from "../../../../components/taxonomy/models/taxonomy-segment";
import { SearchFilters } from "./components/search-filters";
import type { SearchModel } from "./components/search-filters/models/search-model";

interface ISearchProps {
  onSearch: (model: SearchModel) => void;
  searchModel: SearchModel;
}

export function Search({ onSearch, searchModel }: ISearchProps) {
  const searchValidationState = searchModel.getValidationState();

  // eslint-disable-next-line

  const handleSearchChanged = (e: any) => {
    const newModel = searchModel.updateText(e.target?.value || "");
    onSearch(newModel);
  };

  const handleFiltersChanged = (filters: Map<string, ITaxonomicSegmentValue[]>) => {
    const newModel = searchModel.updateFilters(filters);
    onSearch(newModel);
  };

  return (
    <span className="search">
      <Grid container>
        <Grid item>
          <WppTextInput
            name="searchText"
            messageType={searchValidationState.isValid ? undefined : "error"}
            message={searchValidationState.isValid ? undefined : searchValidationState.invalidReason}
            placeholder="Search"
            onWppChange={handleSearchChanged}
            value={searchModel.text}
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
          </WppTextInput>
        </Grid>
        <Grid item>
          <SearchFilters filters={searchModel.filters} onFiltersChanged={handleFiltersChanged} />
        </Grid>
      </Grid>
    </span>
  );
}
