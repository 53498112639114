import { AccordionDetails } from "@mui/material";
import { memo } from "react";
import { AudienceToolAccordion, AudienceToolAccordionSummary } from "../../audience-tool-accordion";
import type { IDemographicCategory } from "../index";
import type { SelectableCondition } from "../models/selectable-condition";
import { DraggableButton } from "./draggable-button";

interface Props {
  onDemographicDropped: (demographic: SelectableCondition) => void;
  onAccordionClicked: (demographic: IDemographicCategory) => void;
  dimensionCategory: IDemographicCategory;
}

const DemographicCategoryComponent = (props: Props): JSX.Element => {
  const { onDemographicDropped, onAccordionClicked, dimensionCategory } = props;

  const summary = (
    <AudienceToolAccordionSummary
      onClick={(e) => {
        e.stopPropagation();
        onAccordionClicked(dimensionCategory);
      }}
    >
      <h4>{dimensionCategory.name}</h4>
    </AudienceToolAccordionSummary>
  );

  const innerAccordion = dimensionCategory.categories.map(
    (c: IDemographicCategory, index: number) =>
      c.isVisible && (
        <DemographicCategory
          key={`${c.name}-${index}`}
          onDemographicDropped={onDemographicDropped}
          onAccordionClicked={onAccordionClicked}
          dimensionCategory={c}
        />
      ),
  );

  const items = dimensionCategory.options.length > 0 && (
    <ul>
      {dimensionCategory.options.map(
        (option: SelectableCondition, index: number) =>
          option.isVisible && (
            <li key={`${option.id}-${index}`}>
              <DraggableButton option={option} onDemographicDropped={onDemographicDropped} />
            </li>
          ),
      )}
    </ul>
  );

  return (
    <AudienceToolAccordion expanded={dimensionCategory.isExpanded} className={"demographic-accordion-editor"}>
      {summary}
      <AccordionDetails>
        {innerAccordion}
        {items}
      </AccordionDetails>
    </AudienceToolAccordion>
  );
};

export const DemographicCategory = memo(DemographicCategoryComponent);
