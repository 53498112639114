"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteWithToken = exports.postWithToken = exports.putWithToken = exports.getWithToken = void 0;
const audience_tool_group_1 = require("@vmlyr/common/dist/constants/audience-tool-group");
const useAudienceToolGroup_1 = require("../../hooks/useAudienceToolGroup");
const useIdentity_1 = require("../../hooks/useIdentity");
const maxRetries = 20;
function getAuthorizationHeaders() {
    const token = (0, useIdentity_1.getCurrentIdentity)()?.accessToken;
    if (!token) {
        return false;
    }
    const audienceToolGroup = (0, useAudienceToolGroup_1.useAudienceToolGroup)().getAudienceToolGroup();
    const headers = {
        authorization: token,
    };
    if (audienceToolGroup) {
        headers[audience_tool_group_1.audienceToolGroupHeader] = audienceToolGroup;
    }
    return headers;
}
async function getWithToken(fetchUrl, currentRetryCount = 0) {
    const token = (0, useIdentity_1.getCurrentIdentity)()?.accessToken;
    if (!token && currentRetryCount < maxRetries) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(getWithToken(fetchUrl, currentRetryCount + 1));
            }, 500);
        });
    }
    if (!token) {
        throw new Error("No bearer token could be retrieved. This sometimes indicates an issue with your browser, and refreshing the page may solve it.");
    }
    const response = await fetch(fetchUrl, { headers: getAuthorizationHeaders() });
    if (!response.ok) {
        throw new Error(`The server returned an error status code of ${response.status} with the following message: ${await response.text()}`);
    }
    return await response.json();
}
exports.getWithToken = getWithToken;
async function putWithToken(fetchUrl, payload) {
    const authHeaders = getAuthorizationHeaders();
    if (!authHeaders) {
        return new Response("No token", { status: 401, statusText: "No token" });
    }
    return await fetch(fetchUrl, {
        headers: authHeaders,
        method: "PUT",
        body: JSON.stringify(payload),
    });
}
exports.putWithToken = putWithToken;
async function postWithToken(fetchUrl, payload) {
    const authHeaders = getAuthorizationHeaders();
    if (!authHeaders) {
        return new Response("No token", { status: 401, statusText: "No token" });
    }
    return await fetch(fetchUrl, {
        headers: authHeaders,
        method: "POST",
        body: payload ? JSON.stringify(payload) : null,
    });
}
exports.postWithToken = postWithToken;
async function deleteWithToken(fetchUrl) {
    const authHeaders = getAuthorizationHeaders();
    if (!authHeaders) {
        return new Response("No token", { status: 401, statusText: "No token" });
    }
    return await fetch(fetchUrl, {
        headers: authHeaders,
        method: "DELETE",
    });
}
exports.deleteWithToken = deleteWithToken;
