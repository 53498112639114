import "./headline-cta.scss";

import { Box, Container, Grid, Modal } from "@mui/material";
import { WppButton } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ConfigurationHelper from "../../../../helpers/configuration-helper";
import { useUserStore } from "../../../../stores/user";

export enum HeadlinePosition {
  Left = "left",
  Middle = "middle",
  Right = "right",
}

interface IHeadlineCtaProps {
  headerImage: string;
  title: string;
  description: string;
  position: HeadlinePosition;
  path: string | null;
  displayModal: boolean;
}

export function HeadlineCta({ headerImage, title, description, path, position, displayModal }: IHeadlineCtaProps) {
  const handleCloseCreateAudienceModal = () => {
    setCreateAudienceModal(false);
  };

  const [openCreateAudienceModal, setCreateAudienceModal] = useState(false);
  const user = useUserStore.authenticatedUser();

  const roleNum = user?.role_num ?? 0;
  const canCreate = ConfigurationHelper.GetPermissions().audiences[0].create.includes(roleNum);

  const isDownload = (path: string) => path.indexOf("/downloads/") === 0;
  const doDownload = (path: string) => window.open(path, "_new");
  const navigate = useNavigate();

  return (
    <>
      <Grid item container className="headline-cta" xs={4}>
        <div
          className={`headline-cta-inner ${position.toString()}`}
          onClick={() => {
            if (path) {
              if (isDownload(path)) {
                doDownload(path);
              } else {
                if (displayModal && !canCreate) {
                  setCreateAudienceModal(true);
                }
                if (!displayModal || canCreate) {
                  navigate(path);
                }
              }
            }
          }}
        >
          <div className="image-container" style={{ backgroundImage: `url(${headerImage})` }} />
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </Grid>

      <Modal
        open={openCreateAudienceModal}
        onClose={handleCloseCreateAudienceModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="form-wrapper">
          <Box className="form-container">
            <Container>
              <Grid>
                <Grid item>
                  <Grid item xs={10}>
                    <div className="modal-header">Advanced user role needed to continue</div>
                  </Grid>
                </Grid>
                <div className="modal-content">
                  <div>To create audiences you'll need to be an Advanced User.</div>
                  <div>
                    <a href="mailto:connekd@wpp.com?subject=Connekd%20support%20request">Get support</a> for role
                    changes.
                  </div>
                </div>
                <Grid item>
                  <WppButton className="understood-btn" variant="primary" onClick={handleCloseCreateAudienceModal}>
                    Understood
                  </WppButton>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      </Modal>
    </>
  );
}

HeadlineCta.defaultProps = {
  path: null,
};
