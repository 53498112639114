import { deleteWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { Profiles } from "@vmlyr/connekdfordshared/models/api/audience-profiles";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function deleteProfile(profileId: Profiles["id"]): Promise<void> {
  const url: string = `${ConfigurationHelper.getConnekdApiEndpoint()}/audience-profiles/${profileId}`;

  const response: Response = await deleteWithToken(url);

  if (!response.ok) {
    console.error(profileId, response);
    throw new Error("failed to delete audience-profile");
  }
}
