import type { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import { Component } from "react";

interface Props {
  oktaAuth: OktaAuth;
  authState: AuthState;
  whenAuthenticated: JSX.Element | null;
  whenNotAuthenticated: JSX.Element | null;
}

export const RenderWhenAuthenticated = withOktaAuth(
  class RenderWhenAuthenticated extends Component<Props> {
    render(): JSX.Element | null {
      if (this.props.authState != null && this.props.authState.isAuthenticated) {
        return this.props.whenAuthenticated;
      }

      return this.props.whenNotAuthenticated;
    }
  },
);
