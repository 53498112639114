import { Tooltip } from "@mui/material";
import type { Profiles } from "@vmlyr/connekdfordshared/models/api/audience-profiles";
import { Link } from "react-router-dom";

const TOOLTIP_TEXT = "Click to open profile";

interface Props {
  profile: Profiles;
}

export default function TitleCell(props: Props): JSX.Element {
  const { profile } = props;
  return (
    <Tooltip className="project-list-title-tooltip" placement="top-start" title={TOOLTIP_TEXT}>
      <Link className="no-style" to={`/audiences/${profile.audience.id}?profile=${profile.id}`}>
        <div>{profile.profileName}</div>
      </Link>
    </Tooltip>
  );
}
