import "./logo.scss";

import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import { convertOktaGroupToConnekdGroup } from "@vmlyr/common/dist/types/connekd-group";
import type { OktaGroup } from "@vmlyr/common/types/okta-group";
import { ReactComponent as ConnekdVmlLogo } from "../../../assets/connekd_vml_logo.svg";
import { ReactComponent as For } from "../../../assets/for.svg";
import { ReactComponent as FordLincolnLogo } from "../../../assets/ford_lincoln.svg";
import { ReactComponent as VMLLogo } from "../../../assets/vml.svg";

export function FallbackLogo(): JSX.Element {
  return (
    <a href="/" className="connekd-logo">
      <ConnekdVmlLogo width={"125px"} height={"40px"} />
    </a>
  );
}

export function HeadingLogo(): JSX.Element {
  const { getAudienceToolGroup } = useAudienceToolGroup();
  const oktaGroup: string | null = getAudienceToolGroup();

  if (oktaGroup === null) {
    return <FallbackLogo />;
  }

  const connekdGroup = convertOktaGroupToConnekdGroup(oktaGroup as OktaGroup);

  switch (connekdGroup) {
    case "ford": {
      return (
        <>
          <FallbackLogo />
          <For style={{ paddingLeft: "17px", paddingRight: "17px" }} />
          <FordLincolnLogo width={"140px"} height={"40px"} />
        </>
      );
    }

    case "demo": {
      return (
        <>
          <FallbackLogo />
          <For style={{ paddingLeft: "17px", paddingRight: "17px" }} />
          <VMLLogo width={"70px"} height={"40px"} />
        </>
      );
    }

    default: {
      return <FallbackLogo />;
    }
  }
}
