import "./delete-button.scss";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

interface Props {
  onDelete: () => void;
  isHidden?: boolean;
}

export function DeleteButton({ onDelete, isHidden }: Props): JSX.Element {
  return (
    <IconButton
      id="delete-button"
      className={"delete-button" + (isHidden ? " hide" : "")}
      onClick={onDelete}
      size="small"
    >
      <Close />
    </IconButton>
  );
}
