import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteButton } from "../../../../../components/delete-button/delete-button";
import type { IRange } from "../../../../../components/editors/range";
import { RangeValueEditor } from "../../../../../components/editors/range";
import type { RangeCondition } from "../../../../../components/query-builder/models/range-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";

interface Props {
  condition: RangeCondition;
}

export function RangeConditionEditor({ condition }: Props): JSX.Element {
  const context = useCreateAudienceContext();

  const handleAgeRangeSelected = (range: IRange): void => {
    condition.setRangeLowerBound(range.lowerBound);
    condition.setRangeUpperBound(range.upperBound);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className="editor-age-condition">
      <DeleteButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        isHidden={!condition.getIsHighlighted()}
      />
      <RangeValueEditor
        valueName="Age"
        lowerRange={condition.getRangeLowerBound()}
        upperRange={condition.getRangeUpperBound()}
        onRangeChosen={handleAgeRangeSelected}
        maxAllowed={condition.getMaximumAllowed()}
        minAllowed={condition.getMinimumAllowed()}
        error={error}
      />
    </div>
  );
}
