import { Grid } from "@mui/material";

interface IControlHeaderProps {
  leftControl: JSX.Element;
  rightControl: JSX.Element;
}

export function ControlHeader({ leftControl, rightControl }: IControlHeaderProps) {
  return (
    <Grid container>
      <Grid item xs={7}>
        {leftControl}
      </Grid>
      <Grid item xs={5} sx={{ textAlign: "right" }}>
        {rightControl}
      </Grid>
    </Grid>
  );
}
