import type { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import AudienceNameCell from "../../../components/table-common/audience-name-cell";

interface Props {
  audience: Pick<IAudienceOverview, "id" | "name" | "taxonomy">;
}

export default function NameCell(props: Props): JSX.Element {
  const { audience } = props;
  return <AudienceNameCell audienceId={audience.id} taxonomy={audience.taxonomy} audienceName={audience.name} />;
}
