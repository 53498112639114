import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import { IDimension } from "../../components/demographic-selector/models/dimensions";
import ConfigurationHelper from "../../helpers/configuration-helper";
import type { Group } from "../../screens/explore/store/groups";
import type { VariableData } from "../../screens/explore/store/variables";
import type { RawGroup, RawVariable } from "./project-serialise";
import { deserializeGroup, deserializeVariable } from "./project-serialise";

export interface ProjectOverview {
  id: string;
  title: string;
  groups: Group[];
  variables: VariableData[];
  modifiedDatetime: string | null;
}

export async function getProjectOverview(dimensions: IDimension[], projectId: string): Promise<ProjectOverview> {
  const endpoint = addQueryParams(
    `${ConfigurationHelper.getConnekdApiEndpoint()}/Projects/Overview`,
    `friendlyId=${projectId}`,
  );

  interface HttpResponse {
    id: string;
    title: string;
    groups: RawGroup[];
    variables: RawVariable[];
    modifiedDatetime: string | null;
  }

  const response = await getWithToken<HttpResponse>(endpoint);

  return {
    id: response.id,
    title: response.title,
    groups: response.groups.map((x) => deserializeGroup(dimensions, x)),
    variables: response.variables.map((x) => deserializeVariable(dimensions, x)),
    modifiedDatetime: response.modifiedDatetime,
  };
}
