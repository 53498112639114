import "./range-value-editor.scss";

import { Grid, InputLabel, TextField } from "@mui/material";

export interface IRange {
  lowerBound: number;
  upperBound: number;
}

interface Props {
  valueName: string;
  onRangeChosen: (chosenRange: IRange) => void;
  lowerRange: number;
  upperRange: number;
  minAllowed: number;
  maxAllowed: number;
  error: JSX.Element | null;
}

export function RangeValueEditor(props: Props): JSX.Element {
  const { valueName, onRangeChosen, lowerRange, upperRange, minAllowed, maxAllowed, error } = props;

  const editorInputProps = {
    inputProps: { max: maxAllowed, min: minAllowed },
    step: 1,
  };

  const inputId = `${valueName}_input`;
  const inputLabelId = `${inputId}_label`;

  const handleUpperChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newRange = { lowerBound: +event.target.value, upperBound: upperRange };
    onRangeChosen(newRange);
  };

  const handleLowerChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newRange = { lowerBound: lowerRange, upperBound: +event.target.value };
    onRangeChosen(newRange);
  };

  const handleInputConversion = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = Math.round(+event.target.value).toString();
  };

  return (
    <div className="editor-range-control">
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <InputLabel id={inputLabelId} className="editor-range-label">
            {valueName}
          </InputLabel>
        </Grid>

        <Grid item>
          <InputLabel>is between</InputLabel>
        </Grid>

        <Grid item>
          <TextField
            type="number"
            value={lowerRange}
            InputProps={editorInputProps}
            label="min"
            size="small"
            onChange={handleUpperChange}
            onInput={handleInputConversion}
          />
        </Grid>

        <Grid item>
          <InputLabel>and</InputLabel>
        </Grid>

        <Grid item>
          <TextField
            type="number"
            value={upperRange}
            InputProps={editorInputProps}
            label="max"
            size="small"
            onChange={handleLowerChange}
            onInput={handleInputConversion}
          />
        </Grid>

        {error !== null && (
          <Grid item xs={12}>
            {error}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
