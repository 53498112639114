import "./nav.scss";

import { FallbackLogo } from "./logo";

export function PublicNavigationBar(): JSX.Element {
  return (
    <nav>
      <div className="nav-heading">
        <FallbackLogo />
      </div>
    </nav>
  );
}
