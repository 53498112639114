import "./results-card.scss";

import Box from "@mui/material/Box";
import type { GridCellParams, GridColDef, GridRowModesModel, GridSortModel } from "@mui/x-data-grid-pro";
import { DataGridPro as DataGrid, GridColumnGroupingModel } from "@mui/x-data-grid-pro";
import { toNumOnlyString } from "@vmlyr/common/dist/helpers/sort-values";
import Humanize from "humanize-plus";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useMemo, useState } from "react";
import { sortNumericalColumns } from "../../../helpers/data-grid-helper";
import {
  Results,
  useProjectHorizontalHighlight,
  useProjectIndexHighlight,
  useProjectResults,
  useProjectShowFigureHighlights,
  useProjectVerticalHighlight,
} from "../../../store/projects";

function convertGroupIdToName(groupId: number): string {
  return `Group ${groupId + 1}`;
}

interface IResultsCardProps {
  selectedColumns: string[];
  columns: GridColDef[];
  setColumns: Dispatch<SetStateAction<Array<GridColDef<GridRowModesModel, string, string>>>>;
}

export function ResultsCard({ selectedColumns, columns, setColumns }: IResultsCardProps): JSX.Element {
  const projectResults = useProjectResults();
  const [rows, setRows] = useState<string[]>([]);
  const [columnGroupingModel, setColumnGroupingModel] = useState<GridColumnGroupingModel>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const showFigureHighlight = useProjectShowFigureHighlights();
  const verticalHlThreshold = useProjectVerticalHighlight();
  const horizontalHlThreshold = useProjectHorizontalHighlight();
  const indexHlThreshold = useProjectIndexHighlight();
  const sortComponents = (a: string, b: string): number => {
    const flattedValues = projectResults.flatMap((item) => item.audiencePortion);
    const firstFirstValue = flattedValues.find((item: Results) => item.dimensionName === a);
    const secondValue = flattedValues.find((item: Results) => item.dimensionName === b);
    if (firstFirstValue?.dimensionCategory != null && secondValue?.dimensionCategory != null) {
      if (firstFirstValue.dimensionCategory === secondValue.dimensionCategory) {
        return firstFirstValue.dimensionName.toLowerCase().localeCompare(secondValue.dimensionName.toLowerCase());
      }
      return firstFirstValue.dimensionCategory.toLowerCase().localeCompare(secondValue.dimensionCategory.toLowerCase());
    }
    return 0;
  };

  useEffect(() => {
    if (projectResults) {
      const initialRows: any[] = [];
      const initialColumns: GridColDef[] = [
        { field: "variable", headerName: "Variable", width: 200, pinnable: true, sortComparator: sortNumericalColumns },
        {
          field: "components",
          headerName: "Components",
          width: 200,
          sortComparator: sortComponents,
        },
      ];
      const initialColumnGroupingModel: GridColumnGroupingModel = [];

      const addedColumns = new Set();
      const addedGroups = new Set();

      for (const column of projectResults) {
        for (const row of column.audiencePortion) {
          const existingRow = initialRows.find(
            (rowData) => rowData.variable === row.variable && rowData.components === row.dimensionName,
          );

          const size = Humanize.formatNumber(Math.round(row.size));
          const horizontalMetric = `${row.horizontal === null ? -1 : row.horizontal.toFixed(2)} %`;
          const verticalMetric = `${row.vertical === null ? -1 : row.vertical.toFixed(2)} %`;
          const indexMetric = `${row.index === null ? -1 : Math.round(row.index)}`;

          if (existingRow) {
            existingRow.components = row.dimensionName;
            existingRow[`${column.id}-vertical`] = verticalMetric;
            existingRow[`${column.id}-horizontal`] = horizontalMetric;
            existingRow[`${column.id}-size`] = size;
            existingRow[`${column.id}-index`] = indexMetric;
          } else {
            const newRow: Record<string, any> = {
              id: `${row.dimensionName}-${row.variable}-${column.id}`,
              variable: row.variable,
              components: row.dimensionName,
              [`${column.id}-vertical`]: verticalMetric,
              [`${column.id}-horizontal`]: horizontalMetric,
              [`${column.id}-size`]: size,
              [`${column.id}-index`]: indexMetric,
            };
            initialRows.push(newRow);
          }

          if (!addedColumns.has(`${column.id}-vertical`)) {
            initialColumns.push(
              {
                field: `${column.id}-vertical`,
                headerName: "% Vertical",
                width: 200,
                sortComparator: sortNumericalColumns,
              },
              {
                field: `${column.id}-horizontal`,
                headerName: "% Horizontal",
                width: 200,
                sortComparator: sortNumericalColumns,
              },
              {
                field: `${column.id}-size`,
                headerName: "Size (Volume)",
                width: 200,
                sortComparator: sortNumericalColumns,
              },
              { field: `${column.id}-index`, headerName: "Index", width: 200, sortComparator: sortNumericalColumns },
            );
            addedColumns.add(`${column.id}-vertical`);
            addedColumns.add(`${column.id}-horizontal`);
            addedColumns.add(`${column.id}-size`);
            addedColumns.add(`${column.id}-index`);
          }

          if (!addedGroups.has(column.id)) {
            initialColumnGroupingModel.push({
              groupId: `${column.id}`,
              headerName: column.name || convertGroupIdToName(column.id),
              children: [
                { field: `${column.id}-vertical` },
                { field: `${column.id}-horizontal` },
                { field: `${column.id}-size` },
                { field: `${column.id}-index` },
              ],
            });
            addedGroups.add(column.id);
          }
        }
      }

      setRows(initialRows);
      setColumns(initialColumns);
      setColumnGroupingModel(initialColumnGroupingModel);
    }
  }, [projectResults, setColumns]);

  const displayedColumns = useMemo(
    () =>
      columns.filter(
        (col) => col.field === "variable" || col.field === "components" || selectedColumns.includes(col.field),
      ),
    [columns, selectedColumns],
  );

  const data = { ...columns, ...rows };

  const renderGridCellsWithHighlighting = (params: GridCellParams<any, any, number>) => {
    if (showFigureHighlight) {
      const colName = params.field.split("-")[1];
      if (colName && params.value) {
        // NB: '+' coerces string to number
        const value = +toNumOnlyString(params.value);
        let hl = false;
        hl = hl || (colName.match("vertical") && value > verticalHlThreshold) || false;
        hl = hl || (colName.match("horizontal") && value > horizontalHlThreshold) || false;
        hl = hl || (colName.match("index") && value > indexHlThreshold) || false;
        return hl ? "highlightCell" : "";
      }
    }
    return "";
  };

  const onSortModelChange = (newSortModel: GridSortModel): void => {
    if (newSortModel.length === 0) {
      return;
    }
    if (newSortModel[0]?.field === "components") {
      setSortModel(newSortModel);
      return;
    }
    if (sortModel.some((e) => e.field === "components")) {
      setSortModel((prev) => {
        const components = prev?.filter((c) => c.field === "components");
        if (components.length > 0) {
          return [...components, ...newSortModel];
        }
        return [...newSortModel];
      });
      return;
    }
    setSortModel([{ field: "components", sort: null }, ...newSortModel]);
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", minHeight: "250px", height: "100%" }}>
      <DataGrid
        {...data}
        experimentalFeatures={{ columnGrouping: true }}
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {
          onSortModelChange(newSortModel);
        }}
        pinnedColumns={{ left: ["components", "variable"] }}
        columns={displayedColumns}
        rows={rows}
        disableSelectionOnClick
        autoHeight
        columnGroupingModel={columnGroupingModel}
        getCellClassName={renderGridCellsWithHighlighting}
      />
    </Box>
  );
}
