import "./demographic-selector.scss";

import { Box, Stack } from "@mui/material";
import { WppIconSearch, WppInlineMessage, WppTextInput } from "@platform-ui-kit/components-library-react";
import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import type { AudienceOverview } from "@vmlyr/connekdfordshared/dist/models/api/audience-overview";
import type { ISearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/search-results-model";
import { SearchResultsModel } from "@vmlyr/connekdfordshared/dist/models/api/search-results-model";
import { useCallback, useEffect, useState } from "react";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { CenteredProgress } from "../centered-progress";
import { AudienceCondition } from "../query-builder/models/audience-condition";
import { CustomAudienceCondition } from "../query-builder/models/custom-audience-condition";
import { DiscreetCondition } from "../query-builder/models/discreet-condition";
import { MultiRangeCondition } from "../query-builder/models/multi-range-condition";
import { MultipleCondition } from "../query-builder/models/multiple-condition";
import { RangeCondition } from "../query-builder/models/range-condition";
import { DemographicCategory } from "./components/demographic-category";
import type { IDimension, IDimensionCategory } from "./models/dimensions";
import { SelectableCondition } from "./models/selectable-condition";

export interface IDemographicCategory {
  name: string;
  isExpanded: boolean;
  isVisible: boolean;
  isVisibleInitially: boolean;
  options: SelectableCondition[];
  categories: IDemographicCategory[];
}

interface IDemographicsSelectorProps {
  onDemographicDropped: (demographic: SelectableCondition) => void;
  currentAudienceFriendlyId: string | null;
  dimensionCategories: IDimensionCategory[];
}

function displayCustomAudiences(): boolean {
  const { getAudienceToolGroup } = useAudienceToolGroup();
  const hideCustomAudiences: boolean =
    ConfigurationHelper.GetAudienceToolGroups(getAudienceToolGroup()).showCustomAudiences;
  return hideCustomAudiences ?? false;
}

const getConditionForDimension = (dimension: IDimension) => {
  switch (dimension.metadata.type) {
    case "discreet": {
      return new DiscreetCondition(dimension);
    }

    case "range": {
      return new RangeCondition(dimension);
    }

    case "multiple": {
      return new MultipleCondition(dimension);
    }

    case "multirange": {
      return new MultiRangeCondition(dimension);
    }

    default: {
      throw new Error(`Dimension type ${dimension.metadata.type} has no registered condition class`);
    }
  }
};

export function DemographicSelector(props: IDemographicsSelectorProps): JSX.Element {
  const { onDemographicDropped, currentAudienceFriendlyId = null, dimensionCategories } = props;

  const [dimensionCategoryList, setDimensionCategoryList] = useState<IDemographicCategory[] | undefined>();
  const [audienceError, setAudienceError] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const hasError = audienceError !== undefined;

  const { getAudienceToolGroup } = useAudienceToolGroup();
  const oktaGroup = getAudienceToolGroup();
  const { dimensionsToIgnore } = ConfigurationHelper.GetAudienceToolGroups(oktaGroup);

  const mapDimensionCategory = (c: IDimensionCategory): IDemographicCategory => ({
    name: c.categoryName,
    isExpanded: false,
    isVisible: true,
    isVisibleInitially: true,
    options: c.dimensions.map(
      (d) => new SelectableCondition(d.name, d.id, true, true, () => getConditionForDimension(d)),
    ),
    categories: c.categories.map(mapDimensionCategory),
  });

  useEffect(() => {
    let demographics = new Array<IDemographicCategory>();
    for (const [catKey] of dimensionCategories.entries()) {
      dimensionCategories[catKey].dimensions = dimensionCategories[catKey].dimensions.filter(
        (d) => !dimensionsToIgnore.includes(d.name),
      );
    }

    const dimensions = dimensionCategories.map(mapDimensionCategory);
    demographics = demographics.concat(dimensions);
    getWithToken<ISearchResultsModel>(ConfigurationHelper.GetAudiencesEndpoint())
      .then((data) => {
        const searchResultsModel = SearchResultsModel.fromExisting(data);
        const audiences = searchResultsModel.results.filter(
          (audience: AudienceOverview) => audience.id !== currentAudienceFriendlyId,
        );
        const audienceCategory = {
          name: "Saved Audiences",
          isExpanded: false,
          isVisible: true,
          isVisibleInitially: true,
          options: audiences.map(
            (audience: AudienceOverview) =>
              new SelectableCondition(audience.name, "", true, true, () => new AudienceCondition(audience.id)),
          ),
          categories: [],
        };
        demographics.push(audienceCategory);

        const customAudienceCategory = {
          name: "Custom Audiences",
          isExpanded: false,
          isVisible: true,
          isVisibleInitially: true,
          options: [
            new SelectableCondition(
              "Data Sources & Segment IDs",
              "",
              true,
              true,
              () => new CustomAudienceCondition(""),
            ),
          ],
          categories: [],
        };
        displayCustomAudiences() ? demographics.push(customAudienceCategory) : null;

        setDimensionCategoryList(demographics);
      })
      .catch((error) => {
        setAudienceError(`An error occurred whilst retrieving the audience list: ${error}`);
      });
  }, []);

  const updateList = useCallback((list: any, searchInput: string, parent?: any) => {
    if (list?.[0]) {
      list?.forEach((category: any, i: number) => {
        // Return early if set to not be visible initially (eg Audiences)
        if (!list[i].isVisibleInitially) {
          return;
        }
        category.isVisible = false;

        const searchLongEnough = searchInput.length >= 2;
        if (!searchLongEnough) {
          category.isVisible = true;
          category.isExpanded = false;
        }
        if (category.categories[0]) {
          updateList(category.categories, searchInput, category);
        }

        category.options.forEach((option: any) => {
          option.isVisible =
            !option.name || !searchInput || option.name.toLowerCase().includes(searchInput.toLowerCase());

          if (option.isVisible) {
            category.isVisible = true;
            if (searchLongEnough) {
              category.isExpanded = true;
            }
          }
        });

        if (category.isVisible && parent) {
          parent.isVisible = true;
          if (searchLongEnough) {
            parent.isExpanded = true;
          }
        }
      });
    }

    setDimensionCategoryList(list);
  }, []);

  const divider = <span className="divider" />;

  const demographicComponent = dimensionCategoryList?.map((demographic: IDemographicCategory) => (
    <div key={demographic.name}>
      {demographic.name === "Saved Audiences" && divider}
      {demographic.isVisible && (
        <DemographicCategory
          onDemographicDropped={onDemographicDropped}
          onAccordionClicked={(d: IDemographicCategory) => {
            d.isExpanded = !d.isExpanded;
            setDimensionCategoryList([...dimensionCategoryList]);
          }}
          dimensionCategory={demographic}
        />
      )}
    </div>
  ));

  const handleSearchDimensionsChanged = useCallback(
    (e: any) => {
      setSearchTerm(e.target.value || "");
      updateList(dimensionCategoryList, e.target.value || false);
    },
    [dimensionCategoryList, updateList],
  );

  const searchbox = (
    <WppTextInput
      name="searchDimensions"
      placeholder="Search"
      onWppChange={handleSearchDimensionsChanged}
      value={searchTerm}
      className="search-dimensions-input"
    >
      <WppIconSearch slot="icon-start" aria-label="Search icon" />
    </WppTextInput>
  );

  const errorMessage: JSX.Element | undefined = hasError ? (
    <WppInlineMessage size="s" message={`There was an error retrieving demographics: ${audienceError}`} />
  ) : undefined;

  const spinner = <CenteredProgress />;

  return (
    <Stack spacing={2} className="demographic-selector" alignItems="flex-start">
      <Box className="search-dimensions-container">{searchbox}</Box>
      {errorMessage}
      {!hasError && dimensionCategoryList !== undefined ? (
        <Stack className="demographic-selector-inner" spacing={0}>
          {demographicComponent}
        </Stack>
      ) : (
        <Stack className="demographic-selector-spinner" spacing={0}>
          {spinner}
        </Stack>
      )}
    </Stack>
  );
}
