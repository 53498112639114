import { Route, Routes } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { TestStrips } from "../components/test-strips";
import { PublicNavigationBar } from "../components/header/nav/public-nav";
import RequestAccessForm from "../screens/landing/requestAccessForm";
import ConfirmationCard from "../screens/landing/confirmationCard";
import DeactivationCard from "../screens/landing/deactivatedCard";

export const PublicRoutes = (): JSX.Element => {
  return (
    <>
      <TestStrips />
      <Routes>
        <Route path="login/callback" element={<LoginCallback />} />
        <Route
          path="/request-access"
          element={
            <>
              <PublicNavigationBar />
              <RequestAccessForm setToggleConfirmation />
            </>
          }
        />
        <Route
          path="/access-pending"
          element={
            <>
              <PublicNavigationBar />
              <ConfirmationCard />
            </>
          }
        />
        <Route
          path="/access-denied"
          element={
            <>
              <PublicNavigationBar />
              <DeactivationCard />
            </>
          }
        />
      </Routes>
    </>
  );
};
