import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createProject } from "../../../connekd-api/projects/create";
import { validatorHelper } from "../helpers/validatorHelper";
import { readGroups } from "./groups";
import { readVariables } from "./variables";

export interface Results {
  dimensionCategory: string;
  dimensionName: string;
  variable: string;
  vertical: number;
  horizontal: number;
  index: number;
  size: number;
}

export interface ResultsData {
  id: number;
  name: string;
  audiencePortion: Results[];
  audienceSize: number;
}

interface State {
  hasUnsavedChanges: boolean;
  resultsLoading: boolean;
  validationError: string | null;
  title: string;
  id: string | undefined;
  results: ResultsData[];
  showSaveHighlightModal: boolean;
  showFigureHighlights: boolean;
  verticalEqualOrGreaterHighlight: number;
  horizontalEqualOrGreaterHighlight: number;
  indexEqualOrGreaterHighlight: number;
  conditionType: string;
  run: boolean;
}

interface Actions {
  overrideProjectId: (id: ProjectState["id"]) => void;
  overrideProjectTitle: (title: string) => void;

  updateTitle: (title: string, originalProjectTitle: string) => void;

  setResults: (results: ResultsData[]) => void;
  setResultsLoading: (status: boolean) => void;

  setDraggedCondition: (conditionType: string) => void;

  resetProject: () => void;
  saveProject: (projectId?: string) => Promise<any>;

  openShowSaveHighlightModal: () => void;
  closeShowSaveHighlightModal: () => void;
  setShowFigureHighlights: (status: boolean) => void;
  setHighlightsValues: (
    verticalEqualOrGreaterHighlight: State["verticalEqualOrGreaterHighlight"],
    horizontalEqualOrGreaterHighlight: State["horizontalEqualOrGreaterHighlight"],
    indexEqualOrGreaterHighlight: State["indexEqualOrGreaterHighlight"],
  ) => void;
  hasRun: (value: boolean) => void;
}

export type ProjectState = State & { actions: Actions };

const useProjectStore = create(
  devtools<ProjectState>(
    (set, get) => ({
      id: undefined,
      title: "",
      results: [],
      resultsLoading: false,
      hasUnsavedChanges: false,
      validationError: null,

      showSaveHighlightModal: false,
      showFigureHighlights: false,
      verticalEqualOrGreaterHighlight: 10,
      horizontalEqualOrGreaterHighlight: 10,
      indexEqualOrGreaterHighlight: 110,
      conditionType: "",
      run: false,
      actions: {
        hasRun: (value: boolean): void => {
          set(() => ({
            run: value,
          }));
        },
        resetProject: () => {
          set(() => ({
            id: undefined,
            title: "",
            hasUnsavedChanges: false,
            validationError: validatorHelper(""),
            results: [],
          }));
        },

        updateTitle: (updatedTitle: State["title"], originalProjectTitle: string) => {
          const validationError = validatorHelper(updatedTitle);

          set(() => ({
            title: updatedTitle,
            validationError,
            hasUnsavedChanges: originalProjectTitle !== updatedTitle,
          }));
        },

        setShowFigureHighlights: (status: boolean) => {
          set(() => ({
            showFigureHighlights: status,
          }));
        },

        setHighlightsValues: (
          verticalEqualOrGreaterHighlight: State["verticalEqualOrGreaterHighlight"],
          horizontalEqualOrGreaterHighlight: State["horizontalEqualOrGreaterHighlight"],
          indexEqualOrGreaterHighlight: State["indexEqualOrGreaterHighlight"],
        ) => {
          set(() => ({
            verticalEqualOrGreaterHighlight,
            horizontalEqualOrGreaterHighlight,
            indexEqualOrGreaterHighlight,
          }));
        },

        overrideProjectTitle: (title: State["title"]) => {
          const validationError = validatorHelper(title);
          set(() => ({ title, validationError }));
        },

        overrideProjectId: (projectId: State["id"]) => {
          set(() => ({ id: projectId }));
        },

        setResults: (results: State["results"]) => {
          set(() => ({ results, resultsLoading: false }));
        },

        setResultsLoading: (status: boolean) => {
          set(() => ({ resultsLoading: status }));
        },

        setDraggedCondition: (conditionType: string) => {
          set(() => ({ conditionType }));
        },

        saveProject: async (projectId?: string): Promise<string | { projectId: string }> => {
          const state: State = get();

          if (state.validationError !== null) {
            alert(state.validationError);
            return state.validationError;
          }

          const groups = readGroups();
          const variables = readVariables();

          const projectData = { title: state.title, groups, variables };

          try {
            const saveResponse = await createProject(projectData, projectId);
            set(() => ({ hasUnsavedChanges: false }));
            return saveResponse;
          } catch (err) {
            console.error(`Unable to save project: ${err}`);
            return (err as Error).message;
          }
        },
        openShowSaveHighlightModal: () => {
          set(() => ({ showSaveHighlightModal: true }));
        },
        closeShowSaveHighlightModal: () => {
          set(() => ({ showSaveHighlightModal: false }));
        },
      },
    }),
    { name: "projectStore" },
  ),
);

export const useProjectActions = (): Actions => useProjectStore((state) => state.actions);

export const useProjectHasUnsavedChanges = (): ProjectState["hasUnsavedChanges"] =>
  useProjectStore((state) => state.hasUnsavedChanges);

export const useProjectValidation = (): ProjectState["validationError"] =>
  useProjectStore((state) => state.validationError);

export const useShowSaveHighlightModal = (): ProjectState["showSaveHighlightModal"] =>
  useProjectStore((state) => state.showSaveHighlightModal);

export const useProjectTitle = (): ProjectState["title"] => useProjectStore((state) => state.title);

export const useProjectId = (): ProjectState["id"] => useProjectStore((state) => state.id);

export const useProjectShowFigureHighlights = (): ProjectState["showFigureHighlights"] =>
  useProjectStore((state) => state.showFigureHighlights);

export const useProjectVerticalHighlight = (): ProjectState["verticalEqualOrGreaterHighlight"] =>
  useProjectStore((state) => state.verticalEqualOrGreaterHighlight);

export const useProjectHorizontalHighlight = (): ProjectState["horizontalEqualOrGreaterHighlight"] =>
  useProjectStore((state) => state.horizontalEqualOrGreaterHighlight);

export const useProjectIndexHighlight = (): ProjectState["indexEqualOrGreaterHighlight"] =>
  useProjectStore((state) => state.indexEqualOrGreaterHighlight);

export const useProjectResults = (): ProjectState["results"] => useProjectStore((state) => state.results);

export const useProjectConditionType = (): ProjectState["conditionType"] =>
  useProjectStore((state) => state.conditionType);

export const useProjectResultsLoading = (): ProjectState["resultsLoading"] =>
  useProjectStore((state) => state.resultsLoading);

export const useProjectRunActions = (): Actions => useProjectStore((store) => store.actions);

export const useHasRunState = () => useProjectStore((store) => store.run);
