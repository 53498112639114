import type { ConditionValidationState } from "../validation-state";
import type { AudienceDefinitionData } from "./audience-definition-data";
import type { DragType } from "./drag-type";

export abstract class QueryCondition {
  public abstract readonly NAME: string;

  protected isHighlighted = false;

  protected isDragTarget = false;

  protected isBeingDragged = false;

  protected dragType: DragType | null = null;

  constructor(protected parent: QueryCondition | null = null) {}

  getParent(): QueryCondition | null {
    return this.parent;
  }

  setParent(newParent: QueryCondition | null): void {
    this.parent = newParent;
  }

  setHighlight(highlightedCondition: QueryCondition): void {
    this.isHighlighted = highlightedCondition === this;
  }

  removeHighlight(highlightedCondition: QueryCondition): void {
    if (highlightedCondition === this) {
      if (!this.getIsHighlighted()) {
        throw new Error("An attempt to remove a highlight was made from a condition that was not highlighted");
      }
      this.isHighlighted = false;
    }
  }

  getIsHighlighted(): boolean {
    return this.isHighlighted;
  }

  setIsDragTarget(dragTargetCondition: QueryCondition | null, type: DragType | null): void {
    if (dragTargetCondition === this) {
      this.isDragTarget = true;
      this.dragType = type;
    } else {
      this.isDragTarget = false;
      this.dragType = null;
    }
  }

  getIsDragTarget(): boolean {
    return this.isDragTarget;
  }

  getDragType(): DragType {
    if (this.dragType === null) {
      throw new Error("Attempt to get drag type for element that does not have one");
    }
    return this.dragType;
  }

  setDraggedCondition(beingDragged: QueryCondition | null): void {
    this.isBeingDragged = beingDragged === this;
  }

  getIsBeingDragged(): boolean {
    return this.isBeingDragged;
  }

  getConditionBeingDragged(): QueryCondition | null {
    return this.isBeingDragged ? this : null;
  }

  getDraggedOverCondition(): QueryCondition | null {
    return this.isDragTarget ? this : null;
  }

  abstract isParentOf(potentialChild: QueryCondition): boolean;

  abstract getValidationState(): ConditionValidationState;

  abstract getConditionData(): AudienceDefinitionData;
}
