import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function recordUserLogin(userId: string): Promise<void> {
  const endpoint = ConfigurationHelper.GetUpdateUserEndpoint();
  const payload = {
    action: "updateLastLoggedIn",
    userId,
  };

  try {
    await postWithToken(endpoint, payload);
  } catch (err) {
    console.error("unable to record user login event", err);
  }
}
