import { memo } from "react";
import { AudienceSizeRender, GetSizeState } from "./render";

interface Props {
  onRefreshClick: () => void;
  audienceSize: number | null;
  isStale: boolean;
  isLoading: boolean;
  isEmptyEditor: boolean;
  hasValidBuilder: boolean;
  position: "top" | "bottom";
}

// NOTE: exported for testing only
export function calculateState(opts: {
  audienceSize: number | null;
  isStale: boolean;
  hasValidBuilder: boolean;
  isLoading: boolean;
}): GetSizeState {
  const { audienceSize, isStale, hasValidBuilder, isLoading } = opts;
  const hasValidSize = !(audienceSize === null || audienceSize === -1);

  if (isLoading) {
    return hasValidSize ? GetSizeState.LOADING_WITH_DATA_DISPLAYED : GetSizeState.LOADING_WITHOUT_DATA_DISPLAYED;
  }

  if (hasValidSize) {
    if (!hasValidBuilder) {
      return GetSizeState.DISPLAYING_DATA_NO_RELOAD;
    }

    return isStale ? GetSizeState.DISPLAYING_DATA_REQUEST_RELOAD : GetSizeState.DISPLAYING_DATA_ALLOW_RELOAD;
  }

  if (hasValidBuilder) {
    return GetSizeState.BEFORE_FIRST_LOAD_WITH_VALID_AUDIENCE;
  }

  if (isStale) {
    return GetSizeState.NOT_SHOWN;
  }

  return GetSizeState.ERROR;
}

function AudienceSizeComponent(props: Props): JSX.Element | null {
  const { audienceSize, isStale, onRefreshClick, isLoading, isEmptyEditor, hasValidBuilder, position } = props;

  const shouldHide = isEmptyEditor || (audienceSize === null && !hasValidBuilder);
  if (shouldHide) {
    return null;
  }

  const state = calculateState({ audienceSize, isStale, hasValidBuilder, isLoading });

  return (
    <AudienceSizeRender
      onRefreshClick={onRefreshClick}
      position={position}
      audienceSize={audienceSize ?? -1}
      state={state}
    />
  );
}

const propsAreEqual = (prevProps: Props, nextProps: Props): boolean => {
  return (
    prevProps.audienceSize === nextProps.audienceSize &&
    prevProps.isStale === nextProps.isStale &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isEmptyEditor === nextProps.isEmptyEditor &&
    prevProps.hasValidBuilder === nextProps.hasValidBuilder &&
    prevProps.position === nextProps.position
  );
};

export const AudienceSize = memo(AudienceSizeComponent, propsAreEqual);
