import "./styles.scss";

import { WppIconCopy } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import { Tooltip } from "@mui/material";

interface Props {
  hidden?: boolean;
  stringDefinitionText: string;
}

export function AudienceStringDefinition(props: Props): JSX.Element {
  const { hidden = false, stringDefinitionText } = props;

  const [userHasCopied, setUserHasCopied] = useState<boolean>(false);
  const [showTooltipCopy, setShowTooltipCopy] = useState(false);

  const tooltipText = userHasCopied ? "Copied!" : "Copy";
  return (
    <div className={`string-definition${hidden ? " string-definition-hide" : " string-definition-show"}`}>
      <div className="string-definition-text">{stringDefinitionText}</div>
      <Tooltip
        arrow
        title={tooltipText}
        classes={{ tooltip: "tooltip" }}
        open={showTooltipCopy}
        onMouseEnter={() => setShowTooltipCopy(true)}
        onMouseLeave={() => setShowTooltipCopy(false)}
        placement="right"
      >
        <WppIconCopy
          className={`copy-icon ${userHasCopied && "copy-icon-green"}`}
          onClick={() => {
            navigator.clipboard.writeText(stringDefinitionText);
            setUserHasCopied(true);
          }}
        />
      </Tooltip>
    </div>
  );
}
