import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTSKEY,
    enableAutoRouteTracking: true,
    disableFetchTracking: true,
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();

export { reactPlugin };

export const { appInsights } = ai;

export const setAuthenticatedUser = (signInId: string | undefined): void => {
  const validatedId = signInId!.replaceAll(/[ ,;=|]+/g, "_");
  ai.setAuthenticatedUserContext(validatedId, undefined, true);
};
