import "./nav.scss";

import { useAudienceToolGroup } from "@vmlyr/appserviceshared/dist/hooks/useAudienceToolGroup";
import { Link, useLocation } from "react-router-dom";
import ConfigurationHelper from "../../../helpers/configuration-helper";
import { HeadingLogo } from "./logo";
import { useUserStore } from "../../../stores/user";
import { GroupConfig } from "@vmlyr/connekdfordshared/config/groups";

interface INavigationLinkProps {
  path: string | null;
  linkText: string;
  currentPath: string;
  search: string;
}

interface IExternalNavigationLink {
  linkText: string;
  externalLink: string;
}

function NavigationLink({ path, linkText, currentPath, search }: INavigationLinkProps): JSX.Element {
  if (path === null) {
    return (
      <button disabled className="secondary-text nav-button">
        {linkText}
      </button>
    );
  }

  let isCurrentPage = currentPath.startsWith(path);
  if (search.includes("profile")) isCurrentPage = false;
  if (path === "/profiles" && search.includes("profile"))
    isCurrentPage = path === "/profiles" && search.includes("profile");

  const buttonStyleClass = isCurrentPage ? "current-page" : "secondary-text";

  return (
    <Link to={path} className={`${buttonStyleClass} nav-button`}>
      {linkText}
    </Link>
  );
}

function ExternalNavigationLink({ linkText, externalLink }: IExternalNavigationLink): JSX.Element {
  return (
    <a className="secondary-text nav-button" target="_blank" rel="noopener noreferrer" href={externalLink}>
      {linkText}
    </a>
  );
}

interface Props {
  AuthResponse: any;
}

export function PrivateNavigationBar({ AuthResponse }: Props): JSX.Element {
  const user = useUserStore.authenticatedUser();
  const isAdmin = useUserStore.isAdmin();
  const exploreAccess = useUserStore.canSeeExplore();

  const { pathname, search } = useLocation();

  function getConfig(): GroupConfig {
    const { getAudienceToolGroup } = useAudienceToolGroup();
    const oktaGroup = getAudienceToolGroup();
    const config = ConfigurationHelper.GetAudienceToolGroups(oktaGroup);
    return config;
  }

  function displayKnowledgeHubLink(): boolean {
    const config = getConfig();
    const khLink: boolean = config.showKnowledgeHubLink;
    return khLink ?? false;
  }

  function getKnowledgeHubLink(): string {
    const config = getConfig();
    const isDemo: boolean = config.isDemoConfig;
    return isDemo ? ConfigurationHelper.GetKnowledgeHubDemoLink() : ConfigurationHelper.GetKnowledgeHubLink();
  }

  function displayExploreLink(): boolean {
    const config = getConfig();
    const eLink: boolean = config.showExplore;
    return eLink ?? false;
  }

  const linkToHome = <NavigationLink currentPath={pathname} search={search} path="/home" linkText="Home" />;
  const linkToAudiences = (
    <NavigationLink currentPath={pathname} search={search} path="/audiences" linkText="Audiences" />
  );
  const linkToExplore = <NavigationLink currentPath={pathname} search={search} path="/explore" linkText="Explore" />;
  const linkToKnowledgeHub = <ExternalNavigationLink externalLink={getKnowledgeHubLink()} linkText="Knowledge Hub" />;
  const linkToAdmin = <NavigationLink currentPath={pathname} search={search} path="/admin" linkText="Admin" />;
  const linkToProfiles = <NavigationLink currentPath={pathname} search={search} path="/profiles" linkText="Profiles" />;

  return (
    <nav>
      <div className="nav-bar-left">
        <div className="nav-heading">
          <HeadingLogo />
        </div>
        <ul className="nav-menu">
          <li>{linkToHome}</li>
          <li>{linkToAudiences}</li>
          <li>{linkToProfiles}</li>
          {exploreAccess && displayExploreLink() && <li>{linkToExplore}</li>}
          {displayKnowledgeHubLink() && <li>{linkToKnowledgeHub}</li>}
          {isAdmin && <li>{linkToAdmin}</li>}
        </ul>
      </div>
      <div className="auth-container">
        <AuthResponse NotificationMessages={user?.messages} />
      </div>
    </nav>
  );
}
