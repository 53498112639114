import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import ConfigurationHelper from "../../helpers/configuration-helper";

export async function getAudienceOverview(friendlyId: string): Promise<IAudienceOverview | null> {
  try {
    const response = await getWithToken<IAudienceOverview>(
      ConfigurationHelper.GetSingleAudienceOverviewEndpoint(friendlyId),
    );

    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
}
