"use strict";
/*
This helper exists to address an error made during a CAB board review in which a claim called "audience_tool_groups" was requested
and then modified to connekd_app_groups, but then the actual change erroneously implemented the original group name.
Due to the need to release, this helper allows the app to function against either group name. Ideally it will be removed when connekd_app_groups
has been the established claim name, as audience_tool_groups is a fairly generic name and risks a conflict with another app
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApplicationGroups = void 0;
const expectedGroupKeys = ["connekd_app_groups", "audience_tool_groups"];
const getGroupClaims = (accessToken) => {
    if (!accessToken) {
        // There is no access token and therefore no claims to retrieve
        return;
    }
    const allClaimsWithData = expectedGroupKeys.filter((key) => accessToken[key]);
    if (allClaimsWithData.length === 0) {
        // There is an access token but no claim regarding groups
        return;
    }
    const groupClaimKey = allClaimsWithData[0];
    if (allClaimsWithData.length === expectedGroupKeys.length) {
        console.warn(`Multiple audience tool group claims were populated - this indicates Okta has multiple claims for the same data. Proceeding with the first populated ${groupClaimKey}`);
    }
    const claimData = accessToken[groupClaimKey];
    if (!Array.isArray(claimData)) {
        console.warn(`Claims data of the format ${JSON.stringify(claimData)} did exist, however it was not an array. Proceeding without a group claim`);
        return;
    }
    return claimData;
};
const filterTestGroups = (audienceToolGroups, useTestGroups) => audienceToolGroups.filter((group) => {
    const groupName = group.toLowerCase();
    const testGroupName = "test";
    return useTestGroups ? groupName.includes(testGroupName) : !groupName.includes(testGroupName);
});
const sortGroups = (audienceToolGroups) => audienceToolGroups.sort((a) => a.length);
const getApplicationGroups = (accessToken, useTestGroups = null) => {
    let audienceToolGroups = getGroupClaims(accessToken);
    if (!audienceToolGroups) {
        return;
    }
    audienceToolGroups = useTestGroups == null ? audienceToolGroups : filterTestGroups(audienceToolGroups, useTestGroups);
    return sortGroups(audienceToolGroups);
};
exports.getApplicationGroups = getApplicationGroups;
