import type { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";

export class EmptyCondition extends QueryCondition {
  public readonly NAME: string = "EmptyCondition";

  isParentOf(): boolean {
    return false;
  }

  getValidationState(): ConditionValidationState {
    return new ConditionValidationState(false, "Please drag a condition onto the editor");
  }

  getConditionData(): AudienceDefinitionData {
    throw new Error("Cannot get condition data for the special empty condition type");
  }
}
